import { useEffect, useState } from "react";
import { useAccount } from "contexts/accountContext"

export type AccountFeatureKeysProps = {
    key: string,
    name: string
}

export const useMenuState = (state: string) => {
    const { account, loading } = useAccount();
    const [callToAction, setCallToAction] = useState<boolean>(false);

    useEffect(() => {
        if (account && account.accountFeatureKeys.some(({key}: AccountFeatureKeysProps) => key === state)) {
            setCallToAction(false);
        } else {
            setCallToAction(true);
        }
    }, [account, state])

    return {
        callToAction,
        loading
    }
}