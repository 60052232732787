import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import Input from 'components/Elements/Input/Input';
import InputSelect from 'UI/atoms/InputSelect/InputSelect';

const ArticleFormFeatures = (props: any) => {
	const {
		data,
		setData,
		pushDataValidation,
		dataValidations,
		formName = 'article-form-features'
	} = props;
	const { t }: any = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const validationsObj: IValidationsObject = {
		color: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		},
		treatment: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		},
		print: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		}
	};

	const {
		formFields,
		onInputChangeExt,
		validations,
		controlledByParent,
		onChange
	} = useFormFields(
		{
			color: data?.color || '',
			treatment: data?.treatment || '',
			print: data?.print || ''
		},
		validationsObj
	);

	useEffect(() => {
		if (data) {
			controlledByParent(data);
		}
	}, [data]);

	const updateData = (key: string, value: any) => {
		if (data && setData) {
			const d = { ...data };
			d[key] = value;
			setData(d);
		}
	}

	const onInput = async (event: any, key: string) => {
		onInputChangeExt(event, key);
		const value = event.target.value;
		updateData(key, value);
	};

	const selectChange = (key: string, value: any) => {
		onChange(key, value);
		updateData(key, value);
	}

	return (
		<>
			<h4 className="text-uppercase font-sfpro-medium" style={{ color: '#E0A75E', letterSpacing: '0.5px', fontWeight: 'normal', fontSize: '14px' }}>
				{t('article-features')}
			</h4>
			<div className="row pl-3 pr-3 pb-3">
				<div className="col-12 col-md-6">
					<Input
						type="text"
						placeholder={t('color')}
						invalid={!validations.color.valid}
						value={formFields.color}
						className=""
						onChange={(e: any) => {
							onInput(e, 'color');
						}}
						labelText={t('color')}
						feedbackMessage={t(validations.color.feedbackMessage)}
						labelClassName=""
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputSelect
						placeholder={t('treatment')}
						labelText={t('treatment')}
						onChange={(value: any) => {selectChange('treatment', value)}}
						defaultValue={{label: data?.treatment}}
						values={[
							{ value: 'treatment-1', label: 'treatment 1' },
							{ value: 'treatment-2', label: 'treatment 2' }
						]}></InputSelect>
				</div>
				<div className="col-12 col-md-6 mt-md-2">
					<InputSelect
						placeholder={t('print')}
						labelText={t('print')}
						onChange={(value: any) => {onChange('print', value)}}
						top={true}
						defaultValue={{label: data?.print}}
						values={[
							{ value: 'print-1', label: 'print 1' },
							{ value: 'print-2', label: 'print 2' }
						]}></InputSelect>
				</div>
			</div>
		</>
	);
};
export default ArticleFormFeatures;
