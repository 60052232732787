import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import Input from 'components/Elements/Input/Input';

import './ArticleMaterialInput.scss';
import InputSelect from 'UI/atoms/InputSelect/InputSelect';

const ArticleMaterialInput = (props: any) => {
	const { showLabels, onClickRemove, last } = props;
	const { t }: any = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const validationsObj: IValidationsObject = {
		material: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		},
		percentage: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		}
	};

	const { formFields, onInputChangeExt, validations, onChange } = useFormFields(
		{
			material: props.material,
			percentage: props.percentage
		},
		validationsObj
	);

	const onClickRemoveBtn = () => {
		onClickRemove();
	};

	const onInput = async (event: any, key: string) => {
		onInputChangeExt(event, key);
		const value = event.target.value;
		props.onInputChange(key, value);
	};

	const selectChange = (key: string, value: any) => {
		onChange(key, value);
		props.onInputChange(key, value);
	};

	return (
		<>
			{showLabels && 
				<div className="row">
					<div className="col-4 col-md-5">
						<label className="input-label">{t('material')}</label>
					</div>
					<div className="col-3 col-md-3">
						<label className="input-label">{t('percentage')}</label>
					</div>
					<div className="col-3 col-md-2 text-center">
						<label className="input-label">{t('weight')}</label>
					</div>
				</div>}
			<div className="row article-material-input">
				<div className="col-4 col-md-5 pr-0 mr-0">
					<InputSelect
						placeholder={t('material')}
						onChange={(data: any) => {
							selectChange('material', data);
						}}
						// labelText={showLabels ? t('material') : ''}
						// labelClassName="input-label"
						top={last}
						defaultValue={props.material != '' ? { label: props.material } : null}
						values={[
							{ value: 'material-1', label: 'material 1' },
							{ value: 'material-2', label: 'material 2' },
							{ value: 'material-3', label: 'material 3' },
							{ value: 'material-4', label: 'material 4' },
							{ value: 'material-5', label: 'material 5' }
						]}></InputSelect>
				</div>
				<div className="col-3 col-md-3 pr-0 mr-0">
					<Input
						type="text"
						placeholder={t('percentage')}
						invalid={!validations.percentage.valid}
						value={formFields.percentage}
						className=""
						onChange={(e: any) => {
							onInput(e, 'percentage');
						}}
						// labelText={showLabels ? t('percentage') : ''}
						feedbackMessage={t(validations.percentage.feedbackMessage)}
						labelClassName="input-label"
					/>
				</div>
				<div className="col-3 col-md-2 pr-0 mr-0">
					<div className="flex-fill d-flex flex-column align-items-center">
						{/* {showLabels && <label className="input-label">{t('weight')}</label>} */}
						<span
							className="mt-2"
							style={{ fontSize: '16px', lineHeight: '26px', color: '#B5B5B5' }}>
							0,51 Kg
						</span>
					</div>
				</div>
				<div className="col-1 col-md-1 mr-0 pr-0 d-flex justify-content-center align-items-center  cursor-pointer ">
					<div
						className="border-left pl-3"
						onClick={() => {
							onClickRemoveBtn();
						}}>
						<span
							className="icon-icon-trash"
							style={{ fontSize: '22px', color: 'black' }}></span>
					</div>
				</div>
			</div>
		</>
	);
};
export default ArticleMaterialInput;
