import React, { useEffect, useRef, useState } from 'react';
import { useTable } from 'contexts/tableContext';
import './searchBarModal.scss';

interface Props {
	searchText: any;
	setSearchText: any;
}

const SearchBarModal = (props: Props) => {
	const { searchText, setSearchText } = props;

	const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (setSearchText) setSearchText(e.target.value);
	};

	const cleanSearch = () => {
		setSearchText('');
	};

	return (
		<form className="search-bar-modal d-flex align-items-center mb-3 mt-1">
			<div className="d-flex align-items-center">
				<span className={`icon-icon-search font-20px mr-2`}></span>
			</div>
			<input
				className="search-input font-sfpro-regular"
				type="text"
				placeholder="Search properties"
				value={searchText}
				onChange={handleSelect}
			/>
			{searchText && (
				<div
					onClick={cleanSearch}
					className="icon-close text-dark-60 d-flex align-items-center cursor-pointer"
				>
					<span className="icon-icon-close font-10px m-2"></span>
				</div>
			)}
		</form>
	);
};

export default SearchBarModal;
