/* eslint-disable */
import React from "react";
import { useProjects } from "contexts/projectsContext";
import { useTable } from "contexts/tableContext";
import TableMetrics from "UI/Template/Table";
import { ModalViewAction } from "UI/organism/ModalViewAction";
import IndexDashboard from "UI/organism/Dashboards/IndexDashboard";
import { useMenuState } from "hooks/useMenuState.hook";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { SpinnerLoader } from "components/Common/Loader/Loader";

const EvaluationArticles = () => {
    const { isDashboard } = useProjects();
    const { callToAction, loading } = useMenuState('eco_score');
	const {  modalViewActionSelected  } = useTable();

    if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

    if (callToAction) {
        return <InformationNavigation keyName="index-upgrade" />
    }

	return (
        <>
        { !isDashboard
        ? <>
            <TableMetrics />
            {modalViewActionSelected && <ModalViewAction />}
            </>
        : <IndexDashboard />
        }
        </>
	);
};

export default EvaluationArticles;

