import React from "react";
import { Highlighted } from "../../atoms/Highlighted/index";
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import useRow from "hooks/useRow";

interface Props {
	id?: string;
	indicator?: string;
	copy: string;
	uppercase?: boolean | undefined;
	bold?: boolean | undefined;
	color: string;
	link?: string;
	extraCss?: string;
	searchColumnsText?: string;
}

const EXCLUDE_COLUMNS = ["certificatesTraceabilityMaterials", "certificates-name"]

const BodyText = (props: Props) => {
	const {
		id,
		indicator = "",
		copy,
		uppercase = false,
		bold = false,
		color = "80", // Color options: 40, 60, 80, 100
		link = "",
		extraCss,
		searchColumnsText,
	} = props;

	const { standarizateName } = useRow()

	const tooltip_id = "B" + id?.replace(/[^A-Z0-9]/ig, "_") + indicator;
    const tooltipId = tooltip_id;
    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

	const handleOpenLink = (e:any) => {
		link && window.open(link);
        e.stopPropagation();
	};

	const text = copy || copy == '0' ? copy : "--"

	const handleMouseAction = (e: any, value: boolean) => {
		if (e.currentTarget.children[0].offsetWidth < e.currentTarget.children[0].scrollWidth) {
            setIsTooltipOpen(value);
        }
    }

	const convertWordCapitalize = (text: string) => {
		if (typeof text === 'string') {

			return standarizateName(text);
		}

		return text;
	}

	return (
		<>
			<span
				id={tooltipId}
				onMouseEnter={(e) => handleMouseAction(e,true)}
				onMouseLeave={(e) => handleMouseAction(e,false)}
				className={`d-flex font-14px text-overflow-ellipsis overflow-hidden text-dark-${color} ${bold ? "font-sfpro-medium" : "font-sfpro-regular"} ${uppercase ? "text-uppercase" : "text-title-sentence"} ${link ? "text-underlined" : ""} ${extraCss ? extraCss : ""}`}
				onClick={(e) => handleOpenLink(e)}
			>
				<Highlighted text={text} highlight={searchColumnsText} />
			</span>
			<SimpleTooltip
				tooltipId={tooltipId}
				tooltipOpen={isTooltipOpen}
				text={copy}
				styles={{ width: 'max-content', textAlign: 'start' }}
			/>
		</>
	);
};

export default BodyText;
