import React, { useState } from 'react';
import Toggle from 'components/Common/toggle/toggle';
import './moduleSection.scss';
import { Environment } from 'types/eCommerceCustomization';
import { useTranslation } from 'react-i18next';
import InputSelect from 'UI/atoms/InputSelect/InputSelect';
import { EcommerceTag } from 'UI/atoms/EcommerceTag';

const ORDER_VALUES = [
	{ label: '1', value: 0 },
	{ label: '2', value: 1 },
	{ label: '3', value: 2 },
];
interface OnToggleParameters {
	option: string;
	value: boolean;
	type?: string;
	property?: string;
}

interface Props {
	moduleTitle: string;
	moduleDescription: string;
	enabled: boolean;
	displayed: boolean;
	order: number;
	handleCardsOrder: (order: number) => void;
	handleTabOrder?: (tab: string, prevOrder: number, order: number) => void;
	onToggle: (parameters: OnToggleParameters) => void;
	open?: boolean;
	environment?: Environment;
}

const ModuleSection = ({
	moduleTitle,
	moduleDescription,
	enabled,
	displayed,
	order,
	handleCardsOrder,
	onToggle,
	open = false,
	environment,
	handleTabOrder,
}: Props) => {
	const { t } = useTranslation();
	const [openModule, setOpenModule] = useState(open);

	return (
		<>
			<div
				className="row p-2 d-flex justify-content-between align-items-end mb-2"
				style={{ margin: '0', borderBottom: '1px solid #75747F' }}
			>
				<div className="d-flex flex-column">
					<p className="font-sfpro-medium font-14px text-dark-100 mb-1">
						{moduleTitle}
					</p>
					<span className="font-sfpro-regular font-14px text-dark-60 py-1">
						{moduleDescription}
					</span>
				</div>
				<span className="d-flex align-items-center">
					<Toggle
						onChange={(value: boolean) =>
							onToggle({ option: 'enabled', value })
						}
						checked={enabled}
						className="ml-2"
					/>
					<span
						onClick={() => setOpenModule(!openModule)}
						className={`icon-icon-chevron-down`}
						style={{
							fontSize: '24px',
							transition: 'all 0.5s ease',
							transform:
								openModule && enabled
									? 'rotate(180deg)'
									: 'rotate(0deg)',
							opacity: enabled ? '1' : '0',
							cursor: enabled ? 'pointer' : 'auto',
						}}
					/>
				</span>
			</div>
			<div
				className={`d-flex flex-column row sub-menu ${
					openModule && enabled ? 'opened' : ''
				} flex-nowrap mb-3`}
				style={{
					margin: '0',
					height: `${openModule && enabled ? '' : '0'}`,
				}}
			>
				{environment && handleTabOrder && (
					<div className="row w-100 d-flex flex-column border-bottom mx-0 pl-2 mb-1">
						<div
							className="row w-100 d-flex align-items-center py-2"
							style={{ margin: '0' }}
						>
								<p className="col-md-4 font-sfpro-regular font-14px text-dark-100 m-0 pl-0">
									{t('unitary-impact')}
								</p>
								<div className="col-md-2">
									<Toggle
										onChange={(value: boolean) =>
											onToggle({
												type: 'tab',
												property: 'main',
												option: 'enabled',
												value,
											})
										}
										checked={!!environment.main.enabled}
										disabled={!enabled}
										className=""
										/>
								</div>
								<div className="col-md-2">
									<InputSelect
										customSection
										labelClassName="clickable"
										placeholder={t('common-order')}
										values={ORDER_VALUES}
										defaultValue={
											ORDER_VALUES[environment.main.order]
										}
										disabled={!enabled}
										onChange={({ value }) =>
											handleTabOrder(
												'main',
												environment.main.order,
												value
											)
										}
										/>
								</div>
							<EcommerceTag />
						</div>
						<div
							className="row w-100 d-flex align-items-center py-2"
							style={{ margin: '0' }}
						>
							<p className="col-md-4 font-sfpro-regular font-14px text-dark-100 m-0 pl-0">
								{t('impact-equivalences')}
							</p>
							<div className="col-md-2">
								<Toggle
									onChange={(value: boolean) =>
										onToggle({
											type: 'tab',
											property: 'equivalences',
											option: 'enabled',
											value,
										})
									}
									checked={!!environment.equivalences.enabled}
									disabled={!enabled}
									className=""
								/>
							</div>
							<div className="col-md-2">
								<InputSelect
									customSection
									labelClassName="clickable"
									placeholder={t('common-order')}
									values={ORDER_VALUES}
									defaultValue={
										ORDER_VALUES[
											environment.equivalences.order
										]
									}
									disabled={!enabled}
									onChange={({ value }) =>
										handleTabOrder(
											'equivalences',
											environment.equivalences.order,
											value
										)
									}
								/>
							</div>
						</div>
						<div
							className="row w-100 d-flex align-items-center pt-2 mb-3"
							style={{ margin: '0' }}
						>
							<p className="col-md-4 font-sfpro-regular font-14px text-dark-100 m-0 pl-0">
								{t('unitary-benchmark')}
							</p>
							<div className="col-md-2">
								<Toggle
									onChange={(value: boolean) =>
										onToggle({
											type: 'tab',
											property: 'savings',
											option: 'enabled',
											value,
										})
									}
									checked={!!environment.savings.enabled}
									disabled={!enabled}
									className=""
								/>
							</div>
							<div className="col-md-2">
								<InputSelect
									customSection
									labelClassName="clickable"
									placeholder={t('common-order')}
									values={ORDER_VALUES}
									defaultValue={
										ORDER_VALUES[environment.savings.order]
									}
									disabled={!enabled}
									onChange={({ value }) =>
										handleTabOrder(
											'savings',
											environment.savings.order,
											value
										)
									}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="row w-100 d-flex align-items-center mb-1 mt-1 mx-0">
						<p className="col-md-4 font-sfpro-regular font-14px text-dark-100 mt-2 pl-2">
							{t('common-open-by-default')}
						</p>
						<div className="col-md-2">
							<Toggle
								onChange={(value: boolean) =>
									onToggle({ option: 'displayed', value })
								}
								checked={displayed}
								disabled={!enabled}
								styles={{ marginLeft: '5px' }}
								/>
						</div>
					<EcommerceTag />
				</div>
				<div className="row w-100 d-flex align-items-center ml-0">
					<p className="col-md-6 font-sfpro-regular font-14px text-dark-100 m-0 pl-2">
						{t('common-order')}
					</p>
					<div className="col-md-2 ml-1">
						<InputSelect
							customSection
							labelClassName="clickable"
							placeholder={t('common-order')}
							values={ORDER_VALUES}
							defaultValue={ORDER_VALUES[order]}
							disabled={!enabled}
							onChange={({ value }) => handleCardsOrder(value)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ModuleSection;
