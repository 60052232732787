import React from "react";
import BodyCopyIcon from "UI/atoms/BodyCopyIcon/Index";
import BodyDownloadIcon from "UI/atoms/BodyDownloadIcon/Index";
import BodyImageLink from "UI/atoms/BodyImageLink/Index";
import BodyText from "UI/atoms/BodyText";

interface Props {
	copy: string,
	uppercase?: boolean | undefined,
	bold?: boolean | undefined,
	color?: string,
	link: string,
	alt?: string,
	width: string,
	height: string,
	id?: string,
	file: any, 
	fileName: string, 
	fileType: string,
	icon: string,
}

const ItemCopyDownload = (props: Props) => {
	const { copy, uppercase, bold, color='', link, alt, file, fileName, fileType, icon, id='', width, height } = props;

	return (
		<div className="d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden justify-content-between">
			{!alt && <BodyText copy={copy} uppercase={uppercase} bold={bold} color={color} link={link} extraCss={'mr-5'}/>}
			{alt && <BodyImageLink link={link} alt={alt} width={width} height={height} extraCss={'mr-5'}/>}
			<BodyCopyIcon textToCopy={copy}  extraCss={'mr-5 font-18px'}/>
			<BodyDownloadIcon projectId={id} file={file} fileName={fileName} fileType={fileType} icon={icon} extraCss={'mr-5'} />
		</div>
	);
};

export default ItemCopyDownload;
