import Crud, { ICrudListResponse } from "./Crud";
import ARTICLES_DATA from '../../mock/articles.json';

export default class Filter extends Crud {
    constructor() {
        super("filters");
    }

    async categoriesByProject(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}`);
    }

    async filterArticles(projectId: string, dataQuery: any): Promise<ICrudListResponse> {
        return await this.post(`/${projectId}`, { dataQuery: dataQuery }, this.requestConfig);
    }

    async filterMaterials(projectId: string, dataQuery: any): Promise<ICrudListResponse> {
        return await this.post(`/material/${projectId}`, { dataQuery: dataQuery }, this.requestConfig);
    }

    async filterSuppliers(projectId: string, dataQuery: any): Promise<ICrudListResponse> {
        return await this.post(`/supplier/${projectId}`, { dataQuery: dataQuery }, this.requestConfig);
    }

    async filterCei(projectId: string, dataQuery: any): Promise<ICrudListResponse> {
        return await this.post(`/cei/${projectId}`, { dataQuery: dataQuery }, this.requestConfig);
    }

    async filterArticlesMock(): Promise<any> {
        return ARTICLES_DATA;
    }

    public getSource() {
        return this.source;
    }

}