import React, { useEffect, useState } from 'react';
import { EcodesignInitSchema, IEcodesignRequest, initialReq } from './types/ecodesign';
import EcodesignInit from './EcodesignInit';
import EcodesignFormWrapper from './EcodesignFormWrapper';
import EcodesignResults from './EcodesignResults';
import EcodesignCompareForm from './EcodesignCompareForm';

const Ecodesign = () => {
    const [ req, setReq ] = useState<IEcodesignRequest>(initialReq);
	const [ unitsSelected, setUnitsSelected ] = useState<string[]>([]);
	const [ ecoResults, setEcoResults ] = useState<any | undefined>();
	const [ currentFormStep, setCurrentFormStep ] = useState<string>('form');
	const formSteps = ['form', 'comparison', 'results']
	const initialOptions = {
        category: [],
        itemA: {
            process: [],
            origin: []
        },
        itemB: {
            process: [],
            origin: []
        }
    }
	const [ dataOptions, setDataOptions ] = useState<any>(initialOptions);
    const [ isLoading, setIsLoading ] = useState(false);

	useEffect(() => {
        if (currentFormStep === 'results') {
			setIsLoading(true);
			setTimeout(() => {
				setIsLoading(false);
			}, 1000);
        }
	}, [currentFormStep]);

	return (
		<>
			<div className="d-flex h-100 pb-2" style={{ overflowX: 'auto'}}>
				<div className="col mb-3" style={{ minWidth: '600px' }}>
					<EcodesignFormWrapper request={req} setRequest={setReq} units={unitsSelected} setUnits={setUnitsSelected} steps={formSteps} currentStep={currentFormStep} setStep={setCurrentFormStep} ecoResults={ecoResults} setEcoResults={setEcoResults} dataOptions={initialOptions} setDataOptions={setDataOptions} initialOptions={initialOptions}>
						{currentFormStep === 'form' && <EcodesignInit request={req} setRequest={setReq} units={unitsSelected} setUnits={setUnitsSelected} />}
						{currentFormStep !== 'form' && <EcodesignCompareForm request={req} setRequest={setReq} schema={EcodesignInitSchema.stage} dataOptions={dataOptions} setDataOptions={setDataOptions} setEcoResults={setEcoResults} />}
					</EcodesignFormWrapper>
				</div>
				{/* <div id="EcoResults" className="col mb-3" style={{ minWidth: '600px' }}> */}
				<div className="col mb-3" style={{ minWidth: '600px' }}>
					<EcodesignFormWrapper request={req} setRequest={setReq} units={unitsSelected} setUnits={setUnitsSelected} steps={formSteps} currentStep={'results'} setStep={setCurrentFormStep} ecoResults={ecoResults} setEcoResults={setEcoResults} dataOptions={initialOptions} setDataOptions={setDataOptions} initialOptions={initialOptions} results>
						{currentFormStep !== 'results' &&
							<div className="d-flex flex-column flex-wrap w-100 h-100 text-center justify-content-center align-content-center">
								<img src="../img/ecocalculator.png" style={{ width: '60%'}} />
							</div>
						}
						{currentFormStep === 'results' && <EcodesignResults ecoResults={ecoResults} request={req} units={unitsSelected} isLoading={isLoading}/>}
					</EcodesignFormWrapper>
				</div>
			</div>
		</>
	);
};

export default Ecodesign;

