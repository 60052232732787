import React, { useEffect, useState } from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Button, ModalFooter, ModalHeader } from 'reactstrap';
import { APP_MODALS } from '../../../constants';
import ModalHelp from '../ModalHelp';
import ArticlesSearchSelector from 'components/Elements/ArticlesSearchSelector/ArticlesSearchSelector';
import Tabs from 'components/Elements/Tabs/Tabs';

import './ModalAddMaterialsToArticles.scss';
import useFormFields from 'hooks/formFields.hook';
import InputField from 'components/Elements/Input/Input';
import InputSelect, { IInputSelectOption } from 'UI/atoms/InputSelect/InputSelect';

const MaterialAddInput = (props: any) => {
	const { t } = useTranslation();
	const { initValue } = props;

	const values: Array<IInputSelectOption> = [
		{
			label: 'MAterial 1',
			value: 'material-1'
		}
	];

	const selectChange = (value: any) => {
		props.onInputChange(value);
	};

	return (
		<div className="w-100 mb-3 d-flex flex-row align-items-center">
			<div className="flex-grow-1 mr-2">
				<InputSelect
					placeholder={t('common-select') + ' ' + t('materials')}
					values={values}
					onChange={(data: any) => {
						selectChange(data);
					}}
					defaultValue={initValue}
				/>
			</div>
			<span
				className="icon-icon-close cursor-pointer"
				onClick={() => {
					props.onClickRemove();
				}}
			/>
		</div>
	);
};

const MaterialAddForm = (props: any) => {
	const { t } = useTranslation();
	const { selectedMaterials } = props;
	const [materials, setMaterials]: any = useState(selectedMaterials);

	const onClickAdd = () => {
		const m: any = {}; // New material;
		setMaterials([...materials, m]);
		props.setSelectedMaterials([...materials, m]);
	};

	const onClickRemove = (m: any, index: number) => {
		const n: Array<any> = [...materials];
		n.splice(index, 1);
		setMaterials(n);
		props.setSelectedMaterials(n);
	};

	const onInputChange = (value: any, index: number) => {
		const n: Array<any> = [...materials];
		n[index] = value;
		setMaterials(n);
		props.setSelectedMaterials(n);
	};

	const inputHtml: any = [];
	materials.forEach((m: any, index: number) => {
		inputHtml.push(
			<MaterialAddInput
				key={index}
				initValue={m}
				onClickRemove={() => {
					onClickRemove(m, index);
				}}
				onInputChange={(newValue: any) => {
					onInputChange(newValue, index);
				}}
			/>
		);
	});

	return (
		<div className="w-100">
			<div className="p-3 d-flex flex-column">
				<div className="mt-2">
					<button
						className="btn btn-outline-primary w-100 d-flex flex-row justify-content-center align-items-center"
						onClick={() => {
							onClickAdd();
						}}>
						<span className="icon-icon-add mr-2"></span>
						{t('forms-addMaterials')}
					</button>
				</div>
				<div className="mt-3">
					<label
						className="font-sfpro-medium"
						style={{ color: '#75747F', fontSize: '18px', lineHeight: '26px' }}>
						{t('material')}
					</label>
					<div className="w-100">{inputHtml}</div>
				</div>
			</div>
		</div>
	);
};

const MaterialAdditionalForm = (props: any) => {
	const { t } = useTranslation();
	const { data, validations } = props;

	const onChange = (key: string, value: any) => {
		props.onInputChange(key, value);
	};

	return (
		<div className="mt-3 pb-3">
			<div>
				<InputSelect
					placeholder={t('category')}
					labelText={t('category')}
					labelClassName="input-label"
					onChange={(data: any) => {
						onChange('category', data);
					}}
					defaultValue={data.category}
					values={[
						{ value: 'category-1', label: 'category 1' },
						{ value: 'category-2', label: 'category 2' }
					]}></InputSelect>
			</div>
			<div className="mt-2">
				<InputSelect
					placeholder={t('supplier')}
					labelText={t('supplier')}
					labelClassName="input-label"
					defaultValue={data.supplier}
					onChange={(data: any) => {
						onChange('supplier', data);
					}}
					values={[
						{ value: 'supplier-1', label: 'supplier 1' },
						{ value: 'supplier-2', label: 'supplier 2' }
					]}></InputSelect>
			</div>
			<div className="mt-2">
				<InputField
					type="text"
					placeholder={t('totalWeight')}
					value={data.totalWeight}
					onChange={(e: any) => {
						const value = e.target.value;
						onChange('totalWeight', value);
					}}
					className=""
					labelText={t('totalWeight')}
					labelClassName="input-label"
				/>
			</div>
			<div className="mt-2">
				<InputField
					type="text"
					placeholder={t('color')}
					value={data.color}
					onChange={(e: any) => {
						const value = e.target.value;
						onChange('color', value);
					}}
					className=""
					labelText={t('color')}
					labelClassName="input-label"
				/>
			</div>
			<div className="mt-2">
				<InputSelect
					placeholder={t('print')}
					labelText={t('print')}
					labelClassName="input-label"
					defaultValue={data.print}
					onChange={(data: any) => {
						onChange('print', data);
					}}
					values={[
						{ value: 'print-1', label: 'print 1' },
						{ value: 'print-2', label: 'print 2' }
					]}></InputSelect>
			</div>
			<div className="mt-2">
				<InputSelect
					placeholder={t('treatment')}
					labelText={t('treatment')}
					labelClassName="input-label"
					defaultValue={data.treatment}
					onChange={(data: any) => {
						onChange('treatment', data);
					}}
					values={[
						{ value: 'treatment-1', label: 'treatment 1' },
						{ value: 'treatment-2', label: 'treatment 2' }
					]}></InputSelect>
			</div>
		</div>
	);
};

const ModalAddMaterialsToArticles = (props: any) => {
	const { showModal, setShowModal, data } = props;
	const { t }: any = useTranslation();
	const [showModalHelp, setShowModalHelp] = useState('');
	const [selectedArticles, setSelectedArticles] = useState([]);
	const [selectedMaterials, setSelectedMaterials] = useState([]);
	const [additionalNum, setAdditionalNum] = useState(0); // Número que se ve en la tab; Son los inputs que se han modificado; 

	// Additional data
	const { formFields, onInputChangeExt, validations, onChange } = useFormFields(
		{
			category: '',
			supplier: '',
			totalWeight: '',
			color: '',
			print: '',
			treatment: ''
		},
		{}
	);

	const onAdditionDataInputChange = (key: any, value: any) => {
		onChange(key, value);
		let n = 0; 
		for(const k in formFields) {
			if (formFields[k] != '') {
				n++; 
			}
		}
		setAdditionalNum(n);
	};

	useEffect(() => {
		if (data && data.items) {
			const items = data.items;
			setSelectedArticles(items);
		}
	}, [data]);

	const onClickHelp = () => {
		setShowModalHelp('help-modal');
	};

	const modalHeader = (props: any) => {
		return (
			<ModalHeader className="custom-modal-header">
				<div className="d-flex flex-row justify-content-center align-items-center">
					<span>{t('forms-addMaterials')}</span>
					<span
						onClick={() => {
							onClickHelp();
						}}
						className="cursor-pointer ml-2 icon-icon-help-circle"
						style={{ color: '#C2C0C0' }}></span>
				</div>
				<div className="close" onClick={props.handleClose} title={t('common-close')} />
			</ModalHeader>
		);
	};

	const onClickSave = () => {
		console.log("ITEMS", selectedArticles);
		console.log("ADDITIONAL DATA", formFields);
		console.log("SELECED MATERIALS", selectedMaterials);
	}

	const modalFooter = (props: any) => {
		return (
			<ModalFooter className="d-flex justify-content-between">
				<Button
					color="secondary"
					className="d-flex justify-content-center"
					onClick={props.handleClose}>
					{t('common-cancel')}
				</Button>
				<Button
					color="primary"
					className="d-flex justify-content-center"
					onClick={() => {onClickSave()}}>
					{t('common-save')}
				</Button>
			</ModalFooter>
		);
	};

	const helpText =
		'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint laudantium ab autem corporis quo nisi provident eius, id iusto atque ratione error voluptates aperiam quis sapiente, optio totam ex officia!';
	const tabs = [
		{
			header: t('common-description'),
			headerExtra: additionalNum,
			content: (
				<MaterialAdditionalForm
					data={formFields}
					validations={validations}
					onInputChange={onAdditionDataInputChange}
				/>
			)
		},
		{
			header: t('materials'),
			headerExtra: selectedMaterials.length,
			content: (
				<MaterialAddForm
					selectedMaterials={selectedMaterials}
					setSelectedMaterials={setSelectedMaterials}
				/>
			)
		},
	];

	return (
		<Modal
			modal={showModal}
			setModal={setShowModal}
			className="modal-add-materials-to-articles page-selector-modal "
			scrollable
			title={t('help')}
			size="lg"
			modalName={APP_MODALS.modalNewMaterial}
			FooterComponent={modalFooter}
			HeaderComponent={modalHeader}
			backdrop={false}>
			<>
				<div className="container-fluid h-100">
					<div className="row h-100">
						<div className="col-12 col-md-6 bg-white pt-3">
							<ArticlesSearchSelector defaultValues={selectedArticles} />
						</div>
						<div className="col-12 col-md-6 pt-4">
							<Tabs tabs={tabs} />
						</div>
					</div>
				</div>
				<ModalHelp
					helpText={helpText}
					showModal={showModalHelp}
					setShowModal={setShowModalHelp}
				/>
			</>
		</Modal>
	);
};
export default ModalAddMaterialsToArticles;
