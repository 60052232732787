import React from "react";
import { PieChart, Pie, Cell } from "recharts";

interface PieChartDashboardInterface {
    centerInformation: any,
    datas: any[],
    values: number [],
    width?: number,
    customStyle?: any,
    cornerRadius?: number,
    tooltip?: boolean
}

export const PieChartDashboard = ({centerInformation, datas, values, width = 150, customStyle, cornerRadius = 0, tooltip = false}: PieChartDashboardInterface) => {
    const axes = (width / 2) - 5
    const startAngle = 90;
    const endAngle = -270

    const dataFormat: any = [];

    values?.forEach((value, index) => {
        dataFormat.push({
            name: datas[index],
            value: typeof value === 'string' ? parseFloat(value) : value
        })
    })

    const totalValue = () => (dataFormat.map((item: any) => item.value).reduce((a: any, b: any) => a + b, 0))

    return (
        <section className={`position-relative ${tooltip ? 'tooltipCustom' : ''}`} style={customStyle}>
            <PieChart width={width} height={width}>
                <Pie
                    data={[{ value: totalValue() }]}
                    cx={axes}
                    cy={axes}
                    innerRadius={axes - 10}
                    outerRadius={axes + 5}
                    cornerRadius={cornerRadius}
                    fill="#F0F0F2"
                    paddingAngle={0}
                    dataKey="value"
                    stroke=""
                    startAngle={startAngle}
                    endAngle={endAngle}
                ></Pie>
                <Pie
                    data={dataFormat}
                    cx={axes}
                    cy={axes}
                    innerRadius={axes - 10}
                    outerRadius={axes + 5}
                    cornerRadius={cornerRadius}
                    fill="#F0F0F2"
                    paddingAngle={0}
                    dataKey="value"
                    isAnimationActive={true}
                    stroke=""
                    startAngle={startAngle}
                    endAngle={endAngle}
                >
                    {datas.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
            {centerInformation}
        </section>
    )
}