import React from "react"
import "./NextPreviousPage.scss"
import { useProjects } from "contexts/projectsContext";
import { useTable } from 'contexts/tableContext';


function NextPreviousPage(){
    const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
    const {firstPage, prevPage, nextPage, lastPage} = useTable()
  
    const handlePaginate = (page:string) => {
        if( openModalSaveChanges.customView ){
            setOpenModalSaveChanges({
                ...openModalSaveChanges,
                open: true
            })
        }else{
            switch(page){
                case 'firstPage': firstPage()
                break
                case 'prevPage': prevPage()
                break
                case 'nextPage': nextPage()
                break
                case 'lastPage': lastPage()
                break
            }
        }
    }

    return(
        <div className="pagination-div">
            <button className="pagination-btn" onClick={() => handlePaginate('firstPage')}><span className="icon-icon-chevron-doble-left"></span></button>
            <button className="pagination-btn" onClick={() => handlePaginate('prevPage')}><span className="icon-icon-chevron-left"></span></button>
            <button className="pagination-btn" onClick={() => handlePaginate('nextPage')}><span className="icon-icon-chevron-right"></span></button>
            <button className="pagination-btn" onClick={() => handlePaginate('lastPage')}><span className="icon-icon-chevron-doble-right"></span></button>
        </div>
    )
}

export default NextPreviousPage