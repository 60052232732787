import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import PageLoader, { SpinnerLoader } from 'components/Common/Loader/Loader';
import './InfiniteList.scss';
import { isEven } from 'utils/utils';



export interface Props {
	[key: string]: any | any[];
}

const InfiniteList: FunctionComponent<Props> = (props: Props) => {
	const {
		items,
		schema,
		isLoading,
		entityName,
		total,
		totalFound,
		fetchMoreData,
		ListItem,
		selectionMode,
		selectedItems,
		setSelectedItems,
		onClickListItem,
		onHoverListItem,
		onMouseLeaveListItem,
		striped
	} = props;

	const { t }: any = useTranslation();
	
	if (isLoading) {
		return (
			<Row>
				<Col xs="12">
					<PageLoader />
				</Col>
			</Row>
		);
	}
	return (
		<>
			{items.length === 0 && (
				<div className={'results-text'}>
					0 {entityName} {t('common-not-found')}
				</div>
			)}

			{items.length > 0 && (
				<InfiniteScroll
					dataLength={items.length}
					next={fetchMoreData}
					hasMore={total > totalFound}
					loader={<SpinnerLoader />}
					scrollThreshold={0.5}
					scrollableTarget="scroll-list-wrapper">
					{items.map((item: any, index: any) => (
						<ListItem
							key={item.id}
							entityName={entityName}
							item={item}
							schema={schema}
							onClick={onClickListItem}
							onHover={onHoverListItem}
							onLeave={onMouseLeaveListItem}
							selectionMode={selectionMode} 
							selectedItems={selectedItems} 
							setSelectedItems={setSelectedItems}
							striped={striped == true && isEven(index)}
						/>
					))}
				</InfiniteScroll>
			)}

			{totalFound > 0 && totalFound >= total && (
				// <div className="results-text">{t('common-end-of-results')}</div>
				<div className="results-text"></div>
			)}

			{totalFound < total && (
				<div className="results-text" style={{ marginTop: 10 }}>
					{t('common-scroll-for-more')}
				</div>
			)}
		</>
	);
};

export default InfiniteList;
