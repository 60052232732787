import React from "react";
import HeaderColumn from "UI/atoms/HeaderColumn";
import HeaderColumnUnderlined from "UI/atoms/HeaderColumnUnderlined";
import SubheaderColumnArrows from "UI/molecules/SubheaderColumnArrows";
import { useTable } from 'contexts/tableContext';
import { ITableSchemaColumn } from "UI/Template/Table/types/TableTypes";
import { EDataTypes } from "UI/Template/Table/types/TableEnums";


const TableHeader = () => {
	const { displayColumns, viewColumnsSchema, columnsList } = useTable();

	return (
		<thead>
			<tr>
				{displayColumns?.map((header: any) => {
					let numberSubColumns;

					for (let i = 0; i < viewColumnsSchema?.length; i++) {
						if (header.label === viewColumnsSchema[i]?.group) {
							numberSubColumns = viewColumnsSchema[i]?.metric.length;
						}
					}
					return (
						<React.Fragment key={header.label}>
							{header.label !== "checklist" ? (
								<HeaderColumnUnderlined
									headerTitle={header.label}
									color={header.color}
									numberSubColumns={numberSubColumns}
								/>
							) : (
								<HeaderColumn key={header.label} headerTitle={header.label} numberSubColumns={numberSubColumns} />
							)}
						</React.Fragment>
					);
				})}
			</tr>
			<tr>
				{columnsList?.map((subHeader: ITableSchemaColumn) => {
					if ((subHeader.dataType === EDataTypes.i) || (subHeader.dataType === EDataTypes.si)) {
						return (
							<React.Fragment key={subHeader.id}>
								<SubheaderColumnArrows
									textSubTitle={subHeader.label}
									columnId={subHeader.id}
									metricName={subHeader.metricName}
								/>
							</React.Fragment>
						)
					} else {
						return (
							<React.Fragment key={subHeader.id}>
								<SubheaderColumnArrows
									indicator={subHeader.indicator}
									stage={subHeader.stage}
									metricUnit={subHeader.metricUnit}
									dataType={subHeader.dataType}
									columnId={subHeader.id}
									metricName={subHeader.metricName}
								/>
							</React.Fragment>
						)
					}
				})}
			</tr>
		</thead>
	);
};

export default TableHeader;
