import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Points from 'UI/atoms/Points/Index';
import ArticleDetail from 'UI/molecules/ArticleDetail';
import { getPoints } from 'utils/IndexQualifications';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';
import { IProps } from '../types/ranking';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import './Row.scss'

const ARTICLE_HIGH_SCORE = 'high';
const ARTICLE_MEDIUM_SCORE = 'medium';
const ARTICLE_LOW_SCORE = 'low';
const TOTAL_COLUMNS = 12
const TRACEABILITY = 'traceability'
const BEST_ARTICLES = 'bestArticles'

const Ranking = (props: IProps) => {
	const { data, product } = props;
	const { t } = useTranslation();

	const rankingOrder: any = {
		evaluation: {
			group1: ['Planet', 'People'],
			group2: ['Transparency', 'Circularity'],
		},
		metrics: {
			group1: [
				'Savings_Water_Relative',
				'Savings_ClimateChange_Relative',
			],
			group2: [
				'Savings_Eutrophication_Relative',
				'Savings_FossilFuels_Relative',
			],
		},
	};

	interface SectionWrapProps {
		sectionGroup: string[];
	}

	const SectionWrap = ({ sectionGroup }: SectionWrapProps) => {
		return (
			<div className="d-flex col flex-wrap px-0">
				{sectionGroup.map((group) => (
					<Section group={group} data={data[group]} />
				))}
			</div>
		);
	};

	return (
		<div className="d-flex w-100 flex-wrap justify-content-around">
			<SectionWrap sectionGroup={rankingOrder[product]['group1']} />
			<SectionWrap sectionGroup={rankingOrder[product]['group2']} />
		</div>
	);
};

export default Ranking;


interface CircleColorProps {
	articleGroupName: string;
}

const CircleColor = ({ articleGroupName }: CircleColorProps) => {
	const color: { [key: string]: string } = {
		bestArticles: '#527800',
		worstArticles: '#B44D49',
	};

	return (
		<div
			className="mr-2"
			style={{
				backgroundColor: color[articleGroupName],
				borderRadius: '50px',
				width: '8px',
				height: '8px',
				minWidth: '8px',
			}}
		></div>
	);
};

interface RowProps {
	article: any;
	group: string;
	articleGroupName: string;
	index: number;
	score_param?: string;
}

const Row = ({ article, group, articleGroupName, index, score_param }: RowProps) => {
	const { t } = useTranslation()
	const h = useHistory();
	const path = h.location.pathname;
	const product = path.split('/')[1];
	const scope = path.split('/')[2];
	const value = Number(article[group]);
	const points = getPoints(value, group);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const score = article[score_param ?? ''];

	const tooltipId = `${articleGroupName}-${group}-${index}`;

	const traceabilityProduct = () => product === TRACEABILITY || scope === 'cei'

	const isZeroNegative = (zero: number) => {
		const isZero = zero === 0;
		const isNegative = 1 / zero === -Infinity;
		return isNegative && isZero;
	}

	const perCent = value * 100;
	const toggleTextByPerCent = Math.round((perCent + Number.EPSILON) * 100) / 100;
	const textByPerCent = isZeroNegative(toggleTextByPerCent) ? 0 : toggleTextByPerCent;

	const columnByProduct = traceabilityProduct() ? 7 : 8
	const calculateColumnByProduct = TOTAL_COLUMNS - columnByProduct

	return (
		<tbody>
			<tr
				className="row overflow-hidden"
				style={{
					borderBottom: '1px solid #D6D6D7',
					// maxWidth: '300px',
				}}
			>
				<td
					className={`col-${columnByProduct} d-flex align-items-center align-middle text-overflow-ellpsis overflow-hidden`}
					style={{ height: '55px' }}
				>
					{
						product !== 'traceability' &&
						<CircleColor articleGroupName={articleGroupName} />
					}
					<ArticleDetail
						name={article.name}
						reference={article.reference}
						imageUrl={article.imageUrl}
						id={article.id}
						styles="mr-2"
					/>
				</td>
				<td
					className={`col-${calculateColumnByProduct} ${product} ${product}-${score} text-center d-flex align-items-center`}
					onMouseEnter={() => setTooltipOpen(true)}
					onMouseLeave={() => setTooltipOpen(false)}
				>
					{(product === 'metrics' && scope !== 'cei') && (<span id={tooltipId}>{`${formatNumberNoDecimals(textByPerCent)} %`}</span>)}
					{product === 'evaluation' && (
						<Points
							value={points}
							styles={'lower-case text-dark-100'}
						/>
					)}
					{
						traceabilityProduct() &&
						<>
							<span id={tooltipId}>{`${formatNumberNoDecimals(textByPerCent)}%`}</span>
							<div className='traceability-status'>{ score }</div>
						</>
					}
				</td>
				{
					product === 'metrics' &&
					(
						<SimpleTooltip
							text={
								textByPerCent === 0
									? t('dashboard-tooltips-neutro')
									: textByPerCent > 0
									? (score_param === 'MCI_A_score' ? t('dashboard-cei-tooltips-positive') : t('dashboard-tooltips-positive'))
									: t('dashboard-tooltips-negative')
							}
							tooltipId={tooltipId}
							tooltipOpen={tooltipOpen}
						/>
					)
				}
			</tr>
		</tbody>
	);
};

export const Table = ({ articleGroup, group, articleGroupName, score }: any) => (
		<table
			key={`ranking-${articleGroupName}`}
			id={`ranking-${group}`}
			className="p-3"
			style={{ tableLayout: 'fixed' }}
		>
			{articleGroup.map((article: any, index: number) => (
				<Row
					article={article}
					group={group}
					articleGroupName={articleGroupName}
					index={index}
					score_param={score}
				/>
			))}
		</table>
);

const HeaderSection = ({ sectionName }: { sectionName: string }) => {
	const { t } = useTranslation()
	return (
		<>
			<section className="row pb-3">
				<div className="col-8 font-14px text-black-100">
					{t(`ranking-title-${sectionName}`)}
				</div>
			</section>
		</>
	);
};

export const HeaderTable = ({ group, bestArticles, worstArticles, traceability }: any) => {

	const { t } = useTranslation()
	const indexHeaders = [
		'Planet',
		'People',
		'Circularity',
		'Transparency',
	];

	return (
		<div className="row pb-3">
			<div className="col-8 font-14px text-blue-metal-30 text-overflow-ellipsis text-nowrap overflow-hidden">
				{bestArticles && t('dash-ranking-best-articles')}
				{worstArticles && t('dash-ranking-worst-articles')}
			</div>
			<div className="font-14px text-blue-metal-30 text-overflow-ellipsis text-nowrap overflow-hidden">
				{
					traceability
					? t('common-total-score')
					: indexHeaders.includes(group)
					? t('qualification')
					: t('percentage')}
			</div>
		</div>
	);
};

interface SectionProps {
	group: string;
	data: any;
}

const Section = (props: SectionProps) => {
	const { group, data } = props;
	const bestArticles = data.bestArticles[0];
	const worstArticles = data.worstArticles[0];

	return (
		<div
			className="mx-2 col"
			style={{ minWidth: '275px', maxWidth: '600px' }}
		>
			<HeaderSection sectionName={group} />
			<HeaderTable group={group} bestArticles />
			<div>
				<Table
					group={group}
					articleGroupName="bestArticles"
					articleGroup={bestArticles}
				/>
			</div>
			<div className="mt-4 mb-4">
				<HeaderTable group={group} worstArticles />

				<Table
					group={group}
					articleGroupName="worstArticles"
					articleGroup={worstArticles}
				/>
			</div>
		</div>
	);
};
