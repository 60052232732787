import { useTable } from "contexts/tableContext";
import React from "react";
import { useTranslation } from "react-i18next";



export const TableActionsMenuButton = (props: any) => {
    const {label, icon, action} = props
    const { t } = useTranslation();

    return (
        <button className='d-flex align-items-center p-2 dropdown-item' onClick={action}>
            <span className={`font-16px icon-icon-${icon}`}></span>
            <span className='ml-2 font-12px'>{t(label)}</span>
        </button>
    )
}

