import { useTable } from 'contexts/tableContext';
import React from 'react';
import "../../Template/Modals/ModalBase/ModalBase.scss"

import { useTranslation } from 'react-i18next';

export const BodyDeleteView = () => {

    const { selectedView} = useTable()
    const { t } = useTranslation();

    return (
            <>
                {t("deleteView-modal-body")} <span className='text-dark-100 font-sfpro-medium'>{t(selectedView.name)}</span> 
            </>          
    )

};