import React from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';

export const ModalUpgrade = ( props:any ) => {
    const { t } = useTranslation();
    const { 
        upgradeMail, 
        moreInformationLink, 
        actionName, 
        children, 
        isShow,
        handleClose,
        title 
    } = props;

    const showHideClassName = isShow ? "modal-base display-block backdrop-color" : "modal-base display-none";

    const sendMail = () => {
        window.location.assign(`mailto:${upgradeMail}`);
    }
    return <>
            <div className={showHideClassName}>
                <section className="modal-base-main py-5 px-4 d-flex flex-column justify-content-center">
                    <section className="d-flex justify-content-between mb-3 mx-2 text-dark-100 font-24px font-sfpro-medium">
                        <div className="mt-4">{ title }</div>
                        <span 
                            className="icon-icon-close cursor-pointer rounded-08 text-dark-60 text-dark-100-hover" 
                            style={{ fontSize: "15px", height: "20px" }} 
                            onClick={handleClose} 
                        />
                    </section>
                    {children}
                    <div className='d-flex flex-column justify-content-center align-items-center mt-3 font-sfpro-regular'>
                        <button onClick={sendMail} className='btn-action m-1 px-5' style={{
                            width: "fit-content"
                        }}>
                            {actionName}
                        </button>
                        <a href={moreInformationLink} target="_blank" rel="noopener noreferrer" className='text-yellow mt-3 mb-4'>
                            { t("more-information") }
                        </a>
                    </div>
                </section>
            </div>
        </>
}