import { EMetricUnitTypes } from 'UI/Template/Table/types/TableEnums';

export const getSummaryOnePage = (article: any, compo: any): any[] => {
	const articleComposition: string[] = [];
	compo?.composition?.map((c: any) => articleComposition.push(c.name));

	return [
		{ title: 'article', description: [article.name] },
		{ title: 'reference', description: [article.reference] },
		{ title: 'composition', description: articleComposition },
	];
};
export const getOverviewOnePageData = (article: any): any => {
	const tableOverviewData = {
		amount: article.amount,
		Water: article.Water,
		ClimateChange: article.ClimateChange,
		Eutrophication: article.Eutrophication,
		FossilFuels: article.FossilFuels,
		savings_Water_relative: article.Savings_Water_Relative,
		savings_ClimateChange_relative: article.Savings_ClimateChange_Relative,
		savings_Eutrophication_relative:
			article.Savings_Eutrophication_Relative,
		savings_FossilFuels_relative: article.Savings_FossilFuels_Relative,
		savings_Water_absolut: article.Savings_Water,
		savings_ClimateChange_absolut: article.Savings_ClimateChange,
		savings_Eutrophication_absolut: article.Savings_Eutrophication,
		savings_FossilFuels_absolut: article.Savings_FossilFuels,
		Savings_Water: article.Savings_Water,
		Savings_ClimateChange: article.Savings_ClimateChange,
		Savings_Eutrophication: article.Savings_Eutrophication,
		Savings_FossilFuels: article.Savings_FossilFuels,
	};
	return tableOverviewData;
};

export const getEquivalencesOnePage = (): any => {
	return {
		waterKeyName: EMetricUnitTypes.bottles,
		climateChangeKeyName: EMetricUnitTypes.lightBulbs,
		eutroKeyName: EMetricUnitTypes.washingMachine,
		fossilFuelsKeyName: EMetricUnitTypes.carTravelling,

		waterSavingsKeyName: EMetricUnitTypes.bottles,
		climateChangeSavingsKeyName: EMetricUnitTypes.lightBulbs,
		eutroSavingsKeyName: EMetricUnitTypes.washingMachine,
		fossilFuelsSavingsKeyName: EMetricUnitTypes.carTravelling,
	};
};

export const getStagesDataOnePage = (article: any): any[] => {
	const absoluteWater = Math.abs(article.Water);
	const absoluteClimateChange = Math.abs(article.ClimateChange);
	const absoluteEutrophication = Math.abs(article.Eutrophication);
	const absoluteFossilFuels = Math.abs(article.FossilFuels);

	const stagesData = [
		{
			name: 'Water',
			rm: (article.RawMaterial_Water / absoluteWater) * 100,
			mp: (article.MaterialProc_Water / absoluteWater) * 100,
			wp:
				((article.MaterialDFP_Water + article.ArticleDFP_Water) /
					absoluteWater) *
				100,
			manuf: (article.Manufacture_Water / absoluteWater) * 100,
			trans: (article.Transport_Water / absoluteWater) * 100,
			pack: (article.LaDP_Water / absoluteWater) * 100,
		},
		{
			name: 'ClimateChange',
			rm:
				(article.RawMaterial_ClimateChange / absoluteClimateChange) *
				100,
			mp:
				(article.MaterialProc_ClimateChange / absoluteClimateChange) *
				100,
			wp:
				((article.MaterialDFP_ClimateChange +
					article.ArticleDFP_ClimateChange) /
					absoluteClimateChange) *
				100,
			manuf:
				(article.Manufacture_ClimateChange / absoluteClimateChange) *
				100,
			trans:
				(article.Transport_ClimateChange / absoluteClimateChange) * 100,
			pack: (article.LaDP_ClimateChange / absoluteClimateChange) * 100,
		},
		{
			name: 'Eutrophication',
			rm:
				(article.RawMaterial_Eutrophication / absoluteEutrophication) *
				100,
			mp:
				(article.MaterialProc_Eutrophication / absoluteEutrophication) *
				100,
			wp:
				((article.MaterialDFP_Eutrophication +
					article.ArticleDFP_Eutrophication) /
					absoluteEutrophication) *
				100,
			manuf:
				(article.Manufacture_Eutrophication / absoluteEutrophication) *
				100,
			trans:
				(article.Transport_Eutrophication / absoluteEutrophication) *
				100,
			pack: (article.LaDP_Eutrophication / absoluteEutrophication) * 100,
		},
		{
			name: 'FossilFuels',
			rm: (article.RawMaterial_FossilFuels / absoluteFossilFuels) * 100,
			mp: (article.MaterialProc_FossilFuels / absoluteFossilFuels) * 100,
			wp:
				((article.MaterialDFP_FossilFuels +
					article.ArticleDFP_FossilFuels) /
					absoluteFossilFuels) *
				100,
			manuf:
				(article.Manufacture_FossilFuels / absoluteFossilFuels) * 100,
			trans: (article.Transport_FossilFuels / absoluteFossilFuels) * 100,
			pack: (article.LaDP_FossilFuels / absoluteFossilFuels) * 100,
		},
	];
	return stagesData;
};

interface TraceabilityData {
	Km: number;
	UnitsProduced: number;
	Process: number;
	Suppliers: number;
}

export const getTraceabilityData = (article: any): TraceabilityData => {
	const tracData = {
		Km: article.Traceability_Km,
		UnitsProduced: article.amount,
		Process: article.Traceability_Process,
		Suppliers: article.Traceability_Suppliers,
	};

	return tracData;
};
