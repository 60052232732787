import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Points from 'UI/atoms/Points/Index';
import ProgressBarSimple from 'UI/atoms/ProgressBarSimple/Index';
import Qualification from 'UI/atoms/Qualification';
import Score from 'UI/atoms/Score/Index';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { dataGroups } from 'UI/Template/Table/types/DataGroups';
import { getLabelByPoints } from 'utils/IndexQualifications';
import { SUSTAINABILITY_INDEX_TOTAL_SCORES } from 'utils/ProjectResults';
import { BlockData } from '../TableRow';
import { useAccount } from 'contexts/accountContext';

interface IProps {
	title: string;
	description: string;
	styles?: string | undefined;
	value: number;
	metricName: string;
}

const SIcard = (props: IProps) => {
	const { account } = useAccount();
	const { styles, title, description, value, metricName } = props;
	const { t } = useTranslation();
	const scores: any = SUSTAINABILITY_INDEX_TOTAL_SCORES;

	const maxScore = scores[metricName];
	let points = null;
	let qualification = null;
	if (value !== null) {
		points = (value / maxScore) * 10;
		qualification = getLabelByPoints(points);
	}

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const tooltipId = `SICard-${title}`;

	return (
		<div
			className="d-flex flex-column flex-grow-1 box-shadow text-dark-100 bg-white-100 rounded-08 p-4 m-2"
			style={{ minWidth: '220px' }}
		>
			<div className="d-flex flex-column mb-5">
				<span
					className={`font-14px text-dark-100 font-sfpro-regular mb-1 ${
						styles ? styles : ''
					}`}
				>
					{`${t(title)} ${t('dash-index')}`}
				</span>
				<span
					className={`font-12px text-dark-60 font-sfpro-regular ${
						styles ? styles : ''
					}`}
				>
					{t(description)}
				</span>
			</div>
			<div>
				{
					!account?.accountFeatureKeys.some(({key}: {key: string}) => key === 'eco_score')
					? <div className='d-flex justify-content-center'><BlockData /></div>
					: <>
						<div className="d-flex flex-row justify-content-between mb-2">
							<span className="font-14px text-dark-100 font-sfpro-regular">
								{t('ecoScore')}
							</span>
							<div
								id={tooltipId}
								onMouseEnter={() => setIsTooltipOpen(true)}
								onMouseLeave={() => setIsTooltipOpen(false)}
							>
								<Qualification label={qualification} />
							</div>
							<SimpleTooltip
								text={t(`score-${qualification}-description`)}
								tooltipId={tooltipId}
								tooltipOpen={isTooltipOpen}
								styles={{
									maxWidth: '250px',
									width: 'auto',
									textAlign: 'start',
								}}
							/>
						</div>
						<div className="d-flex flex-row justify-content-between">
							<span className="font-14px text-dark-100 font-sfpro-regular">
								Qualification
							</span>
							<Points id={metricName} value={points} lowerCase />
						</div>
						<div className="mt-3">
							<a
								className="text-decoration-color-yellow text-yellow-hover"
								href="https://drive.google.com/file/d/1989zLnuwZk4_mAjd2Lg5y71ny74m2T27/view?usp=sharing"
								target='_blank'
								rel='noopener noreferrer'
								style={{ textDecoration: "underline" }}
							>
								<span className="text-yellow">{t('download-certificates')}{' '}</span>
								<span className="text-yellow icon-icon-new-window font-14px"></span>
							</a>
						</div>
					</>
				}
			</div>
		</div>
	);
};

export default SIcard;
