import React from 'react';
import './checkbox.scss';

const Checkbox = (props: any) => {
	const { label, checked, onChange, className, disabled=false } = props;

	const onCheckboxChange = () => {
		if (onChange && !disabled) {
			onChange(!checked);
		}
	};

	return (
		<label className={`custom-checkbox-container d-flex flex-row justify-content-start align-items-center ${className} ${disabled ? "custom-checkbox-container-disabled" : ""}`}>
			<input
				type="checkbox"
				value={checked}
				checked={checked}
				onChange={onCheckboxChange}
			/>
			<span className="checkmark d-flex justify-content-center align-items-center"></span>
			{label && (
				<span
					style={{ marginLeft: "25px", fontSize: "15px" }}
					className={`${checked ? "label-active" : ""}`}
				>
					{label}
				</span>
			)}
		</label>
	);
};

export default Checkbox;
