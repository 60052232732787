import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './InputSelect.scss';
import { useOutsideAlerter } from 'hooks/useOutsideAlerter.hook';

export interface IInputSelectOption {
	label: string;
	value: any;
	disabled?: boolean;
	selected?: boolean;
}

interface InputSelectProps {
	defaultValue?: any;
	placeholder: string | any;
	labelText?: string | any;
	labelClassName?: string;
	values: Array<IInputSelectOption>;
	valueSelected?: string | any | undefined;
	setValueSelected?: any | undefined;
	top?: boolean;
	optionsStyle?: any;
	customSection?: boolean;

	valueType?: 'object' | 'string'; // De que tipo son los values;
	valueKey?: string; // La key del objeto para mostrar en caso de que los values sean object;

	onChange?(value: any): any;
	children?: any;
	disabled?: boolean;
}

export const InputOption = (props: any) => {
	const {
		value,
		label,
		disabled = false,
		selected = false,
		onClickOption,
	} = props;

	const onClick = () => {
		onClickOption(props);
	};

	return (
		<div
			className="pt-2 pb-2 pl-2 cursor-pointer option"
			onClick={() => onClickOption()}
		>
			{label}
		</div>
	);
};

const InputSelect = (props: InputSelectProps) => {
	const { t } = useTranslation();
	const {
		defaultValue,
		placeholder,
		onChange,
		valueSelected,
		labelText,
		labelClassName,
		values = [],
		top = false,
		optionsStyle,
		disabled = false,
		customSection = false,
	} = props;
	const [showOptions, setShowOptions] = useState(false);
	const [selectedValue, setSelectedValue]: any = useState(valueSelected);
	const wrapperRef = useRef(null);
	const dropDownRef = useRef(null);
	const [forceTop, setForceTop] = useState(props.top);
	const [topPosition, setTopPosition] = useState('0px');

	useEffect(() => {
		setSelectedValue(values.find((x) => x.value === valueSelected));
	}, [valueSelected]);
	// Outside click
	useOutsideAlerter(wrapperRef, () => {
		setShowOptions(false);
	});

	useEffect(() => {
		setSelectedValue(defaultValue);

		// Calcular la posición dentro del parent; si es >= 75% del top del parent -> top = true;
		if (wrapperRef && wrapperRef.current) {
			const el: any = wrapperRef.current;
			const elementPosition: any = el.offsetTop;
			const elementParentWidth: any = el.offsetParent.clientHeight;
			if (elementPosition >= (elementParentWidth * 75) / 100) {
				setForceTop(true);
			}
		}
	}, [defaultValue]);

	const calcTopPosition = () => {
		let top = '0px';
		if (dropDownRef.current) {
			const dropdownElement: any = dropDownRef.current;
			const dropdownElementHeight = dropdownElement.clientHeight;
			top = `-${dropdownElementHeight}px`;
			setTopPosition(top);
		}
	};

	useEffect(() => {
		calcTopPosition();
	}, [showOptions]);

	const handleChange = (value: any) => {
		if (onChange && !disabled) onChange(value);
	};

	const onClickInput = () => {
		if (!disabled) setShowOptions(!showOptions);
	};

	const onClickOption = (data: IInputSelectOption) => {
		setSelectedValue(data);
		handleChange(data);
		setShowOptions(false);
	};

	const options: any = [];
	values.forEach((value: IInputSelectOption, index: any) => {
		options.push(
			<InputOption
				{...value}
				key={index}
				onClickOption={() => {
					onClickOption(value);
				}}
			/>
		);
	});

	const showPlaceholder =
		selectedValue == null ||
		selectedValue == '' ||
		(selectedValue && !selectedValue.label);

	return (
		<div
			className={`custom-input-select position-relative ${
				disabled ? 'custom-input-select-disabled' : 'clickable'
			}`}
			ref={wrapperRef}
		>
			{labelText && (
				<label className={`input-label ${labelClassName}`}>
					{t(labelText)}
				</label>
			)}
			<div
				onClick={() => onClickInput()}
				className={`form-control d-flex justify-content-between align-items-center text-overflow-ellipsis overflow-hidden py-0 pl-2 pr-0 ${
					forceTop ? 'top' : ''
				} ${showOptions ? 'open' : ''} ${
					customSection ? 'custom-section' : ''
				}`}
			>
				<label
					className={`mb-0 pb-0 text-overflow-ellipsis overflow-hidden ${
						showPlaceholder ? 'placeholder' : 'value'
					}`}
				>
					{showPlaceholder ? placeholder : selectedValue.label}
				</label>
				<span className="icon-icon-chevron-down font-22px" />
			</div>
			<div
				ref={dropDownRef}
				className={`option-selector ${forceTop ? 'top' : ''}  ${
					showOptions ? '' : 'd-none'
				} ${customSection ? 'custom-section-dropdown' : ''}`}
				style={forceTop ? { top: topPosition } : {}}
			>
				{options}
			</div>
		</div>
	);
};
export default InputSelect;
