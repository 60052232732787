import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

const UNKNOWN = 'unknown'

export const Highlighted = ({ text = '', highlight = '' }) => {
    const { t } = useTranslation()

    const unknownValue = (text: string) => text.toLowerCase().includes(UNKNOWN)

    if (!highlight.trim()) {
        if (typeof text === 'string' && unknownValue(text)) {
            const elements = text.split(', ').map((item: string) => `<span class="${item?.toLowerCase() === UNKNOWN ? 'text-dark-40 text-capitalize' : 'text-dark-100'}">${item}</span>`)

            return <span className={`text-overflow-ellipsis overflow-hidden`} dangerouslySetInnerHTML={{ __html: elements.join(', ') }} />
        }
        return <span className={`text-overflow-ellipsis overflow-hidden ${text === t(UNKNOWN) ? 'text-dark-40' : ''}`} dangerouslySetInnerHTML={{ __html: text }} />
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
        <span className="text-overflow-ellipsis overflow-hidden">
            {parts.filter(part => part).map((part, i) => (
                regex.test(part) ? <span style={{background: "#F0F0F2", borderRadius: "3px"}} key={i}>{part}</span> : <span key={i}>{part}</span>
            ))}
        </span>
    )
}
