/* eslint-disable */
import React from "react";
import { useProjects } from "contexts/projectsContext";
import { useTable } from 'contexts/tableContext';
import TableMetrics from "UI/Template/Table";
import { ModalLateral } from "UI/organism/ModalLateral/Index";
import { ModalViewAction } from "UI/organism/ModalViewAction";
import { SelectorColumnsModal } from "UI/molecules/SelectorColumnsModal/Index";
import { SelectorFiltersModal } from "UI/molecules/SelectorFiltersModal/Index";
import MetricsDashboard from "UI/organism/Dashboards/MetricsDashboard";
import { useMenuState } from "hooks/useMenuState.hook";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { SpinnerLoader } from "components/Common/Loader/Loader";

const LcaArticles = () => {
    const { callToAction, loading } = useMenuState('lca');
    const { isDashboard } = useProjects();
	const { handleCloseColumnSelectorModal, modalViewActionSelected, openColumnSelectorModal, handleCloseFiltersModal, openFiltersModal} = useTable();

    if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

	return (
        <>
            { callToAction ? <InformationNavigation keyName="lca-upgrade" /> :
            !isDashboard ?
            <>
                <TableMetrics />
                {openFiltersModal && <ModalLateral handleClose={handleCloseFiltersModal}>
                    <SelectorFiltersModal title={'Filters'} handleClose={handleCloseFiltersModal} />
                </ModalLateral>}
                {openColumnSelectorModal && <ModalLateral handleClose={handleCloseColumnSelectorModal}>
                    <SelectorColumnsModal title={'Columns'} handleClose={handleCloseColumnSelectorModal} />
                </ModalLateral>}
                {modalViewActionSelected && <ModalViewAction />}
            </>
            : <MetricsDashboard />
        }
       </>
	);
};

export default LcaArticles;
