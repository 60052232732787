import { EIndicators, EStages, ETraceabilityScopes } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const ceiSchema: ITableColumns = {
    // Article scope
    checklist: {
        indicator: EIndicators.checklist,
        stage: EStages.checklist,
        label: 'checklist',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.checklist)
    },
    overview: {
        indicator: EIndicators.overview,
        stage: EStages.overview,
        label: 'overview',
        color: '#',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.overview )
    },
    feedstock: {
        indicator: EIndicators.feedstock,
        stage: EStages.feedstock,
        label: 'feedstock',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.feedstock)
    },
    waste: {
        indicator: EIndicators.waste,
        stage: EStages.waste,
        label: 'waste',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.waste)
    },
    usage_potential: {
        indicator: EIndicators.usage_potential,
        stage: EStages.usage_potential,
        label: 'usage_potential',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.usage_potential)
    },
    saved: {
        indicator: EIndicators.saved,
        stage: EStages.saved,
        label: 'favorite-saved',
        color: '#',
        columns: columnsSchema.filter(x => x.indicator === ETraceabilityScopes.saved)
    },
}

export default ceiSchema;