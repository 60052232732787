import React, { useState, useRef } from "react";
import "./SelectorNumberItemsDisplay.scss";
import { useTable } from "../../../contexts/tableContext";
import useOutsideClick from "hooks/useOutsideClick.hook";
import { useTranslation } from "react-i18next";
import { useProjects } from "contexts/projectsContext";

function SelectorNumberItemsDisplay() {
    const { t } = useTranslation();
	const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const {limitPagination, changePaginationLimit} = useTable();
	const [isShow, setIsShow] = useState(false);
	const ref = useRef<HTMLHeadingElement>(null);
	useOutsideClick(ref, () => { setIsShow(false) });

	const numberArticles = [10, 25, 50, 75, 100];

	const handleShow = () =>{
		if( openModalSaveChanges.customView ){
			console.log(openModalSaveChanges.customView)
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
			setIsShow(!isShow)
		}
	}

	return (
		<>
			<div
				className="d-flex flex-row align-items-center position-relative"
				ref={ref}
			>
				<span className="font-sfpro-regular text-dark-60 font-12px">{t('common-show')}:</span>
				<div
					className="d-flex align-items-center justify-content-around n-articles-displayed cursor-pointer"
					onClick={handleShow}
				>
					<span className="font-sfpro-regular pl-2 font-12px">
						{limitPagination}
					</span>
					<span
						onClick={handleShow}
						className={"icon-icon-chevron-down font-24px"}
						style={{
							transition: "all 0.5s ease",
							transformOrigin: "50% 50%",
							transform: isShow
								? "rotate(180deg)"
								: "rotate(0deg)",
						}}
					/>
				</div>

				{isShow && (
					<div className="dropup-menu show">
						{numberArticles.map((number: number) => (
							<button
								key={number}
								onClick={()=> {setIsShow(false); changePaginationLimit(number) }}
								className={
									number === limitPagination
										? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
										: "dropdown-item"
								}
							>
								{number === limitPagination && (
									<span className="icon-icon-tick mr-1 font-18px text-dark-100"></span>
								)}
								<div
									className={
										number === limitPagination
											? "font-sfpro-medium mr-1"
											: "font-sfpro-regular"
									}
								>
									{number}
								</div>
							</button>
						))}
					</div>
				)}
			</div>
		</>
	);
}

export default SelectorNumberItemsDisplay;
