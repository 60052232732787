import React from "react";
import { useTranslation } from "react-i18next";

type ResultWrapperProps = {
    children: React.ReactNode;
    text: string;
    color: string;
}

type StylesProps = {
    text: string;
    color: string;
}

export const includeStyle = (Component: any, value: number) => {

    return (props: any) => {
        const styles: StylesProps = value > 0 ? {text:"savings", color: "#527800"} : {text: "debts", color: "#B44D49"}
        return <Component {...props} text={styles.text} color={styles.color} />
    }

}

export const ResultWrapperText = ({ children, text, color }: ResultWrapperProps) => {
    const { t } = useTranslation();

    return (
        <p className="m-0">
            {children}
            <span
				className='pl-1 font-sfpro-medium'
				style={{
					color: color
				}}
			>{ t(`lca-benchmark-${text}`) }</span>
        </p>
    )
}

export const ResultWrapperDot = ({ children, text, color }: ResultWrapperProps) => (
    <p className="d-flex align-items-center">
        {children}
        <span
            className="ml-1"
			style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
				background: color,
			}}
		></span>
    </p>
)
