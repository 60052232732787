import { toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorException from '../../services/exception/ErrorException';
import WarningException from '../../services/exception/WarningException';
import SuccessException from '../../services/exception/SuccessException';
import React from 'react';

class Notification {
	currentToast = 0;

	public display(type: TypeOptions, text: string, closeButton?: JSX.Element) {
		let icon;
		let colorIcon;
		if (type === 'success') {
			(icon = 'icon-success'), (colorIcon = '#9A9753');
		}
		if (type === 'warning') {
			(icon = 'icon-warning'), (colorIcon = '#E0A75E');
		}
		if (type === 'error') {
			(icon = 'icon-warning'), (colorIcon = '#B44D49');
		}
		if (type === 'info') {
			(icon = 'icon-info'), (colorIcon = '#1C1C30');
		}

		const closeDefaultButton = (
			<span className="icon-icon-close font-8px Toastify__close-button align-self-center mx-2"></span>
		);

		const button = closeButton ? closeButton : closeDefaultButton;

		const msg = icon ? (
			<div className="d-flex align-items-center">
				<span
					className={`icon-${icon} ml-1 mr-2`}
					style={{ fontSize: '20px', color: colorIcon }}
				></span>
				<span className="d-flex align-items-center font-sfpro-regular font-12px">
					{text}
				</span>
			</div>
		) : (
			<span className="font-sfpro-regular ml-2 font-12px">{text}</span>
		);

		this.currentToast = toast(msg, {
			type: type,
			className: 'border-radius-8',
			position: 'bottom-right',
			hideProgressBar: true,
			autoClose: 5000,
			closeButton: button,
		});
	}

	public displayException(err: ErrorException | WarningException | Error) {
		let type: TypeOptions;
		if (err instanceof ErrorException) {
			type = 'error';
		} else if (err instanceof WarningException) {
			type = 'warning';
		} else if (err instanceof SuccessException) {
			type = 'success';
		} else {
			type = 'error';
		}

		this.display(type, err.message);
	}
}

export default new Notification();
export { ToastContainer as NotificationContainer } from 'react-toastify';
