import React from "react";
import useDownloadFile, { useDownloadQrZip } from "hooks/useDownloadFile.hook";
import { useTable } from "contexts/tableContext";

interface Props {
	projectId?: string,
	file: any, 
	fileName: string, 
	fileType: string,
	icon?: string,
	extraCss?: string,
	text?:string
}

const QrCode = (props: Props) => {
	const { projectId = '', file, fileName, fileType, icon, extraCss, text } = props;
    const { handleLoading } = useTable() || {};
	
	const handleDownloadFile = (e:any) => {
		if (icon === 'download' || text) {		
			useDownloadFile(file, fileName, fileType);
		}
		if (icon === 'zip') {
            handleLoading(true);
			useDownloadQrZip(projectId, fileName, handleLoading);
		}
		e.stopPropagation();
	};

	return (
		<span
			className={`${icon ? `icon-icon-${icon} font-22px` : ""} ${extraCss ? extraCss : ""}`}
			onClick={(e) => handleDownloadFile(e)}
		>{text}</span>
	);
};

export default QrCode;
