import React from "react";
import BodyCopyIcon from "UI/atoms/BodyCopyIcon/Index";
import BodyImageLink from "UI/atoms/BodyImageLink/Index";
import BodyText from "UI/atoms/BodyText";

interface Props {
	copy: string,
	uppercase?: boolean | undefined,
	bold?: boolean | undefined,
	color: string,
	link: string,
	alt?: string,
	width?: string,
	height?: string,
}

const ItemCopy = (props: Props) => {
	const { copy, uppercase, bold, color, link, alt, width='', height='' } = props;

	return (
		<div className="d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden">
			{!alt && <BodyText copy={copy} uppercase={uppercase} bold={bold} color={color} link={link} extraCss={'mr-3'} />}
			{alt && <BodyImageLink link={link} alt={alt} width={width} height={height} extraCss={'mr-3'} />}
			<BodyCopyIcon extraCss='font-18px' textToCopy={copy} />
		</div>
	);
};

export default ItemCopy;
