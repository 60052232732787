import React, { useState, useRef } from 'react';
import { ArticleSelectorDropdown } from 'UI/atoms/DropDown';
import { useHistory } from "react-router-dom";
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { useTranslation } from 'react-i18next';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';

function SelectorArticle({ articleName }:any) {
    const [isShow, setIsShow] = useState(false)
    const ref = useRef<HTMLHeadingElement>(null)
    const h = useHistory();
    const { t } = useTranslation();
    const path = h.location.pathname;
    const articleId = path.split('/')[2]
    const { projectSelected } = useProjects();
    const { validateReferenceType } = useReferenceType(projectSelected);


    const articleClick = (id:string) => {
        h.push(path.replace(articleId, id))
        setIsShow(!isShow)
    }

    const handleOpenDropdown = () => {
        setIsShow(!isShow);
    }


	useOutsideClick(ref, () => { setIsShow(false) });

    return (

        <div className="d-flex project-selector flex-column align-items-start ml-3 mt-2" ref={ref} >
                <div className='text-dark-60 font-sfpro-regular font-12px'>{t(`selector-${validateReferenceType ? 'material': 'article'}`)}</div>
                <div className="position-relative d-flex flex-row nav-link cursor-pointer px-0 mt-1 pt-0">
                    <div className='d-flex align-items-center' onClick={handleOpenDropdown}>
                        <span className="text-dark-100 font-24px font-sfpro-medium text-overflow-ellipsis overflow-hidden">
                            {articleName}
                        </span>
                        <div className={`d-flex align-items-center justify-content-center mt-1 ${isShow ? "bg-gray-01 rounded-08" : ""}`}
                        style={{
                            height: "32px",
                            width: "32px",
                        }}>
                            <span
                                className={`icon-icon-chevron-down text-dark-100 font-30px`}
                                style={{
                                    transition: "all 0.5s ease",
                                    transformOrigin: "50% 50%",
                                    transform: isShow ? "rotate(180deg)" : "rotate(0deg)",
                                }}
                            />
                        </div>
                    </div>
                {isShow &&
                    <ArticleSelectorDropdown articleId={articleId} articleClick={articleClick} />
                }
            </div>
        </div>
    )
}


export default SelectorArticle;