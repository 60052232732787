import React, { useState } from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { APP_MODALS } from '../../constants';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import Input from '../../components/Elements/Input/Input';

import ProjectHttp from 'services/http/Project';
import ArticleHttp from 'services/http/Article';
import InputSelect from 'UI/atoms/InputSelect/InputSelect';

const ModalNewProject = (props: any) => {
	const { showModal, setShowModal } = props;
	const { t }: any = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const validationsObj: IValidationsObject = {
		projectName: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		},
		collectionName: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		},
		category: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		}
	};

	const { formFields, onInputChange, validations, validateForm, onChange } = useFormFields(
		{
			projectName: '',
			category: 'category-1',
			collectionName: ''
		},
		validationsObj
	);

	const submit = async () => {
		setIsLoading(true);
		const isValid = validateForm();
		console.log('FORM IS VALID', isValid);
		if (isValid) {
			const Project = new ProjectHttp();
			const response = await Project.create({name: formFields.projectName});
			if (response && response.status == 201) {
				// TO DO: Success alert;

				// Mockup; 
				try {
					const project = response.data; 
					const Article = new ArticleHttp();
					const n = Math.floor(Math.random() * 10);
					for(let i = 0; i < n; i++) {
						const amount = Math.floor(Math.random() * 100);
						await Article.create({
							name: `article-${i}`,
							reference: `ARTICLE${i}`,
							amount, 
							project: project.id,
						})
					}
				}
				catch (err) {
					console.log(err);
				}

				setShowModal(false);
			}
		}
		//setIsLoading(false);
	};

	return (
		<Modal
			modal={showModal}
			setModal={setShowModal}
			className="page-selector-modal"
			scrollable
			createText={t('forms-saveProject')}
			handleSubmit={submit}
			title={t('forms-newProject')}
			noCancel={false}
			size="sm"
			isLoading={isLoading}
			modalName={APP_MODALS.modalNewProject}>
			<div className="modal-container">
				<div className="col-12">
					<div className="mt-3">
						<Input
							type="text"
							placeholder={t('forms-projectName')}
							invalid={!validations.projectName.valid}
							value={formFields.projectName}
							className=""
							onChange={onInputChange('projectName')}
							labelText={t('forms-projectName')}
							feedbackMessage={t(validations.projectName.feedbackMessage)}
							labelClassName=""
						/>
					</div>
					<div className="mt-3">
						<InputSelect
							placeholder={t('forms-category')}
							onChange={(value: any) => {onChange('category', value)}}
							labelText={t('forms-category')}
							values={[
								{ value: 'category-1', label: 'Category 1' },
								{ value: 'category-2', label: 'Category 2' },
								{ value: 'category-3', label: 'Category 3' },
								{ value: 'category-4', label: 'Category 4' },
								{ value: 'category-5', label: 'Category 5' },
								{ value: 'category-6', label: 'Category 6' }
							]}></InputSelect>
					</div>

					<div className="mt-3 pb-3">
						<Input
							type="text"
							placeholder={t('forms-collectionName')}
							invalid={!validations.collectionName.valid}
							value={formFields.collectionName}
							className=""
							onChange={onInputChange('collectionName')}
							labelText={t('forms-collectionName')}
							feedbackMessage={t(validations.collectionName.feedbackMessage)}
							labelClassName=""
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default ModalNewProject;
