import React from "react";
import "./ModalLateral.scss";


export const ModalLateral = (props: any) => {
	const { handleClose, children, style } = props;

	return (
		<div className="backdrop backdrop-color" onClick={(e) => handleClose(e)}>
			<div className="modal-div d-flex flex-column" style={style}>
				{children}
			</div>
		</div>
	);
};
