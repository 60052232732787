import { useAccount } from 'contexts/accountContext';
import React, { useState } from 'react';

const API_URL = 'https://api.bcome.biz/api/articles/externalApi';

export const ApiDetails = () => {
	const { account } = useAccount();

	return (
		<>
			<CopyField
				title="API Key"
				value={account.apiIntegrationKey ?? 'No API Key'}
			/>
			<CopyField
				title="URL"
				value={`GET ${API_URL}`}
			/>
		</>
	);
};

interface CopyFieldProps {
	title: string;
	value: string;
}

const CopyField = ({ title, value }: CopyFieldProps) => {
	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		let valueToCopy = value;
		if (value.includes('GET')) {
			valueToCopy = value.split(' ')[1];
		}
		navigator.clipboard.writeText(valueToCopy);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 3000)
	};

	return (
		<div className="w-100 py-3 px-2">
			<span className="text-dark-100 font-14px">{title}</span>
			<div className="bg-gray-02 p-3 d-flex flex-row align-items-center justify-content-between mt-3">
				<span className="text-dark-100 font-14px">{value}</span>
				<span className="text-dark-100 text-dark-60-hover font-14px cursor-pointer" onClick={handleCopy}>
					{ !copied ? 'Copy' : 'Copied' }
				</span>
			</div>
		</div>
	);
};
