import React from "react"
import NumberArticlesDisplayed from "UI/atoms/NumberArticlesDisplayed"
import SelectorNumberItemsDisplay from "UI/molecules/SelectorNumberItemsDisplay"
import NextPreviousPage from "../NextPreviousPage"

// interface Props {
//     limitPagination:number
//     setLimitPagination: React.Dispatch<React.SetStateAction<number>>,
//     currentPage: number,
//     totalArticles: number,
//     countCurrentArticles: number,
// }
function PaginationTableFooter(){
    

    return(
    <>
        <SelectorNumberItemsDisplay  />
        <NumberArticlesDisplayed />
        <NextPreviousPage />
    </>
    )
}

export default PaginationTableFooter