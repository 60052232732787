import React from "react";
import { useTable } from "contexts/tableContext";
import { useTranslation } from "react-i18next";

const NoViewsBackground = () => {
    const { t } = useTranslation();
    const {setModalViewActionSelected} = useTable()

	return (
            <div className="m-auto">
                <div className="d-flex justify-content-center" style={{borderRadius: "12px"}}>
                    <img className="p-5" style={{width: "360px"}} src="../img/create_view.gif" alt="" />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center mt-2" style={{height: "128px", width: '480px'}}>
                    <span className="text-dark-60 font-sfpro-regular font-16px mb-3" style={{lineHeight: '32px'}}>{t("no-views-available")}</span>
                    <button className="btn-action font-sfpro-regular" style={{width: "145px"}}
                    onClick={() => setModalViewActionSelected("createView")}>
                        {t("createView")}

                    </button>
                </div>

           </div>
	);
};

export default NoViewsBackground;
