import fp from 'lodash/fp';

export function objToQuery(filter: Record<string, unknown>): string {
  return fp.pipe(
    fp.toPairs,
    fp.filter(([item, value]: [any, any]) => value !== '' && (value === 0 || !!value)),
    fp.map((item: any[]) => fp.join('=')(item)),
    fp.join('&'),
    (str) => `?${str}`
  )(filter);
}

export function queryToObj(search: string) {
  if (!search || search === '') return {};

  return fp.pipe(
    fp.split('?'),
    fp.tail,
    fp.split('&'),
    fp.map((param: string) => param.split('=')),
    fp.map((item: any[]) => decodeURIComponent(item[1])),
    fp.fromPairs
  )(search);
}
