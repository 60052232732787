import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next"

import './EcommerceTag.scss'


export const EcommerceTag = ({children}: {children?: ReactNode}) => {
    const { t } = useTranslation();
    return (
        <div className={`w-100 d-flex justify-content-between align-items-center ${!children ? 'position-absolute r-10' : ''}`}>
            { children ? children : '' }
            <div className="tag">{ t('ecommerce-exclucively') }</div>
        </div>
    )
}