import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BodyText from 'UI/atoms/BodyText';
import { EDataTypes, EMetricNames } from 'UI/Template/Table/types/TableEnums';
import { ITableSchemaColumn } from 'UI/Template/Table/types/TableTypes';
import { useTable } from 'contexts/tableContext';
import columnsSchema from 'UI/Template/Table/schemas/columns.schema';
import { dataGroups } from 'UI/Template/Table/types/DataGroups';
import { useHistory } from 'react-router-dom';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';

import { CustomIcon } from 'UI/atoms/CustomIcon/Index';

interface ISelectorColumnsProps {
	firstValue: string;
	secondValue: string;
	selectorColumnsGroup: any; // (keyof ITableSchemaColumn) [],
	columnsByDataType: any;
	searchColumnsText?: string;
}

const SelectorColumnsRow = (props: ISelectorColumnsProps) => {
	const {
		firstValue,
		secondValue,
		selectorColumnsGroup,
		columnsByDataType,
		searchColumnsText,
	} = props;
	const { columnSelectorColumns, toggleSelectedColumn } = useTable();
	const { t } = useTranslation();
	const h = useHistory();

	const columns = columnsSchema.filter(
		(c: any) =>
			c[selectorColumnsGroup.columnsGroups[0]] === firstValue &&
			c[selectorColumnsGroup.columnsGroups[1]] === secondValue
	);

	const dataTypeOrder = [
		EDataTypes.u,
		EDataTypes.ue,
		EDataTypes.p,
		EDataTypes.pe,
		EDataTypes.r,
		EDataTypes.bar,
	];
	const orderedColumns = dataTypeOrder.map((dT) =>
		columns.filter((c) => c.dataType === dT)
	);

	const someSelected = columns.find((c: ITableSchemaColumn) =>
		columnSelectorColumns?.find((c2: EMetricNames) => c2 === c.id)
	);
	const coloredIcon = dataGroups[secondValue].coloredIcon;

	const path = h.location.pathname;

	const IconSelectorColumn = ({ column, isSelected, allSelected }: any) => {
		const [isShowTooltip, setIsShowTooltip] = useState(false);

		return (
			<>
				<div
					id={`column-${column.id}`}
					onMouseEnter={() => setIsShowTooltip(true)}
					onMouseLeave={() => setIsShowTooltip(false)}
					className={`icon-dataType icon-icon-${column.dataType}${
						isSelected ? '-selected' : ''
					}${allSelected ? '-all' : ''}  ${
						isSelected ? 'text-dark-100' : 'text-dark-60'
					} font-22px cursor-pointer ml-2`}
					style={{ width: '22px' }}
					onClick={() => {
						toggleSelectedColumn(column.id);
					}}
				></div>
				<SimpleTooltip
					text={t(`tooltip-${column.dataType}`)}
					tooltipId={`column-${column.id}`}
					tooltipOpen={isShowTooltip}
					styles={{ maxWidth: '200px', textAlign: 'start' }}
				/>
			</>
		);
	};

	return (
		<div className="d-flex flex-row justify-content-between align-items-center m-2 mt-3 mb-3">			
			<div className="d-flex flex-row align-items-center">
				
				{coloredIcon && (
					<CustomIcon size={22} name={coloredIcon} />
				)}
				<BodyText
					copy={t(`column-row-selector-${secondValue}`)}
					color={someSelected ? '100' : '60'}
					searchColumnsText={searchColumnsText}
				/>
			</div>
			<div className="d-flex flex-row align-items-center">
				{orderedColumns?.map((dTArr) =>
					dTArr.map((c: ITableSchemaColumn) => {
						const isSelected = columnSelectorColumns?.find(
							(c2: EMetricNames) => c2 === c.id
						);
						if (c.isSaving && path.includes('benchmark')) {
							return (
								<IconSelectorColumn
									column={c}
									isSelected={isSelected}
								/>
							);
						}
						if (!c.isSaving && !path.includes('benchmark')) {
							const metricsByDataType = columnsByDataType[
								c.dataType
							].map((c: any) => c.id);
							const metricsNotIncluded = metricsByDataType.filter(
								(metric: any) =>
									!columnSelectorColumns.includes(metric)
							);
							const allSelected =
								metricsNotIncluded.length === 0 ? true : false;

							return (
								<IconSelectorColumn
									column={c}
									isSelected={isSelected}
									allSelected={allSelected}
								/>
							);
						}
					})
				)}
			</div>
		</div>
	);
};

export default SelectorColumnsRow;
