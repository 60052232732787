import React from "react";
import { useProjects } from "contexts/projectsContext";

import SelectorColumnsRow from "../SelectorColumnsRow/Index";
import { useReferenceType } from "hooks/useReferenceType.hook";

const SelectorColumns = (props:any) => {
	const { projectSelected } = useProjects();
	const { toggleSecondGroupValues } = useReferenceType(projectSelected);
	const {  groupingValue, selectorColumnsGroup, secondGroupValues, columnsByDataType, searchColumnsText, enableBenchmarkData } = props;

	return (
		<>
			{toggleSecondGroupValues(secondGroupValues).map((value:any) => {
				return <SelectorColumnsRow
							key={value}
							firstValue={groupingValue}
							secondValue={value}
							selectorColumnsGroup={selectorColumnsGroup}
							columnsByDataType={columnsByDataType}
							searchColumnsText={searchColumnsText}
						/>
			})}
		</>
	)
};

export default SelectorColumns;
