import moment from 'moment';
import { ITableSchema, ITableSchemaColumn } from '../types/TableTypes';

function getViewport() {
	const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	if (width <= 576) return 'xs';
	if (width <= 768) return 'sm';
	if (width <= 992) return 'md';
	if (width <= 1200) return 'lg';
	return 'xl';
}

function getTableWidthByViewport() {
	let ret = 90;
	const viewport = getViewport();
	if (viewport == 'xs') { ret = 75; }
	if (viewport == 'sm') { ret = 80; }
	if (viewport == 'md') { ret = 85; }

	return ret;
}

function getTableTotalWidth(schema: ITableSchema) {
	let ret = 0;
	if (schema.width) {
		if (typeof schema.width == 'number') {
			ret = schema.width;
		}
		else if (typeof schema.width == 'object') {
			const viewport = getViewport();
			ret = schema.width[viewport];
		}
	}
	else {
		ret = getTableWidthByViewport();
	}
	return ret;
}

function getColMaxWidth(column: ITableSchemaColumn): number {
	let ret = 0;
	const maxWidth = column.maxWidth;
	if (typeof maxWidth == "number") {
		ret = maxWidth;
	}
	else if (typeof maxWidth == "object") {
		const viewport = getViewport();
		ret = maxWidth[viewport];
	}
	return ret;
}

/**
 * Calcula el width de las columan;
 * @param schema
 * @returns
 */
function tableCalcBaseColWidth(schema: ITableSchema): number {
	const totalFilters = getTableVisibleColumnsKeys(schema);
	const viewport = getViewport();
	const filters: any[] = [];
	const columns = getTableColumns(schema);
	totalFilters.forEach((filter: string, i: number) => {
		if (columns[filter].breakpoints) {
			if (columns[filter].breakpoints?.indexOf(viewport) != -1){
				filters.push(filter);
			}
		}
		else {
			filters.push(filter);
		}
	})
	return getTableTotalWidth(schema) / filters.length; // 90 porque hay el btn de seleccionar filas;
}

/**
 * Calcula el espacio extra de las colmnas que tienen un maxWidht
 * Este espacio extra se suma a las columnas que no lo tienen definido;
 * @param schema
 * @returns
 */
function tableCalcExtraWidth(schema: ITableSchema): number {
	const cols = getTableColumnsByProperty(schema, 'maxWidth'); // get columnas que tienen un maxWidth definido;
	const totalColsNum = Object.keys(schema.columns).length - cols.length;
	let ret = 0;
	const baseWidth = tableCalcBaseColWidth(schema);
	const tableWidht = getTableTotalWidth(schema);
	cols.forEach((columnKey) => {
		const column = schema.columns[columnKey];
		const f = getColMaxWidth(column);
		const b = tableWidht - f;
		ret += baseWidth * b / tableWidht;
	});
	if (ret > 0 && totalColsNum) { // Para no dividir entre 0;
		ret = ret / totalColsNum;
	}
	return ret;
}

/**
 * Widht de una columna;
 * @param schema
 * @param key la key de la columna,
 * @returns
 */
export function tableGetColWidth(schema: ITableSchema, key: string): number {
	const totalWidth = tableCalcBaseColWidth(schema);
	const field = schema.columns[key];
	let ret = 0;
	if (field.maxWidth) {
		ret = totalWidth * getColMaxWidth(field) / getTableTotalWidth(schema);
	}
	else {
		ret = totalWidth + tableCalcExtraWidth(schema);
	}
	return ret;
}

export const getTableColumnsByProperty = (schema: ITableSchema, prop: string, value?: any): string[] => {
	const ret: string[] = [];

	for (const key in schema.columns) {
		const field: any = schema.columns[key];

		if (value) {
			if (field[prop] == value) {
				ret.push(key);
			}
		}
		else if (field[prop]) {
			ret.push(key);
		}
	}

	return ret;
};

/**
 *
 * @param schema
 * @returns
 */
export const getTableVisibleColumnsKeys = (schema: ITableSchema): string[] => {
	return Object.entries(schema.columns)
		.filter(([key, value]) => value.show)
		.map(([key, value]) => key);
};

export const getTableFilterKeys = (schema: ITableSchema): string[] => {
	return Object.entries(schema.columns)
		.filter(([key, value]) => value.inFilters)
		.map(([key, value]) => key);
};

export const getTableSearchableKeys = (schema: ITableSchema): string[] => {
	return Object.entries(schema.columns)
		.filter(([key, value]) => value.searchable)
		.map(([key, value]) => key);
}

export const getTableColumns = (schema: ITableSchema) => {
	return schema.columns;
}

/**
 *
 * @param schemaProp
 * @param value
 * @returns
 */
export const getFormatedDate = (schemaProp: ITableSchemaColumn, value: Date) => {
	const format = schemaProp['dateFormat'];
	return moment(value).format(format);
};
