import React from "react"
import { Hero } from "UI/atoms/Carousel/Hero";
import { Subtitle } from "UI/atoms/Carousel/Subtitle";
import { Tag } from "UI/atoms/Carousel/Tag";
import { Title } from "UI/atoms/Carousel/Title";
import useCarousel from "./useCarousel.hook";
import { LibraryResource } from "loader/library-loader";
import { CATEGORIES_COLORS } from "../../../constants";

type CarouselProp = {
    data: LibraryResource[]
}

export const Carousel = ({data}: CarouselProp) => {
    const {
        currentIndex,
        showPrev,
        showIndicators,
        goToNextSlide,
        goToPrevSlide,
        slideWidth,
        validateDate
    } = useCarousel(data)

    const translateValue = -currentIndex * slideWidth;

    return (
        <>
            <section className="carousel-container p-2">
                <div className="carousel" style={{ transform: `translateX(${translateValue}px)` }}>
                    {
                        data.map((item: LibraryResource, index: number) => (
                            <a className="text-decoration-none" href={item.url} target="__blank" rel="noopener">
                                <CarouselCard id={`carousel-card-${index}`} key={`carousel-card-${index}`} active={index === currentIndex}>
                                    <CarouselCard.Title text={item.title} />
                                    <CarouselCard.Subtitle text={validateDate(item.createdAt)} tag={<Tag text={item.category} bgColor={CATEGORIES_COLORS[item.category]} />} />
                                    <CarouselCard.Hero image={item.cover} />
                                </CarouselCard>
                            </a>
                        ))
                    }
                </div>
                {
                    showIndicators && (
                        <>
                            <button onClick={goToPrevSlide} className={`control prev ${showPrev ? 'non-click' : ''}`}>
                                <span className="icon-icon-chevron-left"></span>
                            </button>
                            <button onClick={goToNextSlide} className="control next">
                                <span className="icon-icon-chevron-right"></span>
                            </button>
                        </>
                    )
                }
            </section>
        </>
    )
}

const CarouselCard = ({id, active, children}: {id: string, active: boolean, children: any}) => {
    return (
        <article id={id} className={`carousel-article p-3 ${active ? 'active' : ''}`}>
            {children}
        </article>
    )
}

CarouselCard.Title = Title
CarouselCard.Subtitle = Subtitle
CarouselCard.Hero = Hero