import React from "react";
import { Helmet } from "react-helmet-async";

interface HeadInterface {
    title: string;
}

export const Head = ({title}: HeadInterface) => (
    <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" href="/favicon.ico"></link>
        <link rel="manifest" href="/manifest.json"></link>
    </Helmet>
)