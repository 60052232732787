import { useTable } from 'contexts/tableContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Highlighted } from '../Highlighted';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';

interface IDropdownModuleProps {
	grouping?: any | undefined;
	groupingValue: string;
	displayNumItemsSelected?: boolean | undefined;
	children: any;
	searchText: string | undefined;
	groupSearchColumns?: any;
	numItemsSelected?: any;
	filterGroup?: string;
}

export const DropdownModule = (props: IDropdownModuleProps) => {
	const {
		numItemsSelected,
		groupingValue,
		displayNumItemsSelected,
		children,
		searchText,
		groupSearchColumns,
		filterGroup,
	} = props;
	const {
		dataGroupSelectedColumnSelector,
		sectionFilterSelectedToOpen,
	} = useTable();
	const { t } = useTranslation();
	const [openModule, setOpenModule] = useState<boolean>(false);

	const [manualModule, setManualModule] = useState('');

	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);

	// Open/Close modules by search
	useEffect(() => {
		if (searchText) {
			if (groupSearchColumns) {
				if (groupSearchColumns.length === 0) setOpenModule(false);
				else if (
					groupSearchColumns.length !== 0 &&
					(!manualModule || manualModule === 'open')
				)
					setOpenModule(true);
			}
		} else if (manualModule === 'open') {
			setOpenModule(true);
		} else {
			setOpenModule(false);
		}
	}, [groupSearchColumns]);

	useEffect(() => {
		setManualModule('');
		if (searchText) {
			if (groupSearchColumns) {
				if (groupSearchColumns.length === 0) setOpenModule(false);
				else if (groupSearchColumns.length !== 0) setOpenModule(true);
			}
		} else {
			setOpenModule(false);
		}
	}, [dataGroupSelectedColumnSelector]);

	useEffect(() => {
		if (filterGroup === sectionFilterSelectedToOpen) setOpenModule(true);
	}, [sectionFilterSelectedToOpen]);

	const onClick = () => {
		if (openModule) setManualModule('close');
		else setManualModule('open');
		setOpenModule(!openModule);
	};

	const text = t(`toggleColumns-${(validateReferenceType && groupingValue === 'article') ? 'material': groupingValue}`);

	return (
		<>
			<div
				className="title-dropdown-container row p-2 d-flex justify-content-between align-items-center border-bottom-dark-100 m-0 cursor-pointer"
				onClick={onClick}
				style={{ borderColor: '#1c1c30' }}
			>
				<span
					className={`font-14px title-dropdown ${
						openModule
							? 'font-sfpro-medium text-dark-100'
							: 'font-sfpro-regular text-dark-60'
					}`}
				>
					<Highlighted text={text} highlight={searchText} />
					{displayNumItemsSelected && ' (' + numItemsSelected + ')'}
				</span>
				<span
					className={`icon-icon-chevron-down font-22px cursor-pointer ${
						openModule ? 'text-dark-100' : 'text-dark-60'
					}`}
					style={{
						transition: 'all 0.5s ease',
						transform: openModule
							? 'rotate(180deg)'
							: 'rotate(0deg)',
					}}
				/>
			</div>
			{openModule && children}
		</>
	);
};
