import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './lastUpdated.scss';
import { useProjects } from 'contexts/projectsContext';

function LastUpdated() {
	const { projectSelected } = useProjects();
	const lastUpdate = projectSelected?.lastExcelUpload ?? projectSelected?.updatedAt;

	const { t } = useTranslation();

	const [isShow, setIsShow] = useState(false);

	const momentLastUpdate = moment(lastUpdate).format('MMM D, YYYY')

	return (
		<div
			onMouseEnter={() => setIsShow(true)}
			onMouseLeave={() => setIsShow(false)}
			className="font-sfpro-regular last-updated-container mr-5"
		>
			<div className="text-overflow-ellipsis overflow-hidden mr-2">
				{t('lastUpdated')}:
				<span className="last-updated-ago">
					{moment(lastUpdate).fromNow()}
				</span>
			</div>

			{isShow && (
				<div className="last-updated-date w-auto">
					<span className="p-2 text-nowrap">
						{momentLastUpdate}
					</span>
				</div>
			)}
		</div>
	);
}

export default LastUpdated;
