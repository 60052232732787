import ProjectHttp from 'services/http/Project';
import ArticleHttp from 'services/http/Article';
import { formatDate } from 'utils/utils';

const useDownloadFile = (file: any, fileName: string, fileType: string) => {
	fetch(file, {
		method: "GET",
		headers: {
			"Content-Type": fileType, //'image/svg+xml'
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob])); // 1. Create blob link to download
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", fileName);
			document.body.appendChild(link); // 2. Append to html link element page
			link.click(); // 3. Start download
		});
};


export const useDownloadQrZip = (projectId: string, fileName: string, handleLoading: (b:boolean) => void) => {
	const Project = new ProjectHttp();


	if (projectId) {
		const request = Project.downloadZipQR(projectId);

		request
		.then((response) => {
			const blob = response.data;
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link); // 2. Append to html link element page
			link.click(); // 3. Start download
		})
		.then(() => {
			handleLoading(false);
		})
		.catch((err) => console.log(err));
	}
};

export const useDownloadExcel = (projectId: string, projectName: string, handleLoading: (b:boolean) => void) => {
	const Project = new ProjectHttp();

	if (projectId) {
		const request = Project.downloadExcel(projectId, '');
		request
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
			const formatedActualDate = formatDate();
            link.setAttribute('download', `BCome - ${(projectName)} - ${formatedActualDate}.xlsx`);
            document.body.appendChild(link);
            link.click();
		})
		.then(() => {
			handleLoading(false);
		})
		.catch((err) => console.log(err));
	}
};

export const useDownloadSmartLabelsExcel = (projectId: string, projectName: string, handleLoading: (b:boolean) => void) => {
	const Project = new ProjectHttp();

	if (projectId) {
		const request = Project.downloadSmartLabelsExcel(projectId);
		request
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Bcome_${(projectName)}_SL.xlsx`);
            document.body.appendChild(link);
            link.click();
		})
		.then(() => {
			handleLoading(false);
		})
		.catch((err) => console.log(err));
	}
};



export default useDownloadFile;
