export enum ETableTypes {
	pagination = 'pagination', 
	infinite ='infinite'
}
interface IViewports {
    xs: number, 
    sm: number, 
    md: number, 
    lg: number, 
    xl: number,
}

export interface ITableSchemaColumn {
    label: string, // String traducción; 
    type?: 'string' | 'date' | 'number', // Type; Sirve para dar formatos si son numbers || dates;
    show: boolean, // Mostrar en la tabla; 
    component?: any, // Componente custom; Si no se especifica usa el default; 
    dateFormat?: string, // Formato de la fecha si el type == 'date'; 
    inFilters: boolean // Si mostrar como filtro
    filterComponent?: any, //Componente para el filtro; Por defecto == TableFilter;
    searchable?: boolean, // Si se puede buscar; Si es true se añade un input de texto; 
    breakpoints?: string[] // Breakpoints mostrar columna;
    maxWidth?: number | IViewports, // El % de width que puede tener; Es % del total del width de una columna; EJ: Si una columa tiene un width total de 100px y el valor de este campo es 25 el width de la columna será 25px; 
    tableHeaderCssClass?: string, // CSS Claass que se añade a al header de la columna;
    group?: string, // String para agrupar la columna; El valor tiene que estar en columnGroups del schema;
}

export interface ITableSchema {
    width?: number | IViewports, // Width de la tabla; Por defecto es 90;
    tableType?: ETableTypes, 
    height?: number | string, // Max height de la table; Pueden ser px o vh; ej: 35vh;
    paginationLimit?: number,
    itemsStriped?: boolean, // Items strped
    columnGroups?: Array<string>, // Array de string para agrupar columnas; Ver de ejemplo tabla de LCA;
    columns: {
        [key : string] : ITableSchemaColumn
    }
}