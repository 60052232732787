import Crud, { ICrudListResponse } from "./Crud";


export default class Equivalences extends Crud {
    constructor() {
        super("equivalences");
    }

    async getAll(queryString: string): Promise<ICrudListResponse> {
        return await this.get(`?${queryString}`)
    }
}
