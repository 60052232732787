/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageLoader, { SpinnerLoader } from 'components/Common/Loader/Loader';
import ArticleHttp from 'services/http/Article';
import { EProjectResult } from 'utils/ProjectResults';
import { SmartLabelCard } from './SmartLabelCard';
import { SmartLabelCardDetail } from './SmartLabelCardDetail';
import { SmartLabelPlanEssential } from './SmartLabelPlanEssential';
import ConnectionException from 'services/exception/ConnectionException';
import { formatValueFunctionalUnit } from 'utils/utils';
import { Configuration, CustomDataProp, IBlocIds, IBlocs } from './smart-label.interface';

const CIRCULARITY = 'circularity';
const KINGLY_ACCOUNT_ID = '12ccd985-a1cd-4c5c-81aa-d015020ee097'


const TABS: IBlocs = {
	planet: {
		id: IBlocIds.planet,
		label: 'Planet',
		totalScore: 240,
		color: '#773F0F',
		cards: [
			{
				id: EProjectResult.waterUse,
				itemGroup: 'environment',
				label: 'Water',
				metric: 'Water',
				icon: 'icon-icon-water',
				color: '#015594',
			},
			{
				id: EProjectResult.climateChange,
				itemGroup: 'environment',
				label: 'ClimateChange',
				metric: 'ClimateChange',
				icon: 'icon-icon-co2',
				color: '#424242',
			},
			{
				id: EProjectResult.eutrophication,
				itemGroup: 'environment',
				label: 'Eutrophication',
				metric: 'Eutrophication',
				icon: 'icon-icon-po4',
				color: '#887D36',
			},
			{
				id: EProjectResult.fossilFuels,
				itemGroup: 'environment',
				label: 'FossilFuels',
				metric: 'FossilFuels',
				icon: 'icon-icon-abiotic',
				color: '#1F4A53',
			},

			{
				id: EProjectResult.waterGlasses,
				itemGroup: 'equivalences',
				label: 'Equivalences_Water_Relative',
				metric: 'Water',
				eqValue: 15.52,
				icon: 'icon-icon-bottle',
				color: '#015594',
			},
			{
				id: EProjectResult.lightBulbs,
				itemGroup: 'equivalences',
				label: 'Equivalences_ClimateChange_Relative',
				metric: 'ClimateChange',
				eqValue: 50,
				icon: 'icon-icon-bulbs',
				color: '#424242',
			},
			{
				id: EProjectResult.washingMachine,
				itemGroup: 'equivalences',
				label: 'Equivalences_Eutrophication_Relative',
				metric: 'Eutrophication',
				eqValue: 0.185,
				icon: 'icon-icon-washer',
				color: '#887D36',
			},
			{
				id: EProjectResult.carTravelling,
				itemGroup: 'equivalences',
				label: 'Equivalences_FossilFuels_Relative',
				metric: 'FossilFuels',
				eqValue: 0.226,
				icon: 'icon-icon-car',
				color: '#1F4A53',
			},

			{
				id: EProjectResult.waterIntake,
				itemGroup: 'savings',
				label: 'Savings_Water_Relative',
				metric: 'Savings_Water_Relative',
				eqValue: 7.242,
				icon: 'icon-icon-water',
				color: '#015594',
			},
			{
				id: EProjectResult.tree,
				itemGroup: 'savings',
				label: 'Savings_ClimateChange_Relative',
				metric: 'Savings_ClimateChange_Relative',
				eqValue: 2.004,
				icon: 'icon-icon-co2',
				color: '#424242',
			},
			{
				id: EProjectResult.fish,
				itemGroup: 'savings',
				label: 'Savings_Eutrophication_Relative',
				metric: 'Savings_Eutrophication_Relative',
				eqValue: 38.3,
				icon: 'icon-icon-po4',
				color: '#887D36',
			},
			{
				id: EProjectResult.carTravelling,
				itemGroup: 'savings',
				label: 'Savings_FossilFuels_Relative',
				metric: 'Savings_FossilFuels_Relative',
				eqValue: 0.226,
				icon: 'icon-icon-abiotic',
				color: '#1F4A53',
			},
		],
	},
	people: {
		id: IBlocIds.people,
		label: 'People',
		totalScore: 160,
		color: '#0E343C',
		cards: [
			{
				id: EProjectResult.tracPeople,
				itemGroup: 'traceability',
				label: 'Traceability_People',
				icon: 'icon-icon-people',
				color: '#0E343C',
			},
			{
				id: EProjectResult.tracSuppliers,
				itemGroup: 'traceability',
				label: 'Traceability_Suppliers',
				icon: 'icon-icon-manufacturing',
				color: '#0E343C',
			},
		],
	},
	transparency: {
		id: IBlocIds.transparency,
		label: 'Transparency',
		totalScore: 100,
		color: '#BE8F1F',
		cards: [
			{
				id: EProjectResult.tracKm,
				itemGroup: 'traceability',
				label: 'Traceability_Km',
				icon: 'icon-icon-distance',
				color: '#BE8F1F',
			},
			{
				id: EProjectResult.tracProcess,
				itemGroup: 'traceability',
				label: 'Traceability_Process',
				icon: 'icon-icon-process',
				color: '#BE8F1F',
			},
		],
	},
	circularity: {
		id: IBlocIds.circularity,
		label: 'Circularity',
		totalScore: 230,
		color: '#C8B7AF',
		cards: [],
	},
};

const ECO_SCORE_DATA: CustomDataProp[] = Object.values(TABS).map((tab) => {
	return {
		label: tab.label,
		score: tab.totalScore,
	};
})

let configuration: Configuration = {
	traceability: {
	  arrangement: { enabled: true, displayed: false, order: 0 },
	},
	environment: {
	  main: { enabled: true, order: 0 },
	  equivalences: { enabled: true, order: 1 },
	  savings: { enabled: true, order: 2 },
	  arrangement: { enabled: true, displayed: false, order: 1 },
	},
	sustainability: {
	  arrangement: {
		enabled: true,
		displayed: false,
		order: 2,
	  },
	},
	customCss: "",
	customParentCss: "",
  };

const traceabilityFacts = {
	id: 'traceability-facts',
	label: 'traceability-facts',
	cards: [
		{
			id: EProjectResult.tracPeople,
			itemGroup: 'traceability',
			label: 'Traceability_total_suppliers_traced',
			icon: 'icon-icon-circularity'
		},
		{
			id: EProjectResult.tracProcess,
			itemGroup: 'traceability',
			label: 'Traceability_Process',
			icon: 'icon-icon-process'
		},
		{
			id: EProjectResult.tracSuppliers,
			itemGroup: 'traceability',
			label: 'Traceability_Suppliers',
			icon: 'icon-icon-manufacturing'
		},
	]
}

const Article = new ArticleHttp();

const EN = 'en';

const SmartLabels = (props: any) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const addedLang = queryParams.get('lang') ?? 'en';

	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [articleData, setArticleData]: any = useState({});
	const [features, setFeatures] = useState<string[]>([]);
	const articleId = props.match.params.idArticle;
	const [showCardDetail, setShowCardDetail]: any = useState('');
	const [accountName, _] = useState('');
	useEffect(() => {
		setLoading(false);
	}, []);

	useEffect(() => {
		if ( addedLang !== EN ) {
			i18n.changeLanguage(addedLang);
		}
		return () => {
			i18n.changeLanguage(EN);
		  };
	}, [i18n]);

	const getDataMetricsArticleQRCode = () => {
		try {
			const request = Article.metricsArticleQRCode(articleId);

			request.then((req) => {
				if (req.status === 403) throw new ConnectionException('Error load datas!')
				const data = req.data;
				if (data.account && data.account.eCommerceCustomization) {
					configuration = data.account.eCommerceCustomization;
				}
				setArticleData(data);

				if (data['originWarehouseKm']) {
					traceabilityFacts.cards.push({
						id: EProjectResult.tracKm,
						itemGroup: 'traceability',
						label: 'Traceability_Km',
						icon: 'icon-icon-distance'
					})
				}

				// send data to google tag manager
				if (window.dataLayer && data) {
					window.dataLayer.push({
						event: 'smart-label__loaded',
						brand_name: data.account.name,
						article_name: data.name,
						article_ref: data.reference,
					});
				}
			});
		} catch (e) {
			throw new ConnectionException('Error load datas!')
		}
	}

	// Get article data
	useEffect(() => {
		try {
			getDataMetricsArticleQRCode()
		} catch (e) {
			if (e instanceof ConnectionException) {
				setTimeout(() => getDataMetricsArticleQRCode(), 1000)
			}
		}
	}, []);

	// Get plan
	useEffect(() => {
		if (articleData) {
			const acountFeatures = articleData?.accountFeatureKeys ?? [];
			setFeatures(acountFeatures);
		}
	}, [articleData]);

	// Handle open / close tabs
	const handleClicShow = (event: any) => {
		const cardSelected = event;

		if (cardSelected !== CIRCULARITY) {
			setShowCardDetail(cardSelected);
		}
	};
	const handleClicHide = () => {
		setShowCardDetail('');
	};

	let ret = <PageLoader />;

	const validateFeature = (key: string) => features.includes(key);

	if (!loading) {
		ret = (
			// Smartlabel header
			<div style={{ backgroundColor: '#F9F9F9' }}>
				<div className="d-flex flex-row align-items-center" id="sl-0">
					<div className="smartLab-image-wrap mt-2">
						<div
							className="smartLab-image"
							style={{
								backgroundImage: `url(${articleData.imageUrl})`,
							}}
						></div>
					</div>
					<div
						className="d-flex flex-column ml-5 pr-5"
						style={{ width: '100%' }}
					>
						<span className="font-sfpro-medium letter-spacing-normal font-18px text-dark-100 mb-2">
							{t('sustainability-report')}:
						</span>
						<span className="text-dark-60 font-sfpro-regular letter-spacing-normal font-16px">
							{articleData.name}
						</span>
						<span className="text-dark-60 font-sfpro-regular letter-spacing-normal font-10px">
							Ref. {articleData.reference}
						</span>
						{articleData.account && articleData.account.id !== KINGLY_ACCOUNT_ID && (
							<span className="text-dark-60 font-sfpro-regular letter-spacing-normal font-14px mt-3">
								By{' '}
								<span className="gtm-brand-name">
									{articleData.account.name}
								</span>
							</span>
						)}
					</div>
				</div>

				<div
					className="p-3"
					style={{
						zIndex: 10,
						width: '100%',
						maxWidth: '500px',
						backgroundColor: '#F9F9F9',
					}}
				>
					<div>
						{
							showCardDetail === '' && (
								<>
									{
										(validateFeature('plm') && configuration.traceability.arrangement.enabled) && (
											<div
												className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
												style={{
													width: '100%',
													backgroundColor: '#F0F0F2',
												}}
												onClick={() => handleClicShow('traceability-facts')}
											>
												{articleData && (
													<SmartLabelCard
														id={'traceability-facts'}
														color={''}
														value={''}
														score={0}
														label={'traceability-facts'}
														handleClicShow={() => handleClicShow('traceability-facts')}
														noValues
													/>
												)}
											</div>
										)
									}{
										(validateFeature('lca') && configuration.environment.arrangement.enabled) && (
											<div
												className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
												style={{
													width: '100%',
													backgroundColor: '#F0F0F2',
												}}
												onClick={() => handleClicShow('environmentalAssessment')}
											>
												{articleData && (
													<SmartLabelCard
														id={'environmentalAssessment'}
														color={''}
														value={''}
														score={0}
														label={'environmentalAssessment'}
														handleClicShow={() => handleClicShow('environmentalAssessment')}
														noValues
													/>
												)}
											</div>
										)
									}{
										(validateFeature('eco_score') && configuration.sustainability.arrangement.enabled) && (
											<div
												className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
												style={{
													width: '100%',
													backgroundColor: '#F0F0F2',
												}}
												onClick={() => handleClicShow('eco-score')}
											>
												{articleData && (
													<SmartLabelCard
														id={'eco-score'}
														color={''}
														value={''}
														score={0}
														label={'eco-score'}
														handleClicShow={() => handleClicShow('eco-score')}
														noValues
													/>
												)}
											</div>
										)
									}
								</>
							)
						}
						{
							(showCardDetail === 'environmentalAssessment' && validateFeature('lca')) && (
								<div
									key={TABS['planet'].label}
									className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
									style={{
										width: '100%',
										backgroundColor: '#F0F0F2',
									}}
								>
									{articleData && (
										<SmartLabelPlanEssential
											id={TABS['planet'].label}
											color={TABS['planet'].color}
											value={articleData[TABS['planet'].label]}
											score={TABS['planet'].totalScore}
											label={TABS['planet'].label}
											cardItems={TABS['planet'].cards}
											articleMetrics={articleData}
											handleClicHide={handleClicHide}
										/>
									)}
								</div>
						)}
						{
							(showCardDetail === 'traceability-facts' && validateFeature('plm')) && (
									<div
										key={TABS['planet'].label}
										className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
										style={{
											width: '100%',
											backgroundColor: '#F0F0F2',
										}}
									>
										<SmartLabelCardDetail
											id={traceabilityFacts.label}
											color={''}
											value={''}
											score={0}
											label={traceabilityFacts.label}
											cardItems={
												traceabilityFacts.cards
											}
											articleMetrics={articleData}
											handleClicHide={handleClicHide}
											accountName={accountName}
										/>
									</div>
						)}
						{
							(showCardDetail === 'eco-score' && validateFeature('eco_score')) && (
									<div
										key={'eco-score'}
										className="d-flex flex-column justify-content-center p-3 mb-3 rounded-10 box-shadow-4"
										style={{
											width: '100%',
											backgroundColor: '#F0F0F2',
										}}
									>
										<SmartLabelCardDetail
											id={'eco-score'}
											color={''}
											value={''}
											score={0}
											label={'eco-score'}
											cardItems={[]}
											articleMetrics={articleData}
											handleClicHide={handleClicHide}
											accountName={accountName}
											customData={ECO_SCORE_DATA}
										/>
									</div>
						)}
					</div>

					{/* Footer */}
					<div className="d-flex flex-row justify-content-center pt-2">
						<span className="justify-content-center font-sfpro-regular letter-spacing-normal font-14px text-dark-100">
							{t('poweredBy')}
						</span>
						<a className="d-flex" href="https://bcome.biz/radical-transparency/" target="_blank" rel="noopener noreferrer">
							<img
								className="img-fluid ml-1"
								src="../img/bcome-logo1.svg"
								alt="logo-bcome"
								style={{ width: '60px' }}
							/>
						</a>
					</div>
					<div className='w-100 d-flex align-items-center justify-content-center'>
						<span className='font-10px text-dark-60'>{t('fu-based')} {articleData.functionalUnit ? formatValueFunctionalUnit(articleData.functionalUnit, articleData?.category?.type) : '-'}</span>
					</div>
					<div className='w-100 d-flex align-items-center justify-content-center'>
						<span className='font-10px text-dark-60'>{t('version')} {articleData.projectVersion ?? '-'}</span>
					</div>
				</div>
			</div>
		);
		return ret;
	} else {
		return <SpinnerLoader />;
	}
};

export default SmartLabels;
