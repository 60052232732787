import React, { useEffect } from 'react';
import { useProjects } from 'contexts/projectsContext';
import { useHistory } from 'react-router-dom';
import path from 'permissions/roles/AppPaths';
import { useTranslation } from 'react-i18next';
import { useTable } from 'contexts/tableContext';
import { validateReferenceType } from 'utils/utils';

export const TableDashboardSelectorDisabled = () => {
	return (
		<div
			className="d-flex align-items-center text-dark-40"
			style={{ height: '40px' }}
		>
			<span className="icon-icon-dashboard font-20px" />
			<span
				className="mx-3"
				style={{ borderLeft: '1px solid #C6C7C8', height: '40px' }}
			/>
			<div
				className={
					'd-flex align-items-center justify-content-center cursor-pointer rounded-08 text-dark-100-hover bg-gray-01 text-dark-100'
				}
				style={{ height: '32px', width: '32px' }}
			>
				<span className={`icon-icon-table font-20px`} />
			</div>
		</div>
	);
};

const TableDashboardSelector = ({ generalProduct }: { generalProduct: string }) => {
	const h = useHistory();
	const { getSelectedView, handleLoading } = useTable()
	const { isDashboard, setIsDashboard, projectSelected, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();
	const p = h.location.pathname.split('/')[2];
	const generalScope = h.location.pathname.split('/')[3];
	const { t } = useTranslation();

	useEffect(() => {
		if (!h.location.pathname.includes('dashboard')) {
			setIsDashboard(false);
		} else {
			setIsDashboard(true);
			if (p === 'lca') {
				h.push(
					path.metricsLcaDashboard.replace(
						':projectId',
						projectSelected?.id
					)
				);
			}
			if (p === 'sustainabilityindex') {
				h.push(
					path.evaluationSiDashboard.replace(
						':projectId',
						projectSelected?.id
					)
				);
			}
			if (p === 'plm') {
				h.push(
					path.traceabilityDashboard
				)
			}
		}
	}, [h.location.pathname, projectSelected]);

	// Display table and set route for table icon click depending on product selected
	const handleIconTable = () => {
		if( openModalSaveChanges.customView ){
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}else{
			setIsDashboard(false);
			if (p === 'lca') {
				h.push(path.metricsLcaArticles);
			}
			if (p === 'sustainabilityindex') {
				h.push(path.evaluationSiArticles);
			}
			if (p === 'benchmark') {
				h.push(path.metricsBenchmarkArticles)
			}
			if (p === 'plm') {
				let scope = 'article'
				if (validateReferenceType(projectSelected)) {
					scope = 'material'
				}
				if (!generalScope.includes(scope)) {
					getSelectedView(scope)
					handleLoading(true)
					h.push(scope === 'article' ? path.traceability : path.traceabilityMaterials)
				}
			}
			if (p === 'cei') {
				h.push(path.cei);
			}
		}
	};

	// Display dashboard and set route for dashboard icon click depending on product selected
	const handleIconDashboard = () => {
		if (p === "benchmark") {
			setIsDashboard(false)
		} else {
			if( openModalSaveChanges.customView ){
				setOpenModalSaveChanges({
					...openModalSaveChanges,
					open: true
				})
			}else{
				setIsDashboard(true);
				if (p === 'lca') {
					h.push(
						path.metricsLcaDashboard.replace(
							':projectId',
							projectSelected?.id
						)
					);
				}
				if (p === 'sustainabilityindex') {
					h.push(
						path.evaluationSiDashboard.replace(
							':projectId',
							projectSelected?.id
						)
					);
				}
				if (p === 'benchmark') {
					h.push(
						path.metricsBenchmarkArticles
					);
				}

				if (p === 'plm') {
					h.push(
						path.traceabilityDashboard
					)
				}

				if (p === 'cei') {
					h.push(path.ceiDashboard);
				}
			}
		}
	};

	return (
		<div className="d-flex align-items-center">
			{
				p !== 'data-collection' && (
					<div className='d-flex flex-row align-items-center py-3 font-14px' style={{ gap: 8 }}>
						<span>{t('view')}: </span>
						<div onClick={handleIconDashboard} className={`text-dark-100 cursor-pointer text-decoration-hover ${isDashboard ? 'text-decoration-underline' : ''} ${p === 'data-collection' ? 'non-click' : ''}`}>
							{ t('common-dashboard') }
						</div>
						<span className='font-20px' style={{ paddingBottom: 10 }}>.</span>
						<div onClick={handleIconTable} className={`text-dark-100 cursor-pointer text-decoration-hover ${!isDashboard ? 'text-decoration-underline' : ''}`}>
							{ t('list') }
						</div>
					</div>
				)
			}
		</div>
	);
};

export default TableDashboardSelector;
