import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Cell,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';

const LCStagePerImpactIndicatorChart = (props: any) => {
	const { data, labelCopy, validateReferenceType } = props;
	const { t } = useTranslation();

	const customLegend = (props: any) => {
		const { payload } = props;

		const icons: any = {
			rm: 'icon-id-raw-material',
			mp: 'icon-id-material-process',
			wp: 'icon-id-material-wet-process',
			manuf: 'icon-id-manufacturing',
			trans: 'icon-id-transport',
			pack: 'icon-id-packaging',
		};

		return (
			<div className="w-100 mt-3 d-flex justify-content-around align-items-center">
				{payload.map((entry: any, index: number) => (
					<div className="d-flex align-items-center">
						<span
							key={`item-${index}`}
							className={`font-15px mr-1 icon-${
								icons[entry.value]
							}`}
						></span>
						<span className="font-12px text-dark-60">
							{t(`dash-${entry.value}`)}
						</span>
					</div>
				))}
			</div>
		);
	};

	const customTooltip = (props: any) => {
		const { label, payload } = props;

		return (
			<div className="tooltip-dashboard">
				<div className="my-1 text-dark-100">{t(`dash-${label}`)}</div>
				{payload?.map((entry: any, index: number) => (
					<div style={{ color: '#696980' }}>
						<span key={`item-${index}`} className="mr-1">
							{t(`dash-${entry.name}`)}:
						</span>
						<span>
							{formatNumber(entry.value)} {entry.unit}
						</span>
					</div>
				))}
			</div>
		);
	};

	const checkIfNumber = (value: any) => typeof value === 'number';

	const getMaxMinValues = (data: any) => {
		let maxValue = 0;
		let minValue = 0;

		data.forEach((indicatorData: any) => {
			let maxValueIndic = 0;
			let minValueIndic = 0;

			Object.values(indicatorData).forEach((value: any) => {
				if (checkIfNumber(value)) {
					if (value > 0) maxValueIndic += value;
					else if (value < 0) minValueIndic += value;
				}
			});

			if (maxValue < maxValueIndic) maxValue = maxValueIndic;
			if (minValue > minValueIndic) minValue = minValueIndic;
		});
		return { maxValue, minValue };
	};

	const customTicks = (data: any) => {
		const { maxValue, minValue } = getMaxMinValues(data);

		const getTicksPoints = (maxValue: number, minValue: number) => {
			const ticks = [maxValue, minValue];

			for (let value = maxValue; value > minValue; value--) {
				if (Math.round(value) % 10 === 0) ticks.push(Math.round(value));
			}

			return ticks;
		};

		const ticks = getTicksPoints(maxValue, minValue);

		return ticks.sort((a, b) => a - b);
	};

	const CustomYAxisTick = (props: any) => {
		const { y, payload } = props;

		return (
			<g transform={`translate(${0},${y})`}>
				<text
					x={0}
					y={0}
					textAnchor="start"
					fill="#706F7A"
					className="font-12px text-dark-60"
				>
					{labelCopy(payload.value)}
				</text>
			</g>
		);
	};

	const hasValues = (data: any) => {
		let value = 0;
		if (data) {
			data.forEach((indicator: any) => {
				Object.keys(indicator).forEach((stage) => {
					if (stage !== 'name') {
						value += indicator[stage];
					}
				});
			});
			return value !== 0;
		}

		return value !== 0;
	};

	const NoDataToDisplay = () => (
		<div
			className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
			style={{ height: ' 250px' }}
		>
			There's no data to display
		</div>
	);

	return (
		<>
			{hasValues(data) ? (
				<ResponsiveContainer height={250}>
					<BarChart
						layout="vertical"
						width={600}
						height={210}
						data={data}
						stackOffset="sign"
						margin={{
							top: 20,
							right: 30,
							left: 10,
							bottom: 5,
						}}
						className="font-sfpro-regular"
						style={{ color: '#2B2D4D' }}
					>
						<CartesianGrid
							horizontal={false}
							strokeDasharray="0"
							stroke="#D6D6D7"
						/>
						<XAxis
							type="number"
							fontSize="12"
							domain={['dataMin', 'dataMax']}
							ticks={customTicks(data)}
							tick={{ fill: '#C6C7C8' }}
							tickFormatter={formatNumberNoDecimals}
							unit="%"
							axisLine={false}
							tickLine={false}
						/>
						<YAxis
							type="category"
							dataKey="name"
							tickLine={{ stroke: 'transparent' }}
							width={110}
							//tickFormatter={labelCopy}
							axisLine={false}
							tick={<CustomYAxisTick />}
						/>
						<Tooltip content={customTooltip} />
						<Legend content={customLegend} />
						<Bar
							id="rm"
							dataKey="rm"
							stackId="a"
							fill="#927F3F"
							radius={[2, 0, 0, 2]}
							barSize={24}
							unit="%"
						></Bar>
						<Bar
							id="mp"
							dataKey="mp"
							stackId="a"
							fill={'#CD90B3'}
							barSize={24}
							unit="%"
						/>
						<Bar
							dataKey="wp"
							stackId="a"
							fill="#E4AF4C"
							barSize={24}
							unit="%"
						/>
						{
							!validateReferenceType && (
								<Bar
									dataKey="manuf"
									stackId="a"
									fill="#DE6E56"
									barSize={24}
									unit="%"
								/>
							)
						}
						<Bar
							dataKey="trans"
							stackId="a"
							fill="#FB8C00"
							barSize={24}
							unit="%"
						/>
						<Bar
							dataKey="pack"
							stackId="a"
							fill="#5A2320"
							radius={[0, 2, 2, 0]}
							barSize={24}
							unit="%"
						/>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<NoDataToDisplay />
			)}
		</>
	);
};

export default LCStagePerImpactIndicatorChart;
