import { EProjectResult } from "utils/ProjectResults";

export enum IBlocIds {
	people,
	planet,
	transparency,
	circularity,
}

export interface CustomDataProp {
	label: string;
	score: number;
}

interface IBlocCards {
	id: EProjectResult;
	itemGroup: string;
	label: string;
	metric?: string;
	eqValue?: number | undefined;
	icon: string;
	color: string;
}

// interface IBlocItem extends Array<IBlocCards> {
export interface IBlocItem {
	id: IBlocIds;
	label: string;
	totalScore: number;
	color: string;
	cards: Array<IBlocCards>;
}

export interface IBlocs {
	[key: string]: IBlocItem;
}

export interface Configuration {
    traceability:    Ability;
    environment:     Environment;
    sustainability:  Ability;
    customCss:       string;
    customParentCss: string;
}

export interface Environment {
    main:         Equivalences;
    equivalences: Equivalences;
    savings:      Equivalences;
    arrangement:  Arrangement;
}

export interface Arrangement {
    enabled:   boolean;
    displayed: boolean;
    order:     number;
}

export interface Equivalences {
    enabled: boolean;
    order:   number;
}

export interface Ability {
    arrangement: Arrangement;
}