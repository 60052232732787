import Axios from "axios";

import StorageAuth from "./../services/storage/Auth";
import Auth from "./http/Auth";
import Notification from "../components/Elements/Notification";
import AppPaths from "permissions/roles/AppPaths";

const SAuth = new StorageAuth();

export default () => {
	Axios.interceptors.request.use(async (config) => {
		const token = SAuth.getSession().accessToken;
		if (token) {
			let t = token;
			if (t.startsWith('Bearer') == false) {
				t = `Bearer ${token}`
			}
			config.headers && (config.headers.Authorization = `${t}`);
		}

		return config;
	});

	Axios.interceptors.response.use(undefined, async (error) => {
		if (error.response && error.response.data && error.response.data.statusCode == 403) {
			const Session = SAuth.getSession();

			if (!Session.rememberMe || !Session.refreshToken) {
				if ( error.response.data.path !== '/api/auth/access') {
					window.location.href = AppPaths.login;
				}
				SAuth.removeSession();
				// Notification.display("warning",
				// "Your sesion has expired due to inactivity. Reenter your email and password to log in."
				// );

				throw new Axios.Cancel();
			}

			let refreshedSession;
			try {
				refreshedSession = await new Auth().refreshToken(Session.refreshToken);
				SAuth.saveSession({accessToken: refreshedSession.accessToken, refreshToken: Session.refreshToken, rememberMe: Session.rememberMe});
			} catch (err) {
				SAuth.removeSession();
				Notification.display(
					"error",
					`Could not update token. Please log in again.`
				);
				window.location.href = AppPaths.login;
				throw new Axios.Cancel();
			}
			return Axios.request(error.config);
		}

		throw error;
	});
};
