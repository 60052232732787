import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleImgDetails } from 'UI/atoms/ArticleImg';
import BodyCopyIcon from 'UI/atoms/BodyCopyIcon/Index';
import BodyImageLink from 'UI/atoms/BodyImageLink/Index';

import BodyDownloadIcon from 'UI/atoms/BodyDownloadIcon/Index';
import '../../Template/Modals/ModalBase/ModalBase.scss';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';

export const ModalBaseArticleInfo = (props: any) => {
	const { projectSelected } = useProjects()
	const { handleClose, isShow, articleData } = props;
	const showHideClassName = isShow
		? 'modal-base display-block backdrop-color'
		: 'modal-base display-none';
	const { validateReferenceType } = useReferenceType(projectSelected);

	const { t } = useTranslation();

	return (
		<div className={showHideClassName}>
			<div className="modal-base-main product-info-size">
				<div className="d-flex justify-content-between align-items-center">
					<span className="font-sfpro-medium text-black-100 font-24px">
						{t(`${validateReferenceType ? 'material' : 'article'}-detail-title`)}
					</span>
					<span
						className="icon-icon-close font-12px text-dark-60 hover-dark-100"
						onClick={handleClose}
					></span>
				</div>

				<div className="product-info-section pt-2 pb-4">
					<div className="font-sfpro-medium text-dark-60 font-12px my-2">
						{t(`${validateReferenceType ? 'material' : 'article'}-detail-summary`)}
					</div>
					<div className="font-sfpro-medium text-black-100 font-14px my-2">
						Image
					</div>
					<ArticleImgDetails imgUrl={articleData.imageUrl} />
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-black-100 font-14px my-2">
						{t('article-detail-name')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{articleData.name}
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-black-100 font-14px my-2">
						{t('article-detail-reference')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{articleData.reference}
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-100 font-14px my-2">
						{t('bcome-version')}
					</div>
					<div className="font-sfpro-regular text-dark-60 font-14px my-2">
						{projectSelected?.version}
					</div>
				</div>

				<div className="product-info-section py-2">
					<div className="font-sfpro-medium text-dark-60 font-12px my-2">
						{t('article-detail-digitisation')}
					</div>
					<div className="font-sfpro-medium text-black-100 font-14px my-2">
						{t('article-detail-smartLabels')}
					</div>
					<div className="d-flex justify-content-between align-items-center my-3">
						<a
							className="text-yellow text-decoration-color-yellow d-flex"
							href={articleData.smartlabelUrl}
							target="_blank"
						>
							<div
								className="text-yellow font-sfpro-regular overflow-hidden text-overflow-ellipsis"
								style={{ maxWidth: '340px' }}
							>
								{articleData.smartlabelUrl}
							</div>
							<span className="icon-icon-new-window text-yellow m-1 font-14px"></span>
						</a>
						<BodyCopyIcon
							textToCopy={articleData.smartlabelUrl}
							extraCss="text-dark-60 mx-2 cursor-pointer text-dark-100-hover font-14px"
						/>
					</div>
					<div className="mt-2" style={{ marginLeft: '-5px' }}>
						<BodyImageLink
							link={articleData.qrCodeUrl}
							alt="qr"
							width="75px"
							height="75px"
							extraCss="cursor-pointer"
						/>
						<BodyDownloadIcon
							file={articleData.qrCodeUrl}
							fileName={`qr-${articleData.name}-${articleData.reference}.svg`}
							fileType={'image/svg+xml'}
							text={t('common-download')}
							extraCss="font-sfpro-regular font-14px text-dark-60 mx-2 cursor-pointer text-dark-100-hover"
						/>
						<BodyCopyIcon
							textToCopy={articleData.qrCodeUrl}
							extraCss="text-dark-60 mx-2 cursor-pointer text-dark-100-hover font-14px"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
