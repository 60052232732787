import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import smartlabelSchema from 'UI/Template/Table/schemas/smartlabels.schema';
import sustainabilityindexSchema from 'UI/Template/Table/schemas/sustainabilityindex.schema';
import { SimpleTooltip } from '../Tooltips/Index';
import IndicatorsPopovers from '../Tooltips/IndicatorsTooltips';
import StagesTooltips from '../Tooltips/StagesTooltips';

interface Props {
	headerTitle: string;
	color?: string;
	numberSubColumns?: number;
	style?: string | undefined;
}

const HeaderColumnUnderlined = (props: Props) => {
	const { t } = useTranslation();
	const { headerTitle, color, numberSubColumns } = props;
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const tooltipId = `${headerTitle}Tooltip`;
	const smartLabelsItems = Object.getOwnPropertyNames(smartlabelSchema);

	interface thWidth {
		[key: string]: propthWidth;
	}
	interface propthWidth {
		[key: string]: string;
	}
	const thWidth: thWidth = {
		views: { minWidth: '100px' },
		smartlabels: { minWidth: '400px' },
		qrcode: { width: '300px' },
	};

	const headersWithoutTooltip = [
		'views',
		'smartlabels',
		'qrcode',
		'articleSummary',
		'completion',
		'supplierDescription',
		'logistics',
		'certificateLists',
		'materialDescription',
		'materialSupply',
		'material_certificate_names',
		'logisticDescription',
		'packagingDescription',
		'bestPractices',
		'projectDescription',
		'dataRecords',
		'overview',
		'feedstock',
		'waste',
		'usage_potential'
	];
	const headersStatics = ['articleSummary', 'materialDescription', 'supplierDescription', 'logisticDescription']

	return (
		<th
			key={headerTitle}
			colSpan={numberSubColumns}
			className={`align-top pt-0 pr-3 pb-1 pl-3 text-overflow-ellipsis overflow-hidden position-relative ${headersStatics.includes(headerTitle) ? "position-sticky l-0" : ""}`}
			style={
				smartLabelsItems.includes(headerTitle)
					? thWidth[headerTitle]
					: { minWidth: '135px', maxWidth: '135px' }
			}
		>
			<div className={`d-flex font-12px text-dark-100 align-items-center text-overflow-ellipsis overflow-hidden ${headersStatics.includes(headerTitle) ? "bg-white" : ""}`}
				style={{
					width: 183,
					zIndex: 2
				}}
			>
				<p
					id={tooltipId}
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
					className={`font-sfpro-medium text-title-sentence m-0 text-overflow-ellipsis overflow-hidden
                ${
					!headersWithoutTooltip.includes(headerTitle) &&
					'cursor-pointer'
				}`}
				>
					{t(headerTitle)}
				</p>
				{!headersWithoutTooltip.includes(headerTitle) && (
					<SimpleTooltip
						tooltipId={tooltipId}
						tooltipOpen={isTooltipOpen}
						text={t(`${headerTitle}-result-card-popOver`)}
						styles={{ maxWidth: '250px', textAlign: 'start' }}
					/>
				)}
			</div>
			<div
				className={`coloredUnderline mt-1 ${headersStatics.includes(headerTitle) ? "position-absolute w-100" : ""}`}
				style={{ borderBottomColor: '#1C1C30', bottom: headersStatics.includes(headerTitle) ? 4 : "" }}
			></div>
		</th>
	);
};

export const HeaderUnderlined = (props: Props) => {
	const { t } = useTranslation();
	const { headerTitle, style } = props;

	return (
		<div className={`${style}`}>
			<div className="d-flex font-12px text-dark-80 align-items-center text-overflow-ellipsis overflow-hidden">
				<p className="font-sfpro-medium text-title-sentence m-0 text-overflow-ellipsis overflow-hidden">
					{t(headerTitle)}
				</p>
			</div>
			<div
				className="coloredUnderline mt-1 mr-5"
				style={{ borderBottomColor: '#1C1C30' }}
			></div>
		</div>
	);
};

export default HeaderColumnUnderlined;
