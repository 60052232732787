import React, { useState, useRef } from "react";
import { Popover, Tooltip } from "reactstrap";
import { TableViewOptionsDropDown } from "UI/atoms/DropDown";
import useOutsideClick from "hooks/useOutsideClick.hook";
import "./TableMenu.scss";
import { useTranslation } from "react-i18next";

interface Props {
	handleToggleColumns: () => void;
}

const TableMenu = (props: Props) => {
	const { handleToggleColumns } = props;

	const [isShowDropdown, setIsShowDropdown] = useState(false);
	const [isShowHover, setIsShowHover] = useState(false);
	const ref = useRef<HTMLHeadingElement>(null);
	const wPath = window.location.pathname;
	const { t } = useTranslation();

	useOutsideClick(ref, () => {
		setIsShowDropdown(false);
	});

	return (
		<div
			className={`table-menu d-flex align-items-center justify-content-center position-relative text-dark-60 font-sfpro-regular`}
			ref={ref}
			onClick={() => setIsShowDropdown(!isShowDropdown)}
		>
			<div
				className={`icon-div d-flex align-items-center justify-content-center rounded-sm ${
					isShowDropdown ? "active" : ""
				}`}
				style={{ transform: "rotate(90deg)" }}
				id="tableActions"
				onMouseEnter={() => setIsShowHover(true)}
				onMouseLeave={() => setIsShowHover(false)}
			>
				<span className={`font-18px icon-icon-menu`} />
			</div>

			{isShowDropdown && <TableViewOptionsDropDown />}

			<Tooltip
				innerClassName="tooltips"
				arrowClassName="arrow-tooltip"
				placement="bottom-start"
				isOpen={isShowHover}
				target="tableActions"
			>
				{t("tableActions")}
			</Tooltip>
		</div>
	);
};

export default TableMenu;
