import Crud, { ICrudListResponse } from "./Crud";


export default class View extends Crud {
    constructor() {
        super("views"); // <host>/api/views
    }

    async allViews(): Promise<ICrudListResponse> {
        return await this.get('/');
    }

    async updateView(id: string, data: any): Promise<ICrudListResponse> {
        return await this.update(`/${id}`, data, this.requestConfig);
    }

    async deleteView(id: string): Promise<ICrudListResponse> {
        return await this.delete(id);
    }

    async createView(data: any): Promise<ICrudListResponse> {
        return await this.create(data);
    }


}