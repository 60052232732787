import React from "react";
import { useMenuState } from "hooks/useMenuState.hook";

import TableMetrics from "UI/Template/Table";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { SpinnerLoader } from "components/Common/Loader/Loader";
import { EMAIL_INFORMATION } from "../../../constants";

const Traceability = () => {
    const { callToAction, loading } = useMenuState('plm')

    if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

    if (callToAction) {
        return <InformationNavigation keyName="upgrade" email={EMAIL_INFORMATION} />
    }

    return (
        <TableMetrics />
    )
}

export default Traceability;