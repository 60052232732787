import { SimpleTooltip } from "UI/atoms/Tooltips/Index";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type LayoutLibraryProps = {
    keyName: string,
    title: string,
    filters: string[],
    filterSelected: string,
    handleFilter: (key: string, value: string) => void,
    searchText: string,
    handleSearch: (key: string, value: string) => void,
    children: React.ReactNode
}

type FilterSelectedProps = {
    keyName: string,
    filters: string[],
    filterSelected: string,
    handleFilter: (key: string, value: string) => void
}

export const LayoutLibrary = ({ keyName, title, filters, filterSelected, handleFilter, searchText, handleSearch, children }: LayoutLibraryProps) => {
    return (
        <section className="px-2 py-1">
            <header className="d-flex flex-row align-items-center justify-content-between pb-2">
                <div className="d-flex flex-row align-items-center">
                    <h3 className="font-20px text-dark-100 m-0 pr-5 font-sfpro-regular">{title}</h3>
                    <div className="pl-5 d-flex flex-row align-items-center">
                        <span className="font-12px text-dark-100 mr-2">Filter by:</span>
                        <FilterSelected {...{keyName, filterSelected, filters, handleFilter}} />
                    </div>
                </div>
                <Search searchText={searchText} handleSearch={handleSearch} keyName={keyName} />
            </header>
            {children}
        </section>
    )
}

const FilterSelected = ({keyName, filters, filterSelected, handleFilter}: FilterSelectedProps) => {
    const [hoverFilter, setHoverFilter] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const handleOpenDropdown = () => setShowDropdown(!showDropdown);
    const handleHoverFilter = (isHover: boolean) => () => setHoverFilter(isHover);

    return (
        <div
            className="d-flex project-selector flex-column align-items-start ml-1"
            onClick={handleOpenDropdown}
        >
                <div
                    className={`position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-1 pl-3 pr-1 ${hoverFilter ? "border-dark-60 bg-transparent" : showDropdown ? "border-dark-100 bg-transparent" : "border-gray-3 bg-gray-01"}`}
                    style={{ borderRadius: "30px" }}
                    onMouseEnter={handleHoverFilter(true)}
                    onMouseLeave={handleHoverFilter(false)}
                >
                    <span className={`${hoverFilter ? "text-dark-60" : "text-dark-100"} font-12px font-sfpro-medium text-overflow-ellipsis overflow-hidden`}>
                        { filterSelected }
                    </span>
                    <div className={`d-flex align-items-center justify-content-center`}
                    style={{
                        height: "32px",
                        width: "32px",
                    }}>
                        <span
                            onClick={handleOpenDropdown}
                            className={`icon-icon-chevron-down ${hoverFilter ? "text-dark-60" : "text-dark-100"} font-30px`}
                            style={{
                                transition: "all 0.5s ease",
                                transformOrigin: "50% 50%",
                                transform: showDropdown ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                        />
                    </div>
                    {
                        showDropdown &&
                        <FilterDropdown keyName={keyName} filters={filters} filterSelected={filterSelected} handleFilter={handleFilter} />
                    }
            </div>
        </div>
    )
}

const FilterDropdown = ({keyName, filters, filterSelected, handleFilter}:
    {keyName: string, filters: string[], filterSelected: string, handleFilter: (keyName: string, name: string) => void}) => {
    const validateEqual = (filter: string) => filterSelected === filter;
    return (
		<div
			className="dropdown-menu show position-absolute"
			style={{ right: -130, left: "auto" }}
		>
			{filters.map((filter: string) => (
				<button
					key={`filter-${filter}`}
					onClick={() => handleFilter(keyName, filter)}
					className={
						validateEqual(filter)
							? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
							: "dropdown-item"
					}
				>
					{validateEqual(filter) && (
						<span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
					)}
					<div
						className={`${
							validateEqual(filter)
								? "font-sfpro-medium"
								: "font-sfpro-regular"
						}
                        text-overflow-ellipsis overflow-hidden`}
					>
						{filter}
					</div>
				</button>
			))}
		</div>
	);
}

const Search = ({searchText, handleSearch, keyName}: any) => {
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const restartSearch = () => {
		handleSearch(keyName, '');
	};

	const searchClick = () => {
		setIsShow(!isShow);
		restartSearch();
	};

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleSearch(keyName, e.target.value);
	};

    return (
        <div className={
            isShow
                ? 'search-bar-active d-flex flex-row-reverse align-items-center'
                : 'search-bar d-flex flex-row-reverse align-items-center'
        }>
            <div
                id={`search-bar-${keyName}`}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onClick={searchClick}
                className="d-flex align-items-center text-dark-60 text-dark-100-hover"
            >
                <span
                    className={`icon-icon-${
                        isShow ? 'close font-1px' : 'search font-20px'
                    }`}
                    style={{ margin: '6px' }}
                ></span>
            </div>

            {isShow && (
                <input
                    className="search-input font-sfpro-regular"
                    type="text"
                    placeholder={`Search ${keyName}...`}
                    value={searchText}
                    onChange={handleSelect}
                />
            )}

            <SimpleTooltip
				tooltipId={`search-bar-${keyName}`}
				tooltipOpen={isTooltipOpen}
				text={t(`${keyName}-finder`)}
			/>
        </div>
    )
}