import React from 'react';
import { IEcodesignRequest } from 'UI/Pages/DiagnosisEcodesign/types/ecodesign';
import EcocalculatorHttp from "services/http/Ecocalculator";
  
interface IProps {
    request: IEcodesignRequest,
    setRequest: any,
    setDataOptions:any,
    isSwitched: boolean,
    setIsSwitched:any,
    setEcoResults: any,
}

const Ecocalculator = new EcocalculatorHttp();

const FormSwitcher = (props: IProps) => {
	const { request, setRequest, setDataOptions, isSwitched, setIsSwitched, setEcoResults } = props;

    const handleSwitchItems = () => {
        setDataOptions((prevState:any) => ({...prevState, itemA: prevState.itemB, itemB: prevState.itemA}));
        setRequest((prevState:any) => ({...prevState, itemA: prevState.itemB, itemB: prevState.itemA}));
        setIsSwitched(!isSwitched);
    }
    
    const handleCalculate = () => {
		calculateResults();
	}

    const calculateResults = () => {
		const results = Ecocalculator.ecoCalculator(request);
		results.then((res) => {
			const data = res.data;
			setEcoResults(data);
		});
	}

    return (
        <div className="w-100 border-bottom my-5">
            <div className="d-flex flex-column justify-content-center align-items-center position-relative float-right bg-gray-01 mr-3" style={{ width: '24px', height: '24px', borderRadius: '6px', bottom: '12px'}}>
                <span className={`icon-icon-arrows-switch ${isSwitched ? 'text-dark-100' : 'text-dark-60'} hover-dark-100`} onClick={handleSwitchItems}/>
            </div>
        </div>
    )
}
export default FormSwitcher;
