import React from 'react';
import { useTranslation } from 'react-i18next';
import './ModalBase.scss';


export const ModalBaseViewAction = (props: any) => {
  const { handleClose, isShow, children, action, actionName } = props
  const showHideClassName = isShow ? "modal-base display-block backdrop-color" : "modal-base display-none";

  const { t } = useTranslation();

  return (
    <div className={showHideClassName}>
      <section className="modal-base-main">
        {children}
        <div className='d-flex justify-content-end m-3 font-sfpro-regular'>
          <button className='btn btn-tertiary m-1' type="button" onClick={handleClose}>
            {t("common-cancel")}
          </button>
          {/* Action button */}
          <button className='btn-action m-1' type="button" onClick={action}>
            {actionName}
          </button>
        </div>
      </section>
    </div>
  );
}
