import { handleError } from "./utils";
import axios, { AxiosRequestConfig } from 'axios';
import { objToQuery } from "utils/Router";
import Http from "./Http";

export interface ICrudSearchList {
    [key: string]: any
}

export interface ICrudPaginationList {
    [key: string]: any
}

// export interface ICrudListResponse {
//     data: any[],
//     count: number,
//     total: number,
//     page: number,
//     pageCount: number,
// }

export interface ICrudListResponse {
    [key: string]: any
}

export interface ICrudCreateResponse {
    [key: string]: any
}

export interface ICrudUpdateResponse {
    [key: string]: any
}

export interface ICrudDeleteResponse {
    [key: string]: any
}

export interface ICrudDetailResponse {
    [key: string]: any
}

/**
 * @abstract
 */
export default class Crud extends Http {
    protected source: any;

    constructor(prefix: string) {
        super(prefix);

        this.source = axios.CancelToken.source();
    }

    public getSource () {
        return this.source
    }

    public async list(search: ICrudSearchList, pagination: ICrudPaginationList, requestConfig: Partial<AxiosRequestConfig> = {}): Promise<ICrudListResponse> {
        let result: any;

        try {
            requestConfig.cancelToken = this.source.token;
            result = await await this.get(objToQuery({ ...search, ...pagination }), requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    public async detail(id: string, requestConfig: Partial<AxiosRequestConfig> = {}): Promise<ICrudDetailResponse> {
        let result: any;

        try {
            requestConfig.cancelToken = this.source.token;
            result = await this.get(`${id}`, requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    public async create(data: Record<string, unknown>, requestConfig: Partial<AxiosRequestConfig> = {}): Promise<ICrudCreateResponse> {
        let result: any;
        try {
            requestConfig.cancelToken = this.source.token;
            result = await this.post('/', { ...data }, requestConfig);
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    public async update(id: string, data: Record<string, unknown>, requestConfig: Partial<AxiosRequestConfig> = {}): Promise<ICrudUpdateResponse> {
        let result: any;

        try {
            requestConfig.cancelToken = this.source.token;
            result = await this.put(id, data, {...this.requestConfig, ...requestConfig});
        }
        catch (err) {
            handleError(err);
        }

        return result;
    }

    public async delete(id: string, requestConfig: Partial<AxiosRequestConfig> = {}): Promise<ICrudDeleteResponse> {
        requestConfig.cancelToken = this.source.token;
        return await super.delete(`/${id}`, requestConfig);
    }
}