import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";


interface ErrorBoundaryProps extends RouteComponentProps {
    fallback: ReactNode;
    children: ReactNode;
}


class ErrorBoundry extends Component<ErrorBoundaryProps> {
    state = { hasError: false, prevPath: this.props.location.pathname };

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidUpdate(prevProps: ErrorBoundaryProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
          this.setState({ hasError: false, prevPath: this.props.location.pathname });
        }
    }

    render(): any {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundry);