import { useProjects } from "contexts/projectsContext";
import { useReferenceType } from "hooks/useReferenceType.hook";
import React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const LCA = 'lca'
const BENCHMARK = 'benchmark'
const SUSTAINABILITYINDEX = 'sustainabilityindex'
const ECODESIGN = 'ecodesign'

const PRODUCT_METHODOLOGY = "https://bcome.biz/help-center/methodologies-versions/product-methodologies/"
const FLOW_DIAGRAM = "https://drive.google.com/file/d/1cTRp2Jyjw14EW_OJfqlXGNBGChUbuNC3/view?usp=sharing"

function MethodologyFooter() {
    const { t } = useTranslation();
    const h = useHistory();
    const path = h.location.pathname;
    const product = path.split('/')[2] || path.split('/')[1];
    const { projectSelected } = useProjects();
    const { validateReferenceType } = useReferenceType(projectSelected)

    let url;

    if (product === LCA ) {
        url = 'https://drive.google.com/file/d/1xWG9dkKwMBR_Wh3xwSMLsThzhQrJbE3l/view';
    }
    if (product === BENCHMARK) {
        url = 'https://drive.google.com/file/d/1xWG9dkKwMBR_Wh3xwSMLsThzhQrJbE3l/view';
    }
    if (product === SUSTAINABILITYINDEX) {
        url = 'https://drive.google.com/file/d/12VZiPgkMfX2wWffrEyvtCiISNDMXiXMr/view?usp=sharing';
    }
    if (product === ECODESIGN) {
        url = 'https://drive.google.com/file/d/1xSFxpWigTbYRJNSvLXHHVc2Q2ObeHg6X/view';
    }

    return (
        <div className="d-flex align-items-center text-overflow-ellipsis overflow-hidden mr-3 text-dark-60 font-12px font-sfpro-regular">
            {t("methodologyDatabase")}:
            {
                product === LCA ?
                <>
                    <span className="text-dark-100" style={{marginLeft: "3px"}}> {t("footer-lca-text")}</span>
                    <a className="text-overflow-ellipsis overflow-hidden text-yellow text-decoration-color-yellow" target="_blank" href={PRODUCT_METHODOLOGY}>
                        <span className="text-yellow" style={{margin: "0px 3px"}}>{t(`footer-lca-product-methodology`)}</span>
                        <span className="icon-icon-new-window text-yellow"></span>
                    </a>

                    <span className="text-dark-100" style={{marginLeft: "3px"}}> {t("and")}</span>
                    <a className="text-overflow-ellipsis overflow-hidden text-yellow text-decoration-color-yellow" target="_blank" href={
                        validateReferenceType
                        ? 'https://drive.google.com/file/d/1TSmVePKpy1biyIn5y2I5hYUkArQSUzH_/view?usp=sharing'
                        : FLOW_DIAGRAM
                    }>
                        <span className="text-yellow" style={{margin: "0px 3px"}}>{t(`footer-lca-flow-diagram`)}</span>
                        <span className="icon-icon-new-window text-yellow"></span>
                    </a>
                </>:<>
                    <span className="text-dark-100" style={{marginLeft: "3px"}}> {t("new-footer-message-tables")}</span>
                    <a className="text-overflow-ellipsis overflow-hidden text-yellow text-decoration-color-yellow" target="_blank" href="https://bcome.biz/help-center/getting-started/product-methodologies/"> {/* url variable  */}
                        <span className="text-yellow" style={{margin: "0px 3px"}}>{t(`here`)}</span> {/** footer-methodology-${product} */}
                        <span className="icon-icon-new-window text-yellow"></span>
                    </a>
                </>


            }


        </div>
    )
}


export default MethodologyFooter