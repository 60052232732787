import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipTraceabilityScore } from "../Tooltips/Index";
import { formatNumberNoDecimals } from "utils/utils";

interface Props {
	color: string,
	percent: number | undefined | null,
	useColor?: boolean,
	articleSupplier?: number,
	materialSupplier?: number,
	rawMaterialSupplier?: number
	customStyles?: any
	tooltip?: boolean
}

const ProgressBarSimple = (props: Props) => {
	const { t } = useTranslation();
	const { percent, color, useColor = false, articleSupplier, materialSupplier, rawMaterialSupplier, customStyles, tooltip = true } = props;

	if (percent) {
		return (
			<div className={`tooltipCustom ${tooltip ? 'w-50' : 'w-100'}`}>
				<div className="progress rounded-10 w-100 " style={{ height: "10px" }}>
					<div
						className="progress-bar rounded-10 colors-primary-100"
						role="progressbar"
						style={{
							width: `${percent}%`,
							backgroundColor: useColor ? color : ""
						}}
					>
					</div>
				</div>
				{
					tooltip &&
					<TooltipTraceabilityScore>
						<div className="d-flex flex-row" role='traceability-score-tooltip'>
							<div className="mt-1" style={{
								width: "15%",
							}}>
								<div style={{
									width: "12px",
									height: "12px",
									backgroundColor: color,
									borderRadius: "50%"
								}}></div>
							</div>
							<div className="d-flex flex-column w-100">
								<p style={{ marginBottom: 8}}><strong>{`${formatNumberNoDecimals(percent)}% ${t("supplier-know")}`}</strong></p>
								<p style={{ marginBottom: 8}}>{`${articleSupplier ? formatNumberNoDecimals(articleSupplier) : 0}% ${t("article-supplier")}`}</p>
								<p style={{ marginBottom: 8}}>{`${materialSupplier ? formatNumberNoDecimals(materialSupplier) : 0}% ${t("material-supplier")}`}</p>
								<p style={{ marginBottom: 8}}>{`${rawMaterialSupplier ? formatNumberNoDecimals(rawMaterialSupplier) : 0}% ${t("raw-material-supplier")}`}</p>
							</div>
						</div>
					</TooltipTraceabilityScore>
				}
			</div>
		);
	} else {
		const textValue = "--";
		return (
			<span className="font-sfpro-regular letter-spacing-normal font-14px text-dark-80 text-overflow-ellipsis overflow-hidden">
				{textValue}
			</span>
		);
	}
}


export default ProgressBarSimple;
