import React, { useState, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

import './ScopeModal.scss'
import ProgressBarSimple from 'UI/atoms/ProgressBarSimple/Index';
import {
	determinateProgressBarColor,
	formatNumberNoDecimals,
	customTitles,
	formatNumber,
	filterText,
	standardName,
} from 'utils/utils';
import { ArticleImgMedium } from 'UI/atoms/ArticleImg';
import useRow from 'hooks/useRow';
import { BestPracticeIndicator } from 'UI/molecules/TableRow';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import BodyText from 'UI/atoms/BodyText';
import { BlockData } from 'UI/molecules/TableRow';
import { useAccount } from 'contexts/accountContext';
import { AccountFeatureKeysProps } from 'hooks/useMenuState.hook';

const SCOPE_CUSTOM_DATASET = ['article', 'material'];
const SHOW_DATASET_CHIP = 'process_treatment_name';
const CUSTOM_DATASET = 'Custom';

interface ScopeModalInterface {
	scope: string;
	handleCloseModal: () => void;
	data: any;
}

const TITLES: {[index: number]: any} = {
	1: {
		article: 'modal-traceability',
		supplier: 'modal-additional-features',
		material: 'modal-material-finishing',
	},
	2: {
		article: 'modal-article-material-checklist',
		supplier: 'modal-supplier-certificates-standard',
		material: 'modal-traceability',
	},
	3: {
		article: 'modal-additional-features',
		supplier: 'bestPractices',
		material: 'modal-certificates',
	},
};

export const ScopeModal = ({scope, handleCloseModal, data}: ScopeModalInterface) => {
	const { t } = useTranslation();
	const {
		determinateCompletionCategory,
		handleCompositionMaterial,
		standarizateWarehouse,
		standarizateName,
		convertToString
	} = useRow();
	const { account } = useAccount();

	const validateAccountFeature = (compareKey: string) => account && account.accountFeatureKeys?.some(({key}: AccountFeatureKeysProps) => key === compareKey)

	const blockData = !validateAccountFeature('eco_score');

	const handleClick = (e: any) => {
		e.stopPropagation();
	};

	const handleListDatas = (
		names: string[],
		suppliers: string[],
		abbrOrigins: string[],
		origins: string[],
		icons: string[],
		iconNames: string[]
	) => {

		return names.map((name: string, index: number) => ({
			key: name,
			value: <SupplierDetails
				emptyValue={t('not-applicable')}
				name={suppliers[index] ?? ''}
				abbrOrigin={abbrOrigins[index] ?? ''}
				origin={origins[index] ?? ''}
				icon={icons[index] ?? ''}
				iconName={iconNames[index] ?? ''}
			/>
		}))
	}

	const scopeName =
		scope === 'article' ? data.name :
		scope === 'material' ? data.materialName :
		scope === 'supplier' ? data.supplierName :
		'--'

	const toggleGetDatas = (data: any[], param1: string, param2: string, param3: string, param4: string, param5: string, materialProcess?:boolean) => {
		const names: string[] = []
		const suppliers_names: string[] = []
		const origins_abbreviations: string[] = []
		const origins: string[] = []
		const transport_icons: string[] = []
		const transport_names: string[] = []

		if (materialProcess) {
			data?.forEach((item: any) => {
				names.push(item.name)
				suppliers_names.push(item[param1][0] ?? '')
				origins_abbreviations.push(item[param2][0] ?? '')
				origins.push(item[param3][0] ?? '')
				transport_icons.push(item[param4][0] ?? '')
				transport_names.push(item[param5][0] ?? '')
			})
		} else {
			data?.forEach((item: any) => {
				names.push(item.nameMaterial)
				suppliers_names.push(item[param1] ?? '')
				origins_abbreviations.push(item[param2] ?? '')
				origins.push(item[param3] ?? '')
				transport_icons.push(item[param4] ?? '')
				transport_names.push(item[param5] ?? '')
			})
		}

		return {
			names,
			suppliers_names,
			origins_abbreviations,
			origins,
			transport_icons,
			transport_names
		}
	}

	const materialProcess = toggleGetDatas(
		data['article_process_dye'],
		'article_process_matProcessing_suppliers_names',
		'article_process_matProcessing_origins_abbreviations',
		'article_process_matProcessing_origins',
		'article_process_matProcessing_transport_icons',
		'article_process_matProcessing_transport_names',
		true
	)
	const article_process_names_per_material = toggleGetDatas(
		data['article_process_extraction_names_per_material'],
		'article_process_extraction_suppliers_names',
		'article_process_extraction_origins_abbreviations',
		'article_process_extraction_origins',
		'article_process_extraction_transport_icons',
		'article_process_extraction_transport_names',
	)

	const raw_material_processing = toggleGetDatas(
		data['article_process_names_per_material'],
		'article_process_rmatProcessing_suppliers_names',
		'article_process_rmatProcessing_origins_abbreviations',
		'article_process_rmatProcessing_origins',
		'article_process_rmatProcessing_transport_icons',
		'article_process_rmatProcessing_transport_names',
	)

	return (
		<div
			className="p-4 position-relative"
			style={{
				overflowY: 'auto',
				height: '100vh',
			}}
			onClick={handleClick}
		>
			<div className="d-flex flex-row justify-content-between align-items-center position-fixed pt-4 pl-4 pb-3 modal__header">
				<div className="font-24px text-dark-100 font-sfpro-medium">{`${customTitles(
					scope
				)} sheet`}</div>
				<span
					onClick={handleCloseModal}
					data-testid="close-modal"
					className="icon-icon-close text-dark-60 hover-dark-100"
				/>
			</div>
			<div className='modal__body'>
				<div
					className="my-2"
					style={{ borderTop: '0.5px solid rgb(172, 172, 178)' }}
				/>
				<div>
					<div className="mb-3 text-dark-60 font-12px">{t('modal-description', { scope: scope.toUpperCase()})}</div>
					{scope === 'article' && (
						<ArticleImgMedium imgUrl={data.imageUrl} />
					)}

					<ItemData
						title={t('modal-scope-name', { scope: scope })}
						data={scopeName.toLowerCase().includes('unknown') ? 'Unknown' : standarizateName(standarizateWarehouse(scopeName))}
					/>

					{scope === 'article' || scope === 'material' ? (
						<ItemData
							title={t('modal-scope-reference', { scope: scope })}
							data={
								data[
									scope === 'material'
										? 'material_reference'
										: 'reference'
								]
							}
						/>
					) : (
						scope === 'supplier' && (
							<ItemData
								title={t('modal-origin')}
								data={data.origin ? data.origin['name'] : ''}
							/>
						)
					)}
					{scope === 'article' && (
						<ArticleScopeDescription
							{...{ data, determinateCompletionCategory, blockData, t }}
						/>
					)}
					{scope === 'material' && (
						<MaterialScopeDescription
							{...{
								scope,
								customTitles,
								data,
								handleCompositionMaterial,
								t,
								standarizateName,
								convertToString
							}}
						/>
					)}
				</div>

				<div
					className="my-2"
					style={{ borderTop: '0.5px solid rgb(172, 172, 178)' }}
				/>
				<div>
					<div className="mb-3 text-dark-60 font-12px text-uppercase">
						{t(TITLES[1][scope], { scope: scope })}
					</div>
					{scope === 'article' && (
						<>
								<Accordeon
									data={[
										// {
										// 	title: t('modal-accordeon-warehouse'),
										// 	content: (
										// 		<ItemTable
										// 			title=""
										// 			data={[
										// 				{
										// 					key: t('modal-article'),
										// 					value: t('modal-origin'),
										// 					bold: true,
										// 					subtitle: true,
										// 					boldFull: true
										// 				},
										// 				{
										// 					key: data['name'],
										// 					value:
										// 						data[
										// 							'article_warehouse_origin_abbreviation'
										// 						].length > 0
										// 							? data['article_warehouse_origin_abbreviation'].join(', ')
										// 							: '--',
										// 					tooltip: data['article_warehouse_origin'].length > 0 ? data['article_warehouse_origin'][0] : '--',
										// 				},
										// 			]}
										// 		/>
										// 	),
										// 	fullCircle: true,
										// },

										{
											title: t('modal-accordeon-finishing', {detail: t('modal-article')}),
											content: (
												<ItemTable activeSpace={true}
													title=""
													data={[
														{
															key: t('modal-accordeon-finishing', {detail: t('modal-typeof')}),
															value: t('modal-supplier'),
															bold: true,
															subtitle: true,
															boldFull: true
														},
														...handleListDatas(
															[t('modal-dye-title')],
															data['article_process_dye_supplier_name'] ?? [],
															data['article_process_dye_origin_abbreviation'] ?? [],
															data['article_process_dye_origin'] ?? [],
															data['article_process_dye_transport_icon'] ?? [],
															data['article_process_dye_transport_name'] ?? []
														),
														...handleListDatas(
															[t('modal-treatment-title')],
															data['article_process_treatment_supplier_name'] ?? [],
															data['article_process_treatment_origin_abbreviation'] ?? [],
															data['article_process_treatment_origin'] ?? [],
															data['article_process_treatment_transport_icon'] ?? [],
															data['article_process_treatment_transport_name'] ?? []
														),
														...handleListDatas(
															[t('modal-print-title')],
															data['article_process_print_supplier_name'] ?? [],
															data['article_process_print_origin_abbreviation'] ?? [],
															data['article_process_print_origin'] ?? [],
															data['article_process_print_transport_icon'] ?? [],
															data['article_process_print_transport_name'] ?? []
														)
													]}
												/>
											),
											fullCircle: true,
										},

										{
											title: t('modal-accordeon-manufacturing'),
											content: (
												<ItemTable activeSpace={true}
													title=""
													data={[
														{
															key: t('modal-article'),
															value: t('modal-supplier'),
															bold: true,
															subtitle: true,
															boldFull: true
														},
														...handleListDatas(
															[data['name']],
															data['article_process_manufacturing_supplier_name'] ?? [],
															data['article_process_manufacturing_origin_abbreviation'] ?? [],
															data['article_process_manufacturing_origin'] ?? [],
															data['article_process_manufacturing_transport_icon'] ?? [],
															data['article_process_manufacturing_transport_name'] ?? []
														)
													]}
												/>
											),
											fullCircle: true,
										},

										{ title: t('modal-accordeon-finishing', { detail: t('modal-material')}), content: <ItemTable activeSpace={true} title="" data={[
											{ key: t('modal-accordeon-finishing', {detail: t('modal-typeof')}), value: t('modal-supplier'), bold: true, subtitle: true, boldFull: true },
											{ key: t('modal-dye-title'), value: '', bold: true, subtitle: true, boldFull: true },
											...handleListDatas(
												data['article_material_distribution']?.map(({name}:{name:string}) => name),
												data['article_process_matDye_suppliers_names'] ?? [],
												data['article_process_matDye_origins_abbreviations'] ?? [],
												data['article_process_matDye_origins'] ?? [],
												data['article_process_matDye_transport_icons'] ?? [],
												data['article_process_matDye_transport_names'] ?? []
											),
											{ key: t('modal-treatment-title'), value: '', bold: true, subtitle: true, boldFull: true },
											...handleListDatas(
												data['article_material_distribution']?.map(({name}:{name:string}) => name),
												data['article_process_matTreatment_suppliers_names'] ?? [],
												data['article_process_matTreatment_origins_abbreviations'] ?? [],
												data['article_process_matTreatment_origins'] ?? [],
												data['article_process_matTreatment_transport_icons'] ?? [],
												data['article_process_matTreatment_transport_names'] ?? []
											),
											{ key: t('modal-print-title'), value: '', bold: true, subtitle: true, boldFull: true },
											...handleListDatas(
												data['article_material_distribution']?.map(({name}:{name:string}) => name),
												data['article_process_matPrint_suppliers_names'] ?? [],
												data['article_process_matPrint_origins_abbreviations'] ?? [],
												data['article_process_matPrint_origins'] ?? [],
												data['article_process_matPrint_transport_icons'] ?? [],
												data['article_process_matPrint_transport_names'] ?? []
											),
										]} />, fullCircle: true},

										{ title: t('modal-processing', {detail: t('modal-material')}), content: <ItemTable activeSpace={true} title="" data={[
											{ key: t('modal-material'), value: t('modal-supplier'), bold: true, subtitle: true, boldFull: true },
											...handleListDatas(
												materialProcess.names,
												materialProcess.suppliers_names ?? [],
												materialProcess.origins_abbreviations ?? [],
												materialProcess.origins ?? [],
												materialProcess.transport_icons ?? [],
												materialProcess.transport_names ?? []
											)
										]} />, fullCircle: true},

										{ title: "Raw material processing", content: <ItemTable activeSpace={true} title="" data={[
											{ key: 'Raw material', value: 'Supplier', bold: true },
											...handleListDatas(
												data['article_process_names']?.map(({nameMaterial, nameProcess}:{nameMaterial:string, nameProcess: string}) => `${nameProcess} <span data-name="from">from</span> ${nameMaterial}`),
												raw_material_processing.suppliers_names ?? [],
												raw_material_processing.origins_abbreviations ?? [],
												raw_material_processing.origins ?? [],
												raw_material_processing.transport_icons ?? [],
												raw_material_processing.transport_names ?? []
											)
										]} />, fullCircle: true},
										{ title: "Raw material extraction", content: <ItemTable activeSpace={true} title="" data={[
											{ key: 'Raw material', value: 'Supplier', bold: true },
											...handleListDatas(
												data['article_process_names']?.map(({nameMaterial, nameProcess}:{nameMaterial:string, nameProcess: string}) => `${nameProcess} <span data-name="from">from</span> ${nameMaterial}`),
												article_process_names_per_material.suppliers_names ?? [],
												article_process_names_per_material.origins_abbreviations ?? [],
												article_process_names_per_material.origins ?? [],
												article_process_names_per_material.transport_icons ?? [],
												article_process_names_per_material.transport_names ?? []
											)
										]} />, fullCircle: true},
									]}
								/>
						</>
					)}
					{scope === 'supplier' && (
						<>
							<ItemData
								title={t('modal-tier-category')}
								data={data.supplier_tiers.join(', ')}
							/>
							<ItemData
								title={t('modal-stage')}
								data={
									data.categoryId
										? data.categoryId
												.map((item: any) => item['name'])
												.join(', ')
										: ''
								}
							/>
							<ItemData
								title={t('modal-shiping-transport')}
								data={
									data.supplier_transport_category && data.supplier_transport_category !== '-'
										? data.supplier_transport_category
										: <NotAllowed text={t('no-transport')} />
								}
							/>
						</>
					)}

					{scope === 'material' && <ShowDatas {...{ scope, data, t }} />}
				</div>

				<div
					className="my-2"
					style={{ borderTop: '0.5px solid rgb(172, 172, 178)' }}
				/>
				<div>
					<div className="mb-3 text-dark-60 text-uppercase font-12px">
						{t(TITLES[2][scope], { scope: scope })}
					</div>
					{scope === 'article' && (
						<MaterialCategory
							data={data['article_material_distribution']}
							t={t}
						/>
					)}
					{scope === 'supplier' && (
						<>
							<ItemData
								title={t('modal-certificate-list')}
								data={
									blockData
									? <BlockData />
									: data?.supplier_certificate_names.length > 0
										? data.supplier_certificate_names.join(', ')
										: <NotAllowed text={t('no-certificates')} />
								}
							/>
						</>
					)}

					{scope === 'material' && (
						<>
							<Accordeon
								data={[
									{
										title: t('modal-accordeon-finishing', {detail: t('modal-material')}),
										content: (
											<ItemTable activeSpace={true}
												title=""
												data={[
													{
														key: t('modal-accordeon-finishing', {detail: t('modal-typeof')}),
														value: t('modal-supplier'),
														bold: true,
														subtitle: true,
														boldFull: true
													},
													{
														key: t('modal-dye-title'),
														bold: true,
														subtitle: true,
														value: (
															<SupplierDetails
																emptyValue={t('not-applicable')}
																name={data[
																	'material_process_dye_supplier_name'
																].join(', ')}
																abbrOrigin={data[
																	'material_process_dye_origin_abbreviation'
																].join(', ')}
																origin={data[
																	'material_process_dye_origin'
																].join(', ')}
																icon={data[
																	'material_process_dye_transport_icon'
																].join(', ')}
																iconName={data[
																	'material_process_dye_transport_name'
																].join(', ')}
															/>
														),
													},
													{
														key: t('modal-treatment-title'),
														bold: true,
														subtitle: true,
														value: (
															<SupplierDetails
																emptyValue={t('not-applicable')}
																name={data[
																	'material_process_treatment_supplier_name'
																].join(', ')}
																abbrOrigin={data[
																	'material_process_treatment_origin_abbreviation'
																].join(', ')}
																origin={data[
																	'material_process_treatment_origin'
																].join(', ')}
																icon={data[
																	'material_process_treatment_transport_icon'
																].join(', ')}
																iconName={data[
																	'material_process_treatment_transport_name'
																].join(', ')}
															/>
														),
													},
													{
														key: t('modal-print-title'),
														bold: true,
														subtitle: true,
														value: (
															<SupplierDetails
																emptyValue={t('not-applicable')}
																name={data[
																	'material_process_print_supplier_name'
																].join(', ')}
																abbrOrigin={data[
																	'material_process_print_origin_abbreviation'
																].join(', ')}
																origin={data[
																	'material_process_print_origin'
																].join(', ')}
																icon={data[
																	'material_process_print_transport_icon'
																].join(', ')}
																iconName={data[
																	'material_process_print_transport_name'
																].join(', ')}
															/>
														),
													},
												]}
											/>
										),
										fullCircle: true,
									},
									{ title: t('modal-processing', {detail: t('modal-material')}), content: <ItemTable activeSpace={true} title="" data={[
											{ key: t('modal-material'), value: t('modal-supplier'), bold: true, subtitle: true, boldFull: true },
											{
												key: data['materialName'],
												value: <SupplierDetails
												name={data['material_process_matProcessing_supplier_name'].join(', ')}
													abbrOrigin={data['material_process_matProcessing_origin_abbreviation'].join(', ')}
													origin={data['material_process_matProcessing_origin'].join(', ')}
													icon={data['material_process_matProcessing_transport_icon'].join(', ')}
													iconName={data['material_process_matProcessing_transport_name'].join(', ')}
												/>
											}
										]} />, fullCircle: true
									},
									{ title: t('modal-processing', {detail: t('modal-raw-material')}), content: <ItemTable activeSpace={true} title="" data={[
										{ key: t('modal-raw-material'), value: t('modal-supplier'), bold: true, subtitle: true, boldFull: true },
										...handleListDatas(
											data['material_process_rextraction_names']['material_process_extraction_names'].map((item: string) => item) ?? [],
											data['material_process_rextraction_names']['material_process_rmatProcessing_supplier_names'].map((item: string) => item) ?? [],
											data['material_process_rextraction_names']['material_process_rmatProcessing_origin_abbreviations'].map((item: string) => item) ?? [],
											data['material_process_rextraction_names']['material_process_rmatProcessing_origin_names'].map((item: string) => item) ?? [],
											data['material_process_rextraction_names']['material_process_rmatProcessing_transport_icons'].map((item: string) => item) ?? [],
											data['material_process_rextraction_names']['material_process_rmatProcessing_transport_names'].map((item: string) => item) ?? []
										)
										]} />, fullCircle: true
									},
									{ title: t('modal-extraction', {detail: t('modal-raw-material')} ), content: <ItemTable activeSpace={true} title="" data={[
										{ key: t('modal-raw-material'), value: t('modal-supplier'), bold: true, subtitle: true, boldFull: true },
										...handleListDatas(
											data['material_process_extraction']['material_process_extraction_names'].map((item: any) => item),
											data['material_process_extraction']['material_process_extraction_supplier_names'].map((item: any) => item) ?? [],
											data['material_process_extraction']['material_process_extraction_origin_abbreviations'].map((item: any) => item) ?? [],
											data['material_process_extraction']['material_process_extraction_origin_name'].map((item: any) => item) ?? [],
											data['material_process_extraction']['material_process_extraction_transport_icons'].map((item: any) => item) ?? [],
											data['material_process_extraction']['material_process_extraction_transport_names'].map((item: any) => item) ?? []
										)
										]} />, fullCircle: true
									},
								]}
							/>
						</>
					)}
				</div>

				<div
					className="my-2"
					style={{ borderTop: '0.5px solid rgb(172, 172, 178)' }}
				/>

				<div>
					<div className="mb-3 text-dark-60 text-uppercase font-12px">
						{t(TITLES[3][scope], { scope: scope })}
					</div>
					{scope === 'article' && <ShowDatas {...{ scope, data, t }} />}
					{scope === 'supplier' && (
						<>
							<h1 className="text-dark-100 font-14px mb-1 font-sfpro-medium">
								{t('download-certificates')}
							</h1>
							<div
								className="d-flex flex-column"
								style={{ gap: '8px', maxWidth: '90%' }}
							>
								<div className="d-flex flex-row align-items-center justify-content-between">
									<span className="text-dark-100 font-14px">
										{t('supplier-best-practice-declared')}
									</span>
									{
										blockData
										? 	<BlockData />
										: 	<BestPracticeIndicator
												modal
												data={
													data.supplier_closedLoopWaterSystem_validation
												}
												text={t(
													`best-practice${
														data.supplier_closedLoopWaterSystem_validation
															? ''
															: '-not'
													}-declared`
												)}
											/>
									}
								</div>
								<div className="d-flex flex-row align-items-center justify-content-between">
									<span className="text-dark-100 font-14px">
										{t('supplier-best-practice-not-declared')}
									</span>
									{
										blockData
										? 	<BlockData />
										:	<BestPracticeIndicator
												modal
												data={
													data.supplier_renewableEnergy_validation
												}
												text={t(
													`best-practice${
														data.supplier_renewableEnergy_validation
															? ''
															: '-not'
													}-declared`
												)}
											/>
									}
								</div>
							</div>
						</>
					)}
					{scope === 'material' && (
						<>
							<ItemData
								title={t('common-certificates')}
								data={
									blockData
									? <BlockData />
									: data.material_certificate_names.length > 0
										? data.material_certificate_names
												.filter(
													(item: string) => item !== null
												)
												.join(', ')
										: <NotAllowed text={t('no-certificates')} />
								}
							/>
							<ItemData title={t('modal-notes')} data={data['comments']} />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

interface ItemDataInterface {
	title: string;
	data: any;
	complete?: boolean;
	customDataset?: boolean;
	customDatasetText?: string;
}

const ItemData = ({ title, data, customDataset, customDatasetText }: ItemDataInterface) => (
	<div className="my-3">
		<h1 className="text-dark-100 font-14px mb-1 font-sfpro-medium">
			{customTitles(title)}
		</h1>
		<span className="text-dark-60 font-14px">{data}</span>
		{/* {
			customDataset &&
			<span className='ml-2 bg-yellow px-2 py-1 font-12px rounded-20'>{customDatasetText}</span>
		} */}
	</div>
);

const ArticleScopeDescription = ({
	data,
	determinateCompletionCategory,
	blockData,
	t
}: {
	data: any;
	determinateCompletionCategory: (score: string, value: number) => string;
	blockData: boolean;
	t: any
}) => (
	<>
		<ItemData
			title={t('productCategory')}
			data={data.category ? data.category['name'] : ''}
		/>
		<ItemData title={t('modal-units')} data={data.amount} />
		<ItemTable
			title={t('modal-summary')}
			data={[
				{ key: t('modal-materials'), value: formatNumberNoDecimals(data.totalArticleMaterial), bold: true, subtitle: true },
				{ key: t('sidebar-nav-suppliers'), value: formatNumberNoDecimals(data.Traceability_Suppliers), bold: true, subtitle: true },
				{ key: t('tracCertificates'), value: blockData ? <BlockData /> : formatNumberNoDecimals(data.Traceability_Certificates), bold: true, subtitle: true },
			]}
			activeSpace={false}
		/>
		<ItemData
			title={t('composition')}
			data={
				data.composition.length > 0
					? data.composition
							.filter(({amount}: any) => amount > 0.005)
							.map(
								({ processShortName, amount }: any) =>
									`${formatNumberNoDecimals(
										amount * 100
									)}% ${processShortName}`
							)
							.join(' + ')
					: ''
			}
		/>
		<div className="my-3">
			<h1 className="text-dark-100 font-14px font-sfpro-medium">
				{t('completion')}
			</h1>
			<div
				className="d-flex flex-row align-items-center justify-content-start w-100"
				style={{ gap: '20px' }}
			>
				<ProgressBarSimple
					useColor={true}
					percent={
						data.Traceability_total_suppliers_traced
							? data.Traceability_total_suppliers_traced * 100
							: 0
					}
					color={determinateProgressBarColor(
						data.Traceability_total_suppliers_traced
							? data.Traceability_total_suppliers_traced * 100
							: 0
					)}
					articleSupplier={
						data.Traceability_article_suppliers_traced
							? data.Traceability_article_suppliers_traced * 100
							: 0
					}
					materialSupplier={
						data.Traceability_mat_suppliers_traced
							? data.Traceability_mat_suppliers_traced * 100
							: 0
					}
					rawMaterialSupplier={
						data.Traceability_rmat_suppliers_traced
							? data.Traceability_rmat_suppliers_traced * 100
							: 0
					}
				/>
				<span
					role="traceabilityCategory"
					className="text-dark-100 font-14px"
				>
					{determinateCompletionCategory(
						data.Traceability_score,
						data.Traceability_total_suppliers_traced
					)}
				</span>
			</div>
		</div>
	</>
);

const MaterialScopeDescription = ({
	scope,
	customTitles,
	data,
	handleCompositionMaterial,
	t,
	standarizateName,
	convertToString
}: {
	scope: string;
	customTitles: (letter:string) => string;
	data: any;
	handleCompositionMaterial: any;
	t: any;
	standarizateName: any;
	convertToString: any
}) => {
	const toggleCompositionValues = handleCompositionMaterial(
		data['material_process_extraction_relativeAmounts']
	);
	const splitToggleCompositionValues = toggleCompositionValues.split(' + ');
	const arrayData = [{ key: standarizateName(data['materialName']), value: '', bold: true, subtitle: true }];
	splitToggleCompositionValues.forEach((item: string, index: number) =>
		arrayData.push({
			key: item,
			value: '',
			bold: false,
			subtitle: false
		})
	);
	return (
		<>
			<ItemData
				title={`${customTitles(scope)} category`}
				data={
					data.material_category_names.length > 0
						? data.material_category_names
								.filter((item: any) => item !== null)
								.join(', ')
						: '--'
				}
			/>
			<ItemData
				title={`${customTitles(scope)} application`}
				data={
					data.material_application.length > 0
						? data.material_application
								.filter((item: any) => item !== null)
								.join(', ')
						: '--'
				}
			/>
			<ItemTable title={t('composition')} data={arrayData} indicator activeSpace={false} />
		</>
	);
};

const ItemTable = ({ title, data, indicator, activeSpace = true }: {title: string, data: any, indicator?: boolean, activeSpace: boolean}) => (
	<div className="my-3 position-relative w-100">
		<h1 className="text-dark-100 font-14px mb-1 font-sfpro-medium">
			{title}
		</h1>
		<div
			className={`d-flex flex-column mt-2 position-relative ${indicator ? 'pl-2 item-indicator-container' : ''}`}
		>
			{data.length > 0 ? (
				data.map(({ key, value, bold, boldFull, subtitle, tooltip }: any, index: number) => {
					const [openTooltipOrigin, setOpenTooltipOrigin] = useState(false);
					const tooltipIdItemTable = `item_table__${index}`;

					return (
						<div key={index} className="d-flex flex-row align-items-center position-relative">
							{indicator && (
								<div
									className="position-absolute"
									style={{ left: '-12.5px' }}
								>
									<Dot fullCircle={bold} />
								</div>
							)}
							<div style={{
								width: '55%'
							}}>
								{
									indicator
									?	<BodyText
											id={_.uniqueId('key-')}
											copy={key}
											color={bold ? '100' : '60'}
										/>
									:	<BodyText
											id={_.uniqueId('key-')}
											indicator={_.uniqueId('key-')}
											copy={key}
											color={bold ? '100' : '60'}
											extraCss={
												`flex-grow-1 font-sfpro-${(bold && !subtitle) ? 'medium' : 'regular'} supplier-detail__custom`
											}
										/>
								}
							</div>
							<div style={{
								width: '45%'
							}}>
								<span
									className={`font-14px d-flex align-items-center ${boldFull ? 'text-dark-100' : 'text-dark-60'}`}
									onMouseEnter={() => setOpenTooltipOrigin(true)}
									onMouseLeave={() => setOpenTooltipOrigin(false)}
									id={tooltipIdItemTable}
									style={{
										minHeight: activeSpace ? '37px' : 'auto',
										maxHeight: activeSpace ? '37px' : 'auto',
									}}
								>
									{value}
								</span>
								{
									tooltip &&
									<SimpleTooltip
										tooltipId={tooltipIdItemTable}
										tooltipOpen={openTooltipOrigin}
										text={tooltip}
										styles={{
											width: 'max-content',
											textAlign: 'start',
										}}
									/>
								}
							</div>
						</div>
					);
				})
			) : (
				<span>--</span>
			)}
		</div>
	</div>
);

const Accordeon = ({ data }: any) => {
	const [active, setActive] = useState(-1);

	const handleActive = (item: number) => {
		if (active === item) {
			setActive(-1);
			return;
		}

		setActive(item);
	};
	return (
		<section className="d-flex flex-column w-100">
			{data.map(({ title, content, fullCircle }: any, index: number) => (
				<div
					key={index}
					className="d-flex flex-column w-100 position-relative justify-content-start align-items-start"
					style={{
						height: 'max-content',
					}}
				>
					<header
						className="d-flex flex-row align-items-center w-100"
						onClick={() => handleActive(index)}
						style={{
							cursor: 'pointer',
						}}
					>
						<div className="d-flex flex-row justify-content-start align-items-center flex-grow-1">
							<Dot fullCircle={fullCircle} />
							<h3 className="text-dark-100 font-14px m-0 ml-2 font-sfpro-medium">
								{title}
							</h3>
						</div>
						<span
							className={`icon-icon-chevron-down font-20px hover-dark-100 ${active === index ? 'text-dark-100' : 'text-dark-60'}`}
							style={{
								transition: 'all 0.5s ease',
								transformOrigin: '50% 50%',
								transform:
									active === index ? 'rotate(-180deg)' : '',
							}}
						/>
					</header>
					<div className="pl-4 position-relative w-100">
						{index < data.length - 1 && (
							<div
								className="position-absolute text-dark-60 hover-dark-100
                                            font-14px d-flex flex-column justify-content-start align-items-center"
								style={{
									left: '-1px',
									top: '0px',
									bottom: '0px',
								}}
							>
								<PointerArrow />
								<div
									style={{
										background: '#1C1C30',
										width: '0.5px',
										height: '100%',
										marginTop: '-2.5px',
									}}
								/>
							</div>
						)}
						<div
							style={{
								visibility:
									active === index ? 'visible' : 'hidden',
								height:
									active === index ? 'max-content' : '0px'
							}}
						>
							{content}
						</div>
					</div>
				</div>
			))}
		</section>
	);
};

const PointerArrow = () => (
	<svg
		width="8"
		height="5"
		viewBox="0 0 8 5"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.01764 0.837736C4.1885 0.666882 4.46551 0.666882 4.63636 0.837736L7.86321 4.06458C8.03406 4.23544 8.03406 4.51245 7.86321 4.6833C7.69235 4.85416 7.41534 4.85416 7.24449 4.6833L4.327 1.76581L1.40952 4.6833C1.23866 4.85415 0.961653 4.85415 0.790799 4.6833C0.619944 4.51245 0.619944 4.23544 0.790799 4.06458L4.01764 0.837736Z"
			fill="#1C1C30"
		/>
	</svg>
);

const Dot = ({ fullCircle }: { fullCircle: boolean }) => {
	return createElement('div', {
		style: {
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			background: fullCircle ? '#1C1C30' : '#F6F6F7',
			border: '1px solid #1C1C30',
		},
	});
};

const ShowDatas = ({
	scope,
	data,
	t,
}: {
	scope: string;
	data: any;
	t: any;
}) => {
	const PARAMETERS: any = {
		article: [
			'color',
			'article_process_dye_name',
			'article_process_treatment_name',
			'article_process_print_name',
		],
		material: [
			'material_process_dye_name',
			'material_process_treatment_name',
			'material_process_print_name',
		],
	};

	const handleTitle = (title: string) => {
		let text = title;
		if (title.includes('dye')) {
			text = 'modal-dye';
		} else if (title.includes('treatment')) {
			text = 'modal-treatment';
		} else if (title.includes('print')) {
			text = 'modal-print';
		}

		return t(text, { scope: scope });
	};

	const handleValues = (key: string) => {
		if (data[key]) {
			if (typeof data[key] === 'string') {
				return data[key];
			}

			if (data[key].filter((item: any) => item).length > 0) {
				return data[key]
					.filter((item: string) => item !== null)
					.join(', ');
			}
		}

		return <NotAllowed text={t('not-applicable')} />
	};

	return (
		<>
			{PARAMETERS[scope].map((key: string) => (
				<ItemData
					key={key}
					title={handleTitle(key)}
					data={handleValues(key)}
					customDataset={
						(SCOPE_CUSTOM_DATASET.includes(scope) &&
						key.includes(SHOW_DATASET_CHIP) &&
						data['process_origin_type'] === CUSTOM_DATASET)
					}
					customDatasetText={t('custom-dataset')}
				/>
			))}
		</>
	);
};

const MaterialCategory = ({ data, t }: any) => {
	let total = 0;
	const newArray = [];
	if (data && data.length > 0) {
		data.forEach(
			({
				name,
				weight_distribution,
			}: {
				name: string;
				weight_distribution: number;
			}) => {
				total += weight_distribution;
				newArray.push({
					key: name,
					value: `${formatNumber(weight_distribution)} kg`,
					line: true,
				});
			}
		);
	}

	newArray.push({
		key: 'Total weight',
		value: `${formatNumber(total)} kg`,
		bold: true,
	});

	return <ItemTable title={t('modal-weight-distribution')} data={newArray} activeSpace={false} />;
};

interface SupplierDetailsInterface {
	name: string;
	abbrOrigin: string;
	origin: string;
	icon: string;
	iconName: string;
	emptyValue?: string;
}

const SupplierDetails = ({
	name,
	abbrOrigin,
	origin,
	icon,
	iconName,
	emptyValue,
}: SupplierDetailsInterface) => {
    const [openTooltipImage, setOpenTooltipImage] = useState(false)
    const [openTooltipOrigin, setOpenTooltipOrigin] = useState(false)
	const defaultValue = 'Unknown';
	const localName = typeof name === 'object' ? name['name'] : name

	const filteredName = filterText(localName)
	const nameId = 'name_id_'+ filteredName
    const photoTooltipId = 'icon-' + filteredName
    const originTooltipId = 'origin-' + filteredName

	return (
		<div className="d-flex flex-row justify-content-center align-items-center">
			{localName === '' ? (
				<NotAllowed text={emptyValue} />
			) : (
				<>
					<BodyText
						id={`text-id-${filteredName}`}
						indicator={`text-indicator-${nameId}`}
						copy={localName?.toLowerCase().includes('unknown') ? defaultValue : localName}
						color={localName === 'Unknown' ? '40': '60'}
						extraCss='supplier-detail__name'
					/>
					<span className='mx-1'>|</span>
					<>
                        <span
							className={`${abbrOrigin === '' ? 'text-dark-40' : ''}`}
                            id={originTooltipId}
                            onMouseEnter={() => setOpenTooltipOrigin(true)}
                            onMouseLeave={() => setOpenTooltipOrigin(false)}
                        >{abbrOrigin !== '' ? abbrOrigin : defaultValue}</span>
						{
							abbrOrigin !== '' &&
							<SimpleTooltip
								tooltipId={originTooltipId}
								tooltipOpen={openTooltipOrigin}
								text={origin}
								styles={{
									width: 'max-content',
									textAlign: 'start',
								}}
							/>
						}
                    </>
					<span className='mx-1'>|</span>
					{icon && icon !== '' ? (
						<>
							<img
                                id={photoTooltipId}
								src={icon}
								alt={iconName}
								style={{ width: '32px', height: '37px' }}
                                onMouseEnter={() => setOpenTooltipImage(true)}
                                onMouseLeave={() => setOpenTooltipImage(false)}
							/>
							<SimpleTooltip
								tooltipId={photoTooltipId}
								tooltipOpen={openTooltipImage}
								text={iconName !== '' ? iconName : '--'}
								styles={{
									width: 'max-content',
									textAlign: 'start',
								}}
							/>
						</>
					) : (
						<span className='text-dark-40'>{defaultValue}</span>
					)}
				</>
			)}
		</div>
	);
};

const NotAllowed = ({text}: {text: any}) => <span className='text-dark-40'>{text}</span>
