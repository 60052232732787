import React from "react";
import { useProjects } from "contexts/projectsContext";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { EMAIL_INFORMATION } from "../constants";
import { useMenuState } from "hooks/useMenuState.hook";
import { SpinnerLoader } from "components/Common/Loader/Loader";

type FeatureType = 'plm' | 'data_collection' | 'lca' | 'eco_score' | 'labeling' | 'integration' | 'configuration' | 'stage_comparator' | 'library' | 'cei'

const componentWithoutProjectValidation = ['library', 'stage_comparator', 'configuration', 'integration']

export const validateDataLoader = (Component: any, feature: FeatureType) => {
    return (props: any) => {
        const { projectSelected } = useProjects();
        const { callToAction, loading } = useMenuState(feature)

        if (loading) {
            return <div className="m-auto h-100 custom-spinner">
                <SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
            </div>
        }

        if (callToAction) {
            return <InformationNavigation keyName="upgrade" email={EMAIL_INFORMATION} />;
        }

        if (!projectSelected && !componentWithoutProjectValidation.includes(feature)) {
            return <InformationNavigation keyName="empty-data" email={EMAIL_INFORMATION} />;
        }

        return <Component {...props} />
    }
}