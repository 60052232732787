import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAutocomplete from '../InputAutocomplete/InputAutocomplete';

import ARTICLES_DATA from '../../../mock/articles.json';

interface Props {
	defaultValues?: Array<any>;
}

const ArticlesSearchSelector = (props: Props) => {
	const { t }: any = useTranslation();
	const [articlesSelected, setArticlesSelected]: any = useState([]);
	const [searchValues, setSearchValues]: any = useState([]);
	const { defaultValues } = props;

	useEffect(() => {
		if (defaultValues) {
			setArticlesSelected(defaultValues);
		}
	}, []);

	const onSearch = (e: any) => {
		const value = e.target.value;
		if (value && value.length >= 2) {
			setSearchValues(ARTICLES_DATA);
		} else {
			setSearchValues([]);
		}
	};

	const onClickAutocompleteOption = (value: any) => {
		const n: any = [...articlesSelected];
		n.push(value);
		setArticlesSelected(n);
		if (value) {
			setSearchValues([]);
		}
	};

	const onClickRemoveItem = (item: any, index: any) => {
		const n: any = [...articlesSelected];
		n.splice(index, 1);
		setArticlesSelected(n);
	};

	const selectedValuesHtml: any = [];
	articlesSelected.forEach((value: any, i: number) => {
		selectedValuesHtml.push(
			<div
				key={i}
				className="d-flex flex-row justify-content-between align-items-center border-bottom p-2"
			>
				<span
					className="font-sfpro-regular"
					style={{
						fontSize: '16px',
						lineHeight: '26px',
						color: '#1C1C30',
					}}
				>
					{value.name}
				</span>
				<span
					className="icon-icon-close cursor-pointer font-12px"
					style={{ color: '#ACACB2' }}
					onClick={() => {
						onClickRemoveItem(value, i);
					}}
				></span>
			</div>
		);
	});

	return (
		<div className="w-100">
			<div className="p-3 d-flex flex-column">
				<h1
					className="font-sfpro-regular text-uppercase pb-0 d-flex flex-row align-items-center"
					style={{
						fontSize: '20px',
						color: '#E0A75E',
						lineHeight: '32px',
					}}
				>
					<span className="icon-icon-articles mr-3" />
					{t('articles')}
				</h1>
				<div className="mt-3">
					<InputAutocomplete
						type="text"
						placeholder={t('forms-addArticleToList')}
						icon="icon-icon-search-add"
						labelClassName="input-label"
						valuesType="object"
						valuesKey="name"
						onChange={(e: any) => {
							onSearch(e);
						}}
						values={searchValues}
						onClickAutocompleteOption={onClickAutocompleteOption}
					/>
				</div>
				<div className="mt-4">
					<div className="border-bottom">
						<h4
							className="text-uppercase font-sfpro-medium"
							style={{
								lineHeight: '18px',
								letterSpacing: '0.5px',
								color: '#ACACB2',
								fontSize: '16px',
							}}
						>
							{articlesSelected.length} {t('articles')}{' '}
							{t('common-selected')}
						</h4>
					</div>
					<div className="pb-4">{selectedValuesHtml}</div>
				</div>
			</div>
		</div>
	);
};
export default ArticlesSearchSelector;
