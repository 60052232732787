import React, { useContext, useEffect, useState } from "react";
import { NotificationContainer } from '../Elements/Notification';
import Notification from 'components/Elements/Notification';

import PageHeader from '../../UI/organism/PageHeader/Index';
import Sidebar from "../../UI/organism/Sidebar/Index"

import UserProvider from '../../providers/userProvider';
import PermissionProvider from '../../providers/permissionProvider';
import ModalProvider, { ModalProviderData } from 'providers/modalProvider';
import ModalNewProject from 'components/Modals/ModalNewProject';
import ModalNewProjectArticle from 'components/Modals/ModalNewProjectArticle/ModalNewProjectArticle';
import { APP_MODALS } from '../../constants';
import ModalTest from 'components/Modals/ModalTest';
import Sideright from './Sideright/Sideright';
import SiderightProvider from 'providers/siderightProvider';
import ModalAddMaterialsToArticles from 'components/Modals/ModalAddMaterialsToArticles/ModalAddMaterialsToArticles';
import TUser from 'types/TUser';
import './Base.scss';
import StorageAuth from 'services/storage/Auth';
import { titlesByPage } from "utils/titlesByPage";
import { Head } from "UI/molecules/Head/Index";
import { useProjects } from "contexts/projectsContext";
import { toggleRouteReferenceTypeMaterial } from "utils/utils";


const Base = ({ menu, location, history, children }: any) => {
	const { projectSelected } = useProjects()
	const [user, setUser]: TUser | any = useState({});
	const [showModal, setShowModal] = useState({ modalName: '' });
	const [sideright, setSideright] = useState(false);
	const [permissionAccess, setPermissionAccess]: any = useContext(PermissionProvider);

	useEffect(() => {
		handleUser();
	}, []);

	useEffect(() => {
		if (user && Object.keys(user).length > 0) {
			handlePermissions();
		}
	}, [user]);

	const handleUser = async () => {
		try {
			const response = new StorageAuth().getSessionUser();
			if (response) {
				setUser(response);
			}
		} catch (err) {
			Notification.displayException(err);
		}
	};

	const handlePermissions = async () => {
		try {
			const response = new StorageAuth().getSessionUser()?.roles;
			if (response) {
				setPermissionAccess(response);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const getModalComponent = (ctx: string | ModalProviderData) => {
		const defaultModalProps = {
			showModal: '',
			setShowModal: setShowModal,
			data: null
		};

		let modalName = '';
		if (typeof ctx == 'string') {
			modalName = ctx;
		} else if (typeof ctx == 'object') {
			modalName = ctx.modalName;
			defaultModalProps.data = ctx.data;
		}
		defaultModalProps.showModal = modalName;
		let ret = null;
		switch (modalName) {
			case APP_MODALS.modalNewProject:
				ret = <ModalNewProject {...defaultModalProps} />;
				break;
			case APP_MODALS.modalNewProjectArticle:
				ret = <ModalNewProjectArticle {...defaultModalProps} />;
				break;
			case APP_MODALS.modalNewMaterial:
				ret = <ModalAddMaterialsToArticles {...defaultModalProps} />;
				break;
			case 'test':
				ret = <ModalTest {...defaultModalProps} />;
				break;
		}
		return ret;
	};

	const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const product = wPath.split('/')[2];
	const scope = wPath.split("/")[3];

	const titleByRoute = `${generalProduct}/${product}/${scope}`

	const [isSideBar, setIsSideBar] = useState(true)

	const generalProductsWithoutPageHeader = ['articles']

	return (
		<>
			<Head
				title={
					titlesByPage[wPath]
					? titlesByPage[wPath]
					: titlesByPage[titleByRoute]
					? titlesByPage[titleByRoute]
					: "BCome App"
				}
			/>
			<div className="wrapper">
				<NotificationContainer />
				<ModalProvider.Provider value={[showModal, setShowModal]}>
					<UserProvider.Provider value={[user, setUser]}>
						<SiderightProvider.Provider value={[sideright, setSideright]}>
							<div className={`${wPath.includes('smartLabel') ? '' : "app-container"}`}>
								{!wPath.includes('smartLabel') &&
									<aside className={`app-container__sidebar mt-2 ${isSideBar ? "d-visible" : "d-none"}`}>
										<Sidebar menu={menu} isSideBar={isSideBar} />
									</aside>}
								<main className={`${!isSideBar ? 'open-sidebar': ''} ${wPath.includes('smartLabel') ? '' : 'app-container__main container-fluid vh-100 pr-0 ml-0 pl-0' }`} style={{width: `${wPath.includes('smartLabel') ? "auto" : '0px'}`}}>
									{generalProductsWithoutPageHeader.includes(generalProduct) ? <></> : <PageHeader setIsSideBar={setIsSideBar} isSideBar={isSideBar} />}
									{children}
								</main>
							</div>

							<SiderightProvider.Consumer>
								{(value) => (value ? <Sideright {...value} /> : '')}
							</SiderightProvider.Consumer>

							<ModalProvider.Consumer>
								{(value) => getModalComponent(value[0])}
							</ModalProvider.Consumer>
						</SiderightProvider.Provider>
					</UserProvider.Provider>
				</ModalProvider.Provider>
				{/* <Footer /> */}
			</div>
		</>
	);
};

export default Base;
