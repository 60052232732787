import React from "react";

interface ProjectDropdownProps {
    array: any [];
    itemSelected: string;
	handleClick: (project: any) => void;
}
export const DropDown = ({array, itemSelected, handleClick}: ProjectDropdownProps) => {

	return (
		<div
			className="dropdown-menu show position-absolute"
			style={{ right: -130, left: "auto" }}
		>
			{array?.map((item: any, index: number) => (
				<button
					key={index}
					onClick={() => handleClick(item)}
					className={
						itemSelected === item
							? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
							: "dropdown-item"
					}
				>
					{itemSelected === item && (
						<span className="icon-icon-tick mr-2 text-dark-100 font-18px" />
					)}
					<div
						className={`${
							itemSelected === item
								? "font-sfpro-medium"
								: "font-sfpro-regular"
						}
                        text-overflow-ellipsis overflow-hidden text-capitalize`}
					>
						{item}
					</div>
				</button>
			))}
		</div>
	);
}