import React, { Fragment } from "react"
import { useHistory } from "react-router-dom";
import LastUpdated from "UI/atoms/LastUpdated"
import MethodologyFooter from "UI/atoms/MethodologyFooter"
import PaginationTableFooter from "UI/molecules/PaginationTableFooter"


function TableFooter() {
    const h = useHistory();
    const path = h.location.pathname;

    const displayMethodologyProducts: string[] = ["metrics", "evaluation", "traceability"]

    return(
        <div className="d-flex flex-row align-items-center justify-content-between px-4 py-2">
           <div className="d-flex flex-row justify-content-between">

                {displayMethodologyProducts.map((prod, index) => {
                   if (path.includes(prod)) {
                    return(
                        <Fragment key={index}>
                            {/* Last update */}
                            <LastUpdated />

                            {/* Methodology&database */}
                            {
                                path.includes("data-collection") ? null : (
                                    <MethodologyFooter />
                                )
                            }
                        </Fragment>
                    )}
                })}
           </div>

            {/* pagination */}
            <div className="d-flex flex-row justify-content-between align-items-center"style={{minWidth: "300px"}}>
                <PaginationTableFooter />
            </div>
        </div>
    )
}

export default TableFooter
