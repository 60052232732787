import React, { useState } from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { APP_MODALS } from '../../../constants';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import ArticleFormBasic from 'components/Elements/ArticleForms/ArticleFormBasic';
import ArticleFormDescription from 'components/Elements/ArticleForms/ArticleFormDescription';
import ArticleFormFeatures from 'components/Elements/ArticleForms/ArticleFormFeatures';
import ArticleFormMaterials from 'components/Elements/ArticleForms/ArticleFormMaterials';
import { ModalHeader, ModalFooter, Button } from 'reactstrap';

import './modalNewProjectArticle.scss';
import ModalHelp from '../ModalHelp';
import { SpinnerLoader } from 'components/Common/Loader/Loader';


const DEFAULT_FORM_DATA = {
	reference: '',
	name: '',
	unitsProduced: '',
	category: '',
	supplier: '',
	totalWeight: '',
	color: '',
	treatment: '',
	print: '',
	materials: []
};

const ModalNewProjectArticle = (props: any) => {
	const { showModal, setShowModal } = props;
	const { t }: any = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [showModalHelp, setShowModalHelp] = useState('');
	const [data, setData] = useState(DEFAULT_FORM_DATA);
	const [dataValidations, setDataValidations]: any = useState({}); // Un object con las funciones de validacion de los innputs que estan en otrs componentes;
	const [formHasErrors, setFormHasErrors] = useState(false);

	const { validateForm, setFormFields, formFields } = useFormFields({...DEFAULT_FORM_DATA});

	const pushValidations = (obj: IValidationsObject) => {
		const d = {...dataValidations, ...obj}; 
		setDataValidations(d);
	}

	const save = async () => {
		setFormHasErrors(false);
		// Validations;
		let valid = false;

		setFormFields(data);
		valid = validateForm(data, dataValidations);

		if (valid) {
			setIsLoading(true);
			//save
			//crear en BD
			setIsLoading(false);
		} else {
			setFormHasErrors(true);
		}

		return valid;
	};

	const saveAndClose = async () => {
		const valid = await save();
		if (valid) {
			setShowModal('');
		}
	};

	const saveAndNew = async () => {
		const valid = await save();
		if (valid) {
			// Limipiar values de los inputs;
			// const t: any = DEFAULT_FORM_DATA;
			// setData(t);
			setShowModal('');
			setShowModal(APP_MODALS.modalNewProjectArticle);
		}
	};

	const onClickHelp = () => {
		setShowModalHelp('help-modal');
	};

	const modalHeader = (props: any) => {
		return (
			<ModalHeader className="custom-modal-header">
				<div className="d-flex flex-row justify-content-center align-items-center">
					<span>{t('forms-newArticle')}</span>
					<span
						onClick={() => {
							onClickHelp();
						}}
						className="cursor-pointer ml-2 icon-icon-help-circle"
						style={{ color: '#C2C0C0' }}></span>
				</div>
				<div className="close" onClick={props.handleClose} title={t('common-close')} />
			</ModalHeader>
		);
	};

	const modalFooter = (props: any) => {
		return (
			<ModalFooter className="d-flex justify-content-between">
				<Button
					color="secondary"
					className="d-flex justify-content-center"
					onClick={props.handleClose}
					disabled={isLoading}>
					{t('common-cancel')}
				</Button>
				{formHasErrors && 
				<div>
					<div className="alert alert-danger mb-0" role="alert">
						{t('errors-form-error')}
					</div>
				</div>}
				<div className="d-flex flex-row">
					<Button
						color="secondary"
						className="d-flex justify-content-center align-items-center"
						onClick={() => {
							saveAndClose();
						}}
						disabled={isLoading}>
						{isLoading && <SpinnerLoader />}
						{t('common-save')}
					</Button>
					<Button
						color="primary"
						className="d-flex justify-content-center ml-3"
						onClick={() => {
							saveAndNew();
						}}
						disabled={isLoading}>
						{isLoading && <SpinnerLoader />}
						{t('common-save-and-new')}
					</Button>
				</div>
			</ModalFooter>
		);
	};

	/**
	 * Al pulsar ENTER deberá ir de “Reference” a “Name” y
	 * cuando pulse enter en Name deberá guardarse el artículo y limpiarse el formulario
	 * para permitirle generar uno nuevo
	 * @param e
	 */
	const onKeyDown = (e: any) => {
		if (e.keyCode == 13) {
			// On press enter;
			const activeElement = document.activeElement;
			const elementId = activeElement?.id;
			if (elementId == 'article-form-reference') {
				const next = document.getElementById('article-form-name');
				if (next) {
					next.focus();
				}
			} else if (elementId == 'article-form-name') {
				saveAndNew();
			} else {
				const next = document.getElementById('article-form-reference');
				if (next) {
					next.focus();
				}
			}
		}
	};

	const helpText =
		'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet, commodi quod! Optio nulla corrupti esse minima qui praesentium dolor fuga voluptatum perferendis. Dolores nemo molestiae architecto debitis illo dolorem commodi.';

	return (
		<Modal
			modal={showModal}
			setModal={setShowModal}
			className="page-selector-modal modal-new-project-article"
			scrollable={false}
			createText={t('forms-saveProject')}
			title={t('forms-newArticle')}
			noCancel={false}
			size="extra-bg"
			isLoading={isLoading}
			modalName={APP_MODALS.modalNewProjectArticle}
			HeaderComponent={modalHeader}
			FooterComponent={modalFooter}>
			<>
				<div
					className="container-fluid"
					style={{ backgroundColor: '#F9F9F9' }}
					onKeyDown={(e) => {
						onKeyDown(e);
					}}
					tabIndex={1}>
					<div className="row pb-5">
						<div className="col-12">
							<ArticleFormBasic
								data={data}
								setData={setData}
								pushValidations={pushValidations}
								formName="article-form-basic"
							/>
						</div>
						<div className="col-12 col-md-6 col-xl-8 mt-md-3">
							<ArticleFormDescription 
								data={data}
								setData={setData}
								pushValidations={pushValidations}
								formName="article-form-description"
							/>
							<div className="mt-3">
								<ArticleFormFeatures 
									data={data}
									setData={setData}
									pushValidations={pushValidations}
									formName="article-form-features"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6 col-xl-4 mt-md-3 p-3 rounded bg-white">
							<ArticleFormMaterials 
								data={data}
								setData={setData}
								pushValidations={pushValidations}
								formName="article-form-features"
							/>
						</div>
					</div>
				</div>
				<ModalHelp
					helpText={helpText}
					showModal={showModalHelp}
					setShowModal={setShowModalHelp}
				/>
			</>
		</Modal>
	);
};
export default ModalNewProjectArticle;
