import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTable } from 'contexts/tableContext';
import { DropdownModule } from 'UI/atoms/DropdownModule/Index';
import { SelectorFiltersDropdown } from '../SelectorFiltersDropdown/Index';
import BodyText from 'UI/atoms/BodyText';
import { useProjects } from 'contexts/projectsContext';
import SearchBarModal from 'UI/atoms/SearchBarModal';
import { validateReferenceType } from 'utils/utils';

export const SelectorFiltersModal = (props: any) => {
	const { handleClose, title } = props;
	const { filtersCategoriesByProject, projectSelected } = useProjects();
	const {
		deselectFilters,
		handleApplyFilters,
		openFiltersModal,
		filtersModified,
	} = useTable();
	const { t } = useTranslation();

	const [searchFiltersText, setSearchFiltersText] = useState('');

	if (!openFiltersModal || openFiltersModal === null) {
		return null;
	}

	const filtersGroupsOutMaterialType = [
		{
			groupingValue: t('filters-articleCategory'),
			dataGroup: filtersCategoriesByProject?.articleCategories,
			filterGroup: 'articleCategoryId',
		},
		{
			groupingValue: t('filters-material'), //Article composition
			dataGroup: filtersCategoriesByProject?.materials,
			filterGroup: 'materialId',
		},
	]

	const filterGroups = [
		{
			groupingValue: t('filters-material_category_names'), //Material category
			dataGroup: filtersCategoriesByProject?.materialCategories,
			filterGroup: 'materialCategoryId',
		},
		{
			groupingValue: t('filters-rawMaterialProcess'), //Material composition
			dataGroup: filtersCategoriesByProject?.rawMaterialProcesses,
			filterGroup: 'rawMaterialProcessId',
		},
		{
			groupingValue: t('filters-rawMaterialCategory'),
			dataGroup: filtersCategoriesByProject?.rawMaterialCategories,
			filterGroup: 'rawMaterialCategoryId',
		},
		{
			groupingValue: t('filters-wetProcess'),
			dataGroup: filtersCategoriesByProject?.wetProcesses,
			filterGroup: 'wetProcessId',
		},
		{
			groupingValue: t('filters-transport'),
			dataGroup: filtersCategoriesByProject?.transportCategories,
			filterGroup: 'transportCategoryName',
		},
		{
			groupingValue: t('filters-origins'),
			dataGroup: filtersCategoriesByProject?.origins,
			filterGroup: 'originId',
		},
	];

	if (!validateReferenceType(projectSelected)) {
		filterGroups.unshift(...filtersGroupsOutMaterialType)
	}

	//Search by section
	const searchedGroupValues: string[] = [];
	filterGroups.forEach((g) => {
		if (
			g.groupingValue
				.toLowerCase()
				.includes(searchFiltersText.toLowerCase())
		)
			searchedGroupValues.push(g.groupingValue);
	});

	//Select items by search

	const searchedItems: any = {};
	filterGroups.forEach((group: any) => {
		const valuesGroup: any = [];
		group.dataGroup?.forEach((itemObj: any) => {
			if (
				itemObj.name
					.toLowerCase()
					.includes(searchFiltersText.toLowerCase())
			)
				valuesGroup.push(itemObj);
		});
		// if (validateReferenceType(projectSelected) && group.groupingValue.includes())
		searchedItems[group.groupingValue] = valuesGroup;
	});

	const totalItemsSearched: any[] = [];
	for (const groupingValue in searchedItems) {
		const itemsArr = searchedItems[groupingValue];
		itemsArr.forEach((item: string) => totalItemsSearched.push(item));
	}

	const searchedTotalObj: any = {};
	filterGroups.forEach((g) => {
		if (searchedGroupValues.includes(g.groupingValue))
			searchedTotalObj[g.groupingValue] = g.dataGroup;
		else if (searchedItems[g.groupingValue].length > 0)
			searchedTotalObj[g.groupingValue] = searchedItems[g.groupingValue];
	});

	const getFilterGroup = (groupValue: string) => {
		const nameFilterGroup = filterGroups.filter(
			(g) => g.groupingValue === groupValue
		)[0].filterGroup;
		return nameFilterGroup;
	};

	const getNumItemsSelected = (groupValue: string) => {
		const filterGroup = getFilterGroup(groupValue);
		const numItemsSelected = filtersModified[filterGroup].length;
		return numItemsSelected;
	};

	return (
		<>
			{/* Header */}
			<ModalHeader className="modal-lateral-header mx-3">
				<div className="d-flex flex-column w-100">
					<div className="d-flex flex-row justify-content-between align-items-center">
						<h5 className="font-20px m-0">{title}</h5>
						<span
							className="modal-lateral-close"
							onClick={(e) => handleClose(e)}
						/>
					</div>
					<span className="font-14px font-sfpro-regular text-dark-60 mt-2">
						{t('filters-header-description')}
					</span>
				</div>
			</ModalHeader>

			{/* Body */}
			<ModalBody className="overflow-auto">
				<div className="d-flex flex-column justify-content-between h-100">
					<div>
						{/* Searcher */}
						<div className="mt-3 mb-4">
							<SearchBarModal
								searchText={searchFiltersText}
								setSearchText={setSearchFiltersText}
							/>
						</div>

						{/* Select columns */}
						<div>
							<div className="mt-5 mb-3">
								<BodyText
									copy={t('common-add-filters')}
									bold
									uppercase
									color={'100'}
									extraCss={'mb-5'}
								/>
							</div>
							{searchedGroupValues.length === 0 &&
								totalItemsSearched.length === 0 && (
									<div className="m-3 font-sfpro-regular text-dark-60 text-center">
										{t('properties-not-found')}
									</div>
								)}
							{Object.keys(searchedTotalObj).map((groupValue) => (
								<DropdownModule
									groupSearchColumns={
										searchedTotalObj[groupValue]
									}
									groupingValue={groupValue}
									filterGroup={
										filterGroups.filter(
											(g) =>
												g.groupingValue === groupValue
										)[0].filterGroup
									}
									searchText={searchFiltersText}
									numItemsSelected={getNumItemsSelected(
										groupValue
									)}
									displayNumItemsSelected
								>
									<SelectorFiltersDropdown
										dataGroup={searchedTotalObj[groupValue]}
										filterGroup={
											filterGroups.filter(
												(g) =>
													g.groupingValue ===
													groupValue
											)[0].filterGroup
										}
										searchText={searchFiltersText}
									/>
								</DropdownModule>
							))}
						</div>
					</div>

					{/* Call to action */}
					<div className="d-flex flex-row align-items-end pb-2">
						<BodyText
							copy={t('help-column-selector')}
							color={'60'}
						/>
						<a
							href="mailto: info@bcome.biz"
							className="font-sfpro-regular letter-spacing-normal text-dark-100 font-14px text-underlined mt-5 pl-1"
						>
							{t('contact-us')}!
						</a>
					</div>
				</div>
			</ModalBody>

			{/* Footer */}
			<ModalFooter
				className="d-flex justify-content-between position-fixed bg-white-60 mx-3 px-1"
				style={{
					width: '400px',
					bottom: 0,
					borderTop: '0.5px solid #acacb2',
				}}
			>
				<div
					className="font-14px text-dark-60 font-sfpro-regular deselect-columns"
					onClick={deselectFilters}
				>
					{t('common-clean-all-filters')}
				</div>
				<div className="d-flex flex-row">
					<Button color="tertiary" onClick={(e) => handleClose(e)}>
						{t('common-cancel')}
					</Button>
					<Button
						color="primary"
						className="d-flex justify-content-center ml-2"
						onClick={handleApplyFilters}
					>
						{t('common-apply')}
					</Button>
				</div>
			</ModalFooter>
		</>
	);
};
