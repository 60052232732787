import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "contexts/accountContext";
import { Button } from "reactstrap";
import {
	eCommerceCustom,
	CardContainer,
	CardTab,
} from "types/eCommerceCustomization";
import AccountHTTP from "../../../services/http/Account";
import ModuleSection from "./moduleSection/moduleSection";
import "./eCommerceCustomForm.scss";
import { ModalUpgrade } from "UI/molecules/InformationNavigation/ModalUpgrade";
import Notification from "../Notification";
import { AccountFeatureKeysProps } from "hooks/useMenuState.hook";
import { EcommerceTag } from "UI/atoms/EcommerceTag";

// * Fallback state in case nothing loads on api response
const FB_STATE = {
	traceability: {
		arrangement: { enabled: true, displayed: false, order: 2 },
	},
	environment: {
		main: { enabled: true, order: 0 },
		equivalences: { enabled: true, order: 1 },
		savings: { enabled: true, order: 2 },
		arrangement: { enabled: true, displayed: false, order: 1 },
	},
	sustainability: {
		arrangement: { enabled: true, displayed: false, order: 0 },
	},
	elementSelector: "",
	mode: "append",
	customCss: "",
	customParentCss: "",
};

const ECO_SCORE_STATE_KEY = "eco_score";

const accountReq = new AccountHTTP();

const ECommerceCustomForm = () => {
	const { t } = useTranslation();
	const { account, setAccount } = useAccount();

	const [showModal, setShowModal] = useState(false);
	const [base, setBase] = useState<eCommerceCustom>(FB_STATE);
	const [formState, setFormState] = useState<eCommerceCustom>(FB_STATE);
	const [elementSelector, setElementSelector] = useState<string>(
		FB_STATE.elementSelector
	);
	const [customCss, setCustomCss] = useState<string>(FB_STATE.customCss);
	const [customParentCss, setCustomParentCss] = useState<string>(
		FB_STATE.customParentCss
	);

	const accountEcom = account?.eCommerceCustomization;
	const accountEcomCss = account?.eCommerceCustomization.customCss;
	const accountElementSelector =
		account?.eCommerceCustomization.elementSelector;
	const accountEcomFonts = account?.eCommerceCustomization.customParentCss;

	useEffect(() => {
		if (accountEcom) {
			setFormState(accountEcom);
			setCustomCss(accountEcomCss);
			setElementSelector(accountElementSelector);
			setCustomParentCss(accountEcomFonts);
			setBase(accountEcom);
		}
	}, [accountEcom]);

	const handleSubmit = () => {
		const data = {
			...formState,
			customCss,
			customParentCss,
			elementSelector,
		};

		if (account?.id) {
			const accountDetails = { ...account };
			const body = { ...accountDetails, eCommerceCustomization: data };
			accountReq
				.updateEcommerceConfiguration(account.id, body)
				.then((res: any) => {
					const { eCommerceCustomization } = res.data;
					Notification.display('success', t('save-ecommerce-data-success'));
					const accountUpdated = {
						...account,
						eCommerceCustomization
					}

					if (setAccount) setAccount(accountUpdated);
				});
		}
	};

	const handleCancel = () => {
		setFormState(base);
		setCustomCss(base.customCss);
		setElementSelector(base.elementSelector);
		setCustomParentCss(base.customParentCss);
	};

	const {
		traceability: { arrangement: traceability },
		environment,
		sustainability: { arrangement: sustainability },
	} = formState;

	const handleCardsOrder = (
		card: string,
		prevValue: number,
		newValue: number
	) => {
		const newState: eCommerceCustom = JSON.parse(JSON.stringify(formState));
		// * put the cards in an array to search for the card with the previous order
		const cards = [
			newState.environment.arrangement,
			newState.sustainability.arrangement,
			newState.traceability.arrangement,
		];
		const cardToReplaceIndex: number = cards.findIndex(
			(card: CardContainer) => card.order === newValue
		);

		// * Thanks to the constant array we can handpick the card to replace using the index found above
		switch (cardToReplaceIndex) {
			case 0:
				newState.environment.arrangement.order = prevValue;
				break;
			case 1:
				newState.sustainability.arrangement.order = prevValue;
				break;
			case 2:
				newState.traceability.arrangement.order = prevValue;
				break;
			default:
				null;
				break;
		}

		newState[card].arrangement.order = newValue;

		setFormState(newState);
	};

	const handleTabsOrder = (
		tab: string,
		prevValue: number,
		newValue: number
	) => {
		const newState: eCommerceCustom = JSON.parse(JSON.stringify(formState));
		// * put the tabs in an array to search for the card with the previous order
		const tabs = [
			newState.environment.main,
			newState.environment.equivalences,
			newState.environment.savings,
		];

		const tabToReplaceIndex: number = tabs.findIndex(
			(tab: CardTab) => tab.order === newValue
		);

		// * Thanks to the constant array we can handpick the tab to replace using the index found above
		switch (tabToReplaceIndex) {
			case 0:
				newState.environment.main.order = prevValue;
				break;
			case 1:
				newState.environment.equivalences.order = prevValue;
				break;
			case 2:
				newState.environment.savings.order = prevValue;
				break;
			default:
				null;
				break;
		}

		newState.environment[tab].order = newValue;

		setFormState(newState);
	};

	const handleCheckboxChange = (
		type: string,
		property: string,
		key: string,
		value: boolean
	) => {
		console.log("handleCheckboxChange", type, property, key, value);

		const newState: eCommerceCustom = JSON.parse(JSON.stringify(formState));
		if (type === "card") {
			newState[property].arrangement[key] = value;
		} else {
			newState.environment[property][key] = value;
		}
		setFormState(newState);
	};

	const closeModal = () => {
		setShowModal(false);
	}

	return (
		<>
			<div
				className="configuration-menu container w-100"
				style={{
					height: "100%",
					maxWidth: "none",
					overflowY: "auto",
				}}
			>
				<div
					className="row h-100 w-100 m-0"
					style={{ borderRadius: "12px", backgroundColor: "#F9F9F9" }}
				>
					<div className="col-md-6 p-4">
						<ModuleSection
							moduleTitle={t("traceabilityFacts")}
							moduleDescription={t(
								"traceability-description-ecommerce"
							)}
							enabled={traceability.enabled}
							displayed={traceability.displayed}
							onToggle={({ option, value }) =>
								handleCheckboxChange(
									"card",
									"traceability",
									option,
									value
								)
							}
							order={traceability.order}
							handleCardsOrder={(order: number) =>
								handleCardsOrder(
									"traceability",
									traceability.order,
									order
								)
							}
						/>
						<ModuleSection
							moduleTitle={t("environmentalAssessment")}
							moduleDescription={t(
								"environmental-description-ecommerce"
							)}
							enabled={environment.arrangement.enabled}
							displayed={environment.arrangement.displayed}
							onToggle={({
								option,
								value,
								type = "card",
								property = "environment",
							}) =>
								handleCheckboxChange(
									type,
									property,
									option,
									value
								)
							}
							order={environment.arrangement.order}
							handleCardsOrder={(order: number) =>
								handleCardsOrder(
									"environment",
									environment.arrangement.order,
									order
								)
							}
							handleTabOrder={(
								tab: string,
								prevOrder: number,
								order: number
							) => handleTabsOrder(tab, prevOrder, order)}
							environment={environment}
						/>
						<ModuleSection
							moduleTitle={t("sustainabilityIndex")}
							moduleDescription={t(
								"sustainability-description-ecommerce"
							)}
							enabled={!account?.accountFeatureKeys.some(({key}: AccountFeatureKeysProps) => key === ECO_SCORE_STATE_KEY) ? false : sustainability.enabled}
							displayed={sustainability.displayed}
							onToggle={
								({ option, value }) =>
									account?.accountFeatureKeys.some(({key}: AccountFeatureKeysProps) => key === ECO_SCORE_STATE_KEY) ?
									handleCheckboxChange(
										"card",
										"sustainability",
										option,
										value
									)
									: setShowModal(true)
							}
							order={sustainability.order}
							handleCardsOrder={(order) =>
								handleCardsOrder(
									"traceability",
									traceability.order,
									order
								)
							}
						/>
					</div>

					<div className="col-md-6 p-4">
						<div className="d-flex">
							<EcommerceTag>
								<h3 className="font-sfpro-medium font-14px text-dark-100 my-2">
									{t("set-placement-ecommerce")}
								</h3>
							</EcommerceTag>
						</div>
						<div className="row mt-4">
							<textarea
								className="css-textarea w-100 h-auto bg-white p-3"
								style={{ border: "1px solid #D6D6D7" }}
								onChange={(e) =>
									setElementSelector(e.target.value)
								}
								value={elementSelector}
								placeholder={
									accountElementSelector
										? accountElementSelector
										: t("write-css-selector-ecommerce")
								}
							/>
							<span className="ml-3 font-sfpro-regular font-12px">
								{t("optional-ecommerce")}
							</span>
						</div>

						<EcommerceTag>
							<h3 className="font-sfpro-medium font-14px text-dark-100 mt-4 mb-3 ">
								{t("set-up-css-ecommerce")}
							</h3>
						</EcommerceTag>
						<div className="row mt-4">
							<textarea
								style={{ border: "1px solid #D6D6D7" }}
								className="css-textarea w-100 bg-white p-3"
								onChange={(e) => setCustomCss(e.target.value)}
								value={customCss}
								placeholder={
									accountEcomCss
										? accountEcomCss
										: t("write-css-ecommerce")
								}
							/>
							<span className="ml-3 font-sfpro-regular font-12px">
								{t("optional-ecommerce")}
							</span>
						</div>
						<EcommerceTag>
							<h3 className="font-sfpro-medium font-14px text-dark-100 mt-4 mb-3 ">
								{t("set-up-fonts-ecommerce")}
							</h3>
						</EcommerceTag>
						<div className="row mt-4">
							<textarea
								className="css-textarea w-100 bg-white p-3"
								style={{ border: "1px solid #D6D6D7" }}
								onChange={(e) =>
									setCustomParentCss(e.target.value)
								}
								value={customParentCss}
								placeholder={
									accountEcomFonts
										? accountEcomFonts
										: t("write-fonts-ecommerce")
								}
							/>
							<span className="ml-3 font-sfpro-regular font-12px">
								{t("optional-ecommerce")}
							</span>
						</div>
						<div className="row d-flex justify-content-end mt-4">
							<Button
								className="mx-2 btn btn-tertiary"
								onClick={handleCancel}
								disabled={false}
							>
								{t("common-cancel")}
							</Button>
							<Button
								color="primary-alt"
								className="mr-3"
								onClick={handleSubmit}
								disabled={false}
							>
								{t("common-save")}
							</Button>
						</div>
					</div>
				</div>
			</div>
			<ModalUpgrade
				upgradeMail="info@bcome.biz"
				moreInformationLink="https://bcome.biz/pricing/"
				actionName="Upgrade"
				title={t("ecommerce-upgrade-title")}
				handleClose={() => closeModal()}
				isShow={showModal}
			>
				<div className="mt-2 ml-2 mr-4 text-dark-60 font-14px font-sfpro-regular text-left mb-5">{ t("ecommerce-upgrade-subtitle") }</div>
				<img className="w-75 m-auto" src="../img/sustainability-index-ecommerce.png" alt={t("ecommerce-upgrade-title")} />
			</ModalUpgrade>
		</>
	);
};

export default ECommerceCustomForm;
