import React, { useEffect, useState } from "react";
import { useProjects } from "contexts/projectsContext";
import { useTranslation } from "react-i18next";
import { useTable } from "contexts/tableContext";
import {
	useDownloadExcel,
	useDownloadQrZip,
	useDownloadSmartLabelsExcel,
} from "hooks/useDownloadFile.hook";
import { useHistory } from "react-router-dom";
import { TableActionsMenuButton } from "UI/molecules/TableActionsMenuButton";
import { useAccount } from "contexts/accountContext";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import ArticleHttp from "services/http/Article";

import { handleTitle } from "utils/utils";

interface ProjectDropdownProps {
	handleClick: (project: any) => void;
}
export function ProjectsDropdown(props: ProjectDropdownProps) {
	const { handleClick } = props;
	const { projectSelected, allProjects } = useProjects();

	return (
		<div
			className="dropdown-menu show position-absolute"
			style={{ right: -130, left: "auto" }}
		>
			{allProjects?.map((project: any) => (
				<button
					key={project.id}
					onClick={() => handleClick(project)}
					className={
						projectSelected?.id === project?.id
							? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
							: "dropdown-item"
					}
				>
					{projectSelected?.id === project?.id && (
						<span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
					)}
					<div
						className={`${
							projectSelected?.id === project?.id
								? "font-sfpro-medium"
								: "font-sfpro-regular"
						}
                        text-overflow-ellipsis overflow-hidden`}
					>
						{project.name}
					</div>
				</button>
			))}
		</div>
	);
}

interface ViewButton {
	id: string;
	icon: string;
	label: string;
	action: () => JSX.Element | undefined | void;
}

export function ViewDropDown() {
	const {
		setModalViewActionSelected,
		selectedView,
		setSelectedView,
		viewsByProduct,
	} = useTable();
	const { t } = useTranslation();
	const h = useHistory();

	const globalGroupPresetViews = viewsByProduct?.filter(
		(view: any) => view.groupDropdown === "global"
	);
	const customGroupPresetViews = viewsByProduct?.filter(
		(view: any) => view.groupDropdown === "custom"
	);

	const totalViews = [globalGroupPresetViews, customGroupPresetViews];

	const path = h.location.pathname;
	const product = path.split("/")[1];


	const handleDeleteView = () => {
		if (selectedView) {
			setModalViewActionSelected("deleteView");
		} else {
			return <></>;
		}
	};
	const deleteViewBtn: ViewButton = {
		id: "deleteView",
		icon: "trash",
		label: "deleteView",
		action: handleDeleteView,
	};

	const handleCloneView = () => {
		setModalViewActionSelected("cloneView");
	};
	const cloneViewBtn: ViewButton = {
		id: "cloneView",
		icon: "copy",
		label: "cloneView",
		action: handleCloneView,
	};

	const handleEditViewName = () => {
		setModalViewActionSelected("editNameView");
	};
	const editViewNameBtn: ViewButton = {
		id: "editView",
		icon: "edit",
		label: "editViewName",
		action: handleEditViewName,
	};

	const handleNewView = () => {
		setModalViewActionSelected("createView");
	};
	const createViewBtn: ViewButton = {
		id: "createView",
		icon: "add",
		label: "createView",
		action: handleNewView,
	};

	const tableMenuBtnByPath: any = {
		metrics: [createViewBtn, cloneViewBtn, deleteViewBtn, editViewNameBtn],
		evaluation: [editViewNameBtn],
		diagnosis: [
			createViewBtn,
			cloneViewBtn,
			deleteViewBtn,
			editViewNameBtn,
		],
		smartlabels: [],
	};

	const Line = () => {
		return (
			<div style={{ backgroundColor: "#F0F0F2", height: "1px" }}></div>
		);
	};

	const ViewButton = ({ view }: any) => (
		<button
			key={view.name}
			onClick={() => setSelectedView(view)}
			style={{ height: "36px" }}
			className={
				view === selectedView
					? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
					: "dropdown-item"
			}
		>
			{view === selectedView && (
				<span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
			)}
			<div
				className={`font-12px text-overflow-ellipsis overflow-hidden ${
					view === selectedView
						? "font-sfpro-medium"
						: "font-sfpro-regular"
				}`}
			>
				{t(handleTitle(view))}
			</div>
		</button>
	);

	return (
		<div
			className="dropdown-menu show position-absolute overflow-auto rounded-10"
			style={{
				width: "163px",
				right: "-110px",
				left: "auto",
				maxHeight: "300px",
			}}
		>
			{totalViews.map((groupView, i) => (
				<>
					{groupView.map((view: any) => (
						<ViewButton view={view} />
					))}
					{i < totalViews.length - 1 &&
						totalViews[i].length > 0 &&
						totalViews[i + 1].length > 0 && <Line />}
				</>
			))}
			{tableMenuBtnByPath[product] && <Line />}
			{tableMenuBtnByPath[product] &&
				tableMenuBtnByPath[product].map((button: ViewButton) => (
					<TableActionsMenuButton
						icon={button.icon}
						label={button.label}
						action={button.action}
					/>
				))}
		</div>
	);
}

interface ArticleSelectorDropdownProps {
	articleClick: (id: string) => void;
	articleId: string;
}

interface TableOptionsDropDownProps {
	extraCss?: string;
}

export function TableViewOptionsDropDown(props: TableOptionsDropDownProps) {
	const { handleLoading } = useTable();
	const { projectSelected } = useProjects();
	const h = useHistory();

	const path = h.location.pathname;


	const handleExportData = () => {
		if (path.includes("smartlabels")) {
			handleLoading(true);
			useDownloadSmartLabelsExcel(
				projectSelected.id,
				projectSelected.name,
				handleLoading
			);
		} else {
			handleLoading(true);
			useDownloadExcel(
				projectSelected.id,
				projectSelected.name,
				handleLoading
			);
		}
	};

	const handleDownloadZip = () => {
		handleLoading(true);
		useDownloadQrZip(
			projectSelected?.id,
			`QR_Project_${projectSelected?.name}.zip`,
			handleLoading
		);
	};

	const exportDataBtn = {
		id: "exportData",
		icon: "download",
		label: "exportData",
		action: handleExportData,
		group: "1",
	};

	const downloadSvgBtn = {
		id: "downloadSvg",
		icon: "download",
		label: "downloadSvg",
		action: handleDownloadZip,
		group: "1",
	};

	const tableMenuBtnByPath: any = {
		metrics: [],
		evaluation: [],
		diagnosis: [],
		smartlabels: [exportDataBtn, downloadSvgBtn],
	};

	return (
		<div
			className="dropdown-menu show position-absolute text-dark-60 font-sfpro-regular"
			style={{ left: "-132px", width: "163px" }}
		>
			{Object.keys(tableMenuBtnByPath).map((p) => {
				if (path.includes(p)) {
					const btnsByGroupObj: any = {};
					const btnsByGroupArr: any = [];
					tableMenuBtnByPath[p].forEach((b: any) => {
						if (!Object.keys(btnsByGroupObj).includes(b.group))
							btnsByGroupObj[b.group] = [b];
						else btnsByGroupObj[b.group].push(b);
					});
					for (const group in btnsByGroupObj)
						btnsByGroupArr.push(btnsByGroupObj[group]);
					return btnsByGroupArr.map((bg: any, i: number) => (
						<>
							{bg.map((button: any) => {
								return (
									<TableActionsMenuButton
										icon={button.icon}
										label={button.label}
										action={button.action}
									/>
								);
							})}
							{i + 1 < btnsByGroupArr.length && (
								<div
									style={{
										backgroundColor: "#F0F0F2",
										height: "1px",
									}}
								></div>
							)}
						</>
					));
				}
			})}
		</div>
	);
}

export function ArticleSelectorDropdown(props: ArticleSelectorDropdownProps) {
	const { articleClick, articleId } = props;
	const { projectSelected } = useProjects();
	const [articlesSearched, setArticlesSearched] = useState([]);
	const [searchReference, setSearchReference] = useState("");
	const ArticlesResquest = new ArticleHttp();

	const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchReference(e.target.value);
	};

	const getArticles = () => {
		const queryString = RequestQueryBuilder.create({
			search: {
				$and: [
					{ projectId: { $eq: projectSelected.id } },
					{ reference: { $contL: searchReference } },
				],
			},
			sort: [{ field: "reference", order: "ASC" }],
			resetCache: true,
		}).query();

		const request = ArticlesResquest.articlesByProject(queryString);
		request
			.then((res) => {
				setArticlesSearched(res.data);
			})
			.catch((err) => console.log({ err }));
	};

	useEffect(() => {
		getArticles();
	}, [searchReference]);

	return (
		<>
			<div
				className="dropdown-menu show position-absolute overflow-auto"
				style={{
					right: -170,
					left: "auto",
					maxHeight: "300px",
					width: "203px",
				}}
			>
				<form
					className="search-bar-modal d-flex align-items-center mb-3 mx-3 mt-2"
					style={{
						border: "#75747F 1px solid",
						borderRadius: "7px",
						width: "168px",
						height: "32px",
					}}
				>
					<div className="d-flex align-items-center">
						<span
							className={`icon-icon-search font-13px mr-2`}
						></span>
					</div>
					<input
						className="search-input font-sfpro-regular"
						type="text"
						placeholder="Article reference"
						value={searchReference}
						onChange={handleSelect}
					/>
				</form>

				{articlesSearched?.map((article: any) => (
					<button
						style={{ width: "168px" }}
						key={article.id}
						onClick={() => articleClick(article.id)}
						className={
							articleId === article.id
								? "selected-project font-sfpro-medium d-flex align-items-center dropdown-item"
								: "dropdown-item"
						}
					>
						{articleId === article.id && (
							<span className="icon-icon-tick mr-2 text-dark-100 font-18px"></span>
						)}
						<div
							className={`${
								articleId === article.id
									? "font-sfpro-medium"
									: "font-sfpro-regular"
							}
                        text-overflow-ellipsis overflow-hidden`}
						>
							{article.reference}
						</div>
					</button>
				))}
			</div>
		</>
	);
}
