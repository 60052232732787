export const equivalenceByStaticColumns: any = {
	"Water": "WS-U_IS",
	"WaterTotal": "WS-P_IS",
	"Equivalences_Water_Relative": "WS-UE_IS",
	"Equivalences_Water_Total": "WS-PE_IS",
	"ClimateChange": "GW-U_IS",
	"ClimateChangeTotal": "GW-P_IS",
	"Equivalences_ClimateChange_Relative": "GW-UE_IS",
	"Equivalences_ClimateChange_Total": "GW-PE_IS",
	"Eutrophication": "Eutr-U_IS",
	"EutrophicationTotal": "Eutr-P_IS",
	"Equivalences_Eutrophication_Relative": "Eutr-UE_IS",
	"Equivalences_Eutrophication_Total": "Eutr-PE_IS",
	"FossilFuels": "AD-U_IS",
	"FossilFuelsTotal": "AD-P_IS",
	"Equivalences_FossilFuels_Relative": "AD-UE_IS",
	"Equivalences_FossilFuels_Total": "AD-PE_IS",


	// extraction water impact
	'RawMaterial_Water': 'WS-RME-U_IS',
	'RawMaterial_Water_Total': 'WS-RME-P_IS',
	'Equivalences_RawMaterial_Water_Relative': 'WS-RME-UE_IS',
	'Equivalences_RawMaterial_Water_Total': 'WS-RME-PE_IS',

	// extraction climate change impact
	'RawMaterial_ClimateChange': 'GW-RME-U_IS',
	'RawMaterial_ClimateChange_Total': 'GW-RME-P_IS',
	'Equivalences_RawMaterial_ClimateChange_Relative': 'GW-RME-UE_IS',
	'Equivalences_RawMaterial_ClimateChange_Total': 'GW-RME-PE_IS',

	// extraction eutrophication impact
	'RawMaterial_Eutrophication': 'Eutr-RME-U_IS',
	'RawMaterial_Eutrophication_Total': 'Eutr-RME-P_IS',
	'Equivalences_RawMaterial_Eutrophication_Relative': 'Eutr-RME-UE_IS',
	'Equivalences_RawMaterial_Eutrophication_Total': 'Eutr-RME-PE_IS',

	// extraction fossil fuels impact
	'RawMaterial_FossilFuels': 'AD-RME-U_IS',
	'RawMaterial_FossilFuels_Total': 'AD-RME-P_IS',
	'Equivalences_RawMaterial_FossilFuels_Relative': 'AD-RME-UE_IS',
	'Equivalences_RawMaterial_FossilFuels_Total': 'AD-RME-PE_IS',

	// material processing water impact
	'MaterialProc_Water': 'WS-MP-U_IS',
	'MaterialProc_Water_Total': 'WS-MP-P_IS',
	'Equivalences_MaterialProc_Water_Relative': 'WS-MP-UE_IS',
	'Equivalences_MaterialProc_Water_Total': 'WS-MP-PE_IS',

	// material processing climate change impact
	'MaterialProc_ClimateChange': 'GW-MP-U_IS',
	'MaterialProc_ClimateChange_Total': 'GW-MP-P_IS',
	'Equivalences_MaterialProc_ClimateChange_Relative': 'GW-MP-UE_IS',
	'Equivalences_MaterialProc_ClimateChange_Total': 'GW-MP-PE_IS',

	// material processing eutrophication impact
	'MaterialProc_Eutrophication': 'Eutr-MP-U_IS',
	'MaterialProc_Eutrophication_Total': 'Eutr-MP-P_IS',
	'Equivalences_MaterialProc_Eutrophication_Relative': 'Eutr-MP-UE_IS',
	'Equivalences_MaterialProc_Eutrophication_Total': 'Eutr-MP-PE_IS',

	// material processing fossil fuels impact
	'MaterialProc_FossilFuels': 'AD-MP-U_IS',
	'MaterialProc_FossilFuels_Total': 'AD-MP-P_IS',
	'Equivalences_MaterialProc_FossilFuels_Relative': 'AD-MP-UE_IS',
	'Equivalences_MaterialProc_FossilFuels_Total': 'AD-MP-PE_IS',

	// material finishing water impact
	'MaterialDFP_Water': 'WS-MF-U_IS',
	'MaterialDFP_Water_Total': 'WS-MF-P_IS',
	'Equivalences_MaterialDFP_Water_Relative': 'WS-MF-UE_IS',
	'Equivalences_MaterialDFP_Water_Total': 'WS-MF-PE_IS',

	// material finishing climate change impact
	'MaterialDFP_ClimateChange': 'GW-MF-U_IS',
	'MaterialDFP_ClimateChange_Total': 'GW-MF-P_IS',
	'Equivalences_MaterialDFP_ClimateChange_Relative': 'GW-MF-UE_IS',
	'Equivalences_MaterialDFP_ClimateChange_Total': 'GW-MF-PE_IS',

	// material finishing eutrophication impact
	'MaterialDFP_Eutrophication': 'Eutr-MF-U_IS',
	'MaterialDFP_Eutrophication_Total': 'Eutr-MF-P_IS',
	'Equivalences_MaterialDFP_Eutrophication_Relative': 'Eutr-MF-UE_IS',
	'Equivalences_MaterialDFP_Eutrophication_Total': 'Eutr-MF-PE_IS',

	// material finishing fossil fuels impact
	'MaterialDFP_FossilFuels': 'AD-MF-U_IS',
	'MaterialDFP_FossilFuels_Total': 'AD-MF-P_IS',
	'Equivalences_MaterialDFP_FossilFuels_Relative': 'AD-MF-UE_IS',
	'Equivalences_MaterialDFP_FossilFuels_Total': 'AD-MF-PE_IS',

	// article finishing water impact
	'ArticleDFP_Water': 'WS-AF-U_IS',
	'ArticleDFP_Water_Total': 'WS-AF-P_IS',
	'Equivalences_ArticleDFP_Water_Relative': 'WS-AF-UE_IS',
	'Equivalences_ArticleDFP_Water_Total': 'WS-AF-PE_IS',

	// article finishing climate change impact
	'ArticleDFP_ClimateChange': 'GW-AF-U_IS',
	'ArticleDFP_ClimateChange_Total': 'GW-AF-P_IS',
	'Equivalences_ArticleDFP_ClimateChange_Relative': 'GW-AF-UE_IS',
	'Equivalences_ArticleDFP_ClimateChange_Total': 'GW-AF-PE_IS',

	// article finishing eutrophication impact
	'ArticleDFP_Eutrophication': 'Eutr-AF-U_IS',
	'ArticleDFP_Eutrophication_Total': 'Eutr-AF-P_IS',
	'Equivalences_ArticleDFP_Eutrophication_Relative': 'Eutr-AF-UE_IS',
	'Equivalences_ArticleDFP_Eutrophication_Total': 'Eutr-AF-PE_IS',

	// article finishing fossil fuels impact
	'ArticleDFP_FossilFuels': 'AD-AF-U_IS',
	'ArticleDFP_FossilFuels_Total': 'AD-AF-P_IS',
	'Equivalences_ArticleDFP_FossilFuels_Relative': 'AD-AF-UE_IS',
	'Equivalences_ArticleDFP_FossilFuels_Total': 'AD-AF-PE_IS',

	// manufacture water impact
	'Manufacture_Water': 'WS-M-U_IS',
	'Manufacture_Water_Total': 'WS-M-P_IS',
	'Equivalences_Manufacture_Water_Relative': 'WS-M-UE_IS',
	'Equivalences_Manufacture_Water_Total': 'WS-M-PE_IS',

	// manufacture climate change impact
	'Manufacture_ClimateChange': 'GW-M-U_IS',
	'Manufacture_ClimateChange_Total': 'GW-M-P_IS',
	'Equivalences_Manufacture_ClimateChange_Relative': 'GW-M-UE_IS',
	'Equivalences_Manufacture_ClimateChange_Total': 'GW-M-PE_IS',

	// manufacture eutrophication impact
	'Manufacture_Eutrophication': 'Eutr-M-U_IS',
	'Manufacture_Eutrophication_Total': 'Eutr-M-P_IS',
	'Equivalences_Manufacture_Eutrophication_Relative': 'Eutr-M-UE_IS',
	'Equivalences_Manufacture_Eutrophication_Total': 'Eutr-M-PE_IS',

	// manufacture fossil fuels impact
	'Manufacture_FossilFuels': 'AD-M-U_IS',
	'Manufacture_FossilFuels_Total': 'AD-M-P_IS',
	'Equivalences_Manufacture_FossilFuels_Relative': 'AD-M-UE_IS',
	'Equivalences_Manufacture_FossilFuels_Total': 'AD-M-PE_IS',

	// transport water impact
	'Transport_Water': 'WS-T-U_IS',
	'Transport_Water_Total': 'WS-T-P_IS',
	'Equivalences_Transport_Water_Relative': 'WS-T-UE_IS',
	'Equivalences_Transport_Water_Total': 'WS-T-PE_IS',

	// transport climate change impact
	'Transport_ClimateChange': 'GW-T-U_IS',
	'Transport_ClimateChange_Total': 'GW-T-P_IS',
	'Equivalences_Transport_ClimateChange_Relative': 'GW-T-UE_IS',
	'Equivalences_Transport_ClimateChange_Total': 'GW-T-PE_IS',

	// transport eutrophication impact
	'Transport_Eutrophication': 'Eutr-T-U_IS',
	'Transport_Eutrophication_Total': 'Eutr-T-P_IS',
	'Equivalences_Transport_Eutrophication_Relative': 'Eutr-T-UE_IS',
	'Equivalences_Transport_Eutrophication_Total': 'Eutr-T-PE_IS',

	// transport fossil fuels impact
	'Transport_FossilFuels': 'AD-T-U_IS',
	'Transport_FossilFuels_Total': 'AD-T-P_IS',
	'Equivalences_Transport_FossilFuels_Relative': 'AD-T-UE_IS',
	'Equivalences_Transport_FossilFuels_Total': 'AD-T-PE_IS',

	// packaging water impact
	'LaDP_Water': 'WS-Pack-U_IS',
	'LaDP_Water_Total': 'WS-Pack-P_IS',
	'Equivalences_LaDP_Water_Relative': 'WS-Pack-UE_IS',
	'Equivalences_LaDP_Water_Total': 'WS-Pack-PE_IS',

	// packaging climate change impact
	'LaDP_ClimateChange': 'GW-Pack-U_IS',
	'LaDP_ClimateChange_Total': 'GW-Pack-P_IS',
	'Equivalences_LaDP_ClimateChange_Relative': 'GW-Pack-UE_IS',
	'Equivalences_LaDP_ClimateChange_Total': 'GW-Pack-PE_IS',

	// packaging eutrophication impact
	'LaDP_Eutrophication': 'Eutr-Pack-U_IS',
	'LaDP_Eutrophication_Total': 'Eutr-Pack-P_IS',
	'Equivalences_LaDP_Eutrophication_Relative': 'Eutr-Pack-UE_IS',
	'Equivalences_LaDP_Eutrophication_Total': 'Eutr-Pack-PE_IS',

	// packaging fossil fuels impact
	'LaDP_FossilFuels': 'AD-Pack-U_IS',
	'LaDP_FossilFuels_Total': 'AD-Pack-P_IS',
	'Equivalences_LaDP_FossilFuels_Relative': 'AD-Pack-UE_IS',
	'Equivalences_LaDP_FossilFuels_Total': 'AD-Pack-PE_IS',
}

export const staticsColums = [
	"name",
	"Water",
	"WaterTotal",
	"Equivalences_Water_Relative",
	"Equivalences_Water_Total",
	"ClimateChange",
	"ClimateChangeTotal",
	"Equivalences_ClimateChange_Relative",
	"Equivalences_ClimateChange_Total",
	"Eutrophication",
	"EutrophicationTotal",
	"Equivalences_Eutrophication_Relative",
	"Equivalences_Eutrophication_Total",
	"FossilFuels",
	"FossilFuelsTotal",
	"Equivalences_FossilFuels_Relative",
	"Equivalences_FossilFuels_Total",
	'ArticleDFP_FossilFuels',
	'ArticleDFP_Eutrophication',
	'ArticleDFP_ClimateChange',
	'ArticleDFP_Water',
	'Equivalences_ArticleDFP_Water_Relative',
	'Equivalences_ArticleDFP_ClimateChange_Relative',
	'Equivalences_ArticleDFP_Eutrophication_Relative',
	'Equivalences_ArticleDFP_FossilFuels_Relative',
	'ArticleDFP_Water_Total',
	'ArticleDFP_ClimateChange_Total',
	'ArticleDFP_Eutrophication_Total',
	'ArticleDFP_FossilFuels_Total',
	'Equivalences_ArticleDFP_Water_Total',
	'Equivalences_ArticleDFP_ClimateChange_Total',
	'Equivalences_ArticleDFP_Eutrophication_Total',
	'Equivalences_ArticleDFP_FossilFuels_Total',
	'RawMaterial_Water',
	'RawMaterial_ClimateChange',
	"RawMaterial_Eutrophication",
	"RawMaterial_FossilFuels",
	"Equivalences_RawMaterial_Water_Relative",
	"Equivalences_RawMaterial_ClimateChange_Relative",
	"Equivalences_RawMaterial_Eutrophication_Relative",
	"Equivalences_RawMaterial_FossilFuels_Relative",
	"RawMaterial_Water_Total",
	"RawMaterial_ClimateChange_Total",
	"RawMaterial_Eutrophication_Total",
	"RawMaterial_FossilFuels_Total",
	"Equivalences_RawMaterial_Water_Total",
	"Equivalences_RawMaterial_ClimateChange_Total",
	"Equivalences_RawMaterial_Eutrophication_Total",
	"Equivalences_RawMaterial_FossilFuels_Total",
	"Manufacture_Water",
	"Manufacture_ClimateChange",
	"Manufacture_Eutrophication",
	"Manufacture_FossilFuels",
	"Equivalences_Manufacture_Water_Relative",
	"Equivalences_Manufacture_ClimateChange_Relative",
	"Equivalences_Manufacture_Eutrophication_Relative",
	"Equivalences_Manufacture_FossilFuels_Relative",
	"Manufacture_Water_Total",
	"Manufacture_ClimateChange_Total",
	"Manufacture_Eutrophication_Total",
	"Manufacture_FossilFuels_Total",
	"Equivalences_Manufacture_Water_Total",
	"Equivalences_Manufacture_ClimateChange_Total",
	"Equivalences_Manufacture_Eutrophication_Total",
	"Equivalences_Manufacture_FossilFuels_Total",
	"MaterialDFP_Water",
	"MaterialDFP_ClimateChange",
	"MaterialDFP_Eutrophication",
	"MaterialDFP_FossilFuels",
	"Equivalences_MaterialDFP_Water_Relative",
	"Equivalences_MaterialDFP_ClimateChange_Relative",
	"Equivalences_MaterialDFP_Eutrophication_Relative",
	"Equivalences_MaterialDFP_FossilFuels_Relative",
	"Equivalences_MaterialDFP_Water_Total",
	"Equivalences_MaterialDFP_ClimateChange_Total",
	"Equivalences_MaterialDFP_Eutrophication_Total",
	"Equivalences_MaterialDFP_FossilFuels_Total",
	"MaterialProc_Water",
	"MaterialProc_Water_Total",
	"Equivalences_MaterialProc_Water_Relative",
	"Equivalences_MaterialProc_Water_Total",
	"MaterialProc_ClimateChange",
	"MaterialProc_ClimateChange_Total",
	"Equivalences_MaterialProc_ClimateChange_Relative",
	"Equivalences_MaterialProc_ClimateChange_Total",
	"MaterialProc_Eutrophication",
	"MaterialProc_Eutrophication_Total",
	"Equivalences_MaterialProc_Eutrophication_Relative",
	"Equivalences_MaterialProc_Eutrophication_Total",
	"MaterialProc_FossilFuels",
	"MaterialProc_FossilFuels_Total",
	"Equivalences_MaterialProc_FossilFuels_Relative",
	"Equivalences_MaterialProc_FossilFuels_Total",
	"MaterialDFP_Water_Total",
	"MaterialDFP_ClimateChange_Total",
	"MaterialDFP_Eutrophication_Total",
	"MaterialDFP_FossilFuels_Total",
	"MaterialProc_Water",
	"MaterialProc_ClimateChange",
	"MaterialProc_Eutrophication",
	"MaterialProc_FossilFuels",
	"MaterialProc_Water_Total",
	"MaterialProc_ClimateChange_Total",
	"MaterialProc_Eutrophication_Total",
	"MaterialProc_FossilFuels_Total",
	"ArticleDFP_Water",
	"ArticleDFP_ClimateChange",
	"ArticleDFP_Eutrophication",
	"ArticleDFP_FossilFuels",
	"ArticleDFP_Water_Total",
	"ArticleDFP_ClimateChange_Total",
	"ArticleDFP_Eutrophication_Total",
	"ArticleDFP_FossilFuels_Total",
	"Transport_Water",
	"Transport_Water_Total",
	"Equivalences_Transport_Water_Relative",
	"Equivalences_Transport_Water_Total",

	"Transport_ClimateChange",
	"Transport_ClimateChange_Total",
	"Equivalences_Transport_ClimateChange_Relative",
	"Equivalences_Transport_ClimateChange_Total",

	"Transport_Eutrophication",
	"Transport_Eutrophication_Total",
	"Equivalences_Transport_Eutrophication_Relative",
	"Equivalences_Transport_Eutrophication_Total",

	"Transport_FossilFuels",
	"Transport_FossilFuels_Total",
	"Equivalences_Transport_FossilFuels_Relative",
	"Equivalences_Transport_FossilFuels_Total",

	"LaDP_Water",
	"LaDP_Water_Total",
	"Equivalences_LaDP_Water_Relative",
	"Equivalences_LaDP_Water_Total",

	"LaDP_ClimateChange",
	"LaDP_ClimateChange_Total",
	"Equivalences_LaDP_ClimateChange_Relative",
	"Equivalences_LaDP_ClimateChange_Total",

	"LaDP_Eutrophication",
	"LaDP_Eutrophication_Total",
	"Equivalences_LaDP_Eutrophication_Relative",
	"Equivalences_LaDP_Eutrophication_Total",

	"LaDP_FossilFuels",
	"LaDP_FossilFuels_Total",
	"Equivalences_LaDP_FossilFuels_Relative",
	"Equivalences_LaDP_FossilFuels_Total",
]

export const SAVING_DEBTS_INDUSTRY_STANDARD: any = {
	"Water": "Savings_Water_Relative",
	"WaterTotal": "Savings_Water_Relative",
	"Equivalences_Water_Relative": "Savings_Water_Relative",
	"Equivalences_Water_Total": "Savings_Water_Relative",

	"ClimateChange": "Savings_ClimateChange_Relative",
	"ClimateChangeTotal": "Savings_ClimateChange_Relative",
	"Equivalences_ClimateChange_Relative": "Savings_ClimateChange_Relative",
	"Equivalences_ClimateChange_Total": "Savings_ClimateChange_Relative",

	"Eutrophication": "Savings_Eutrophication_Relative",
	"EutrophicationTotal": "Savings_Eutrophication_Relative",
	"Equivalences_Eutrophication_Relative": "Savings_Eutrophication_Relative",
	"Equivalences_Eutrophication_Total": "Savings_Eutrophication_Relative",

	"FossilFuels": "Savings_FossilFuels_Relative",
	"FossilFuelsTotal": "Savings_FossilFuels_Relative",
	"Equivalences_FossilFuels_Relative": "Savings_FossilFuels_Relative",
	"Equivalences_FossilFuels_Total": "Savings_FossilFuels_Relative",

	// extraction water impact
	'RawMaterial_Water': 'WS-RME-R_SD',
	'RawMaterial_Water_Total': 'WS-RME-R_SD',
	'Equivalences_RawMaterial_Water_Relative': 'WS-RME-R_SD',
	'Equivalences_RawMaterial_Water_Total': 'WS-RME-R_SD',

	// extraction climate change impact
	'RawMaterial_ClimateChange': 'GW-RME-R_SD',
	'RawMaterial_ClimateChange_Total': 'GW-RME-R_SD',
	'Equivalences_RawMaterial_ClimateChange_Relative': 'GW-RME-R_SD',
	'Equivalences_RawMaterial_ClimateChange_Total': 'GW-RME-R_SD',

	// extraction eutrophication impact
	'RawMaterial_Eutrophication': 'Eutr-RME-R_SD',
	'RawMaterial_Eutrophication_Total': 'Eutr-RME-R_SD',
	'Equivalences_RawMaterial_Eutrophication_Relative': 'Eutr-RME-R_SD',
	'Equivalences_RawMaterial_Eutrophication_Total': 'Eutr-RME-R_SD',

	// extraction fossil fuels impact
	'RawMaterial_FossilFuels': 'AD-RME-R_SD',
	'RawMaterial_FossilFuels_Total': 'AD-RME-R_SD',
	'Equivalences_RawMaterial_FossilFuels_Relative': 'AD-RME-R_SD',
	'Equivalences_RawMaterial_FossilFuels_Total': 'AD-RME-R_SD',

	// material processing water impact
	'MaterialProc_Water': 'WS-MP-R_SD',
	'MaterialProc_Water_Total': 'WS-MP-R_SD',
	'Equivalences_MaterialProc_Water_Relative': 'WS-MP-R_SD',
	'Equivalences_MaterialProc_Water_Total': 'WS-MP-R_SD',

	// material processing climate change impact
	'MaterialProc_ClimateChange': 'GW-MP-R_SD',
	'MaterialProc_ClimateChange_Total': 'GW-MP-R_SD',
	'Equivalences_MaterialProc_ClimateChange_Relative': 'GW-MP-R_SD',
	'Equivalences_MaterialProc_ClimateChange_Total': 'GW-MP-R_SD',

	// material processing eutrophication impact
	'MaterialProc_Eutrophication': 'Eutr-MP-R_SD',
	'MaterialProc_Eutrophication_Total': 'Eutr-MP-R_SD',
	'Equivalences_MaterialProc_Eutrophication_Relative': 'Eutr-MP-R_SD',
	'Equivalences_MaterialProc_Eutrophication_Total': 'Eutr-MP-R_SD',

	// material processing fossil fuels impact
	'MaterialProc_FossilFuels': 'AD-MP-R_SD',
	'MaterialProc_FossilFuels_Total': 'AD-MP-R_SD',
	'Equivalences_MaterialProc_FossilFuels_Relative': 'AD-MP-R_SD',
	'Equivalences_MaterialProc_FossilFuels_Total': 'AD-MP-R_SD',

	// material finishing water impact
	'MaterialDFP_Water': 'WS-MF-R_SD',
	'MaterialDFP_Water_Total': 'WS-MF-R_SD',
	'Equivalences_MaterialDFP_Water_Relative': 'WS-MF-R_SD',
	'Equivalences_MaterialDFP_Water_Total': 'WS-MF-R_SD',

	// material finishing climate change impact
	'MaterialDFP_ClimateChange': 'GW-MF-R_SD',
	'MaterialDFP_ClimateChange_Total': 'GW-MF-R_SD',
	'Equivalences_MaterialDFP_ClimateChange_Relative': 'GW-MF-R_SD',
	'Equivalences_MaterialDFP_ClimateChange_Total': 'GW-MF-R_SD',

	// material finishing eutrophication impact
	'MaterialDFP_Eutrophication': 'Eutr-MF-R_SD',
	'MaterialDFP_Eutrophication_Total': 'Eutr-MF-R_SD',
	'Equivalences_MaterialDFP_Eutrophication_Relative': 'Eutr-MF-R_SD',
	'Equivalences_MaterialDFP_Eutrophication_Total': 'Eutr-MF-R_SD',

	// material finishing fossil fuels impact
	'MaterialDFP_FossilFuels': 'AD-MF-R_SD',
	'MaterialDFP_FossilFuels_Total': 'AD-MF-R_SD',
	'Equivalences_MaterialDFP_FossilFuels_Relative': 'AD-MF-R_SD',
	'Equivalences_MaterialDFP_FossilFuels_Total': 'AD-MF-R_SD',

	// article finishing water impact
	'ArticleDFP_Water': 'WS-AF-R_SD',
	'ArticleDFP_Water_Total': 'WS-AF-R_SD',
	'Equivalences_ArticleDFP_Water_Relative': 'WS-AF-R_SD',
	'Equivalences_ArticleDFP_Water_Total': 'WS-AF-R_SD',

	// article finishing climate change impact
	'ArticleDFP_ClimateChange': 'GW-AF-R_SD',
	'ArticleDFP_ClimateChange_Total': 'GW-AF-R_SD',
	'Equivalences_ArticleDFP_ClimateChange_Relative': 'GW-AF-R_SD',
	'Equivalences_ArticleDFP_ClimateChange_Total': 'GW-AF-R_SD',

	// article finishing eutrophication impact
	'ArticleDFP_Eutrophication': 'Eutr-AF-R_SD',
	'ArticleDFP_Eutrophication_Total': 'Eutr-AF-R_SD',
	'Equivalences_ArticleDFP_Eutrophication_Relative': 'Eutr-AF-R_SD',
	'Equivalences_ArticleDFP_Eutrophication_Total': 'Eutr-AF-R_SD',

	// article finishing fossil fuels impact
	'ArticleDFP_FossilFuels': 'AD-AF-R_SD',
	'ArticleDFP_FossilFuels_Total': 'AD-AF-R_SD',
	'Equivalences_ArticleDFP_FossilFuels_Relative': 'AD-AF-R_SD',
	'Equivalences_ArticleDFP_FossilFuels_Total': 'AD-AF-R_SD',

	// manufacture water impact
	'Manufacture_Water': 'WS-M-R_SD',
	'Manufacture_Water_Total': 'WS-M-R_SD',
	'Equivalences_Manufacture_Water_Relative': 'WS-M-R_SD',
	'Equivalences_Manufacture_Water_Total': 'WS-M-R_SD',

	// manufacture climate change impact
	'Manufacture_ClimateChange': 'GW-M-R_SD',
	'Manufacture_ClimateChange_Total': 'GW-M-R_SD',
	'Equivalences_Manufacture_ClimateChange_Relative': 'GW-M-R_SD',
	'Equivalences_Manufacture_ClimateChange_Total': 'GW-M-R_SD',

	// manufacture eutrophication impact
	'Manufacture_Eutrophication': 'Eutr-M-R_SD',
	'Manufacture_Eutrophication_Total': 'Eutr-M-R_SD',
	'Equivalences_Manufacture_Eutrophication_Relative': 'Eutr-M-R_SD',
	'Equivalences_Manufacture_Eutrophication_Total': 'Eutr-M-R_SD',

	// manufacture fossil fuels impact
	'Manufacture_FossilFuels': 'AD-M-R_SD',
	'Manufacture_FossilFuels_Total': 'AD-M-R_SD',
	'Equivalences_Manufacture_FossilFuels_Relative': 'AD-M-R_SD',
	'Equivalences_Manufacture_FossilFuels_Total': 'AD-M-R_SD',

	// transport water impact
	'Transport_Water': 'WS-T-R_SD',
	'Transport_Water_Total': 'WS-T-R_SD',
	'Equivalences_Transport_Water_Relative': 'WS-T-R_SD',
	'Equivalences_Transport_Water_Total': 'WS-T-R_SD',

	// transport climate change impact
	'Transport_ClimateChange': 'GW-T-R_SD',
	'Transport_ClimateChange_Total': 'GW-T-R_SD',
	'Equivalences_Transport_ClimateChange_Relative': 'GW-T-R_SD',
	'Equivalences_Transport_ClimateChange_Total': 'GW-T-R_SD',

	// transport eutrophication impact
	'Transport_Eutrophication': 'Eutr-T-R_SD',
	'Transport_Eutrophication_Total': 'Eutr-T-R_SD',
	'Equivalences_Transport_Eutrophication_Relative': 'Eutr-T-R_SD',
	'Equivalences_Transport_Eutrophication_Total': 'Eutr-T-R_SD',

	// transport fossil fuels impact
	'Transport_FossilFuels': 'AD-T-R_SD',
	'Transport_FossilFuels_Total': 'AD-T-R_SD',
	'Equivalences_Transport_FossilFuels_Relative': 'AD-T-R_SD',
	'Equivalences_Transport_FossilFuels_Total': 'AD-T-R_SD',

	// packaging water impact
	'LaDP_Water': 'WS-Pack-R_SD',
	'LaDP_Water_Total': 'WS-Pack-R_SD',
	'Equivalences_LaDP_Water_Relative': 'WS-Pack-R_SD',
	'Equivalences_LaDP_Water_Total': 'WS-Pack-R_SD',

	// packaging climate change impact
	'LaDP_ClimateChange': 'GW-Pack-R_SD',
	'LaDP_ClimateChange_Total': 'GW-Pack-R_SD',
	'Equivalences_LaDP_ClimateChange_Relative': 'GW-Pack-R_SD',
	'Equivalences_LaDP_ClimateChange_Total': 'GW-Pack-R_SD',

	// packaging eutrophication impact
	'LaDP_Eutrophication': 'Eutr-Pack-R_SD',
	'LaDP_Eutrophication_Total': 'Eutr-Pack-R_SD',
	'Equivalences_LaDP_Eutrophication_Relative': 'Eutr-Pack-R_SD',
	'Equivalences_LaDP_Eutrophication_Total': 'Eutr-Pack-R_SD',

	// packaging fossil fuels impact
	'LaDP_FossilFuels': 'AD-Pack-R_SD',
	'LaDP_FossilFuels_Total': 'AD-Pack-R_SD',
	'Equivalences_LaDP_FossilFuels_Relative': 'AD-Pack-R_SD',
	'Equivalences_LaDP_FossilFuels_Total': 'AD-Pack-R_SD',
}