const defaultAcronym = 'en';
export const languagesList: any = [
  { acronym: 'en', title: 'languagesList-en' },
  { acronym: 'ca', title: 'languagesList-ca' },
  { acronym: 'es', title: 'languagesList-es' },
  { acronym: 'de', title: 'languagesList-de' },
  { acronym: 'dk', title: 'languagesList-dk' },
  { acronym: 'fr', title: 'languagesList-fr' },
  { acronym: 'he', title: 'languagesList-he' },
  { acronym: 'it', title: 'languagesList-it' },
  { acronym: 'ko', title: 'languagesList-ko' },
  { acronym: 'nl', title: 'languagesList-nl' },
];

export const languageDefault: any = languagesList.find(
  (item: any) => item.acronym === defaultAcronym
);
