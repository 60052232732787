import Crud, {ICrudListResponse} from "./Crud";


export default class Project extends Crud {
    constructor() {
        super("projects");
    }

    async allProjects(filter?: string): Promise<ICrudListResponse> {
        return await this.get(`/?s={"isActive": "true"}${filter}`);
    }
    async metricsSustainabilityIndex(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/sustainabilityIndex/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async metricsSustainabilityIndexTotals(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/sustainabilityIndex/totals?agg=AVG`);
    }
    async metricsEquivalences(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/equivalences/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async metricsEquivalencesTotals(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/equivalences/totals`);
    }
    async metricslifeCycleAssessment(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/lca/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async metricslifeCycleAssessmentTotals(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/lca/totals`);
    }
    async metricsSavings(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/savings/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async metricsSavingsTotals(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/savings/totals?agg=AVG`);
    }
    async metricsTraceability(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/traceability/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async metricsTraceabilityTotals(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/traceability/totals?agg=AVG`);
    }
    async metricsDigitization(projectId: string, nameArticleSearch: string, referenceArticleSearch: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/digitization/detail?nameArt=${nameArticleSearch}&refArt=${referenceArticleSearch}`);
    }
    async downloadZipQR(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/digitization/zip`, {
            responseType: 'blob'
        });
    }
    async downloadExcel(projectId: string, planName: string): Promise<ICrudListResponse> {
        return await this.post(`/${projectId}/downloadExcel`, {planName: planName}, {
            responseType: 'blob'
        });
    }

    async downloadSmartLabelsExcel(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`/${projectId}/downloadSLExcel`, {
            responseType: 'blob'
        });
    }

    // Data collection endpoints
    async dataCollection(): Promise<ICrudListResponse> {
        return await this.get('/');
    }
}


