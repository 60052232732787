import React from "react";
import EcodesignResultsDetailCard from "./EcodesignResultsDetailCard";
import { EcodesignResultsIndicatorHeader } from "./EcodesignResultsHeader";

interface IProps {
	ecoResults: any,
    indicator: string,
	schema: any,
    stage: string,
    units: string[],
}

const EcodesignResultsIndicator = (props:IProps) => {
	const { ecoResults, indicator, schema, stage, units } = props;
	const s = schema[stage];
    let unitsSorted: string[] = [];
    
    if (units.length > 2) {
        unitsSorted = ['impact', 'equivalence', 'relative'];
    } else if (units.length === 2 && units.includes('impact')) {
        unitsSorted = ['impact', units.filter((u:string) => u !== 'impact')[0]]
    } else {
        unitsSorted = units?.sort();
    }

    return (
        <div className="border-bottom pb-3 mb-5">
            <EcodesignResultsIndicatorHeader indicator={indicator} stage={s.label} />
            <div className={`d-flex flex-row ${units.length === 2 ? 'justify-content-around' : 'justify-content-between'}`}>
                {ecoResults && unitsSorted && unitsSorted.map((unit:string) => (
                    <EcodesignResultsDetailCard key={`${stage}-${indicator}-${unit}`} ecoResults={ecoResults} indicator={indicator} unit={unit} stage={stage} />
                ))}
            </div>
        </div>
    )
}

export default EcodesignResultsIndicator;