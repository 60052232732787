import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatNumberNoDecimals, calculateEquivalences } from 'utils/utils';
import { SmartLabelTooltip } from './SmartLabelTooltip';

interface ISmartLabelIconCard {
	color: string;
	icon: string;
	value: number | undefined;
	label: string | undefined;
	accountName?: string;
	description?: boolean;
	percent?: boolean;
}

const EQUIVALENCES = [
	{ keyName: "bottles", amount: 15.52 },
	{ keyName: "ligthBulbs", amount: 50 },
	{ keyName: "washingMachine", amount: 0.185 },
	{ keyName: "carTravelling", amount: 0.226 }
]

export const SmartLabelIconCard = (props: ISmartLabelIconCard) => {
	const { t } = useTranslation();
	const { color, icon, value, label, accountName, description = true, percent = false } = props;

	const handleEquivalences = (value:number, keyName: string): number => {
		let equivalenceKeyName:any = "";
		switch(keyName){
			case "Equivalences_Water_Relative": equivalenceKeyName = "bottles"; break;
			case "Equivalences_ClimateChange_Relative": equivalenceKeyName = "ligthBulbs"; break;
			case "Equivalences_Eutrophication_Relative": equivalenceKeyName = "washingMachine"; break;
			case "Equivalences_FossilFuels_Relative": equivalenceKeyName = "carTravelling"; break;
		}
		return calculateEquivalences(value, EQUIVALENCES, equivalenceKeyName) || 0;
	}

	const validatePercent = () => !value ? '-' : percent ? (formatNumberNoDecimals(Number(value)) + '%') : formatNumberNoDecimals(Number(value));

	return (
		<div className="w-100 d-flex flex-row justify-content-between align-items-center mt-2 mb-2">
			<div className="d-flex flex-column justify-content-between ">
				<div className="d-flex flex-row">
					{label !== 'Equivalences_Water_Relative' &&
					label !== 'Equivalences_ClimateChange_Relative' &&
					label !== 'Equivalences_Eutrophication_Relative' &&
					label !== 'Equivalences_FossilFuels_Relative' &&
					label !== 'Equivalences_FossilFuels_Relative' &&
					label !== 'Traceability_Suppliers' &&
					label !== 'Traceability_Process' &&
					label !== 'Traceability_Km' &&
					label !== 'Traceability_total_suppliers_traced' ? (
						<span className="text-dark-100 font-sfpro-medium letter-spacing-normal pr-1">
							{value ? formatNumber(Number(value)) : '-'}
						</span>
					) : (
						<span className="text-dark-100 font-sfpro-medium letter-spacing-normal pr-1">
							{
								!value ? '-' :
								accountName === "Premium"
								? formatNumberNoDecimals(handleEquivalences(Number(value), label))
								: validatePercent()
							}
						</span>
					)}
					{label !== 'Water' &&
						label !== 'ClimateChange' &&
						label !== 'Eutrophication' &&
						label !== 'FossilFuels' && (
							<span className="text-dark-60 font-sfpro-regular letter-spacing-normal">
								{t(`${label}-qr-unit`)}
							</span>
						)}
					{label === 'Water' && (
						<span className="font-sfpro-regular letter-spacing-normal text-dark-60" dangerouslySetInnerHTML={{ __html: t("environment_water_unit") }}></span>
					)}
					{label === 'ClimateChange' && (
						<span className="font-sfpro-regular letter-spacing-normal text-dark-60" dangerouslySetInnerHTML={{ __html: t('environment_climateChange_unit') }}></span>
					)}
					{label === 'Eutrophication' && (
						<span className="font-sfpro-regular letter-spacing-normal text-dark-60" dangerouslySetInnerHTML={{ __html: t('environment_eutrophication_unit') }}></span>
					)}
					{label === 'FossilFuels' && (
						<span className="font-sfpro-regular letter-spacing-normal text-dark-60" dangerouslySetInnerHTML={{ __html: t('environment_fossilFuels_unit') }}></span>
					)}
				</div>
				{
					description && (
						<div className='d-flex flex-row align-items-center'>
							<span className="text-dark-80 font-sfpro-regular letter-spacing-normal">
								{t(`${label}-qr-description`)}
							</span>
							<SmartLabelTooltip label={label} text={t(`${label}-qr-tooltip`)} />
						</div>
					)
				}
			</div>
			<div
				className="d-flex justify-content-center"
				style={{ width: '20px' }}
			>
				{
					icon.includes('circularity')
					? 	<img src={`/img/${icon}.svg`} alt="Supplier traceability" />
					: 	<span
							className={`${icon}`}
							style={{ color: `${color}`, fontSize: '22px' }}
						></span>
				}
			</div>
		</div>
	);
};
