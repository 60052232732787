import React from 'react';
import './Loader.scss';

export const LocalLoader: React.FC = () => (
	<div className="local-loader">
		<svg
			className="spinner"
			width="40px"
			height="40px"
			viewBox="0 0 66 66"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				className="path"
				fill="none"
				strokeWidth="6"
				strokeLinecap="round"
				cx="33"
				cy="33"
				r="30"></circle>
		</svg>
	</div>
);

export const SpinnerLoader = (props: any) => {
	const { width = '26px', height = '20px', color = 'white' } = props;
	return (
		<div
			className="spinner-bcome"
			style={{
				width: `${width}`,
				height: `${height}`,
				backgroundColor: color,
			}}
		/>
	);
};

const PageLoader: React.FC = () => (
	<div className="page-loader">
		<svg
			className="spinner"
			width="40px"
			height="40px"
			viewBox="0 0 66 66"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				className="path"
				fill="none"
				strokeWidth="6"
				strokeLinecap="round"
				cx="33"
				cy="33"
				r="30"></circle>
		</svg>
	</div>
);

export default PageLoader;
