import { EEquivalencesKeyNames } from "UI/Template/Table/types/TableEnums";
import Crud, { ICrudListResponse } from "./Crud";

interface EquivalencesKeyNames {
    waterKeyName: string,
    climateChangeKeyName: string,
    eutroKeyName: string,
    fossilFuelsKeyName: string
}


export default class Dashboard extends Crud {
    constructor() {
        super("dashboard");
    }

    //General

    async projectSummary(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`projectSummary`, { projectId: projectId }, this.requestConfig)
    }

    async materialsCertificates(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`materialsCertificates`, { projectId: projectId }, this.requestConfig)
    }

    async suppliersCertificates(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`suppliersCertificates`, { projectId: projectId }, this.requestConfig)
    }

    async projectOrigins(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/origins`);
    }

    async getCustomDataset(accountId: string): Promise<ICrudListResponse> {
        return await this.get(`/${accountId}/custom-datasets`);
    }

    //Metrics

    async LCStagePerImpactIndicatorChart(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`LCStagePerIndicator`, { projectId: projectId }, this.requestConfig)
    }

    async stagesByIndicator(indicator: string, projectId: string): Promise<ICrudListResponse> {
        return await this.post(`stages/${indicator}`, { projectId: projectId }, this.requestConfig)
    }
    async lca(projectId: string, equivalences:EquivalencesKeyNames): Promise<ICrudListResponse> {
        return await this.post(`lca`, { projectId: projectId, equivalences: equivalences }, this.requestConfig)
    }

    //Index

    async si(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`si`, { projectId: projectId }, this.requestConfig)
    }

    async scoreDistributionIndex(projectId: string): Promise<ICrudListResponse> {
        return await this.post(`scoreDistribution`, { projectId: projectId }, this.requestConfig)
    }

    async scoreByAreaPPTC(PPTC:string, projectId: string): Promise<ICrudListResponse> {
        return await this.post(`scoreByArea/${PPTC}`, { projectId: projectId }, this.requestConfig)
    }

    // Traceability

    async traceabilitySummary(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/traceabilitySummary`, this.requestConfig)
    }

    async traceabilityCompletion(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/traceabilityCompletion`, this.requestConfig)
    }

    async traceabilityDistributionTransportUsed(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/transportDistribution`, this.requestConfig)
    }

    async traceabilityManufacturingCountries(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/manufacturingCountries`, this.requestConfig)
    }

    async traceabilityMaterialCountries(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/materialCountries`, this.requestConfig)
    }

    async traceabilityRanking(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/queryForTraceabilityRanking`, this.requestConfig)
    }

    async traceabilityRawMaterialComposition(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/rawMaterialCompositionDistribution`, this.requestConfig)
    }

    async traceabilityRawMaterialsCharacters(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/queryRawMaterialsCharacters`, this.requestConfig)
    }

    // CEI
    async ceiSummary(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/cei-summary`, this.requestConfig)
    }

    async rankingPerformanceCircularity(projectId: string): Promise<ICrudListResponse> {
        return await this.get(`${projectId}/ranking-performance-circularity`, this.requestConfig)
    }

}
