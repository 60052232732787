const useCopyToClipboard = (text: string, copyText: boolean, setCopyText: any) => {
	
	const input = document.createElement("textarea");
	input.innerHTML = text;
	document.body.appendChild(input);
	input.select();

	const result = document.execCommand("copy");
	document.body.removeChild(input);
	
	setCopyText(!copyText);

	setTimeout(() => {
		setCopyText(false);
	}, 2000);
	return result;
};

export default useCopyToClipboard;
