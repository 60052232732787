import Swal from 'sweetalert2';

export const Alert = (
  title: string,
  text: string,
  icon: 'success' | 'error' | 'warning' | 'info' | 'question',
  showCancelButton: boolean,
  onClose?: () => void
) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton,
    onClose: handleClose
  });
};
