import { IEcodesignRequest } from "UI/Pages/DiagnosisEcodesign/types/ecodesign";
import Crud, { ICrudListResponse } from "./Crud";

export default class Ecocalculator extends Crud {
    constructor() {
        super("ecodesign");
    }

    async ecoCalculator(request:IEcodesignRequest): Promise<ICrudListResponse> {        
        return await this.post('/calculator', {...request});
    }
}