import { EIndicators, EStages } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const diagnosticsSchema: ITableColumns = {
    checklist: {
        indicator: EIndicators.checklist,
        stage: EStages.checklist,
        label: 'checklist',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.checklist)
    },
    waterUse: {
        indicator: EIndicators.waterUse,
        stage: EStages.article,
        label: 'waterUse',
        color: '#015594',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.waterUse)
    },
    climateChange: {
        indicator: EIndicators.climateChange,
        stage: EStages.article,
        label: 'climateChange',
        color: '#424242',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.climateChange)
    },
    eutrophication: {
        indicator: EIndicators.eutrophication,
        stage: EStages.article,
        label: 'eutrophication',
        color: '#887D37',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.eutrophication)
    },
    fossilFuels: {
        indicator: EIndicators.fossilFuels,
        stage: EStages.article,
        label: 'fossilFuels',
        color: '#1F4A53',
        columns: columnsSchema.filter(x => x.indicator === EIndicators.fossilFuels)
    },
}

export default diagnosticsSchema;