import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DropdownModule } from "UI/atoms/DropdownModule/Index";
import BodyText from "UI/atoms/BodyText";
import { EMetricNames } from "UI/Template/Table/types/TableEnums";

import { ItemCheckColumnSelector } from "UI/molecules/ItemCheck/Index";
import { SelectorTypeView } from "UI/molecules/SelectorHorizontal";
import { useTable } from "contexts/tableContext";
import { useProjects } from "contexts/projectsContext";
import { SelectorColumnsSchema } from "UI/molecules/SelectorColumns/SelectorColumns.schema";
import columnsSchema, { columnsSchemaById } from "UI/Template/Table/schemas/columns.schema";
import { ISelectorColumnsGroups } from "UI/molecules/SelectorColumns/SelectorColumnsTypes";
import { SelectorColumnsDropdown } from "../SelectorColumnsDropdown/Index";
import SearchBarModal from "UI/atoms/SearchBarModal";
import { ITableSchemaColumn } from "UI/Template/Table/types/TableTypes";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useReferenceType } from "hooks/useReferenceType.hook";


export const SelectorColumnsModal = (props: any) => {
	const { handleClose, title } = props;
	const { projectSelected } = useProjects();
	const { checkStages } = useReferenceType(projectSelected);

	const { deselectColumns, columnSelectorColumns, openColumnSelectorModal, handleColumnSelectorApply, dataGroupSelectedColumnSelector, newViewName } = useTable();
	const { productSelected } = useProjects();
	const { t } = useTranslation();
	const h = useHistory();

	const [searchColumnsText, setSearchColumnsText] = useState("")

	if (!openColumnSelectorModal || columnSelectorColumns === null) {
		return null;
	}

	const selectorColumnsGroup: ISelectorColumnsGroups = dataGroupSelectedColumnSelector === "benchmark"
		? SelectorColumnsSchema[productSelected]["indicators"]
		: SelectorColumnsSchema[productSelected][dataGroupSelectedColumnSelector]

	const [firstGroup, secondGroup, thirdGroup]: (keyof ITableSchemaColumn)[] = selectorColumnsGroup.columnsGroups;

	const firstGroupValues = Object.keys(columnsSchema.filter(selectorColumnsGroup.columnFilter).map((c: any) => c[firstGroup]).reduce((prev, next) => ({ ...prev, [next]: true }), {}));

	const valuesGroups: any = firstGroupValues
		.filter(v => v !== 'checklist')
		.map((value) => {
			const secondGroupValues = Object.keys(columnsSchema
				.filter(selectorColumnsGroup.columnFilter)
				.filter((c: ITableSchemaColumn) => c[firstGroup] === value)
				.map((c: any) => c[secondGroup])
				.reduce((prev, next) => ({ ...prev, [next]: true }), {}))

			return secondGroupValues
		})

	//Select product info items by Search

	const searchedColumnsProductInfo: any = []
	Object.entries(columnsSchemaById).map(([key, item]) => {
		if (item.id !== 'color' && item.indicator === 'checklist' || item.id === 'amount') {
			if (t(`select-${item.label}`).toLowerCase().includes(searchColumnsText.toLowerCase())) searchedColumnsProductInfo.push(item.label)
		}
	})

	//Search firstGroupValues (Water use, Global warming... (from indicators); Article, Raw material... (from stages))

	const searchedFirstGroupValues: string[] = []
	firstGroupValues.forEach((item => {
		if (t(item).toLowerCase().includes(searchColumnsText.toLowerCase())) searchedFirstGroupValues.push(item)
	}))



	//Select subcolumns by search

	const searchedColumnsGroups: any[] = [];
	valuesGroups.forEach((group: any) => {
		const valuesGroup: any = []
		group.forEach((value: any) => {
			if (t(`column-row-selector-${value}`).toLowerCase().includes(searchColumnsText.toLowerCase())) valuesGroup.push(value)
		})
		searchedColumnsGroups.push(valuesGroup)
	})

	const totalSearchColumnsLength = searchedColumnsGroups.reduce((prev, cur) => {
		prev.push(...cur);
		return prev
	}, []).length


	const columnsByFirstGroupValues: any = {}
	const searchedColumnsByFirstGroupValues: any = {}
	firstGroupValues
		.filter((v: string) => v !== 'checklist')
		.forEach((value) => {
			const secondGroupValues = Object.keys(columnsSchema // secondGroupValue = columns by indicators
				.filter(selectorColumnsGroup.columnFilter)
				.filter((c: ITableSchemaColumn) => c[firstGroup] === value)
				.map((c: any) => c[secondGroup])
				.reduce((prev, next) => ({ ...prev, [next]: true }), {}))


			const searchedColumns: any[] = []

			secondGroupValues.forEach(v => {
				if (t(v).toLocaleLowerCase().includes(searchColumnsText.toLowerCase())) searchedColumns.push(v)
			})

			columnsByFirstGroupValues[value] = secondGroupValues
			searchedColumnsByFirstGroupValues[value] = searchedColumns
		})



	const searchedTotalObj: any = {}
	firstGroupValues.forEach((fgv => {
		if (!checkStages(fgv)) {
			if (fgv !== 'checklist') {
				if (searchedFirstGroupValues.includes(fgv)) searchedTotalObj[fgv] = columnsByFirstGroupValues[fgv]
				else if (searchedColumnsByFirstGroupValues[fgv].length > 0) searchedTotalObj[fgv] = searchedColumnsByFirstGroupValues[fgv]
			}
		}
	}))

	const getNumColumnsSelected = (grouping: string, groupingValue: string) => {
		const selectedColumns = columnSelectorColumns
			.map((id: EMetricNames) => columnsSchemaById[id])
			.filter((c: any) => c[grouping] === groupingValue);

		const numColumnsSelected = selectedColumns.length
		return numColumnsSelected
	}



	const path = h.location.pathname;

	return (
		<>
			{/* Header */}
			<ModalHeader className="modal-lateral-header mx-3 px-0" >
				<h5 className="font-24px m-0 px-1 pt-2">{title}</h5>
				<span
					className="modal-lateral-close"
					onClick={(e) => handleClose(e)}
				/>
			</ModalHeader>

			{/* Body */}
			<ModalBody className="overflow-auto">
				<div className="d-flex flex-column justify-content-between h-100">
					<div>

						{/* Menu */}
						<div className="d-flex flex-row mt-2 mb-4" style={{ width: "395px" }}>
							<SelectorTypeView />
						</div>
						{/* Searcher */}
						<div className="mt-3 mb-4">
							<SearchBarModal searchText={searchColumnsText} setSearchText={setSearchColumnsText} />
						</div>
						{/* Select product information */}
						<div className="mb-5">
							<div className="mb-4">
								<BodyText copy={t('common-select-product-information')} bold uppercase color={'100'} />
							</div>
							<div className="d-flex flex-wrap pr-3 pl-1">
								{Object.entries(columnsSchemaById).map(([key, item]) => {
									if ((item.indicator === 'checklist' || item.id === 'amount') && searchedColumnsProductInfo.includes(item.label)) {
										return (
											<div className="mb-3" style={{ flex: '1 0 50%' }}>
												<ItemCheckColumnSelector item={item} searchColumnsText={searchColumnsText} />
											</div>
										)
									}
								})}
								{searchedColumnsProductInfo.length === 0 && <div className="w-100 font-sfpro-regular text-dark-60 text-center">{t("properties-not-found")}</div>}
							</div>
						</div>

						{/* Select columns */}
						<div>
							<div className="mb-3">
								<BodyText copy={t('common-add-columns')} bold uppercase color={'100'} extraCss={'mb-5'} />
							</div>
							{searchedFirstGroupValues.length === 0 && totalSearchColumnsLength === 0 && <div className="m-3 font-sfpro-regular text-dark-60 text-center">{t("properties-not-found")}</div>}
							{Object.keys(searchedTotalObj).map((value, i) => (
								<DropdownModule
									groupSearchColumns={searchedTotalObj[value]}
									grouping={firstGroup}
									groupingValue={value}
									numItemsSelected={getNumColumnsSelected(firstGroup, value)}
									displayNumItemsSelected
									searchText={searchColumnsText}
								>
									<SelectorColumnsDropdown
										secondGroupValues={searchedTotalObj[value]}
										grouping={firstGroup} groupingValue={value}
										selectorColumnsGroup={selectorColumnsGroup}
										searchColumnsText={searchColumnsText}
									/>
								</DropdownModule>
							)
							)}
						</div>
					</div>

					{/* Call to action */}
					<div className="d-flex flex-row align-items-end pb-2">
						<BodyText copy={t('help-column-selector')} color={'60'} />
						<a href="mailto: info@bcome.biz" className="font-sfpro-regular letter-spacing-normal text-yellow text-decoration-color-yellow font-14px mt-5 pl-1" target="_blank"><span className="text-yellow">{t('contact-us')}</span></a>
					</div>
				</div>
			</ModalBody>


			{/* Footer */}
			<ModalFooter
				className="d-flex justify-content-between position-fixed bg-white-60 mx-3 px-1"
				style={{ width: "400px", bottom: 0, borderTop: '0.5px solid #acacb2' }}
			>
				<button className="btn btn-ghost font-14px text-dark-60 font-sfpro-regular deselect-columns" onClick={deselectColumns}>
					{t("common-delete-all-columns")}
				</button>
				<div className="d-flex flex-row">
					<Button
						color="tertiary"
						onClick={(e) => handleClose(e)}
					>
						{t("common-cancel")}
					</Button>
					<Button
						color="primary"
						className="d-flex justify-content-center ml-2"
						onClick={() => {
							handleColumnSelectorApply(newViewName);
						}}
					>
						{t("common-apply")}
					</Button>
				</div>
			</ModalFooter>
		</>
	);
};
