import React from 'react';
import './InputRadio.scss';

interface IProps {
	label: string,
	checked: boolean, 
	handleOnChange: any,
	className?: string | undefined,
	disabled?: boolean | undefined
}

const InputRadio = (props: IProps) => {
	const { label, checked, handleOnChange, className, disabled=false } = props;

	// const onCheckboxChange = () => {
	// 	if (onChange && !disabled) {
	// 		onChange(!checked);
	// 	}
	// };

	return (
		<div 
			className={`d-flex flex-row justify-content-start align-items-center cursor-pointer ${className} ${disabled ? "input-radio-disabled" : ""}`}
			onClick={handleOnChange}
		>
			<input
				type="radio"
				value={label}
				checked={checked}
				onChange={handleOnChange}
			/>
			{label && (
				<span className={`${checked ? "text-dark-100" : "text-dark-60"} font-sfpro-regular font-14px ml-2`}>
					{label}
				</span>
			)}
		</div>
	);
};

export default InputRadio;
