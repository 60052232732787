import { EIndicators, EStages } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";


const stagesSchema: ITableColumns = {
    checklist: {
            indicator: EIndicators.checklist,
            stage: EStages.checklist,
            label: 'checklist',
            columns: columnsSchema.filter(x => x.stage === EStages.checklist)
    },
    article: {
            indicator: EIndicators.allIndicators,
            stage: EStages.article,
            label: 'article',
            color: '#937533',
            columns: columnsSchema.filter(x => x.stage === EStages.article)
    },
    rawMaterials: {
            indicator: EIndicators.allIndicators,
            stage: EStages.rawMaterials,
            label: 'rawMaterials',
            color: '#937533',
            columns: columnsSchema.filter(x => x.stage === EStages.rawMaterials)
    },
    materialProcess: {
            indicator: EIndicators.allIndicators,
            stage: EStages.materialProcess,
            label: 'materialProcess',
            color: '#4E3814',
            columns: columnsSchema.filter(x => x.stage === EStages.materialProcess)
    },
    materialWetProcess: {
            indicator: EIndicators.allIndicators,
            stage: EStages.materialWetProcess,
            label: 'materialWetProcess',
            color: '#9F7070',
            columns: columnsSchema.filter(x => x.stage === EStages.materialWetProcess)
    },
    articleWetProcess: {
            indicator: EIndicators.allIndicators,
            stage: EStages.articleWetProcess,
            label: 'articleWetProcess',
            color: '#9F7070',
            columns: columnsSchema.filter(x => x.stage === EStages.articleWetProcess)
    },
    manufacture: {
            indicator: EIndicators.allIndicators,
            stage: EStages.manufacture,
            label: 'manufacture',
            color: '#9A4735',
            columns: columnsSchema.filter(x => x.stage === EStages.manufacture)
    },
    transport: {
            indicator: EIndicators.allIndicators,
            stage: EStages.transport,
            label: 'transport',
            color: '#BD9759',
            columns: columnsSchema.filter(x => x.stage === EStages.transport)
    },
    packaging: {
            indicator: EIndicators.allIndicators,
            stage: EStages.packaging,
            label: 'packaging',
            color: '#966026',
            columns: columnsSchema.filter(x => x.stage === EStages.packaging)
    },
}

export default stagesSchema;