import { useTable } from 'contexts/tableContext';
import React, { useState } from 'react';
import { ModalBaseViewAction } from '../../Template/Modals/ModalBase/index';
import "../../Template/Modals/ModalBase/ModalBase.scss"

import ReactDOM from "react-dom";
import { BodyDeleteView } from './bodyDeleteView';
import { useTranslation } from 'react-i18next';
import { useProjects } from 'contexts/projectsContext';
import columnsSchema from "UI/Template/Table/schemas/columns.schema";
import { EMetricNames } from 'UI/Template/Table/types/TableEnums';
import Notification from "../../../components/Elements/Notification";
import { InputBodyViewAction } from './inputBodyViewAction';


export const ModalViewAction = () => {
    const { modalViewActionSelected, setModalViewActionSelected,
        deleteView, selectedView, setSelectedView, setCustomView,
        saveView, viewsByProduct, setOpenColumnSelectorModal, deselectColumns,
        newViewName, setNewViewName, getSelectedView } = useTable()
    const { projectSelected, productSelected, setOpenModalSaveChanges } = useProjects()
    const [errMsg, setErrMsg] = useState("")

    const modalRoot = document.getElementById("modal-root");
    const show = modalViewActionSelected ? true : false;
    const { t } = useTranslation();

    const dupView = (selectedView: any, newName: string, propsToDelete: string[], custom: boolean) => {
        const copySelectedView = JSON.parse(JSON.stringify(selectedView)) // copia del objeto selectedView
        propsToDelete.forEach((element: string) => {
            delete copySelectedView[element]
        });
        copySelectedView.name = newName
        if (custom) copySelectedView.groupDropdown = "custom"
        setCustomView(copySelectedView)
        setSelectedView(copySelectedView)
    }


    const newView = {
        account: projectSelected.account,
        columns: columnsSchema.filter((c: any) => c.id === EMetricNames.name),
        groupDropdown: "custom",
        name: newViewName,
        product: productSelected,
    }

    const createView = () => {
        setCustomView(newView)
    }


    const updateViewName = () => {
        dupView(selectedView, newViewName, ["createdAt", "updatedAt"], false)
        Notification.display("success", t("toast-info-view-name-edited"))
    }

    const cloneView = () => {
        dupView(selectedView, newViewName, ["createdAt", "updatedAt", "id"], true)
        Notification.display("info", `${newViewName} ${t("common-cloned")}!`)
    }

    const checkViewName = () => {
        const namesViewsByProduct = viewsByProduct.map((v: any) => v.name)
        if (namesViewsByProduct.includes(newViewName)) {
            setErrMsg(t("common-msg-err-view-name-exists-modal"))
            return false
        }
        else if (newViewName.replace(/\s/g, "") == "") {
            setErrMsg(t("common-msg-err-not-blank-modal"))
            return false
        } else {
            return true
        }
    }

    const handleClose = () => {
        setModalViewActionSelected("")
        setOpenModalSaveChanges({
            customView: false,
            open: false
        })
    }

    const DeleteViewAction = () => {
        selectedView.id ? deleteView(selectedView.id) : getSelectedView();
        handleClose();
    }

    const CloneViewAction = () => {
        if (!checkViewName()) return
        cloneView();
        handleClose();

    }
    const EditNameViewAction = () => {
        if (!checkViewName()) return
        updateViewName();
        handleClose();
    }

    const SaveViewAction = () => {
        if (!checkViewName()) return
        saveView();
        handleClose();
    }

    const CreateViewAction = () => {
        if (!checkViewName()) return
        createView();
        handleClose();
        setOpenColumnSelectorModal(true)
        deselectColumns();
    }

    const SaveWarningAction = () => {
        saveView();
        handleClose();        
    }

    const modalsConfig: any = {
        "saveWarning": {
            action: SaveWarningAction,
            title: t("save-warning-modal-title"),
            actionName: t("common-save"),
            isInput: false
        },
        "deleteView": {
            action: DeleteViewAction,
            title: t("deleteView-modal-title"),
            actionName: t("common-delete"),
            isInput: false
        },
        "cloneView": {
            action: CloneViewAction,
            title: t("cloneView-modal-title"),
            actionName: t("common-clone"),
            isInput: true,
            placeholder: t('forms-placeholder-cloneView'),
            inputValue: selectedView ? `Clone of ${t(selectedView.name)}` : ''
        },
        "editNameView": {
            action: EditNameViewAction,
            title: t("editViewName"),
            actionName: t("common-save"),
            isInput: true,
            placeholder: t('forms-placeholder-editViewName'),
            inputValue: selectedView ? t(selectedView.name) : ''
        },
        "saveView": {
            action: SaveViewAction,
            title: t("saveView-modal-title"),
            actionName: t("common-create"),
            isInput: true,
            placeholder: selectedView ? t(selectedView.name) : '',
            inputValue: selectedView ? t(selectedView.name) : '',
        },
        "createView": {
            action: CreateViewAction,
            title: t("createView-modal-title"),
            actionName: t("common-create"),
            isInput: true,
            placeholder: t("forms-placeholder-createView"),
            inputValue: ''
        }

    }

    return modalRoot
        ? ReactDOM.createPortal(
            <>
                <ModalBaseViewAction isShow={show} handleClose={handleClose} action={modalsConfig[modalViewActionSelected].action} actionName={modalsConfig[modalViewActionSelected].actionName}>
                    <section className="d-flex align-items-center justify-content-between mr-4 ml-4 mt-4 mb-3 text-dark-100 font-24px font-sfpro-medium">
                        <div>{modalsConfig[modalViewActionSelected].title}</div>
                        <span style={{ fontSize: "15px" }} className="icon-icon-close" onClick={handleClose}></span>
                    </section>
                    <section className="ml-4 mr-4 mb-5 mt-4 font-sfpro-regular">

                        {modalsConfig[modalViewActionSelected].isInput
                            ? <InputBodyViewAction inputValue={modalsConfig[modalViewActionSelected].inputValue} newViewName={newViewName} setNewViewName={setNewViewName} errMsg={errMsg} placeholder={modalsConfig[modalViewActionSelected].placeholder} />
                            : modalsConfig[modalViewActionSelected].title == t("deleteView-modal-title")
                            ? <BodyDeleteView />
                            : <span style={{
                                fontSize: 16,
                                lineHeight: "26px"
                            }}>{ t("save-warning-modal-body") }</span>
                        }

                    </section>
                </ModalBaseViewAction>
            </>,
            modalRoot
        )
        : null;
};