import { useLocation } from "react-router-dom";


export const useValidateSmartLabel = () => {
    const location = useLocation();

    const validate = (callback: () => void) => {
        if (!location.pathname.endsWith("smartLabel")) {
            callback();
        }
    }

    return {
        validate
    }
}