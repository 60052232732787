/* eslint-disable */
import fp from 'lodash/fp';
import { sortBy } from 'lodash';
import { IPresetView } from "UI/molecules/SelectorViews/ViewsTypes";
import { ISidebarMenuItem } from 'permissions/roles/MetaMenu';
import { REFERENCES_TYPE, TRACEABILITY } from '../constants';
import routes from '../permissions/roles/AppPaths'

export function objsToNameList(identity: any, key: string, noItemsText: string) {
	return !identity || !identity[key] || !identity[key].length
		? noItemsText
		: fp.pipe(
				fp.map((item: any) => item.name),
				fp.join(', ')
		  )(identity[key]);
}

export const toMoney = (number: any) => {
	return !isNaN(number)
		? new Intl.NumberFormat('de-DE', {
				style: 'currency',
				currency: 'EUR'
		  }).format(number)
		: '0,00 €';
};

export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const filterText = (text: any) => {
    // Define a regular expression to match the characters you want to remove
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\s-]/g;

    // Use replace to remove the matched characters
    return text.replace(regex, '')
}

export const byteSizeToHuman = (byteSize: number) => {
	return byteSize / 1024 < 1000
		? `${Math.floor(byteSize / 1024)} KB`
		: `${Math.floor(byteSize / 1024 / 1024)} MB`;
};

export const sortImages = (images: any[]) => {
	return sortBy(images, (i: any) => !(i.extraData || {}).isMain);
};

export const downloadFile = (url: string) => {
	const anchor = document.createElement('a');
	anchor.href = url;
	anchor.target = '_blank';
	anchor.download = url;
	anchor.click();
};

export const objToQueryString = (obj: any) => {
	const qs = Object.keys(obj)
		.map((key: any) => {
			if (obj[key] !== '' && obj[key] !== null) {
				return `${key}=${obj[key]}`;
			} else {
				return '';
			}
		})
		.filter((item: any) => item !== '');

	return qs.join('&');
};

export const queryStringToObj = (qs: string) => {
	const urlParams = new URLSearchParams(qs);
	let params;
	if (Object.fromEntries) {
		params = Object.fromEntries(urlParams);
	}
	else {
		if (qs !== '') {
			let newObject: any = {};
			const splitQS = qs.split('&');
			splitQS.map((item: any) => {
				let dataArray: any[] = item.split('=');
				newObject[dataArray[0]] = dataArray[1];
			});
			params = newObject;
		}
	}

	for(const key in params) {
		const value = params[key];
		const values = value.split(",");
		if (values.length) { // Is array;
			params[key] = values;
		}
	}
	return params;
};

export const clearPathParams = () => {
	window.history.replaceState(null, '', window.location.pathname);
};

/**
 * Convertir array de values en un string;
 * @param values
 * @param key Si los values son objetos, la key del objeto;
 */
export const arrayToText = (values: any[], key? : string) => {
	let ret = '';

	if (values) {
		values.forEach((value, index) => {
			if (typeof value == "string") {
				ret += `${value}`;
			}
			else if (typeof value == "object" && key && value[key]) {
				ret += `${value[key]}`;
			}

			if (index < values.length - 1) {
				ret += ", ";
			}
		})
	}

	return ret;
}

export const numberInRange = (num: number, min: number, max: number) => {
	return num >= min && num <= max;
}

export function isOdd(num: number) {
	return num % 2;
}

export function isEven(num: number) {
	return !isOdd(num);
}

//format numbers  -> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

// IN THE FURUTE: handle language selected or number format settings (lines of code above).
// const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
// const locale = navigator.language.split("-")[0];
const navLanguage = navigator.language; // eg. 'es.ES'

export function formatNumber(x: number): string {
	const numberFormated = new Intl.NumberFormat(navLanguage, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(x);
	return numberFormated;
}

export function formatNumberNoDecimals(x: number): string {
	const numberFormated = new Intl.NumberFormat(navLanguage, { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(x);
	if (numberFormated === '-0') return '0';
	
	return numberFormated;
}

export function numberWithDots(x:number|string): string {
	if(typeof x === 'string')  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function calculateEquivalences(data:number, equivalencesList:any[], keyName: string): number | null{
	let result = null;
	if(data && equivalencesList && equivalencesList.length > 0 && keyName){
		result = data * equivalencesList?.filter((e: any) => e.keyName === keyName)[0].amount
	}

	return result;
}

export function formatNumberThreeDigits(x: number): string {
	const numberFormated = new Intl.NumberFormat(navLanguage, { maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(x);
	return numberFormated;
}

export function handleTitle (selectView:IPresetView): string {
	let name = "";
	if (selectView?.product === "benchmark" && selectView?.name === "impactView") {
		name = "benchmarkView";
	} else name = selectView?.name;

	return name;
}

export function determinateProgressBarColor (value:number) {
	if (value <= 20) return '#E1EEFE';
	else if (value > 20 && value <= 30) return '#C9DBF8';
	else if (value > 30 && value <= 40) return '#9DBEF4';
	else if (value > 40 && value <= 50) return '#729FEE';
	else if (value > 50 && value <= 60) return '#497ACF';
	else if (value > 60 && value <= 70) return '#2754B1';
	else if (value > 70 && value <= 80) return '#10399E';
	else return '#001D6C';
}

// Determinate completion category
export function determinateCompletionCategory (value:number) {
	let result = "";
	if (value < 33) result = `LOW | ${value}%`;
	else if (value >= 33 && value < 66) result = `MEDIUM | ${value}%`;
	else result = `HIGH | ${value}%`;

	return result
}

export const customTitles = (letter:string) : string => letter.charAt(0).toUpperCase() + letter.slice(1)

export const toggleRouteReferenceTypeMaterial = (menu: Array<ISidebarMenuItem>, projectSelected: any) => {
	if (REFERENCES_TYPE.includes(projectSelected?.referenceType?.toLowerCase())) {
		menu.forEach(item => {
			if (item.name === TRACEABILITY && item.submenu) {
				item.submenu[1] = {
					...item.submenu[1],
					path: routes.traceabilityMaterials
				}
			}
		})
	}

	return menu
}

export const validateReferenceType = (projectSelected: any) => projectSelected && REFERENCES_TYPE.includes(projectSelected.referenceType?.toLowerCase())

export const standardName = (name: any) => {
	if (typeof name === 'object') return name.name;

	return name
}

export const maxValue = (list: any[], key: string) => {
	if (list.length > 0)
		return list.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)

	return null
}

export const calculateYAxis = (list: any[], key: string) => {
	const value = maxValue(list, key)

	if (value) {
		return Math.ceil(value[key])
	}

	return 100;
}

export const formatDate = (date = new Date()) => {
	return new Intl.DateTimeFormat('en-EN', {
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	}).format(date);
}

export const isElementOutsideViewport = (element: Element) => {
    const elementRect = element.getBoundingClientRect();
    return elementRect.right > window.innerWidth;
}

export const isChildOutsideParent = (childElement: Element, parentElement: Element) => {
    if (parentElement && childElement) {
      const parentRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();

	  const isLastItemOutsideViewport =
            childRect.right > parentRect.right || childRect.left < parentRect.left;

      return isLastItemOutsideViewport;
    }

	return true;
};

export const formatTagName = (tag: string) => {
	return tag.toLowerCase().replace(" ", "-");
}

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

type FormatType = 'Single' | 'Pair' | 'Set' | null;

export const formatValueFunctionalUnit = (functionUnit: string, type: FormatType) => {
	if (functionUnit.includes('m2')) {
		return functionUnit.replace('m2', 'm²')
	}

	if (type) {
		if (type === 'Pair' || type === 'Set') {
			return `${functionUnit.split(' ')[0]} ${type.toLowerCase()}`;
		}
	}

	return functionUnit;
}
