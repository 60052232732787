import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';

const AreasByPPTCChart = ({ data, color, unit, labelShortCopy }: any) => {
	const { t } = useTranslation();

	const customTooltip = (props: any) => {
		const { label, payload } = props;

		return (
			<div className="tooltip-dashboard">
				{payload.map((entry: any, index: number) => (
					<div>
						<div
							key={`item-${index}`}
							className="mr-1 text-dark-100"
						>
							{t(`dash-${label}`)}
						</div>
						<div>
							<span>
								{formatNumber(entry.value)} {entry.unit}
							</span>
						</div>
					</div>
				))}
			</div>
		);
	};

	return (
		<BarChart width={260} height={190} data={data}>
			<CartesianGrid
				vertical={false}
				strokeDasharray="0"
				stroke="#D6D6D7"
			/>
			<XAxis
				type="category"
				dataKey="name"
				angle={-50}
				textAnchor="end"
				tickFormatter={labelShortCopy}
				height={50}
				tickLine={{ stroke: 'transparent' }}
				fontSize="12"
				axisLine={false}
				tick={{ fill: '#C6C7C8' }}
			/>
			<YAxis
				type="number"
				allowDataOverflow={false}
				dataKey="data"
				domain={[0, 100]}
				tickFormatter={formatNumberNoDecimals}
				width={40}
				fontSize="12"
				unit="%"
				axisLine={false}
				tickLine={false}
				tick={{ fill: '#C6C7C8' }}
			/>
			<Tooltip content={customTooltip} />
			<Bar
				dataKey="data"
				fill={color}
				barSize={22}
				radius={[2, 2, 2, 2]}
				unit={unit}
			/>
		</BarChart>
	);
};

export default AreasByPPTCChart;
