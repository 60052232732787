import React, { useState } from "react";
import useCopyToClipboard from "hooks/useCopyToClipboard.hook";
import Notification from "../../../components/Elements/Notification";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


interface Props {
	textToCopy: string,
	extraCss?: string,
}

const BodyCopyIcon = (props: Props) => {
	const { textToCopy, extraCss } = props;
	const [copyText, setCopyText] = useState<boolean>(false);
	const { t }: any = useTranslation();

	const handleCopyToClipboard = (e:any) => {
		useCopyToClipboard(textToCopy, copyText, setCopyText);	
		Notification.display("info", t("toast-info-url-copied"))
		e.stopPropagation();
	};

	return (
		<span
			className={`${copyText ? "icon-icon-check-select" : "icon-icon-copy"} ${extraCss ? extraCss : ""}`}
			onClick={(e) => handleCopyToClipboard(e)}
		/>
	);
};

export default BodyCopyIcon;
