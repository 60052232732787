import { SUSTAINABILITY_INDEX_TOTAL_SCORES } from "utils/ProjectResults";

export function getLabelByPoints(score: number): string {
  const value = Math.round(score * 100) / 100
  let resultLabel = 'E';

  if (value > 5.9 && value <= 6.9) {
    resultLabel = 'D';
  } else if (value > 6.9 && value <= 7.9) {
    resultLabel = 'C';
  } else if (value > 7.9 && value <= 8.9) {
    resultLabel = 'B';
  } else if (value > 8.9) {
    resultLabel = 'A';
  }
  return resultLabel
}

export const getPoints = (value: number, category: string) => {
  const scores: any = SUSTAINABILITY_INDEX_TOTAL_SCORES;
  const maxScore = scores[category];
  const score = (value / maxScore * 10);
  return score
}

export const getResultLabelCategory = (value: number, category: string) => {
  const scores: any = SUSTAINABILITY_INDEX_TOTAL_SCORES;
  const maxScore = scores[category];
  const resultLabelCategory = getLabelByPoints(value / maxScore * 10);
  return resultLabelCategory
}


