import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';

const ModalTest = (props: any) => {
	const { showModal, setShowModal, isLoading } = props;
    const { t }: any = useTranslation();

    const submit = () => {
        console.log("SUBMIT");
    }

    return (
		<Modal
			modal={showModal}
			setModal={setShowModal}
			className="page-selector-modal"
			scrollable
			createText={t('login-resetPassword')}
			handleSubmit={submit}
			title={t('login-forgotPassword')}
			noCancel
			size="md"
			isLoading={isLoading}
			modalName={'test'}
			>
			<h1>TESTING</h1>
		</Modal>
	);
};
export default ModalTest;
