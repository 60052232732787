import PATHS from "permissions/roles/AppPaths";

interface TITLES_INTERFACE {
    [key: string]: string
}

export const titlesByPage: TITLES_INTERFACE = {
    [PATHS.home]: "BCome Home",
    [PATHS.management]: "Traceability",
    "metrics/lca/dashboard": "LCA Dashboard",
    [PATHS.metricsLcaArticles]: "LCA List",
    [PATHS.metricsBenchmarkArticles]: "LCA Benchmark",
    [PATHS.cei]: "CEI",
    "evaluation/sustainabilityindex/dashboard": "Eco-Score",
    [PATHS.diagnosisReporting]: "Reporting",
    [PATHS.digitizationSmartLabel]: "Digitization Smart Labels",
    [PATHS.digitizatioEcommerceIntegration]: "Digitization E-Integration",
    [PATHS.ecodesign]: "Ecodesign Calculator"
}