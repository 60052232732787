import { useTable } from 'contexts/tableContext';
import React, { useEffect } from 'react';
import "../../Template/Modals/ModalBase/ModalBase.scss"
import { useTranslation } from 'react-i18next';

interface Props {
    newViewName: string,
    setNewViewName: React.Dispatch<React.SetStateAction<string | undefined>>
    errMsg: string,
    placeholder: string,
    inputValue: string | undefined
}

export const InputBodyViewAction = (props: Props) => {
    const { newViewName, setNewViewName, errMsg, placeholder, inputValue } = props
    const { t }: any = useTranslation();

    useEffect(() => {
        setNewViewName && setNewViewName(inputValue)
    }, [])

    const handleName = (e: any) => setNewViewName && setNewViewName(e.target.value);


    return (
        <form className='d-flex flex-column'>
            <div className='d-flex'>
                <label className='font-sfpro-medium text-dark-100'>{t('name')}</label>
            </div>
            <input className={`text-dark-60 font-sfpro-medium ${errMsg ? "errMsg" : ""} `} type="text" name="name" placeholder={placeholder} value={newViewName} onChange={handleName} />
            {errMsg && <div className='pt-1 font-sfpro-regular font-12px' style={{ color: "#B44D49" }}>{errMsg}</div>}
        </form>
    )
};
