import React, { useEffect, useRef, useState } from "react";

import { useProjects } from "contexts/projectsContext";
import { useTable } from "contexts/tableContext";
import FilterHttp from 'services/http/Filters';
import { ICrudListResponse } from "services/http/Crud";
import Project from "services/http/Project";

import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { EMetricNames } from "UI/Template/Table/types/TableEnums";
import { EMAIL_INFORMATION } from "../constants";

export type ScopeType = 'materials' | 'suppliers' | 'articles' | 'cei' | 'projects';

const validateDataFields = [EMetricNames.MCI_A, EMetricNames.Recycled_A, EMetricNames.TU_Waste_A, EMetricNames.Utility_A]

const Filters = new FilterHttp();
const Projects = new Project();

function fetchData (scope: ScopeType, projectId: string, dataQuery: any): Promise<ICrudListResponse> {
    if (scope === 'materials') return Filters.filterMaterials(projectId, dataQuery);
    if (scope === 'suppliers') return Filters.filterSuppliers(projectId, dataQuery);
    if (scope === 'articles') return Filters.filterArticles(projectId, dataQuery);
    if (scope === 'projects') return Projects.allProjects('&sort=createdAt,DESC');
    if (scope === 'cei') return Filters.filterCei(projectId, dataQuery);

    return Promise.reject('Invalid scope');
}

function defineScope (path: string): ScopeType {
    if (path.includes('/plm/materials')) return 'materials';
    if (path.includes('/plm/suppliers')) return 'suppliers';
    if (path.includes('/data-collection')) return 'projects';
    if (path.includes('/cei/')) return 'cei';
    return 'articles';

}

export const tableLoader = (Component: any) => {
    return (props: any) => {
        const scope = useRef<ScopeType>('articles');
        const source = Filters.getSource();
        const { projectSelected, setShowHeader } = useProjects();
        const [noData, setNoData] = useState(false);
        const {
            currentPage,
            limitPagination,
            searchText,
            sortMetric,
            filtersApplied,
            selectedView,
            isLoading,
            handleLoading,
            setRes,
            res
        } = useTable();


        const getData = (scope: ScopeType) => {
            const dataQuery = {
                currentPage: currentPage,
                limitPagination: limitPagination,
                search: searchText,
                sortMetric: sortMetric,
                filters: filtersApplied
            }

            handleLoading(true)

            const request = fetchData(scope, projectSelected.id, dataQuery);

            request.then((response) => {
                let data = null;
                if (scope === 'cei') {
                    data = response.data['articles'];
                } else if (scope === 'projects') {
                    data = response.data;
                } else {
                    data = response.data[scope]
                }

                if ( data.length === 0 ) setNoData(true)
                else setNoData(false)

                setRes({
                    datas: data,
                    pageCount: response.data.pageCount ?? 1,
                    total: response.data.total ?? response.data.length
                })
                setShowHeader(true);
            })
            .catch((err) => console.log({ err }))
            .finally(() => handleLoading(false))
        };

        useEffect(() => {
            (async () => {
                const wPath = window.location.pathname
                scope.current = defineScope(wPath)

                if (projectSelected) getData(scope.current);
            })()

            return () => source.cancel();
        }, [projectSelected, filtersApplied, currentPage, limitPagination, sortMetric, selectedView])

        useEffect(() => {
            const wPath = window.location.pathname
            const scope = defineScope(wPath)

            const delay = setTimeout(() => {
                if (projectSelected) getData(scope);
            }, 100);
            return () => {
                clearTimeout(delay);
                source.cancel();
            }
        }, [searchText]);

        const validateData = () => {
            if (scope.current === 'cei') {
                let count = 0;
                validateDataFields.forEach(item => {
                    if (res.datas[0] && (res.datas[0][item] === null || res.datas[0][item] === 0)) {
                        count++;
                    }
                })
                if (count === validateDataFields.length) {
                    setShowHeader(true);
                    return true;
                } else {
                    setShowHeader(true);
                    return false;
                }
            }

            return false;
        }

        return validateData()
        ? <InformationNavigation keyName={scope.current} />
        : <Component {...props} items={res.datas} isLoading={isLoading} />
    }
}