import React, { useState } from "react";
import { useTable } from "contexts/tableContext";
import { useHistory } from "react-router-dom";

import "./SaveIcon.scss"
import { SimpleTooltip } from "../Tooltips/Index";
import { useTranslation } from "react-i18next";

export const SaveIcon = () => {
    const { saveView } = useTable();
    const h = useHistory();
    const { t } = useTranslation()
    const id = 'save-view-icon'

    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const path = h.location.pathname;

    const handleSaveView = () => {
        saveView();
    }

    return(
        <div className="save-icon d-flex align-items-center ml-2" onClick={handleSaveView}> 
            <span id={id}
                onMouseEnter={()=>setIsTooltipOpen(true)}
                onMouseLeave={()=>setIsTooltipOpen(false)}
                className='icon-icon-save font-20px'></span>
            <SimpleTooltip 
                tooltipId={id}
                tooltipOpen={isTooltipOpen}
                text={t('common-save')}
            />

            
        </div>
    )
}

