import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDown } from '../../atoms/DropDown/DropDown';
import AppPaths from '../../../permissions/roles/AppPaths';
import { useLocation, useHistory } from 'react-router-dom';
import { useTable } from 'contexts/tableContext';
import { ModalProjectInfo } from 'UI/organism/ModalProjectInfo';
import { validateReferenceType } from 'utils/utils';

interface ScopeSelectionInterface {
	scopes: string[];
	projectSelected: any;
	isLoading: boolean;
}

const DEFAULT_DASHBOARD = 'sidebar-nav-articles'

export const ScopeSelection = ({scopes, projectSelected, isLoading}: ScopeSelectionInterface) => {
	const { t } = useTranslation()
	const { getSelectedView, handleLoading } = useTable()
	const [showProjectInfo, setShowProjectInfo] = React.useState<boolean>(false)
	const [scopeSelected, setScopeSelected] = React.useState<string>(t(DEFAULT_DASHBOARD));
	const [isShow, setIsShow] = React.useState<boolean>(false);
	const location = useLocation()
	const h = useHistory()

	const handleDropDown = () => {
		!isLoading && setIsShow(!isShow);
	};

	const getScope = () => location.pathname.split('/')[3]

	const validatePlmDashboard = () => location.pathname.includes('plm/dashboard')

	const validatePlmPath = () => location.pathname.includes('/plm')

	useEffect(() => {
		if (!validatePlmPath()) {
			if (validateReferenceType(projectSelected)) {
				setScopeSelected('Materials')
			} else setScopeSelected('Articles')
		} else {
			const scope = getScope()
			if (!validatePlmDashboard()) setScopeSelected(scope)
			else {
				const localeKey = validateReferenceType(projectSelected) ? 'sidebar-nav-materials' : DEFAULT_DASHBOARD
				setScopeSelected(t(localeKey))
			}
		}
	}, [location])


	const handleInfoDetails = () => {
		setShowProjectInfo(true);
	}
	const handleSelection = (item: string) => {
		setScopeSelected(item);
		switch (item) {
			case 'articles':
				redirectScope(AppPaths.traceability);
				break;
			case 'suppliers':
				redirectScope(AppPaths.traceabilitySupplier);
				break;
			case 'materials':
				redirectScope(AppPaths.traceabilityMaterials);
				break;
			case 'logistics':
				redirectScope(AppPaths.traceabilityLogistics);
				break;
		}
		handleDropDown()
	};

	const redirectScope = (uri: string) => {
		handleLoading(true)
		h.push(uri);
		getSelectedView()
	}

	const handleScopes = () => {
		if (scopes.length > 1) {
			const scope = getScope()

			const index = scopes.findIndex(
				(item) => item.toLowerCase() === scope
			);
			setScopeSelected(scopes[index]);
		}
	};

	useEffect(() => {
		if (!validatePlmDashboard()) handleScopes()
	}, []);

	return (
		<div
			className={`position-relative d-flex flex-row align-items-center nav-link cursor-pointer px-0 py-1 pl-3 pr-1 text-dark-100`}
			style={{ width: 'max-content', minWidth: '165px', opacity: (isLoading && !validatePlmDashboard()) ? 0.5 : 1 }}
		>
			<span
				className={`font-20px font-sfpro-medium text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize ${(validatePlmDashboard()) ? '' : ''}`}
			>
				{scopeSelected}
			</span>
			<span
				className="icon-icon-info font-18px text-dark-60 hover-dark-100 pl-2"
				onClick={handleInfoDetails}
			/>
			<div
				className={`d-flex align-items-center justify-content-center`}
				style={{
					height: '32px',
					width: '32px',
				}}
			>
				{((validatePlmPath() && !validatePlmDashboard()) && scopes.length > 1) && (
					<span
						className={`icon-icon-chevron-down font-30px text-dark-60 text-dark-100-hover scope-rotate-icon ${
							isShow ? 'bg-gray-01 text-dark-100' : ''
						}`}
						onClick={handleDropDown}
						style={{
							transform: (isShow && !isLoading)
								? 'rotate(180deg)'
								: 'rotate(0deg)',
						}}
					/>
				)}
			</div>
			{(isShow && !isLoading) && (
				<DropDown
					array={scopes}
					itemSelected={scopeSelected}
					handleClick={handleSelection}
				/>
			)}

			<ModalProjectInfo
				isShow={showProjectInfo}
				handleClose={() => setShowProjectInfo(false)}
				projectData={projectSelected}
			/>
		</div>
	);
};
