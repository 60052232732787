export interface IDataGroupsType {
    [key: string]: IDataGroupsInfo
}

export interface IDataGroupsInfo {
    label: string,
    color: string,
    icon: string,
    coloredIcon?: string | undefined,
    iconEqImpactU?: string | undefined,
    iconEqImpactP?: string | undefined,
    iconEqSavingU?: string | undefined,
    iconEqSavingP?: string | undefined,
    
}

export const dataGroups: IDataGroupsType = {
    planet: {
        label: 'planet',
        color: '#773F0F',
        icon: 'icon-icon-planet'
    },
    people: {
        label: 'people',
        color: '#0E343C',
        icon: 'icon-icon-people',
    },
    transparency: {
        label: 'transparency',
        color: '#BE8F1F',
        icon: 'icon-icon-transparency',
    },
    circularity: {
        label: 'circularity',
        color: '#C8B7AF',
        icon: 'icon-icon-circularity',
    },
    water: {
        label: 'waterUse',
        color: '#015594',
        icon: 'icon-icon-water',
        coloredIcon: 'icon-icon-id-water',
        iconEqImpactU: 'icon-icon-bottle',
        iconEqImpactP: 'icon-icon-pool',
        iconEqSavingU: 'icon-icon-glasses',
        iconEqSavingP: 'icon-icon-water-year',
    },
    waterUse: {
        label: 'waterUse',
        color: '#015594',
        icon: 'icon-icon-water',
        coloredIcon: 'icon-icon-id-water',
        iconEqImpactU: 'icon-icon-bottle',
        iconEqImpactP: 'icon-icon-pool',
        iconEqSavingU: 'icon-icon-glasses',
        iconEqSavingP: 'icon-icon-water-year',
    },
    climateChange: {
        label: 'climateChange',
        color: '#424242',
        icon: 'icon-icon-co2',
        coloredIcon: 'icon-icon-id-global',
        iconEqImpactU: 'icon-icon-bulbs',
        iconEqImpactP: 'icon-icon-electricity',
        iconEqSavingU: 'icon-icon-trees',
        iconEqSavingP: 'icon-icon-planted-trees',
    },
    eutrophication: {
        label: 'eutrophication',
        color: '#887D36',
        icon: 'icon-icon-po4',
        coloredIcon: 'icon-icon-id-eutrophication',
        iconEqImpactU: 'icon-icon-washer',
        iconEqImpactP: 'icon-icon-detergent',
        iconEqSavingU: 'icon-icon-fish-saved',
        iconEqSavingP: 'icon-icon-oxigen',
    },
    fossilFuels: {
        label: 'fossilFuels',
        color: '#1F4A53',
        icon: 'icon-icon-abiotic',
        coloredIcon: 'icon-icon-id-abiotic-depletion',
        iconEqImpactU: 'icon-icon-car',
        iconEqImpactP: 'icon-icon-plane',
        iconEqSavingU: 'icon-icon-car-not-travelled',
        iconEqSavingP: 'icon-icon-plane-not-travelled',
    },
    article: {
        label: 'article',
        color: '#1C1C30',
        icon: 'icon-icon-articles',
        coloredIcon: 'icon-icon-id-articles',
    },
    rawMaterials: {
        label: 'rawMaterials',
        color: '#727B66',
        icon: 'icon-icon-raw-material',
        coloredIcon: 'icon-icon-id-raw-material',
    },
    materialProcess: {
        label: 'materialProcess',
        color: '#483919',
        icon: 'icon-icon-material-process',
        coloredIcon: 'icon-icon-id-material-process',
    },
    materialWetProcess: {
        label: 'materialWetProcess',
        color: '#9F7070',
        icon: 'icon-icon-wetprocess-material',
        coloredIcon: 'icon-icon-id-material-wet-process',
    },
    articleWetProcess: {
        label: 'articleWetProcess',
        color: '#9F7070',
        icon: 'icon-icon-wetprocess-article',
        coloredIcon: 'icon-icon-id-article-wet-process',
    },
    manufacture: {
        label: 'manufacture',
        color: '#AD614F',
        icon: 'icon-icon-manufacturing',
        coloredIcon: 'icon-icon-id-manufacturing',
    },
    packaging: {
        label: 'packaging',
        color: '#966026',
        icon: 'icon-icon-packaging',
        coloredIcon: 'icon-icon-id-packaging',
    },
    transport: {
        label: 'transport',
        color: '#BD9759',
        icon: 'icon-icon-suppliers',
        coloredIcon: 'icon-icon-id-transport',
    },
    retail: {
        label: 'retail',
        color: '#5A2320',
        icon: 'icon-icon-retail',
        coloredIcon: 'icon-icon-id-retail',
    },
    use: {
        label: 'use',
        color: '#91939F',
        icon: 'icon-icon-use',
        coloredIcon: 'icon-icon-id-use',
    },
    endLife: {
        label: 'endLife',
        color: '#728081',
        icon: 'icon-icon-end-life',
        coloredIcon: 'icon-icon-id-end-life',
    }
}

export const SustainabilityIndexGroups: IDataGroupsType = {
    planet: {
        label: 'planet',
        color: '#773F0F',
        icon: 'icon-icon-planet'
    },
    people: {
        label: 'people',
        color: '#0E343C',
        icon: 'icon-icon-people',
    },
    transparency: {
        label: 'transparency',
        color: '#BE8F1F',
        icon: 'icon-icon-transparency',
    },
    circularity: {
        label: 'circularity',
        color: '#C8B7AF',
        icon: 'icon-icon-circularity',
    },
}

export const TraceabilityGroups: IDataGroupsType = {
    certificates: {
        label: 'tracCertificates',
        color: '#BE8F1F',
        icon: 'icon-icon-certificate',
    },
    km: {
        label: 'tracKm',
        color: '#BE8F1F',
        icon: 'icon-icon-distance'
    },
    people: {
        label: 'tracPeople',
        color: '#05343C',
        icon: 'icon-icon-people',
    },
    process: {
        label: 'tracProcess',
        color: '#BE8F1F',
        icon: 'icon-icon-process' 
    },
    suppliers: {
        label: 'tracSuppliers',
        color: '#05343C',
        icon: 'icon-icon-manufacturing'
    }
}

export const IndicatorsGroups: IDataGroupsType = {
    waterUse: {
        label: 'waterUse',
        color: '#015594',
        icon: 'icon-icon-water',
        coloredIcon: 'icon-icon-id-water',
    },
    climateChange: {
        label: 'climateChange',
        color: '#424242',
        icon: 'icon-icon-co2',
        coloredIcon: 'icon-icon-id-global',
    },
    eutrophication: {
        label: 'eutrophication',
        color: '#887D36',
        icon: 'icon-icon-po4',
        coloredIcon: 'icon-icon-id-eutrophication',
    },
    fossilFuels: {
        label: 'fossilFuels',
        color: '#1F4A53',
        icon: 'icon-icon-abiotic',
        coloredIcon: 'icon-icon-id-abiotic-depletion',
    }
}

export const StagesGroups: IDataGroupsType = {
    article: {
        label: 'article',
        color: '#1C1C30',
        icon: 'icon-icon-articles',
        coloredIcon: 'icon-icon-id-articles',
    },
    rawMaterials: {
        label: 'rawMaterials',
        color: '#727B66',
        icon: 'icon-icon-raw-material',
        coloredIcon: 'icon-icon-id-raw-material',
    },
    materialProcess: {
        label: 'materialProcess',
        color: '#483919',
        icon: 'icon-icon-material-process',
        coloredIcon: 'icon-icon-id-material-process',
    },
    materialWetProcess: {
        label: 'materialWetProcess',
        color: '#9F7070',
        icon: 'icon-icon-wetprocess-material',
        coloredIcon: 'icon-icon-id-material-wet-process',
    },
    articleWetProcess: {
        label: 'articleWetProcess',
        color: '#9F7070',
        icon: 'icon-icon-wetprocess-article',
        coloredIcon: 'icon-icon-id-article-wet-process',
    },
    manufacture: {
        label: 'manufacture',
        color: '#AD614F',
        icon: 'icon-icon-manufacturing',
        coloredIcon: 'icon-icon-id-manufacturing',
    },
    packaging: {
        label: 'packaging',
        color: '#966026',
        icon: 'icon-icon-packaging',
        coloredIcon: 'icon-icon-id-packaging',
    },
    transport: {
        label: 'transport',
        color: '#BD9759',
        icon: 'icon-icon-suppliers',
        coloredIcon: 'icon-icon-id-transport',
    },
    retail: {
        label: 'retail',
        color: '#5A2320',
        icon: 'icon-icon-retail',
        coloredIcon: 'icon-icon-id-retail',
    },
    use: {
        label: 'use',
        color: '#91939F',
        icon: 'icon-icon-use',
        coloredIcon: 'icon-icon-id-use',
    },
    endLife: {
        label: 'endLife',
        color: '#728081',
        icon: 'icon-icon-end-life',
        coloredIcon: 'icon-icon-id-end-life',
    }

}