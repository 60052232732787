import Crud, { ICrudListResponse } from "./Crud";
import {RequestQueryBuilder} from "@nestjsx/crud-request";


export default class MaterialsCategories extends Crud {
    constructor() {
        super("materialsCategory");
    }

    async getAllForEcoCalculator():Promise<ICrudListResponse> {
        const queryString = RequestQueryBuilder.create({
            sort: { field: 'name', order: 'ASC' },
            filter: [{
                field: "visibleInAppEcoCalculator", value: true, operator: 'eq'
            }],
            resetCache: true,
        }).query();
        return this.getAll(queryString);
    }
    async getAll(queryString?: string): Promise<ICrudListResponse> {
        return await this.get(`?${queryString}`)
    }
}
