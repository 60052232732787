import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';

const StagesByIndicatorChart = ({
	data,
	color,
	className,
	unit,
	labelShortCopy,
}: any) => {
	const { t } = useTranslation();

	const getMaxMinValues = (data: any) => {
		let maxValue = 0;
		let minValue = 0;

		data.forEach((stageData: any) => {
			const value = stageData.data;

			if (value > maxValue) maxValue = value;
			else if (value < minValue) minValue = value;
		});
		return { maxValue: maxValue <= 1 ? maxValue : Math.ceil(maxValue), minValue: maxValue <= 1 ? minValue : Math.floor(minValue) };
	};

	const getTickAxisYInterval = (maxValue: number, minValue: number) => {
		return (maxValue - minValue) / 4;
	};

	const customTicks = (data: any) => {
		const { maxValue, minValue } = getMaxMinValues(data);
		const interval = getTickAxisYInterval(maxValue, minValue);
		const ticks = [maxValue, 0];
		let value = maxValue;
		if (minValue < 0) ticks.push(minValue);

		const tick_value = maxValue <= 1 ? 0.1 : maxValue < 10 ? 5 : 10;

		while (value > minValue + interval) {
			const newValue = value - interval;
			const roundedValue = Math.floor(newValue / tick_value) * tick_value;
			if (roundedValue !== 0) ticks.push(roundedValue);
			value = roundedValue;
		}

		return ticks.sort((a, b) => a - b);
	};

	const metricUnit = (unitMetric: string) => {
		return (
			<>
				{unitMetric === 'm3eq' && (
					<span>
						m<sup>3</sup> water eq
					</span>
				)}
				{unitMetric === 'kgCO2eq' && (
					<span>
						kg CO<sub>2</sub> eq
					</span>
				)}
				{unitMetric === 'gPO4eq' && <span>g phosphates eq</span>}
				{unitMetric === 'MJ' && <span>MJ</span>}
			</>
		);
	};

	const customTooltip = (props: any) => {
		const { label, payload } = props;
		return (
			<div className="tooltip-dashboard">
				{payload.map((entry: any, index: number) => (
					<div>
						<div
							key={`item-${index}`}
							className="mr-1 text-dark-100"
						>
							{t(`dash-${label}`)}
						</div>
						<div>
							<span>
								{' '}
								{formatNumber(entry.value)}{' '}
								{metricUnit(entry.unit)}
							</span>
						</div>
					</div>
				))}
			</div>
		);
	};

	const AxisLabel = () => {
		return (
			<div className="mr-3 pb-5 position-relative d-flex align-items-center justify-content-center font-12px">
				<div
					style={{
						transform: 'rotate(270deg)',
						width: '150px',
						position: 'absolute',
						textAlign: 'center',
						color: '#C6C7C8',
					}}
				>
					{metricUnit(unit)}
				</div>
			</div>
		);
	};

	const hasValues = (stagesByIndicatorData: any) => {
		let value = 0;
		if (stagesByIndicatorData) {
			stagesByIndicatorData.forEach((stage: any) => {
				value = +stage.data;
			});
		}

		return value !== 0;
	};

	const NoDataToDisplay = () => (
		<div className="d-flex align-items-center text-dark-60 font-12px">
			There's no data to display
		</div>
	);

	return (
		<>
			{hasValues(data) ? (
				<div className="d-flex flex-row">
					<AxisLabel />
					<BarChart width={385} height={250} data={data}>
						<CartesianGrid
							vertical={false}
							strokeDasharray="0"
							stroke="#D6D6D7"
						/>
						<XAxis
							type="category"
							dataKey="name"
							angle={-50}
							textAnchor="end"
							tickFormatter={labelShortCopy}
							height={50}
							tickLine={{ stroke: 'transparent' }}
							fontSize="12"
							axisLine={false}
							tick={{ fill: '#C6C7C8' }}
						/>
						<YAxis
							type="number"
							fontSize="12"
							domain={['dataMin', 'dataMax']}
							interval={0}
							tickFormatter={getMaxMinValues(data).maxValue > 1 ? formatNumberNoDecimals : formatNumber}
							ticks={customTicks(data)}
							allowDataOverflow={false}
							axisLine={false}
							tickLine={false}
							tick={{ fill: '#C6C7C8' }}
						/>
						<Tooltip content={customTooltip} />
						<Bar
							dataKey="data"
							fill={"#001D6C"}
							barSize={22}
							radius={[2, 2, 2, 2]}
							unit={unit}
						/>
					</BarChart>
				</div>
			) : (
				<NoDataToDisplay />
			)}
		</>
	);
};

export default StagesByIndicatorChart;
