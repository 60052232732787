import { T } from 'lodash/fp';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetricNumber from 'UI/atoms/MetricNumber';
import MetricUnit from 'UI/atoms/MetricUnit';
import { CustomSimpleTooltip, SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { dataGroups } from 'UI/Template/Table/types/DataGroups';
import { EDataTypes } from 'UI/Template/Table/types/TableEnums';
import { Icons } from './Icons';
import { ResultWrapperText, ResultWrapperDot, includeStyle } from 'UI/atoms/ResultWrapper/result-wrapper';
import { TooltipWrapper } from 'UI/atoms/Tooltips/tooltip-wrapper';
// import { useTranslation } from "react-i18next";

interface IProps {
	ecoResults: any;
	indicator: string;
	unit: string;
	stage: string;
}

const EcodesignResultsDetailCard = (props: IProps) => {
	const { t } = useTranslation();
	const { ecoResults, indicator, unit, stage } = props;
	const dataSchema = dataGroups;
	const result =
		stage === 'T' && unit !== 'relative'
			? ecoResults[unit] * 10000
			: ecoResults[unit];
	const indicatorIcon = dataSchema[indicator].icon;
	const indicatorColor = dataSchema[indicator].color;
	const isNeg = result < 0 ? true : false;

	const ResultWrapperTextByResult = includeStyle(ResultWrapperText, result);
	const ResultWrapperDotByResult = includeStyle(ResultWrapperDot, result);

	return (
		<div className="d-flex flex-column w-25 mt-3">
			{unit !== 'equivalence' && (
				<span
					className={`${indicatorIcon} font-30px mb-1 text-dark-100`}
				/>
			)}
			{unit === 'equivalence' && indicator === 'water' && (
				<span
				className={`icon-icon-bottle font-30px mb-1 text-dark-100`}
			/>
			)}
			{unit === 'equivalence' && indicator === 'climateChange' && (
				<span
				className={`icon-icon-bulbs font-30px mb-1 text-dark-100`}
			/>
			)}
			{unit === 'equivalence' && indicator === 'eutrophication' && (
				<span
				className={`icon-icon-washer font-30px mb-1 text-dark-100`}
			/>
			)}
			{unit === 'equivalence' && indicator === 'fossilFuels' && (
				<span
					className={`icon-icon-car font-30px mb-1 text-dark-100`}
				/>
			)}
			<ResultWrapperDotByResult>
				{unit !== 'equivalence' && (
					<MetricNumber
						id={unit}
						value={result}
						styles={'font-20px font-sfpro-medium text-dark-100'}
						isInteger={unit !== 'impact'}
						dataType={unit === 'relative' ? EDataTypes.r : EDataTypes.u}
					/>
				)}
				{unit === 'equivalence' && (
					<MetricNumber
						id={unit}
						value={Math.abs(result)}
						styles={'font-20px font-sfpro-medium text-dark-100'}
						isInteger
						dataType={EDataTypes.u}
					/>
				)}
			</ResultWrapperDotByResult>

			<ResultWrapperTextByResult>
				{unit === 'impact' && (
					<MetricUnit
						indicator={indicator}
						dataType={EDataTypes.u}
						difference
						styles={'font-sfpro-regular font-14px text-dark-100'}
					/>
				)}
				{unit === 'equivalence' && (
					<>
						<MetricUnit
							indicator={indicator}
							dataType={EDataTypes.ue}
							metricUnit={`ecodesign-eq-${unit}-${indicator}${
								isNeg ? '-neg' : ''
							}`}
							styles={'font-sfpro-regular font-14px text-dark-100'}
						/>
						<TooltipWrapper tooltipText={t(`ecodesign-results-eq-tooltip-${indicator}`)}>
							<span
								className="icon-icon-info font-12px ml-1 cursor-pointer"
							/>
						</TooltipWrapper>
					</>
				)}
				{unit === 'relative' && (
					<MetricUnit
						indicator={indicator}
						dataType={EDataTypes.ue}
						metricUnit={`ecodesign-eq-${unit}-${indicator}`}
						styles={'font-sfpro-regular font-14px text-dark-100'}
					/>
				)}
			</ResultWrapperTextByResult>
		</div>
	);
};

export default EcodesignResultsDetailCard;
