import React from 'react';
import { EDataTypes } from 'UI/Template/Table/types/TableEnums';
import { formatNumber, formatNumberNoDecimals, numberWithDots } from "../../../utils/utils";

interface Props {
   id: string,
   value:number | undefined | null,
   isInteger:boolean,
   dataType: EDataTypes,
   nullReplacement:string, //default '--'
   styles?: string | undefined,
}
const defaultProps:Partial<Props> = {
   nullReplacement: '--',
   isInteger: false,
};
/*
   <MetricNumber value={number} />
 */
const MetricNumber = (props:Props) => {
   const { id, value, isInteger, nullReplacement, dataType, styles } = props;

   let textValue;
   if (value === null || value === undefined) {
      textValue = nullReplacement;
   } else {
      textValue = isInteger ? formatNumberNoDecimals(value) : formatNumber(value);
   }

   return (
         <span className={`font-sfpro-regular font-14px text-dark-100 text-overflow-ellipsis overflow-hidden ${styles ? styles : ''}`}>{numberWithDots(textValue)}{dataType === EDataTypes.r && '%'}</span>
   )

}

MetricNumber.defaultProps = defaultProps;

export const MetricInteger = (props:Props) => <MetricNumber {...props} isInteger />;

export const MetricNumberDashboard = (props:Props) => {
   const { id, value, isInteger, nullReplacement, dataType } = props;

   let textValue;
   if (value === null || value === undefined) {
      textValue = nullReplacement;
   } else {
      textValue = isInteger ? formatNumberNoDecimals(value) : formatNumber(value);
   }

   return (
         <span className="font-sfpro-medium font-16px text-dark-100">{textValue}{dataType === EDataTypes.r && '%'}</span>
   )
}

MetricNumberDashboard.defaultProps = defaultProps;

export default MetricNumber;

