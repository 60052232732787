import React from "react";

interface Props {
	link: string,
	alt: string,
	width: string,
	height: string,
	extraCss?: string,
}

const BodyImageLink = (props: Props) => {
	const { link, alt, extraCss, width, height } = props;

	const handleOpenLink = (e:any) => {
		window.open(link);
        e.stopPropagation();
	};

	return (
		<img
			src={link}
			alt={alt}
			className={`${extraCss ? extraCss : ""}`}
			style={{ width: width, height: height }}
			onClick={(e) => handleOpenLink(e)}
		/>
	);
};

export default BodyImageLink;
