import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils/utils';

interface Props {
	id: string;
	value: number | undefined | null;
	nullReplacement: string; //default '--'
	styles?: string | undefined;
	lowerCase?: boolean;
}
const defaultProps: Partial<Props> = {
	nullReplacement: '--',
};

const Points = (props: Props) => {
	const { id, value, nullReplacement, styles, lowerCase } = props;
	const { t } = useTranslation();

	let textValue;
	if (value === null || value === undefined) {
		textValue = nullReplacement;
	} else {
		textValue = formatNumber(value);
	}

	const textPoints = lowerCase ? t('points').toLowerCase() : t('points');

	return (
		<span
			className={`font-sfpro-regular font-14px text-dark-60 text-overflow-ellipsis overflow-hidden ${
				styles ? styles : ''
			}`}
		>
			{textValue === '--' ? textValue : `${textValue} ${textPoints}`}
		</span>
	);
};

Points.defaultProps = defaultProps;

export default Points;
