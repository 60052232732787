import React from 'react';
import Modal from 'components/Common/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { Button, ModalFooter } from 'reactstrap';

const ModalHelp = (props: any) => {
	const { showModal, setShowModal, helpText } = props;
	const { t }: any = useTranslation();

	const modalFooter = (props: any) => {
		return (
			<ModalFooter className="d-flex justify-content-between">
				<Button
					color="secondary"
					className="d-flex justify-content-center"
					onClick={props.handleClose}>
					{t('common-close')}
				</Button>
			</ModalFooter>
		);
	};

	return (
		<Modal
			modal={showModal}
			setModal={setShowModal}
			className="page-selector-modal"
			scrollable
			title={t('help')}
			size="md"
			modalName={'help-modal'}
			FooterComponent={modalFooter}
            backdrop={false}
            >
			<p className="p-3">{helpText}</p>
		</Modal>
	);
};
export default ModalHelp;
