import Crud, { ICrudListResponse } from "./Crud";


export default class LcaCategories extends Crud {
    constructor() {
        super("lcaCategory");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/')
    }
}
