import React from "react";
import { Highlighted } from "../../atoms/Highlighted/index";
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';

interface Props {
	id?: string;
	indicator?: string;
	copy: string;
	color: string;
	tooltipText?: string;
}

const BodyTextTooltip = (props: Props) => {
	const {
		id,
		indicator = "",
		copy,
		color = "80",
        tooltipText
	} = props;

	const tooltip_id = "B" + id?.replace(/[^A-Z0-9]/ig, "_") + indicator;
    const tooltipId = tooltip_id;
    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);


	const text = copy ? copy : "--"

	const handleMouseAction = (value: boolean) => setIsTooltipOpen(value)

	return (
		<>
			<span
				id={tooltipId}
				onMouseEnter={() => handleMouseAction(true)}
				onMouseLeave={() => handleMouseAction(false)}
				className={`d-flex font-14px text-overflow-ellipsis overflow-hidden text-dark-${color} font-sfpro-regular text-title-sentence`}
			>
				<Highlighted text={text} />
			</span>
			<SimpleTooltip
				tooltipId={tooltipId}
				tooltipOpen={isTooltipOpen}
				text={tooltipText}
				styles={{ maxWidth: '250px', textAlign: 'start' }}
			/>
		</>
	);
};

export default BodyTextTooltip;
