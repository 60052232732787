import React, { useState } from 'react';
import { useTable } from 'contexts/tableContext';
import { useTranslation } from 'react-i18next';
import BodyCheckIcon from 'UI/atoms/BodyCheckIcon/Index';
import { EMetricNames } from 'UI/Template/Table/types/TableEnums';
import { ITableSchemaColumn } from 'UI/Template/Table/types/TableTypes';
import { Highlighted } from 'UI/atoms/Highlighted';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';

interface IItemCheckProps {
	id: string;
	name: string;
	item: any;
	handleSelect: (id: string) => void;
	isSelected: boolean;
	searchText?: string;
}

const ItemCheck = (props: IItemCheckProps) => {
	const { id, name, item, handleSelect, isSelected, searchText } = props;

	const handleCheckItem = (id: string) => {
		handleSelect(id);
	};

	return (
		<div
			className={`product-info-item d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden`}
			onClick={() => handleCheckItem(id)}
		>
			<BodyCheckIcon
				selectItem={isSelected}
				handleCheckItem={handleCheckItem}
				extraCss={'mr-2'}
				item={item}
			/>
			<div
				className={`font-14px text-overflow-ellipsis overflow-hidden cursor-pointer ${
					isSelected
						? 'text-dark-100 font-sfpro-medium'
						: 'text-dark-60 font-sfpro-regular'
				}`}
				onClick={() =>
					id ? handleCheckItem(id) : handleCheckItem(name)
				}
			>
				<Highlighted text={name} highlight={searchText} />
			</div>
		</div>
	);
};

export default ItemCheck;

interface ICheckboxProps {
	id: string;
	label: string;
	item: any;
	handleSelect: (id: string) => void;
	isSelected: boolean;
}

export const Checkbox = (props: ICheckboxProps) => {
	const { id, label, item, handleSelect, isSelected } = props;

	const handleCheckItem = (id: string) => {
		handleSelect(id);
	};

	return (
		<div
			className={`product-info-item d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden`}
			onClick={() => handleCheckItem(id)}
		>
			<BodyCheckIcon
				selectItem={isSelected}
				handleCheckItem={handleCheckItem}
				extraCss={'mr-2'}
				item={item}
			/>
			<div
				className={`font-14px text-overflow-ellipsis overflow-hidden cursor-pointer ${
					isSelected
						? 'text-dark-100 font-sfpro-medium'
						: 'text-dark-60 font-sfpro-regular'
				}`}
			>
				{label}
			</div>
		</div>
	);
};

interface IItemCheckColumnSelector {
	item: ITableSchemaColumn;
	searchColumnsText: string;
}

export const ItemCheckColumnSelector = (props: IItemCheckColumnSelector) => {
	const { t } = useTranslation();
	const { item, searchColumnsText } = props;
	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);
	const { columnSelectorColumns, toggleSelectedColumn } = useTable();
	const isSelected = columnSelectorColumns?.find(
		(c2: EMetricNames) => c2 === item.id
	);

	const handleCheckItem = (id: string) => {
		if (id !== 'name') toggleSelectedColumn(id);
	};

	const text = t(`select-${(validateReferenceType && item.label === 'articleInfo') ? 'material' : item.label}`);

	return (
		<>
			<div
				className={`product-info-item d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden`}
			>
				<BodyCheckIcon
					selectItem={isSelected}
					handleCheckItem={handleCheckItem}
					extraCss={'mr-2'}
					item={item}
				/>
				<div
					className={`font-14px text-overflow-ellipsis overflow-hidden ${
						isSelected
							? 'text-dark-100 font-sfpro-medium'
							: 'text-dark-60 font-sfpro-regular'
					} ${item.id !== 'name' ? 'cursor-pointer' : ''}`}
					onClick={() => handleCheckItem(item.id)}
				>
					<Highlighted text={text} highlight={searchColumnsText} />
				</div>
			</div>
		</>
	);
};
