// import PATHS from 'permissions/roles/AppPaths';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { useHistory } from 'react-router-dom';

import { ETableTypes, ITableSchema } from '../types/TableTypes';
import PATHS from 'permissions/roles/AppPaths';
import { useProjects } from 'contexts/projectsContext';

const ReferenceItem = (props: any) => {
    const { contArticle } = props.item;

    return (
    <div className="d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden pr-5">
        <span className="pl-2 text-uppercase text-overflow-ellipsis overflow-hidden">{contArticle}</span>
    </div>)
}

const VersionItem = (props: any) => {
    const { version } = props.item;
    return (
        <div className="d-flex flex-row align-items-center text-overflow-ellipsis overflow-hidden pr-5">
            <span className="pl-2 text-uppercase text-overflow-ellipsis overflow-hidden">{version}</span>
        </div>)
}

const ProjectItem = (props: any) => {
    const { name } = props.item;

    return (
        <span className="font-sfpro-medium text-dark-100 text-overflow-ellipsis overflow-hidden pr-5" style={{letterSpacing: 'unset'}}>{name}</span>
    )
}

const ProjectCreationDate = (props: any) => {
    const { updatedAt } = props.item;
    // const date = new Date(createdAt).toDateString().split(' ').slice(1).join(' ');

    return (
        <span className="font-sfpro-regular text-dark-100 text-overflow-ellipsis overflow-hidden pr-5" style={{letterSpacing: 'unset'}}>{moment(updatedAt).format('MMM D, YYYY')}</span>
    )
}


const ProjectNoData = () => {
    const {t} = useTranslation();
    return (
        <span className="font-sfpro-regular">{t('Soon')}</span>
    )
}

const Product = (props: any) => {
    const h = useHistory();
    const { item } = props;
    const { setProjectSelected } = useProjects();
    const projectId = useMemo(() => props.item.id, []);
    const lcaPath = PATHS.metricsLcaDashboard.replace(':projectId', projectId);

    const commonStyle = 'mx-1 rounded-3 text-uppercase font-sfpro-medium cursor-pointer home-project-list';

    const handleClick = (path: string) => () => {
        if (setProjectSelected) setProjectSelected(item);
        h.push(path);
    }

    return (
        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <button
                className={`${commonStyle}__traceability`}
                onClick={handleClick(PATHS.traceabilityDashboard)}
            >
                <span>PLM</span>
            </button>
            <button
                className={`${commonStyle}__lca`}
                onClick={handleClick(lcaPath)}
            >
                <span>LCA</span>
            </button>
        </div>
    )
}

const schema : ITableSchema = {
    width: 100,
    tableType: ETableTypes.infinite,
    itemsStriped: true,
    columns: {
        project: {
            label: 'Name',
            type: 'string',
            show: true,
            inFilters: false,
            component: ProjectItem,
            tableHeaderCssClass: 'font-sfpro-regular text-dark-60 font-14px',
        },
        reference: {
            label: 'Total Ref',
            type: 'string',
            show: true,
            component: ReferenceItem,
            inFilters: false,
            searchable: true,
            tableHeaderCssClass: 'font-sfpro-regular text-dark-60 font-14px',
        },
        season: {
            label: 'Version',
            type: 'string',
            show: true,
            inFilters: false,
            component: VersionItem,
            // breakpoints: ['lg', 'xl'],
            tableHeaderCssClass: 'font-sfpro-regular text-dark-60 font-14px',
        },
        dischargeDate: {
            label: 'updatedAt',
            type: 'string',
            show: true,
            dateFormat: '',
            inFilters: false,
            // breakpoints: ['lg', 'xl'],
            component: ProjectCreationDate,
            tableHeaderCssClass: 'font-sfpro-regular text-dark-60 font-14px',
        },
        products: {
            label: 'Products',
            type: 'string',
            show: true,
            inFilters: false,
            component: Product,
            tableHeaderCssClass: 'font-sfpro-regular text-dark-60 font-14px',
        },
    }
};

export default schema;