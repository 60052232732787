import React from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();
    const h = useHistory();

    const handleBack = () => {
        h.push("/")
    }

    return(
        <section className="w-100 h-100 d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: "40%"}}>
                <img className="w-100" src="/img/not-found.png" alt={t('not-found-title')} />
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ textAlign: "center"}}>
                    <span className="mantainance-page__title" dangerouslySetInnerHTML={{ __html: t('not-found-title')}} />
                    <span className="mantainance-page__subtitle mb-4" dangerouslySetInnerHTML={{ __html: t('not-found-subtitle')}} style={{width: "80%"}} />
                    <button
                        onClick={handleBack}
                        className='btn-action m-1 px-5 font-sfpro-medium'
                        style={{ width: "fit-content"}}
                        type="button"
                    >
                        { t("common-go-back") }
                    </button>
                </div>
            </div>
        </section>
    )
}

export default NotFound;