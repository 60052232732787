import Crud, { ICrudListResponse } from "./Crud";


export default class RawMaterialsCategories extends Crud {
    constructor() {
        super("rawMaterialCategory");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/')
    }
}
