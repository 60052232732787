import useFormFields from 'hooks/formFields.hook';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import Input, { InputFieldProps } from '../Input/Input';

import './InputAutocomplete.scss';

interface Props extends InputFieldProps {
	values: Array<any>,
	valuesType: "string" | "object", // Tipo de los values; 
	valuesKey?: string, // En caso de que values sean objetos, la key que mostrar;  
	onClickAutocompleteOption(value: any): any, // Al hacer click sobre una opción; 
	onChange(e: any): any, // Al escribir en el input de texto; 
}

const InputAutocomplete = (props: Props) => {
	const { t } = useTranslation();
	const { values, onClickAutocompleteOption, valuesType, valuesKey } = props;
	const [showOptions, setShowOptions]: any = useState(false);
	const componentRef = useRef(null);

    const { formFields, onInputChangeExt, validations, onChange } = useFormFields(
		{
			searchValue: '',
		}
	);

	useOutsideClick(componentRef, () => {
		setShowOptions(false);
	});

	const handleChange = (e: any) => {
		onInputChangeExt(e, 'searchValue');
        const value = e.target.value;
		const show = (value && values.length); 
		setShowOptions(show);
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const onClickOption = (value: any) => {
        setShowOptions(false);
		onChange('searchValue', '');
        if (onClickAutocompleteOption) {
            onClickAutocompleteOption(value);
        }
	};

	const optionsHtml: any = [];

	if (values) {
		values.forEach((value: any, index: number) => {
			let label = ''; 
			if (valuesType == "object" && valuesKey) {
				label = value[valuesKey]
			}
			else if (valuesType == "string") {
				label = value; 
			}

			optionsHtml.push(
				<p
					key={index}
					className="option cursor-pointer"
					onClick={() => {
						onClickOption(value);
					}}>
					{label}
				</p>
			);
		});
	}

	return (
		<div className="input-autocomplete" ref={componentRef}>
			<Input
				{...props}
				onChange={(e: any) => handleChange(e)}
                value={formFields.searchValue}
				className={`${showOptions ? 'open' : ''}`}
				>
					{showOptions ? <div className="options p-2">{optionsHtml}</div> : ''}
			</Input>
		</div>
	);
};

export default InputAutocomplete;
