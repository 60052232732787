import { useTable } from 'contexts/tableContext';
import React from 'react';
import { ESortTypes } from 'UI/molecules/SubheaderColumnArrows';

interface Props {
   metricName: string
   columnId?: string
}

function SortArrows(props: Props) {
   const { columnId } = props
   const { sortMetric, sortColumnId } = useTable()

   return (
      <div className='d-flex align-items-center justify-content-center'>
         {sortColumnId === columnId &&
            <span className={`${(sortMetric[0].order === ESortTypes.ASC || sortMetric.length === 0) && "icon-icon-arrow-filter-low"} 
                              ${sortMetric[0].order === ESortTypes.DESC && "icon-icon-arrow-filter-high"} text-dark-100 font-14px`}>
            </span>
         }
         {sortColumnId !== columnId &&
            <span className={`icon-arrow text-dark-60 hover-dark-100 icon-icon-arrow-filter-high font-14px`}></span>
         }
      </div>

   )
}

export default SortArrows;
