import React, { MouseEventHandler, useState } from "react";
import { EMetricNames } from "UI/Template/Table/types/TableEnums";

import { CustomIcon } from "../CustomIcon/Index";

interface IColorIconProps {
    color?: string,
    icon?: string,
    coloredIcon?: string,
}

const ColorIcon = (props: IColorIconProps) => {
    const {color, icon, coloredIcon} = props;

    if (coloredIcon) {
        return (
            <CustomIcon size={16} name={coloredIcon} />
        )
    } else {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center rounded-circle" style={{backgroundColor: color, minWidth: "13.5px", maxWidth: "13.5px", height: "13.5px", marginRight: "2px"}} >
                    <span style={{fontSize: "7px", color:"white"}} className={icon}></span>
                </div>
            </>
        )
    }
}

export default ColorIcon;

interface IColorIconSelectProps {
    metric?: EMetricNames,
    icon: string,
    color: string,
    // coloredIcon: string | undefined,
    font: string,
    extraCss?: string,
    itemSelected: boolean
    handleClick?: any,
}

export const ColorIconSelect = (props: IColorIconSelectProps) => {
    const {metric, icon, color, font, extraCss, itemSelected, handleClick} = props;    
    
    return (
        <span className={`icon-icon-${icon}${itemSelected ? '-selected' : ''} ${itemSelected ? '' : color} ${font} ${extraCss}`} onClick={() => handleClick(metric)}></span>
    )
}