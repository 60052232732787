import Notification from "../../components/Elements/Notification";

export default class Hubspot {

    async submit(email: string, successMessage: string, errorMessage:string, formId: string | undefined) {
        const xhr = new XMLHttpRequest();
        const url = `
        https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_FORM_PORTAL_ID}/${formId}
        `
        const data = {
            "fields": [
                {
                    "name": "email",
                    "value": email
                },
            ],
            "context": {
                "pageUri": "https://app.bcome.biz/",
                "pageName": "BCome App"
            },
        }

        const finalData = JSON.stringify(data);
        xhr.open("POST", url);

        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                Notification.display(
					"success",
					`${successMessage}`
				); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                Notification.display(
					"error",
					`${errorMessage}`
				);  // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                Notification.display(
					"error",
					`${errorMessage}`
				); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                Notification.display(
					"error",
					`${errorMessage}`
				); //Returns a 404 error if the formGuid isn't found     
            }
        }

        // Sends the request 
        xhr.send(finalData);
    }
}