import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpStorageI18n from './services/storage/Language';

import translation_en from './i18n/en/translations.json';
import translation_es from './i18n/es/translations.json';
import translation_ca from './i18n/ca/translations.json';
import translation_de from './i18n/de/translations.json';
import translation_dk from './i18n/dk/translations.json';
import translation_fr from './i18n/fr/translations.json';
import translation_he from './i18n/he/translations.json';
import translation_it from './i18n/it/translations.json';
import translation_ko from './i18n/ko/translations.json';
import translation_nl from './i18n/nl/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translation_en },
    es: { translation: translation_es },
    ca: { translation: translation_ca },
    de: { translation: translation_de },
    dk: { translation: translation_dk },
    fr: { translation: translation_fr },
    he: { translation: translation_he },
    it: { translation: translation_it },
    ko: { translation: translation_ko },
    nl: { translation: translation_nl }
  },
  lng: HttpStorageI18n.getCurrentLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  ns: ['translations', 'countries']
});

export default i18n;
