import React from 'react';
import { Popover, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';


interface Props {
    dataType?: string,
    tooltipId: string,
    tooltipOpen: boolean,
}

const FunctionalUnitTooltips = (props: Props) => {
    const { dataType, tooltipId, tooltipOpen } = props
    const { t } = useTranslation();


    interface dataTypeWidth {
        [key: string]: propDataTypeWidth
    }
    interface propDataTypeWidth {
        [key: string]: string
    }
    const dataTypeWidth: dataTypeWidth = {
        u: { width: "85px" },
        p: { width: "100px" },
        r: { minWidth: "135px" },
        bar: { minWidth: "145px" },
        ue: { minWidth: "110px" },
        pe: { minWidth: "125px" }
    }

    return (
        <Tooltip isOpen={tooltipOpen} target={tooltipId} placement="bottom-start" innerClassName="tooltips" arrowClassName="arrow-tooltip">
            <div className="font-sfpro-regular font-12px text-dark-60 p-2 d-flex align-items-start"
                style={tooltipId.includes("Savings") && dataType === "u"
                    ? { width: "350px", maxWidth: "350px", lineHeight: "20px" }
                    : { width: "400px", lineHeight: "20px" }}
            >

                <div className='text-dark-100 d-flex align-items-start pr-3'>
                    <span className={`icon-icon-${dataType} font-16px mr-1`} style={{ marginTop: "1px" }}></span>
                    {tooltipId.includes("Savings")
                        ? <span className="text-left" style={dataType ? dataTypeWidth[dataType] : { minWidth: '135px', maxWidth: "135px" }}>{t(`${dataType}-impact-benchmark`)}</span>
                        : <span className="text-left text-nowrap">{t(`${dataType}-impact`)}</span>
                    }
                </div>

                {tooltipId.includes("Savings")
                    ? <div className='text-left'>{t(`${dataType}-impact-benchmark-description`)}</div>
                    : <div className='text-left'>{t(`${dataType}-impact-description`)}</div>
                }
            </div>

        </Tooltip>

    )

}

export default FunctionalUnitTooltips