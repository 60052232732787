import React from 'react';
import { useTranslation } from 'react-i18next';
import { EcodesignInitSchema, IEcodesignRequest } from './types/ecodesign';
import InputRadio from 'UI/atoms/InputRadio/InputRadio';
import { Checkbox } from 'UI/molecules/ItemCheck/Index';
import FormatSubSupMetrics from 'utils/formatSubSupMetrics';

interface IProps {
	request: IEcodesignRequest;
	setRequest: any;
	units: string[];
	setUnits: any;
}

const EcodesignInit = (props: IProps) => {
	const { request, setRequest, units, setUnits } = props;
	const { t }: any = useTranslation();

	// Select stage
	const handleSelectStages = (value: string) => {
		const requestUpdated = { ...request, stage: value };
		setRequest(requestUpdated);
	};

	// Select indicators
	const handleSelectIndicators = (value: string) => {
		const isIncluded = request.indicators.includes(value);
		let requestUpdated;
		if (isIncluded) {
			const indicatorsUpdated = request.indicators.filter(
				(v: string) => v !== value
			);
			requestUpdated = { ...request, indicators: indicatorsUpdated };
		} else {
			requestUpdated = {
				...request,
				indicators: [...request.indicators, value],
			};
		}
		setRequest(requestUpdated);
	};

	// Select units
	const handleSelectUnits = (value: string) => {
		const isIncluded = units.includes(value);
		let unitsUpdated;
		if (isIncluded) {
			unitsUpdated = units.filter((v: string) => v !== value);
		} else {
			unitsUpdated = [...units, value];
		}
		setUnits(unitsUpdated);
	};

	return (
		<>
			{Object.entries(EcodesignInitSchema).map(([keyGroup, values]) => {
				return (
					<div
						key={keyGroup}
						className={`${
							keyGroup === 'unit' ? '' : 'border-bottom'
						} mt-4 pb-2`}
					>
						<h3 className="font-sfpro-medium font-14px text-dark-100">
							{t(`ecodesign-title-${keyGroup}`)}
						</h3>
						<h5 className="font-sfpro-regular font-12px text-dark-40 mb-4">
							{t(`ecodesign-subtitle-${keyGroup}`)}
						</h5>
						{Object.values(values).map((value: any) => {
							if (keyGroup === 'stage') {
								return (
									<div
										key={value.id}
										className="d-flex flex-row my-3"
									>
										<InputRadio
											label={t(
												`ecodesign-checkbox-${value.label}`
											)}
											checked={request.stage === value.id}
											handleOnChange={() =>
												handleSelectStages(value.id)
											}
										/>
									</div>
								);
							}
							if (keyGroup === 'indicator') {
								return (
									<div
										key={value.id}
										className="d-flex flex-row my-3"
									>
										<Checkbox
											id={value.id}
											item={value}
											label={t(
												`ecodesign-checkbox-${value.label}`
											)}
											handleSelect={(id: string) =>
												handleSelectIndicators(id)
											}
											isSelected={request.indicators.includes(
												value.id
											)}
										/>
									</div>
								);
							}
							if (keyGroup === 'unit') {
								return (
									<div
										key={value.id}
										className="d-flex flex-column my-3"
									>
										<Checkbox
											id={value.id}
											item={value}
											label={t(
												`ecodesign-checkbox-${value.label}`
											)}
											handleSelect={(id: string) =>
												handleSelectUnits(id)
											}
											isSelected={units.includes(
												value.id
											)}
										/>
										<span className="font-sfpro-regular font-14px text-dark-40 ml-4">
											<FormatSubSupMetrics
												indicator={'water'}
												text={t(
													`ecodesign-checkbox-${value.label}-eg`
												)}
											/>
										</span>
									</div>
								);
							}
						})}
					</div>
				);
			})}
		</>
	);
};
export default EcodesignInit;
