import SiderightProvider from 'providers/siderightProvider';
import React, { useContext, useRef } from 'react';
import Slide from 'react-reveal/Slide';

import './sideright.scss';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick.hook';
import { TFunctionResult } from 'i18next';

export interface SiderightProps {
	title: string | TFunctionResult, 
	ContentComponent: any, 
	backgroundColor?: string,
}

const Sideright = (props: any) => {
	const [sideright, setSideright] = useContext(SiderightProvider);
	const { ContentComponent, backgroundColor } = sideright;
	const { t }: any = useTranslation();
	const wrapperRef: any = useRef(null);

	const close = () => {
		setSideright(false);
	};

	useOutsideClick(wrapperRef, () => {
		close();
	})

	if (sideright == false) {
		return <div></div>;
	}

	const onKeyDown = (e: any) => {
		if (e.keyCode == 27) {
			close();
		}
	};

	return (
		<div
			className="sideright-wrapper"
			onKeyDown={(e) => {
				onKeyDown(e);
			}}
			>
			<Slide right style={{height: '100%'}}>
				<div className="row" ref={wrapperRef}>
					<div className="component col-12 offset-md-6 col-md-6 offset-xl-7 col-xl-5 pl-0 d-flex flex-column justify-content-between">
						<div className="w-100 header p-3 d-flex flex-row justify-content-between align-items-center">
							<h1 className="font-sfpro-regular pb-0 mb-0 text-uppercase">
								{t('articles')}
							</h1>
							<span
								className="icon-icon-close cursor-pointer"
								style={{ fontSize: '22px' }}
								onClick={() => {
									close();
								}}></span>
						</div>
						<div className="content d-flex flex-column justify-content-between" style={{ height: '100%', backgroundColor: `${backgroundColor}` }}>
							{ContentComponent}
						</div>
					</div>
				</div>
			</Slide>
		</div>
	);
};

export default Sideright;