import React from "react";

interface Props {
	extraCss?: string,
	selectItem: boolean | undefined, 
	handleCheckItem: (id: string) => void,
	item: any,
}

const BodyCheckIcon = (props: Props) => {
	const { extraCss, selectItem, handleCheckItem, item } = props;

	return (
		<span 
			className={`font-18px ${selectItem ? 'text-dark-100 icon-icon-checkbox-selected' : 'text-dark-60 icon-icon-checkbox'} ${extraCss ? extraCss : ''} ${item.id !== 'name' ? 'cursor-pointer' : ''}`}
			onClick={() => handleCheckItem(item.id)}
		/>
	);
};

export default BodyCheckIcon;
