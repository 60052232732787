import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSteps from 'UI/molecules/FormSteps/Index';
  
interface IProps {
	steps: string[],
    currentStep: string,
	results?: boolean | undefined
}

const EcodesignFormWrapperHeader = (props: IProps) => {
	const { steps, currentStep, results } = props;
	const { t }: any = useTranslation();
	
    return (
        <div className="border-bottom">
			<h3 className="font-sfpro-medium font-24px text-dark-100">{t(`ecodesign-header-title-${results ? currentStep : 'comparison'}`)}</h3>
			<h5 className="font-sfpro-regular font-20px text-dark-40 mb-5">{t(`ecodesign-header-subtitle-${results ? currentStep : 'comparison'}`)}</h5>
			{!results && (
				<div className="mt-5" style={{ width: '400px'}}>
					<FormSteps steps={steps} currentStep={currentStep} />
				</div>
			)}
			{results && <p className="font-sfpro-regular font-14px text-dark-40">{t(`ecodesign-header-description-results`)}</p>}
        </div>
    )
}
export default EcodesignFormWrapperHeader;
