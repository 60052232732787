import React from "react";
import { InformationNavigation } from "UI/molecules/InformationNavigation";
import { useDisableCEI } from "./hooks/useDisableCEI.hook";
import TableMetrics from "UI/Template/Table";
import { useMenuState } from "hooks/useMenuState.hook";
import { SpinnerLoader } from "components/Common/Loader/Loader";

const CEI = () => {
    const { allProjects, disabled } = useDisableCEI();
    const keyName = disabled ? 'cei-block' : 'cei-empty';
    const { callToAction, loading } = useMenuState('cei')

    if (loading) {
		return <div className="m-auto h-100 custom-spinner">
			<SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"}/>
		</div>
	}

    return callToAction
    ? <InformationNavigation keyName={'cei'} />
    : (allProjects?.length > 0 && !disabled) ? <TableMetrics /> : <InformationNavigation keyName={keyName} />
}

export default CEI;