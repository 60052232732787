import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBarScoreIcon } from 'UI/atoms/ProgressBarScoreIcon/ProgressBarScoreIcon';
import { SmartLabelIconCard } from './SmartLabelIconCard';
import iconArrow from "../../../styles/icons/icon-arrow.svg";
import iconArrowDisabled from "../../../styles/icons/icon-arrow-disabled.svg";
import { SmartLabelTooltip } from './SmartLabelTooltip';

interface ISmartLabelPlanEssential {
	id: any;
	color: string;
	value?: string | undefined;
	label: string | undefined;
	score: number | undefined;
	cardItems: any;
	articleMetrics: any;
	handleClicHide: any
}

export const SmartLabelPlanEssential = (props: ISmartLabelPlanEssential) => {
	const { t } = useTranslation();
	const [slide, setSlide]: any = useState<number>(0);
	const [touchStartX, setTouchStartX]: any = useState(undefined);
	const [touchEnd, setTouchEnd]: any = useState<number>(0);
	const { id, color, value, label, score, cardItems, articleMetrics, handleClicHide } = props;

	const planetFooterCss = 'font-sfpro-medium letter-spacing-normal text-dark-60 font-12px cursor-pointer hover-dark-100'
	const activeFooterOption = 'bg-gray-01 text-dark-100'

	let environmentGroup;
	let equivalencesGroup;
	let savingsGroup;

	if (label === 'Planet') {
		environmentGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'environment'
		);
		equivalencesGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'equivalences'
		);
		savingsGroup = cardItems.filter(
			(item: any) => item.itemGroup === 'savings'
		);
	}

	const handleTouchStart = (e: any) =>
		setTouchStartX(e.targetTouches[0].clientX);
	const handleTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);
	const handleTouchEnd = () => {
		if (touchStartX - touchEnd > 0) {
			if (slide < 2) {
				const nextSlide = slide + 1;
				setSlide(nextSlide);
			}
		}

		if (touchStartX - touchEnd < 0) {
			if (slide > 0) {
				const prevSlide = slide - 1;
				setSlide(prevSlide);
			}
		}
	};

	const handleCurrentSlide = (option: number) => () => setSlide(option);

	return (
		<div
			className="w-100 d-flex flex-column justify-content-between pb-3"
		>
			<div className="w-100 d-flex flex-row justify-content-between mb-2">
				<span className="d-flex font-sfpro-medium letter-spacing-normal font-16px text-dark-100">
					{t(`${'environmentalAssessment'}`)}
				</span>
				<span
					id={id?.toLowerCase()}
					className="icon-icon-close font-sfpro-medium letter-spacing-normal font-10px cursor-pointer"
					onClick={handleClicHide}
				></span>
			</div>
			<span
				className="text-dark-60 font-sfpro-regular letter-spacing-normal d-flex flex-row align-items-center"
				style={{ marginBottom: '30px' }}
			>
				{t(`environmentalAssessment-qr-description`)}
				<SmartLabelTooltip label={label} text={t(`${label}-qr-description-inside-tooltip`)} />
			</span>
			<div
				onTouchStart={(touchStartEvent) =>
					handleTouchStart(touchStartEvent)
				}
				onTouchMove={(touchMoveEvent) =>
					handleTouchMove(touchMoveEvent)
				}
				onTouchEnd={() => handleTouchEnd()}
			>
				{slide === 0 &&
					environmentGroup.map(
						(item: any) =>
							(articleMetrics[item.label] || articleMetrics[item.label] === 0)  && (
								<SmartLabelIconCard
									key={item.id}
									icon={item.icon}
									value={articleMetrics[item.label]}
									color="#2C2C51"
									label={item.label}
								/>
							)
					)}
				{slide === 1 &&
					equivalencesGroup.map(
						(item: any) =>
							(articleMetrics[item.label] || articleMetrics[item.label] === 0) && (
								<SmartLabelIconCard
									key={item.id}
									icon={item.icon}
									value={
										articleMetrics[item.metric] * item.eqValue
									}
									color="#2C2C51"
									label={item.label}
								/>
							)
					)}
				{slide === 2 && (
					<>
						{savingsGroup.map(
							(item: any) =>
								articleMetrics[item.label] !== null && (
									<ProgressBarScoreIcon
										key={item.id}
										icon={item.icon}
										value={articleMetrics[item.label]}
										score={articleMetrics[item.label]}
										color={item.color}
										label={item.label}
									/>
								)
						)}
						<SmartLabelTooltip label={'qr-compared-industry'} text={t('qr-compared-industry-tooltip')}>
							<span className="text-dark-60 mt-4 text-decoration-underline cursor-pointer">
								{t('qr-compared-industry')}
							</span>
						</SmartLabelTooltip>
					</>
				)}
			</div>

			<div className="d-flex flex-row mt-3 mb-1 justify-content-center">
				<div className="d-flex flex-row mt-3 mb-1 justify-content-center smart-label__footer bg-white">
					<span onClick={handleCurrentSlide(0)} className={`${planetFooterCss} ${slide === 0 && activeFooterOption}`}>
						{t('environmentGroup')}
					</span>
					<span onClick={handleCurrentSlide(1)} className={`${planetFooterCss} ${slide === 1 && activeFooterOption}`}>
						{t('equivalencesGroup')}
					</span>
					<span onClick={handleCurrentSlide(2)} className={`${planetFooterCss} ${slide === 2 && activeFooterOption}`}>
						{t('savingsGroup')}
					</span>
				</div>
			</div>
		</div>
	);
};
