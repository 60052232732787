export const isElementInViewport = (selector: string): Promise<boolean> => {
	return new Promise((resolve) => {
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				resolve(entry.intersectionRatio === 1);
				observer.disconnect();
			});
		});

		const element = document.querySelector(selector) as Element;
		if (element) {
			observer.observe(element);
		}
	});
};
