import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import React, { Children } from 'react';
import { Fragment, useState } from 'react';

type ISmartLabelTooltip = {
	label: string | undefined;
	text: string;
	children?: React.ReactNode;
};

interface ChildrenComponentProps {
	id?: string;
	onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }

export const SmartLabelTooltip = ({
	label,
	text,
	children,
}: ISmartLabelTooltip) => {
	const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

	const tooltipId = 'Tooltip' + label?.replace(/[^A-Z0-9]/gi, '_');

	const handleHover = (value: boolean) => () => setIsTooltipOpen(value);

	const options: ChildrenComponentProps = {
		id: tooltipId,
		onMouseEnter: handleHover(true),
		onMouseLeave: handleHover(false),
	};

	return (
		<Fragment>
			{children ? (
				React.Children.map(children, (child) =>
					React.isValidElement(child)
						? React.cloneElement(child, options)
						: child
				)
			) : (
				<span
					id={tooltipId}
					className="icon-icon-info ml-1"
					onMouseEnter={handleHover(true)}
					onMouseLeave={handleHover(false)}
				></span>
			)}
			<SimpleTooltip
				tooltipId={tooltipId}
				tooltipOpen={isTooltipOpen}
				text={text}
				styles={{ maxWidth: '250px', textAlign: 'start' }}
			/>
		</Fragment>
	);
};
