declare global {
    interface Crypto {
        randomUUID: () => string;
    }
}

import React, { useMemo, useState } from "react";
import { SimpleTooltip } from "./Index";

type TextJSX = () => JSX.Element;

type TooltipWrapperProps = {
    tooltipText: string | TextJSX;
    children:any;
    styles?: { maxWidth: string, textAlign: string };
}

export const TooltipWrapper = ({tooltipText, children, styles}: TooltipWrapperProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const tooltipId = useMemo(() => 'd-' + self.crypto.randomUUID(), [])

    const handleIsOpen = (open: boolean) => () => setIsOpen(open);

    const injectTooltipContent = typeof tooltipText === 'string' ? { text: tooltipText } : { JSXElement: tooltipText }

    return (
        <>
            {
                React.isValidElement(children) as any ?
                    React.cloneElement(children, {
                        id: tooltipId,
                        onMouseEnter: handleIsOpen(true),
                        onMouseLeave: handleIsOpen(false),
                    }) :
                    children
            }
            <SimpleTooltip
                tooltipId={tooltipId}
                tooltipOpen={isOpen}
                styles={styles}
                {...injectTooltipContent}
            />
        </>
    )
}