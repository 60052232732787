export function getResultLabelByScore(score: number) : string {
    let resultLabel = "E"; // = E ?

    if (score > 5.9 && score <= 6.9) {
        resultLabel = "D"; // = D ?
    }
    else if (score > 6.9 && score <= 7.9) {
        resultLabel = "C"; // = C ?
    }
    else if (score > 7.9 && score <= 8.9) {
        resultLabel = "B"; // = B ?
    }
    else if (score > 8.9) {
        resultLabel = "A"; // = A ?
    }
    return resultLabel;
}

export function getResultLabelByScoreSavings(score: number) : string {
    let resultLabel = "low";
    if (score > 20 && score <= 60) {
        resultLabel = "medium";
    }
    else if (score > 60) {
        resultLabel = "high";
    }
    return resultLabel;
}

interface IProjectResultInfoByType {
    [key: string]: IProjectResultInfoByTypeItem
}

interface IProjectResultInfoByTypeItem {
    label: string,
    color: string,
    icon?: string,
    metricName?: string,
}

export const SUSTAINABILITY_INDEX_TOTAL_SCORES: any = {
    Planet: 240.0,
    People: 160.0,
    Transparency: 100.0,
    Circularity: 230.0
}

export const PROJECT_RESULT_INFO_BY_TYPE: IProjectResultInfoByType = {
    planet: {
        label: 'planet',
        color: '#773F0F',
        metricName: "Planet",
    },
    people: {
        label: 'people',
        color: '#0E343C',
        metricName: "People",
    },
    transparency: {
        label: 'transparency',
        color: '#BE8F1F',
        metricName: "Transparency",
    },
    circularity: {
        label: 'circularity',
        color: '#C8B7AF',
        metricName: "Circularity",
    },
    waterUse: {
        label: 'waterUse',
        color: '#015594',
        metricName: "Water",
    },
    climateChange: {
        label: 'climateChange',
        color: '#424242',
        metricName: 'ClimateChange',
    },
    eutrophication: {
        label: 'eutrophication',
        color: "#887D37",
        metricName: 'Eutrophication',
    },
    fossilFuels: {
        label: 'fossilFuels',
        color: "#1F4A53",
        metricName: 'FossilFuels',
    },
    totalWaterConsumption: {
        label: 'totalWaterConsumption',
        color: '#015594',
    },
    totalClimateChange: {
        label: 'totalClimateChange',
        color: '#424242',
    },
    totalEutrophication: {
        label: 'totalEutrophication',
        color: '#887D37',
    },
    totalFossilFuels: {
        label: 'totalFossilFuels',
        color: '#1F4A53',
    },
    unit_lH2O: {
        label: 'unit_lH2O',
        color: '#015594',
        metricName: "Water"
    },
    eq_lH2O: {
        label: 'eq_lH2O',
        color: '#015594',
        icon: 'icon-icon-bottle',
        metricName: 'Equivalences_Water_Relative',
    },
    eq_lH2ORate: {
        label: 'eq_lH2O',
        color: '#015594',
        metricName: 'Water'
    },
    savings_lH2O: {
        label: 'eq_lH2O',
        color: '#015594',
        metricName: 'Savings_Water_Relative'
    },
    savings_lH2O_rate: {
        label: 'eq_lH2O',
        color: '#015594',
        metricName: 'Savings_Water'
    },
    unit_kgCO2: {
        label: 'unit_kgCO2',
        color: '#424242',
        metricName: 'ClimateChange'
    },
    eq_kgCO2: {
        label: 'eq_kgCO2',
        color: '#424242',
        icon: 'icon-icon-battery',
        metricName: 'Equivalences_ClimateChange_Relative',
    },
    eq_kgCO2Rate: {
        label: 'eq_kgCO2Rate',
        color: '#424242',
        metricName: 'ClimateChange',
    },
    savings_kgCO2: {
        label: 'eq_kgCO2',
        color: '#424242',
        metricName: 'Savings_ClimateChange_Relative',
    },
    savings_kgCO2_rate: {
        label: 'eq_kgCO2',
        color: '#424242',
        metricName: 'Savings_ClimateChange',
    },
    unit_gPO4: {
        label: 'unit_gPO4',
        color: '#887D37',
        metricName: 'Eutrophication'
    },
    eq_gPO4: {
        label: 'eq_gPO4',
        color: '#887D37',
        icon: 'icon-icon-tomatoes',
        metricName: 'Equivalences_Eutrophication_Relative',
    },
    eq_gPO4Rate: {
        label: 'eq_gPO4',
        color: '#887D37',
        metricName: 'Eutrophication',
    },
    savings_gPO4: {
        label: 'eq_gPO4',
        color: '#887D37',
        metricName: 'Savings_Eutrophication_Relative',
    },
    savings_gPO4_rate: {
        label: 'eq_gPO4',
        color: '#887D37',
        metricName: 'Savings_Eutrophication',
    },
    unit_gFossilFuels: {
        label: 'unit_gFossilFuels',
        color: '#1F4A53',
        metricName: 'FossilFuels'
    },
    eq_mJ: {
        label: 'eq_mJ',
        //color: '#85694F', // waste color
        color: '#1F4A53', // fossilfuel color
        icon: 'icon-icon-hairdryer',
        metricName: 'Equivalences_FossilFuels_Relative',
    },
    kg: {
        label: 'kg',
        color: '#1F4A53',
        metricName: 'FossilFuels',
    },
    eq_kgWateRate: {
        label: 'eq_kgWateRate',
        color: '#1F4A53',
        metricName: 'FossilFuels',
    },
    savings_kgFossilFuels: {
        label: 'eq_mJ',
        color: '#1F4A53',
        metricName: 'Savings_FossilFuels_Relative',
    },
    savings_kgFossilFuels_rate: {
        label: 'eq_mJ',
        color: '#1F4A53',
        metricName: 'Savings_FossilFuels',
    },
    total_lH2O: {
        label: 'total_lH2O',
        color: '#015594',
        metricName: "Water"
    },
    total_kgCO2: {
        label: 'total_kgCO2',
        color: '#424242',
        metricName: "ClimateChange"
    },
    total_gPO4: {
        label: 'total_gPO4',
        color: '#887D37',
        metricName: "Eutrophication"
    },
    total_gFossilFuels: {
        label: 'total_gFossilFuels',
        color: '#1F4A53',
        metricName: "FossilFuels"
    },
    waterGlasses: {
        label: 'waterGlasses',
        color: '#015594',
        icon: 'icon-icon-bottle',
        metricName: 'Equivalences_Water_Relative',
    },
    pool: {
        label: 'pool',
        color: '#015594',
        icon: 'icon-icon-pool'
    },
    lightBulbs: {
        label: 'lightBulbs',
        color: '#424242',
        icon: 'icon-icon-bulbs',
    },
    battery: {
        label: 'battery',
        color: '#424242',
        icon: 'icon-icon-battery',
        metricName: 'Equivalences_ClimateChange_Relative',
    },
    tomatoes: {
        label: "tomatoes",
        color: '#887D37',
        icon: 'icon-icon-tomatoes',
        metricName: 'Equivalences_Eutrophication_Relative',
    },
    detergent: {
        label: "detergent",
        color: '#887D37',
        icon: 'icon-icon-detergent',
    },
    carRefueling: {
        label: "carRefueling",
        color: "#1F4A53",
        icon: 'icon-icon-refuels',
    },
    hairdryer: {
        label: "hairdryer",
        color: "#1F4A53",
        icon: 'icon-icon-hairdryer',
        metricName: 'Equivalences_FossilFuels_Relative',
    },
    unit_savings: {
        label: 'unit_savings',
        color: '#015594',
    },
    unit_savingsRate: {
        label: 'unit_savingsRate',
        color: '#015594'
    },
    tracCertificates: {
        label: 'tracCertificates',
        color: '#BE8F1F',
        icon: 'icon-icon-certificate',
        metricName: 'Traceability_Certificates',
    },
    tracKm: {
        label: 'tracKm',
        color: '#BE8F1F',
        metricName: 'Traceability_Km',
    },
    tracPeople: {
        label: 'tracPeople',
        color: '#05343C',
        metricName: 'Traceability_People',
    },
    tracProcess: {
        label: 'tracProcess',
        color: '#BE8F1F',
        metricName: 'Traceability_Process',
    },
    tracSuppliers: {
        label: 'tracSuppliers',
        color: '#05343C',
        metricName: 'Traceability_Suppliers',
    },
    tracCertificatesOnePage: {
        label: 'tracCertificatesOnePage',
        color: '#BE8F1F',
        icon: 'icon-icon-certificate',
        metricName: 'Traceability_Certificates',
    },
    tracKmOnePage: {
        label: 'tracKmOnePage',
        color: '#BE8F1F',
        icon: 'icon-icon-distance',
        metricName: 'Traceability_Km',
    },
    tracPeopleOnePage: {
        label: 'tracPeopleOnePage',
        color: '#05343C',
        icon: 'icon-icon-people',
        metricName: 'Traceability_People',
    },
    tracProcessOnePage: {
        label: 'tracProcessOnePage',
        color: '#BE8F1F',
        icon: 'icon-icon-process',
        metricName: 'Traceability_Process',
    },
    tracSuppliersOnePage: {
        label: 'tracSuppliersOnePage',
        color: '#05343C',
        icon: 'icon-icon-manufacturing',
        metricName: 'Traceability_Suppliers',
    },
}

/**
 * Objeto que sirve para obtener los colores y label de los campos de las tablas de resultados de LCA y sus procesos;
 */

interface ILCAProcessesByType {
    [key: string]: ILCAProcessesByTypeItem
}

interface ILCAProcessesByTypeItem {
    label: string,
    color: string,
    icon?: string,
    metricName?: string,
}

 export const LCA_ARTICLE_DETAIL: ILCAProcessesByType = {
   climateChange: {
        label: 'ClimateChange',
        color: '#424242',
        metricName: 'ClimateChange',
    },
    eutrophication: {
        label: 'Eutrophication',
        color: '#887D37',
        metricName: 'Eutrophication',
    },
    water: {
        label: 'Water',
        color: '#015594',
        metricName: 'Water',
    },
    fossilFuels: {
        label: 'FossilFuels',
        color: '#1F4A53',
        metricName: 'FossilFuels',
    },
    articleDFPClimateChange: {
        label: 'articleDFPClimateChange',
        color: '#424242',
        metricName: 'ArticleDFP_ClimateChange',
    },
    articleDFPEutrophication: {
        label: 'articleDFPEutrophication',
        color: '#887D37',
        metricName: 'ArticleDFP_Eutrophication',
    },
    articleDFPWater: {
        label: 'articleDFPWater',
        color: '#015594',
        metricName: 'ArticleDFP_Water',
    },
    articleDFPFossilFuels: {
        label: 'articleDFPFossilFuels',
        color: '#1F4A53',
        metricName: 'ArticleDFP_FossilFuels',
    },

    laDPClimateChange: {
        label: 'laDPClimateChange',
        color: '#424242',
        metricName: 'LaDP_ClimateChange',
    },
    laDPEutrophication: {
        label: 'laDPEutrophication',
        color: '#887D37',
        metricName: 'LaDP_Eutrophication',
    },
    laDPWater: {
        label: 'laDPWater',
        color: '#015594',
        metricName: 'LaDP_Water',
    },
    laDPFossilFuels: {
        label: 'laDPFossilFuels',
        color: '#1F4A53',
        metricName: 'LaDP_FossilFuels',
    },

    manufactureClimateChange: {
        label: 'manufactureClimateChange',
        color: '#424242',
        metricName: 'Manufacture_ClimateChange',
    },
    manufactureEutrophication: {
        label: 'manufactureEutrophication',
        color: '#887D37',
        metricName: 'Manufacture_Eutrophication',
    },
    manufactureWater: {
        label: 'manufactureWater',
        color: '#015594',
        metricName: 'Manufacture_Water',
    },
    manufactureFossilFuels: {
        label: 'manufactureFossilFuels',
        color: '#1F4A53',
        metricName: 'Manufacture_FossilFuels',
    },

    materialDFPClimateChange: {
        label: 'materialDFPClimateChange',
        color: '#424242',
        metricName: 'MaterialDFP_ClimateChange',
    },
    materialDFPEutrophication: {
        label: 'materialDFPEutrophication',
        color: '#887D37',
        metricName: 'MaterialDFP_Eutrophication',
    },
    materialDFPWater: {
        label: 'materialDFPWater',
        color: '#015594',
        metricName: 'MaterialDFP_Water',
    },
    materialDFPFossilFuels: {
        label: 'materialDFPFossilFuels',
        color: '#1F4A53',
        metricName: 'MaterialDFP_FossilFuels',
    },

    materialProcClimateChange: {
        label: 'materialProcClimateChange',
        color: '#424242',
        metricName: 'MaterialProc_ClimateChange',
    },
    materialProcEutrophication: {
        label: 'materialProcEutrophication',
        color: '#887D37',
        metricName: 'MaterialProc_Eutrophication',
    },
    materialProcWater: {
        label: 'materialProcWater',
        color: '#015594',
        metricName: 'MaterialProc_Water',
    },
    materialProcFossilFuels: {
        label: 'materialProcFossilFuels',
        color: '#1F4A53',
        metricName: 'MaterialProc_FossilFuels',
    },

    rawMaterialClimateChange: {
        label: 'rawMaterialClimateChange',
        color: '#424242',
        metricName: 'RawMaterial_ClimateChange',
    },
    rawMaterialEutrophication: {
        label: 'rawMaterialEutrophication',
        color: '#887D37',
        metricName: 'RawMaterial_Eutrophication',
    },
    rawMaterialWater: {
        label: 'rawMaterialWater',
        color: '#015594',
        metricName: 'RawMaterial_Water',
    },
    rawMaterialFossilFuels: {
        label: 'rawMaterialFossilFuels',
        color: '#1F4A53',
        metricName: 'RawMaterial_FossilFuels',
    },

    transportClimateChange: {
        label: 'transportClimateChange',
        color: '#424242',
        metricName: 'Transport_ClimateChange',
    },
    transportEutrophication: {
        label: 'transportEutrophication',
        color: '#887D37',
        metricName: 'Transport_Eutrophication',
    },
    transportWater: {
        label: 'transportWater',
        color: '#015594',
        metricName: 'Transport_Water',
    },
    transportFossilFuels: {
        label: 'transportFossilFuels',
        color: '#1F4A53',
        metricName: 'Transport_FossilFuels',
    },
    totalProcessArticle: {
        label: 'totalProcessArticle',
        color: '#937533',
    },
    rawMaterial: {
        label: 'rawMaterial',
        color: '#937533',
    },
    materialProc: {
        label: 'materialProc',
        color: '#4E3814',
    },
    materialDFP: {
        label: 'materialDFP',
        color: '#9F7070',
    },
    articleDFP: {
        label: 'articleDFP',
        color: '#9F7070',
    },
    manufacture: {
        label: 'manufacture',
        color: '#9A4735',
    },
    transport: {
        label: 'transport',
        color: '#C4BBA9',
    },
    laDP: {
        label: 'laDP',
        color: '#B7B7B7',
    },
}

export enum EProjectResult {
    planet = "planet",
    people = "people",
    transparency = "transparency",
    circularity = "circularity",
    waterUse = "waterUse",
    climateChange = "climateChange",
    eutrophication = "eutrophication",
    fossilFuels = "fossilFuels",
    totalWaterConsumption = "totalWaterConsumption",
    totalClimateChange = "totalClimateChange",
    totalEutrophication = "totalEutrophication",
    totalFossilFuels = "totalFossilFuels",
    bottles = "bottles",
    waterGlasses = "waterGlasses",
    lightBulbs = "lightBulbs",
    washingMachine = "washingMachine",
    tomatoes = "tomatoes",
    carRefueling = "carRefueling",
    battery = "battery",
    hairdryer = "hairdryer",
    pool = "pool",
    detergent = "detergent",
    carTravelling = "carTravelling",
    waterIntake = "waterIntake",
    tree = "tree",
    fish = "fish",
    carNotTravelled = "carNotTravelled",
    tracCertificates = 'tracCertificates',
    tracKm = 'tracKm',
    tracPeople = 'tracPeople',
    tracProcess = 'tracProcess',
    tracSuppliers = 'tracSuppliers',
    tracCertificatesOnePage = 'tracCertificatesOnePage',
    tracKmOnePage = 'tracKmOnePage',
    tracPeopleOnePage = 'tracPeopleOnePage',
    tracProcessOnePage = 'tracProcessOnePage',
    tracSuppliersOnePage = 'tracSuppliersOnePage',
    traceability = 'traceability',
	equivalences = 'equivalences',
	lifeCycleAssessment = 'lifeCycleAssessment',
	sustainabilityIndex = 'sustainabilityIndex'
}

export enum EProjectResultMetrics {
    waterConsumptionUnit = "lH2O",
    climateChangeUnit = "kgCO2",
    eutrophicationUnit = "gPO4",
    wasteUnit = "mjFossilFuels",
    tracCertificatesUnit = "tracCertificates",
    tracKmUnits = 'tracKm',
    tracPeopleUnits = 'tracPeople',
    tracProcessUnits = 'tracProcess',
    tracSuppliersUnits = 'tracSuppliers',
    waterGlasses = "waterGlasses",
    lightBulbs = "lightBulbs",
    tomatoes = "tomatoes",
    carRefueling = "carRefueling",
    battery = "battery",
    hairdryer = "hairdryer",
}

export enum ELCAProcessTypes {
    climateChange = 'climateChange',
    eutrophication = 'eutrophication',
    water = 'water',
    fossilFuels = 'fossilFuels',
    articleDFPClimateChange = 'articleDFPClimateChange',
    articleDFPEutrophication = 'articleDFPEutrophication',
    articleDFPWater = 'articleDFPWater',
    articleDFPFossilFuels = 'articleDFPFossilFuels',
    laDPClimateChange = 'laDPClimateChange',
    laDPEutrophication = 'laDPEutrophication',
    laDPWater = 'laDPWater',
    laDPFossilFuels = 'laDPFossilFuels',
    manufactureClimateChange = 'manufactureClimateChange',
    manufactureEutrophication = 'manufactureEutrophication',
    manufactureWater = 'manufactureWater',
    manufactureFossilFuels = 'manufactureFossilFuels',
    materialDFPClimateChange = 'materialDFPClimateChange',
    materialDFPEutrophication = 'materialDFPEutrophication',
    materialDFPWater = 'materialDFPWater',
    materialDFPFossilFuels = 'materialDFPFossilFuels',
    materialProcClimateChange = 'materialProcClimateChange',
    materialProcEutrophication = 'materialProcEutrophication',
    materialProcWater = 'materialProcWater',
    materialProcFossilFuels = 'materialProcFossilFuels',
    rawMaterialClimateChange = 'rawMaterialClimateChange',
    rawMaterialEutrophication = 'rawMaterialEutrophication',
    rawMaterialWater = 'rawMaterialWater',
    rawMaterialFossilFuels = 'rawMaterialFossilFuels',
    transportClimateChange = 'transportClimateChange',
    transportEutrophication = 'transportEutrophication',
    transportWater = 'transportWater',
    transportFossilFuels = 'transportFossilFuels',
}
