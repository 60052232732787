import Crud, { ICrudListResponse } from "./Crud";


export default class Library extends Crud {
    constructor() {
        super("library");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/?sort=createdAt,DESC');
    }
}
