import { EIndicators, ESchemasIds, ESustainibilityIndex } from "UI/Template/Table/types/TableEnums";
import { ISelectorByProduct } from "UI/molecules/SelectorColumns/SelectorColumnsTypes";
import { EMixedIndicator, ITableSchemaColumn } from "UI/Template/Table/types/TableTypes";

export const SelectorColumnsSchema: ISelectorByProduct = {
	lca: {
		indicators: {
			id: ESchemasIds.indicators,
			columnsGroups: ['indicator', 'stage', 'dataType'],
			columnFilter: (column: ITableSchemaColumn) => {
				const indicators: EMixedIndicator[] = [EIndicators.checklist, EIndicators.waterUse, EIndicators.climateChange, EIndicators.eutrophication, EIndicators.fossilFuels];
				return indicators.includes(column.indicator) && !column.isSaving;
			}
		},
		stages: {
			id: ESchemasIds.stages,
			columnsGroups: ['stage', 'indicator', 'dataType'],
			columnFilter: (column: ITableSchemaColumn) => {
				const indicators: EMixedIndicator[] = [EIndicators.checklist, EIndicators.waterUse, EIndicators.climateChange, EIndicators.eutrophication, EIndicators.fossilFuels];
				return indicators.includes(column.indicator) && !column.isSaving;
			}
		},
	},
	sustainabilityindex: {
		// sustainabilityindex: {
		// 	id: ESchemasIds.indicators,
		// 	columnsGroups: ['indicator', 'stage', 'dataType'],
		// 	columnFilter: (column: ITableSchemaColumn) => {
		// 		const indicators: EMixedIndicator[] = [ESustainibilityIndex.planet, ESustainibilityIndex.people, ESustainibilityIndex.transparency, ESustainibilityIndex.circularity];
		// 		return indicators.includes(column.indicator);
		// 	}
		// },
	},
	benchmark: {
		indicators: {
			id: ESchemasIds.benchmark,
			columnsGroups: ['indicator', 'stage', 'dataType'],
			columnFilter: (column: ITableSchemaColumn) => {
				const indicators: EMixedIndicator[] = [EIndicators.waterUse, EIndicators.climateChange, EIndicators.eutrophication, EIndicators.fossilFuels];
				return indicators.includes(column.indicator) && column.isSaving;
			}
		},
		// indicators: {
		// 	id: ESchemasIds.indicators,
		// 	columnsGroups: ['indicator', 'stage', 'dataType'],
		// 	columnFilter: (column: ITableSchemaColumn) => {
		// 		const indicators: EMixedIndicator[] = [EIndicators.checklist, EIndicators.waterUse, EIndicators.climateChange, EIndicators.eutrophication, EIndicators.fossilFuels];
		// 		return indicators.includes(column.indicator) && !column.isSaving;
		// 	}
		// },
		// stages: {
		// 	id: ESchemasIds.stages,
		// 	columnsGroups: ['stage', 'indicator', 'dataType'],
		// 	columnFilter: (column: ITableSchemaColumn) => {
		// 		const indicators: EMixedIndicator[] = [EIndicators.checklist, EIndicators.waterUse, EIndicators.climateChange, EIndicators.eutrophication, EIndicators.fossilFuels];
		// 		return indicators.includes(column.indicator) && !column.isSaving;
		// 	}
		// },
	},
	smartlabels: {
	},
	ecommerce: {
	},
};