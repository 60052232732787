import React from "react";

interface FormatSubSupMetricsProps {
	indicator: string;
	text: string;
}

const formatSubSupMetrics = (props: FormatSubSupMetricsProps) => {
	const { indicator, text } = props;

	const formatUnits: any = {
		waterUse: {
			unit: "m3",
			formatValue: {
				sup: "3",
			},
			preValue: "m",
		},
		water: {
			unit: "m3",
			formatValue: {
				sup: "3",
			},
			preValue: "m",
		},
		climateChange: {
			unit: "CO2",
			formatValue: {
				sub: "2",
			},
			preValue: "CO",
		},
		eutrophication: {
			unit: "PO43-",
			formatValue: {
				sub: "4",
				sup: "3-",
			},
			preValue: "PO",
		},
	};

	const unitsWithFormat = [
		"water",
		"waterUse",
		"globalWarming",
		"climateChange",
		"eutrophication",
	];

	if (unitsWithFormat.includes(indicator)) {
		const superScript = (value: string) => <sup>{value}</sup>;
		const subScript = (value: string) => <sub>{value}</sub>;

		const unit: any = formatUnits[indicator]["unit"];
		const textTokens: any = text.split(unit);

		const isLastElement = (i: number, arr: string[]) => i < arr.length - 1;
		const formattedUnit = (
			<>
				{formatUnits[indicator]["preValue"]}
				{formatUnits[indicator]["formatValue"]["sub"] &&
					subScript(formatUnits[indicator]["formatValue"]["sub"])}
				{formatUnits[indicator]["formatValue"]["sup"] &&
					superScript(formatUnits[indicator]["formatValue"]["sup"])}
			</>
		);

		return textTokens.map((segment: string, i: number) => (
			<>
				{segment}
				{isLastElement(i, textTokens) && formattedUnit}
			</>
		));
	} else {
		return text;
	}
};

export default formatSubSupMetrics;
