import React from "react";
import { validateReferenceType } from "utils/utils";

const VALUES_OUT_MATERIAL_TYPE = ["articleWetProcess", "manufacture"]

export const useReferenceType = (projectSelected: string) => {

    const toggleSecondGroupValues = (values: string[]): string[] => {
		if (validateReferenceType(projectSelected)) {
			return values.filter((value) => !VALUES_OUT_MATERIAL_TYPE.includes(value));
		}

		return values;
	}

    const checkStages = (key: string) => validateReferenceType(projectSelected) && VALUES_OUT_MATERIAL_TYPE.includes(key)

    return {
        validateReferenceType: validateReferenceType(projectSelected),
        toggleSecondGroupValues,
        checkStages
    }
}
