import React from 'react';
import './FloatingLabelInput.scss';
import { VisibilityPassword } from 'UI/atoms/PasswordVisibility';

interface FloatingLabelInputProps {
  label: string;
  type: string;
  value: string;
  setValue: (value: string) => void;
  required: boolean;
  invalid?: boolean;
  setInputTypePassword?: (value: VisibilityPassword) => void;
  feedbackMessage?: string;
  validateValue: () => void;
}

export default function FloatingLabelInput({ label, type, value, setValue, required, invalid, setInputTypePassword, feedbackMessage, validateValue }: FloatingLabelInputProps) {

  return (
    <>
      <div className={`floating-label-container ${invalid ? 'invalid' : ''}`}>
        <input
          type={type}
          className={`floating-input ${value ? 'has-value' : ''}`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => validateValue()}
        />
        <label className="floating-label">{ label }</label>
        {
          label.toLowerCase().includes('password') && (
            <span className='text-dark-100' onClick={() => setInputTypePassword && setInputTypePassword(type === 'password' ? 'text' : 'password')}>{ type === 'password' ? 'Show' : 'Hide'}</span>
          )
        }
      </div>
      {
        invalid && (
          <span className='error-message'>{ feedbackMessage }</span>
        )
      }
    </>
  );
}
