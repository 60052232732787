import {
    EDataTypes,
    EEquivalencesKeyNames,
    EIndicators,
    EMetricNames,
    EMetricUnitTypes,
    ESmartlabels,
    EStages,
    ESustainibilityIndex,
    ETraceabilityScopes,
    EDataCollection
} from "../types/TableEnums";
import { ITableSchemaColumn } from "../types/TableTypes";

const columnsSchema: ITableSchemaColumn[] = [
    // Checklist - article information
    { id: EMetricNames.name, label: 'articleInfo', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.name, visualType: 'articleDetail' },
    { id: EMetricNames.Amount, label: 'amount', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.Amount, visualType: 'text' },
    { id: EMetricNames.Collection, label: 'collection', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.Collection, visualType: 'text' },
    { id: EMetricNames.Weight, label: 'weight', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.Weight, visualType: 'text' },
    { id: EMetricNames.Category, label: 'category', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.Category, visualType: 'text' },
    { id: EMetricNames.Color, label: 'color', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.checklist, stage: EStages.checklist, isSaving: false, metricName: EMetricNames.Color, visualType: 'text' },

    { id: EMetricNames.scans, label: 'scans', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: ESmartlabels.views, stage: ESmartlabels.views, isSaving: false, metricName: EMetricNames.scans, visualType: 'soon' },
    { id: EMetricNames.smartlabelUrl, label: 'smartlabelUrl', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: ESmartlabels.smartlabels, stage: ESmartlabels.smartlabels, isSaving: false, metricName: EMetricNames.smartlabelUrl, visualType: 'smartlabelUrl' },
    { id: EMetricNames.qrCodeUrl, label: 'qrCodeUrl', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: ESmartlabels.qrcode, stage: ESmartlabels.qrcode, isSaving: false, metricName: EMetricNames.qrCodeUrl, visualType: 'qrCodeUrl' },

    // Unitary impact
    { id: EMetricNames.Water, label: 'articleWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: false, metricName: EMetricNames.Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_Water, label: 'rawMaterialWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_Water, label: 'materialProdWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_Water, label: 'materialDFPWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_Water, label: 'articleDFPWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_Water, label: 'manufactureWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.Transport_Water, label: 'transportWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.LaDP_Water, label: 'packagingWater', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.ClimateChange, label: 'articleClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: false, metricName: EMetricNames.ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_ClimateChange, label: 'rawMaterialClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_ClimateChange, label: 'materialProdClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_ClimateChange, label: 'materialDFPClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_ClimateChange, label: 'articleDFPClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_ClimateChange, label: 'manufactureClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.Transport_ClimateChange, label: 'transportClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.LaDP_ClimateChange, label: 'packagingClimateChange', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.Eutrophication, label: 'articleEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: false, metricName: EMetricNames.Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_Eutrophication, label: 'rawMaterialEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_Eutrophication, label: 'materialProdEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_Eutrophication, label: 'materialDFPEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_Eutrophication, label: 'articleDFPEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_Eutrophication, label: 'manufactureEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.Transport_Eutrophication, label: 'transportEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.LaDP_Eutrophication, label: 'packagingEutrophication', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.FossilFuels, label: 'articleFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: false, metricName: EMetricNames.FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_FossilFuels, label: 'rawMaterialFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_FossilFuels, label: 'materialProdFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_FossilFuels, label: 'materialDFPFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_FossilFuels, label: 'articleDFPFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_FossilFuels, label: 'manufactureFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.Transport_FossilFuels, label: 'transportFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.LaDP_FossilFuels, label: 'packagingFossilFuels', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },

    // Production impact
    { id: EMetricNames.WaterTotal, label: 'articleWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: false, metricName: EMetricNames.Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_Water_Total, label: 'rawMaterialWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_Water_Total, label: 'materialProdWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_Water_Total, label: 'materialDFPWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_Water_Total, label: 'articleDFPWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_Water_Total, label: 'manufactureWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.Transport_Water_Total, label: 'transportWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.LaDP_Water_Total, label: 'packagingWaterTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Water, icon: 'icon-icon-water', visualType: 'numeric' },
    { id: EMetricNames.ClimateChangeTotal, label: 'articleClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: false, metricName: EMetricNames.ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_ClimateChange_Total, label: 'rawMaterialClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_ClimateChange_Total, label: 'materialProdClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_ClimateChange_Total, label: 'materialDFPClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_ClimateChange_Total, label: 'articleDFPClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_ClimateChange_Total, label: 'manufactureClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.Transport_ClimateChange_Total, label: 'transportClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.LaDP_ClimateChange_Total, label: 'packagingClimateChangeTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_ClimateChange, icon: 'icon-icon-co2', visualType: 'numeric' },
    { id: EMetricNames.EutrophicationTotal, label: 'articleEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: false, metricName: EMetricNames.Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_Eutrophication_Total, label: 'rawMaterialEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_Eutrophication_Total, label: 'materialProdEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_Eutrophication_Total, label: 'materialDFPEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_Eutrophication_Total, label: 'articleDFPEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_Eutrophication_Total, label: 'manufactureEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.Transport_Eutrophication_Total, label: 'transportEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.LaDP_Eutrophication_Total, label: 'packagingEutrophicationTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Eutrophication, icon: 'icon-icon-po4', visualType: 'numeric' },
    { id: EMetricNames.FossilFuelsTotal, label: 'articleFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: false, metricName: EMetricNames.FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.RawMaterial_FossilFuels_Total, label: 'rawMaterialFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.MaterialProc_FossilFuels_Total, label: 'materialProdFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.MaterialDFP_FossilFuels_Total, label: 'materialDFPFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.ArticleDFP_FossilFuels_Total, label: 'articleDFPFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.Manufacture_FossilFuels_Total, label: 'manufactureFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.Transport_FossilFuels_Total, label: 'transportFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },
    { id: EMetricNames.LaDP_FossilFuels_Total, label: 'packagingFossilFuelsTotal', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_FossilFuels, icon: 'icon-icon-abiotic', visualType: 'numeric' },

    // Unitary equivalence
    { id: EMetricNames.Equivalences_Water_Relative, label: 'articleWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: false, metricName: EMetricNames.Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_Water_Relative, label: 'rawMaterialWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_Water_Relative, label: 'materialProdWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_Water_Relative, label: 'materialDFPWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_Water_Relative, label: 'articleDFPWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_Water_Relative, label: 'manufactureWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_Water_Relative, label: 'transportWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_Water_Relative, label: 'packagingWaterEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Water, icon: 'icon-icon-bottle', defaultEq: EEquivalencesKeyNames.bottles, visualType: 'numeric' },


    // NEW equivalences
    { id: EMetricNames.Equivalences_ClimateChange_Relative, label: 'articleClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: false, metricName: EMetricNames.ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_ClimateChange_Relative, label: 'rawMaterialClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_ClimateChange_Relative, label: 'materialProdClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_ClimateChange_Relative, label: 'materialDFPClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_ClimateChange_Relative, label: 'articleDFPClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_ClimateChange_Relative, label: 'manufactureClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_ClimateChange_Relative, label: 'transportClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_ClimateChange_Relative, label: 'packagingClimateChangeEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_ClimateChange, icon: 'icon-icon-bulbs', defaultEq: EEquivalencesKeyNames.lightBulbs, visualType: 'numeric' },

    { id: EMetricNames.Equivalences_Eutrophication_Relative, label: 'articleEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: false, metricName: EMetricNames.Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_Eutrophication_Relative, label: 'rawMaterialEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_Eutrophication_Relative, label: 'materialProdEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_Eutrophication_Relative, label: 'materialDFPEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_Eutrophication_Relative, label: 'articleDFPEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_Eutrophication_Relative, label: 'manufactureEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_Eutrophication_Relative, label: 'transportEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_Eutrophication_Relative, label: 'packagingEutrophicationEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Eutrophication, icon: 'icon-icon-washer', defaultEq: EEquivalencesKeyNames.washingMachine, visualType: 'numeric' },

    { id: EMetricNames.Equivalences_FossilFuels_Relative, label: 'articleFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: false, metricName: EMetricNames.FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_FossilFuels_Relative, label: 'rawMaterialFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_FossilFuels_Relative, label: 'materialProdFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_FossilFuels_Relative, label: 'materialDFPFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_FossilFuels_Relative, label: 'articleDFPFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_FossilFuels_Relative, label: 'manufactureFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_FossilFuels_Relative, label: 'transportFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_FossilFuels_Relative, label: 'packagingFossilFuelsEqRel', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_FossilFuels, icon: 'icon-icon-car', defaultEq: EEquivalencesKeyNames.carTravelling, visualType: 'numeric' },

    // Production equivalence
    { id: EMetricNames.Equivalences_Water_Total, label: 'articleWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: false, metricName: EMetricNames.Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_Water_Total, label: 'rawMaterialWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_Water_Total, label: 'materialProdWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_Water_Total, label: 'materialDFPWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_Water_Total, label: 'articleDFPWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_Water_Total, label: 'manufactureWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_Water_Total, label: 'transportWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_Water_Total, label: 'packagingWaterEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Water, icon: 'icon-icon-pool', defaultEq: EEquivalencesKeyNames.olympicPools, visualType: 'numeric' },


    // New equivalences
    { id: EMetricNames.Equivalences_ClimateChange_Total, label: 'articleClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: false, metricName: EMetricNames.ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_ClimateChange_Total, label: 'rawMaterialClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_ClimateChange_Total, label: 'materialProdClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_ClimateChange_Total, label: 'materialDFPClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_ClimateChange_Total, label: 'articleDFPClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_ClimateChange_Total, label: 'manufactureClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_ClimateChange_Total, label: 'transportClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_ClimateChange_Total, label: 'packagingClimateChangeEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_ClimateChange, icon: 'icon-icon-electricity', defaultEq: EEquivalencesKeyNames.electricity, visualType: 'numeric' },

    { id: EMetricNames.Equivalences_Eutrophication_Total, label: 'articleEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: false, metricName: EMetricNames.Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_Eutrophication_Total, label: 'rawMaterialEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_Eutrophication_Total, label: 'materialProdEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_Eutrophication_Total, label: 'materialDFPEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_Eutrophication_Total, label: 'articleDFPEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_Eutrophication_Total, label: 'manufactureEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_Eutrophication_Total, label: 'transportEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_Eutrophication_Total, label: 'packagingEutrophicationEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_Eutrophication, icon: 'icon-icon-detergent', defaultEq: EEquivalencesKeyNames.detergent, visualType: 'numeric' },


    // NEW EQUIVALENCES
    { id: EMetricNames.Equivalences_FossilFuels_Total, label: 'articleFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: false, metricName: EMetricNames.FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_RawMaterial_FossilFuels_Total, label: 'rawMaterialFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.rawMaterials, isSaving: false, metricName: EMetricNames.RawMaterial_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialProc_FossilFuels_Total, label: 'materialProdFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.materialProcess, isSaving: false, metricName: EMetricNames.MaterialProc_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_MaterialDFP_FossilFuels_Total, label: 'materialDFPFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.materialWetProcess, isSaving: false, metricName: EMetricNames.MaterialDFP_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_ArticleDFP_FossilFuels_Total, label: 'articleDFPFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.articleWetProcess, isSaving: false, metricName: EMetricNames.ArticleDFP_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Manufacture_FossilFuels_Total, label: 'manufactureFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.manufacture, isSaving: false, metricName: EMetricNames.Manufacture_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_Transport_FossilFuels_Total, label: 'transportFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.transport, isSaving: false, metricName: EMetricNames.Transport_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },
    { id: EMetricNames.Equivalences_LaDP_FossilFuels_Total, label: 'packagingFossilFuelsEqTotal', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.packaging, isSaving: false, metricName: EMetricNames.LaDP_FossilFuels, icon: 'icon-icon-plane', defaultEq: EEquivalencesKeyNames.planeTravelling, visualType: 'numeric' },

    // Unitary saving relative (%)
    { id: EMetricNames.Savings_Water_Relative, label: '', dataType: EDataTypes.bar, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water_Relative, icon: 'icon-icon-water', color: '#015594', visualType: 'progressDouble' },
    { id: EMetricNames.Savings_ClimateChange_Relative, label: '', dataType: EDataTypes.bar, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange_Relative, icon: 'icon-icon-co2', color: '#424242', visualType: 'progressDouble' },
    { id: EMetricNames.Savings_Eutrophication_Relative, label: '', dataType: EDataTypes.bar, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication_Relative, icon: 'icon-icon-po4', color: '#887D37', visualType: 'progressDouble' },
    { id: EMetricNames.Savings_FossilFuels_Relative, label: '', dataType: EDataTypes.bar, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels_Relative, icon: 'icon-icon-abiotic', color: '#1F4A53', visualType: 'progressDouble' },

    // // Unitary saving relative (units)
    { id: EMetricNames.Savings_Water_Relative_Numeric, label: '', dataType: EDataTypes.r, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water_Relative, icon: 'icon-icon-water', color: '#015594', visualType: 'numeric' },
    { id: EMetricNames.Savings_ClimateChange_Relative_Numeric, label: '', dataType: EDataTypes.r, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange_Relative, icon: 'icon-icon-co2', color: '#424242', visualType: 'numeric' },
    { id: EMetricNames.Savings_Eutrophication_Relative_Numeric, label: '', dataType: EDataTypes.r, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication_Relative, icon: 'icon-icon-po4', color: '#887D37', visualType: 'numeric' },
    { id: EMetricNames.Savings_FossilFuels_Relative_Numeric, label: '', dataType: EDataTypes.r, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels_Relative, icon: 'icon-icon-abiotic', color: '#1F4A53', visualType: 'numeric' },

    // Unitary saving
    { id: EMetricNames.Savings_Water, label: '', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water, icon: 'icon-icon-water', color: '#015594', visualType: 'numeric' },
    { id: EMetricNames.Savings_ClimateChange, label: '', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange, icon: 'icon-icon-co2', color: '#424242', visualType: 'numeric' },
    { id: EMetricNames.Savings_Eutrophication, label: '', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication, icon: 'icon-icon-po4', color: '#887D37', visualType: 'numeric' },
    { id: EMetricNames.Savings_FossilFuels, label: '', dataType: EDataTypes.u, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels, icon: 'icon-icon-abiotic', color: '#1F4A53', visualType: 'numeric' },

    // Production saving
    { id: EMetricNames.Savings_Water_Total, label: '', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.waterUse, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water, icon: 'icon-icon-water', color: '#015594', visualType: 'numeric' },
    { id: EMetricNames.Savings_ClimateChange_Total, label: '', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.climateChange, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange, icon: 'icon-icon-co2', color: '#424242', visualType: 'numeric' },
    { id: EMetricNames.Savings_Eutrophication_Total, label: '', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.eutrophication, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication, icon: 'icon-icon-po4', color: '#887D37', visualType: 'numeric' },
    { id: EMetricNames.Savings_FossilFuels_Total, label: '', dataType: EDataTypes.p, metricUnit: EMetricUnitTypes.fossilFuels, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels, icon: 'icon-icon-abiotic', color: '#1F4A53', visualType: 'numeric' },

    // NEW EQUIVALENCES. Savings-Unitary
    { id: EMetricNames.Equivalence_Savings_Water, label: '', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.bottles, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water, defaultEq: EEquivalencesKeyNames.bottles, icon: 'icon-icon-glass', color: '#015594', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_ClimateChange, label: '', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.lightBulbs, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange, defaultEq: EEquivalencesKeyNames.ligthBulbs, icon: 'icon-icon-trees', color: '#424242', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_Eutrophication, label: '', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.washingMachine, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication, defaultEq: EEquivalencesKeyNames.washingMachine, icon: 'icon-icon-fish-saved', color: '#887D37', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_FossilFuels, label: '', dataType: EDataTypes.ue, metricUnit: EMetricUnitTypes.carTravelling, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels, defaultEq: EEquivalencesKeyNames.carTravelling, icon: 'icon-icon-car-not-travelled', color: '#1F4A53', visualType: 'numeric' },

   // NEW EQUIVALENCES. Savings-Production
    { id: EMetricNames.Equivalence_Savings_Water_Total, label: '', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.pool, indicator: EIndicators.waterUse, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Water, defaultEq: EEquivalencesKeyNames.olympicPools, icon: 'icon-icon-glasses', color: '#015594', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_ClimateChange_Total, label: '', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.electricity, indicator: EIndicators.climateChange, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_ClimateChange, defaultEq: EEquivalencesKeyNames.electricity, icon: 'icon-icon-planted-trees', color: '#424242', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_Eutrophication_Total, label: '', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.detergent, indicator: EIndicators.eutrophication, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_Eutrophication, defaultEq: EEquivalencesKeyNames.detergent, icon: 'icon-icon-oxigen', color: '#887D37', visualType: 'numeric' },
    { id: EMetricNames.Equivalence_Savings_FossilFuels_Total, label: '', dataType: EDataTypes.pe, metricUnit: EMetricUnitTypes.planeTravelling, indicator: EIndicators.fossilFuels, stage: EStages.article, isSaving: true, metricName: EMetricNames.Savings_FossilFuels, defaultEq: EEquivalencesKeyNames.planeTravelling, icon: 'icon-icon-plane-not-travelled', color: '#1F4A53', visualType: 'numeric' },

    // Sustainibility Index - Evaluations
    { id: EMetricNames.Planet, label: 'index', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.planet, stage: EStages.article, isSaving: false, metricName: EMetricNames.Planet, icon: 'icon-icon-planet', visualType: 'index' },
    { id: EMetricNames.People, label: 'index', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.people, stage: EStages.article, isSaving: false, metricName: EMetricNames.People, icon: 'icon-icon-people', visualType: 'index' },
    { id: EMetricNames.Transparency, label: 'index', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.transparency, stage: EStages.article, isSaving: false, metricName: EMetricNames.Transparency, icon: 'icon-icon-transparency', visualType: 'index' },
    { id: EMetricNames.Circularity, label: 'index', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.circularity, stage: EStages.article, isSaving: false, metricName: EMetricNames.Circularity, icon: 'icon-icon-circularity', visualType: 'index' },
    { id: EMetricNames.Planet_Score, label: 'qualification', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.planet, stage: EStages.article, isSaving: false, metricName: EMetricNames.Planet, icon: 'icon-icon-planet', visualType: 'progressSimple', color: "#773F0F" },
    { id: EMetricNames.People_Score, label: 'qualification', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.people, stage: EStages.article, isSaving: false, metricName: EMetricNames.People, icon: 'icon-icon-people', visualType: 'progressSimple', color: "#0E343C" },
    { id: EMetricNames.Transparency_Score, label: 'qualification', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.transparency, stage: EStages.article, isSaving: false, metricName: EMetricNames.Transparency, icon: 'icon-icon-transparency', visualType: 'progressSimple', color: "#BE8F1F" },
    { id: EMetricNames.Circularity_Score, label: 'qualification', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.circularity, stage: EStages.article, isSaving: false, metricName: EMetricNames.Circularity, icon: 'icon-icon-circularity', visualType: 'progressSimple', color: "#C8B7AF" },
    { id: EMetricNames.Planet_Qualification, label: 'ecoScore', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.planet, stage: EStages.article, isSaving: false, metricName: EMetricNames.Planet, icon: 'icon-icon-planet', visualType: 'qualification' },
    { id: EMetricNames.People_Qualification, label: 'ecoScore', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.people, stage: EStages.article, isSaving: false, metricName: EMetricNames.People, icon: 'icon-icon-people', visualType: 'qualification' },
    { id: EMetricNames.Transparency_Qualification, label: 'ecoScore', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.transparency, stage: EStages.article, isSaving: false, metricName: EMetricNames.Transparency, icon: 'icon-icon-transparency', visualType: 'qualification' },
    { id: EMetricNames.Circularity_Qualification, label: 'ecoScore', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.circularity, stage: EStages.article, isSaving: false, metricName: EMetricNames.Circularity, icon: 'icon-icon-circularity', visualType: 'qualification' },
    { id: EMetricNames.Planet_Points, label: 'points', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.planet, stage: EStages.article, isSaving: false, metricName: EMetricNames.Planet, icon: 'icon-icon-planet', visualType: 'numeric' },
    { id: EMetricNames.People_Points, label: 'points', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.people, stage: EStages.article, isSaving: false, metricName: EMetricNames.People, icon: 'icon-icon-people', visualType: 'numeric' },
    { id: EMetricNames.Transparency_Points, label: 'points', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.transparency, stage: EStages.article, isSaving: false, metricName: EMetricNames.Transparency, icon: 'icon-icon-transparency', visualType: 'numeric' },
    { id: EMetricNames.Circularity_Points, label: 'points', dataType: EDataTypes.si, metricUnit: EMetricUnitTypes.sustainabilityindex, indicator: ESustainibilityIndex.circularity, stage: EStages.article, isSaving: false, metricName: EMetricNames.Circularity, icon: 'icon-icon-circularity', visualType: 'numeric' },

    // Traceability Article Scope
    { id: EMetricNames.articleName, label: 'articleInfo', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.name, visualType: 'articleDetail' },
    { id: EMetricNames.category, label: 'productCategory', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracCategory, indicator: ETraceabilityScopes.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.category, icon: '', visualType: 'text' },
    { id: EMetricNames.amountArticle, label: 'unitsProduced', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracUnits, indicator: ETraceabilityScopes.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.amountArticle, icon: '', visualType: 'amountArticle' },
    { id: EMetricNames.totalArticleMaterial, label: 'materialsTraceability', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMaterials, indicator: ETraceabilityScopes.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.totalArticleMaterial, icon: '', visualType: 'text' },
    { id: EMetricNames.Traceability_Suppliers, label: 'suppliersTraceability', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracSuppliers, indicator: ETraceabilityScopes.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.Traceability_Suppliers, icon: '', visualType: 'text' },
    { id: EMetricNames.Traceability_Certificates, label: 'certificatesTraceability', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracCertificates, indicator: ETraceabilityScopes.articleSummary, stage: EStages.article, isSaving: false, metricName: EMetricNames.Traceability_Certificates, icon: '', visualType: 'text' },
    { id: EMetricNames.Traceability_Completion, label: 'completionTraceability', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracCompetion, indicator: ETraceabilityScopes.completion, stage: EStages.article, isSaving: false, metricName: EMetricNames.Traceability_Completion, icon: '', visualType: 'simpleBarWithText' },

    // Traceability Suppliers Scope
    { id: EMetricNames.supplierName, label: 'supplierName', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.supplierDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.name, visualType: 'supplierName' },
    { id: EMetricNames.supplier_tiers, label: 'tierCategory', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracTierCategory, indicator: ETraceabilityScopes.supplierDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_tiers, icon: '', visualType: 'text' },
    { id: EMetricNames.supplier_origin_abbreviation, label: 'origin', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracOrigin, indicator: ETraceabilityScopes.supplierDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_origin_abbreviation, icon: '', visualType: 'text_tooltip' },

    { id: EMetricNames.categoryId, label: 'stage', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracStage, indicator: ETraceabilityScopes.logistics, stage: EStages.article, isSaving: false, metricName: EMetricNames.categoryId, icon: '', visualType: 'text' },
    { id: EMetricNames.supplier_transport_category, label: 'shippingTransport', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracShippingTransport, indicator: ETraceabilityScopes.logistics, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_transport_category, icon: '', visualType: 'text' },

    { id: EMetricNames.supplier_certificate_names, label: 'certificates-name', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracCertNames, indicator: ETraceabilityScopes.certificateLists, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_certificate_names, icon: '', visualType: 'text' },

    { id: EMetricNames.supplier_closedLoopWaterSystem_validation, label: 'supplier-best-practice-declared', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracBestPracticeDeclared, indicator: ETraceabilityScopes.bestPractices, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_closedLoopWaterSystem_validation, icon: '', visualType: 'bestPractices' },
    { id: EMetricNames.supplier_renewableEnergy_validation, label: 'supplier-best-practice-not-declared', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracBestPracticeNotDeclared, indicator: ETraceabilityScopes.bestPractices, stage: EStages.article, isSaving: false, metricName: EMetricNames.supplier_renewableEnergy_validation, icon: '', visualType: 'bestPractices' },

    // Traceability Materials Scope
    { id: EMetricNames.materialName, label: 'materialName', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.checklist, indicator: EIndicators.materialDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.name, visualType: 'materialName' },
    { id: EMetricNames.material_category_names, label: 'material_category_names', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMCategory, indicator: ETraceabilityScopes.materialDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_category_names, icon: '', visualType: 'text' },
    { id: EMetricNames.material_process_extraction_relativeAmounts, label: 'composition', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMComposition, indicator: ETraceabilityScopes.materialDescription, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_process_extraction_relativeAmounts, icon: '', visualType: 'text' },

    { id: EMetricNames.material_process_finishing_supplier_names, label: 'tMaterialWetProcess', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMWProcess, indicator: ETraceabilityScopes.materialSupply, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_process_finishing_supplier_names, icon: '', visualType: 'text' },
    { id: EMetricNames.material_process_matProcessing_supplier_name, label: 'materialProcess', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMProcess, indicator: ETraceabilityScopes.materialSupply, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_process_matProcessing_supplier_name, icon: '', visualType: 'text' },
    { id: EMetricNames.material_process_rmatProcessing_supplier_names, label: 'rawMaterialProcess', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMProcess, indicator: ETraceabilityScopes.materialSupply, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_process_rmatProcessing_supplier_names, icon: '', visualType: 'text' },
    { id: EMetricNames.material_process_extraction_supplier_names, label: 'rawMaterialExtraction', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracRMExtraction, indicator: ETraceabilityScopes.materialSupply, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_process_extraction_supplier_names, icon: '', visualType: 'text' },

    { id: EMetricNames.material_certificate_names, label: 'certificatesTraceabilityMaterials', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.tracMCertificate, indicator: ETraceabilityScopes.material_certificate_names, stage: EStages.article, isSaving: false, metricName: EMetricNames.material_certificate_names, icon: '', visualType: 'text' },

    // Data Collection
    { id: EMetricNames.projectName, label: 'project_name', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.projectName, indicator: EDataCollection.projectDescription, stage: EStages.projectDescription, isSaving: false, metricName: EMetricNames.name, icon: '', visualType: 'articleDetail' },
    { id: EMetricNames.contArticle, label: 'total_ref', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.totalRef, indicator: EDataCollection.projectDescription, stage: EStages.projectDescription, isSaving: false, metricName: EMetricNames.contArticle, icon: '', visualType: 'text' },
    { id: EMetricNames.referenceType, label: 'reference_type', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.refType, indicator: EDataCollection.projectDescription, stage: EStages.projectDescription, isSaving: false, metricName: EMetricNames.referenceType, icon: '', visualType: 'referenceType' },
    { id: EMetricNames.dataCollectionURL, label: 'form_url', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.formUrl, indicator: EDataCollection.dataRecords, stage: EStages.dataRecords, isSaving: false, metricName: EMetricNames.dataCollectionURL, icon: '', visualType: 'link' },
    { id: EMetricNames.status, label: 'status', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.status, indicator: EDataCollection.dataRecords, stage: EStages.dataRecords, isSaving: false, metricName: EMetricNames.status, icon: '', visualType: 'tag' },
    { id: EMetricNames.createdAt, label: 'creation_date', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.createDate, indicator: EDataCollection.dataRecords, stage: EStages.dataRecords, isSaving: false, metricName: EMetricNames.createdAt, icon: '', visualType: 'date' },
    { id: EMetricNames.closingDate, label: 'closing_date', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.closingDate, indicator: EDataCollection.dataRecords, stage: EStages.dataRecords, isSaving: false, metricName: EMetricNames.closingDate, icon: '', visualType: 'date' },
    { id: EMetricNames.folderURL, label: 'folder_url', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.link, indicator: EDataCollection.dataRecords, stage: EStages.dataRecords, isSaving: false, metricName: EMetricNames.folderURL, icon: '', visualType: 'folder' },
    // CEI
    { id: EMetricNames.MCI_A, label: 'mci_score', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.mci_score, indicator: EIndicators.overview, stage: EStages.article, isSaving: false, metricName: EMetricNames.MCI_A, icon: '', visualType: 'percent' },
    { id: EMetricNames.Recycled_A, label: 'recycled_content', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.recycled_content, indicator: EIndicators.feedstock, stage: EStages.article, isSaving: false, metricName: EMetricNames.Recycled_A, icon: '', visualType: 'percent' },
    { id: EMetricNames.TU_Waste_A, label: 'total_waste', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.total_waste, indicator: EIndicators.waste, stage: EStages.article, isSaving: false, metricName: EMetricNames.TU_Waste_A, icon: '', visualType: 'numeric' },
    { id: EMetricNames.Utility_A, label: 'utility', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.utility, indicator: EIndicators.usage_potential, stage: EStages.article, isSaving: false, metricName: EMetricNames.Utility_A, icon: '', visualType: 'utility' },


    { id: 'detail', label: 'article-journey', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.detail, indicator: ETraceabilityScopes.detail, stage: EStages.article, isSaving: false, metricName: EMetricNames.detail, icon: '', visualType: 'detail' },
    { id: 'saved', label: 'saved-list', dataType: EDataTypes.i, metricUnit: EMetricUnitTypes.saved, indicator: ETraceabilityScopes.saved, stage: EStages.article, isSaving: false, metricName: EMetricNames.saved, icon: '', visualType: 'saved' },
]

export default columnsSchema;
export type ColumnSchemaByIdType = {[key in EMetricNames]: ITableSchemaColumn};
export const columnsSchemaById: ColumnSchemaByIdType = Object.seal(columnsSchema.reduce((prev: ColumnSchemaByIdType, next:ITableSchemaColumn) =>({...prev, [next.id]: next}), {} as ColumnSchemaByIdType));