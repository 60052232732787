import React, { useEffect, useState } from "react";
import { CarouselItemProps } from "reactstrap";
import { isElementInViewport } from "services/observer/detect-element-in-screen";
import { formatDate, isChildOutsideParent } from "utils/utils";

const useCarousel = (data: CarouselItemProps[]) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showPrev, setShowPrev] = useState(true);
    const [showIndicators, setShowIndicators] = useState(false);
    const slideWidth = 341;

    const goToNextSlide = async() => {
        const lastElementInViewPort = await isElementInViewport(`#carousel-card-${data.length - 1}`);
        const newIndex = ((currentIndex === data.length - 1) || lastElementInViewPort) ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        handleVisibilityPrevClick(newIndex)
    };

    const goToPrevSlide = () => {
        const newIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        handleVisibilityPrevClick(newIndex)
    };

    const handleVisibilityPrevClick = (index: number) => {
        if (index === 0) {
            setShowPrev(true);
        } else {
            setShowPrev(false);
        }
    }

    const validateDate = (date: Date | string) => {
        if (typeof date === 'string') date = new Date(date);
        return formatDate(date);
    }

    const handleElementOutViewport = () => {
        const carousel = document.querySelector('.carousel') as Element;
        const lastElement = document.querySelector(`#carousel-card-${data.length - 1}`) as Element;
        const elementOutViewport = isChildOutsideParent(lastElement, carousel);

        if (elementOutViewport) {
            setShowIndicators(true);
        } else {
            setShowIndicators(false);
        }
    }

    useEffect(() => {
        handleElementOutViewport();
        window.addEventListener('resize', handleElementOutViewport);
        window.addEventListener('orientationchange', handleElementOutViewport);

        return () => {
            window.removeEventListener('resize', handleElementOutViewport);
            window.removeEventListener('orientationchange', handleElementOutViewport);
        }
    }, [])

    return {
        currentIndex,
        showPrev,
        showIndicators,
        slideWidth,
        goToNextSlide,
        goToPrevSlide,
        validateDate
    }
}

export default useCarousel;