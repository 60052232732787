

export class Favorites {
  public saveFavorites(favorite: string): void {
    return localStorage.setItem('favorites', favorite);
  }

  public removeFavorites() {
    return localStorage.removeItem('favorites');
  }

  public getCurrentfavorites(): string[] {
    const favorites = localStorage.getItem('favorites') || 'none';

    if (favorites === 'none') {
      return [];
    }

    return JSON.parse(favorites);
  }
}

export default new Favorites();
