import React from 'react';
import {
	EDataTypes,
	EMetricUnitTypes,
	EIndicators,
} from 'UI/Template/Table/types/TableEnums';
import { CustomTooltip } from '../Tooltips/Index';

interface Props {
	color: string;
	value: number; //relative value in %
	absolute?: number | undefined; //absolute in units
	indicator?: EIndicators | string | undefined;
	metricUnit?: EMetricUnitTypes | string | undefined;
	dataType?: EDataTypes | string | undefined;
	smartLabel?: boolean
	// label: string,
}

const ProgressBarDouble = (props: Props) => {
	const { value, color, absolute, indicator, metricUnit, dataType, smartLabel } = props;
	const percent = value * 100;
	let percentN = 0;
	let percentP = 0;

	if (percent < 0) percentN = Math.abs(percent);
	else percentP = percent;

	if (value === null || value === undefined) {
		const textValue = '--';
		return (
			<span className="font-sfpro-regular letter-spacing-normal font-14px text-dark-80 text-overflow-ellipsis overflow-hidden">
				{textValue}
			</span>
		);
	} else {
		return (
			<div className={`tooltipCustom ${smartLabel && "w-100"}`} style={{
				minWidth: smartLabel ? 126 : ''
			}}>
				<div className={`d-flex flex-row align-items-center ${smartLabel ? "w-100" : "w-75"}`}>
					{/* NEGATIVE PROGRESS BAR */}
					<div
						className={`progress rounded-left-10 flex-row-reverse w-100 ${smartLabel && "bg-white"}`}
						style={{ height: '10px' }}
					>
						<div
							className="progress-bar rounded-left-10 flex-row-reverse colors-ecoscore-E"
							role="progressbar"
							style={{
								width: `${percentN}%`,
							}}
						></div>
					</div>

					<span className="icon-icon-line-thermometer text-dark-100 font-20px m-0 p-0" />
					{/* POSITIVE PROGRESS BAR */}
					<div
						className={`progress rounded-right-10 w-100 ${smartLabel && "bg-white"}`}
						style={{ height: '10px' }}
					>
						<div
							className="progress-bar rounded-right-10 colors-ecoscore-A"
							role="progressbar"
							style={{
								width: `${percentP}%`,
							}}
						></div>
					</div>
				</div>
				{percent < 0 && !smartLabel && (
					<CustomTooltip
						metric={percent}
						absolute={absolute}
						isPercent
						indicator={indicator}
						metricUnit={metricUnit}
						dataType={dataType}
					/>
				)}
				{percent >= 0 && !smartLabel && (
					<CustomTooltip
						metric={percent}
						absolute={absolute}
						isPercent
						extraCss={'tooltiptext-left'}
						indicator={indicator}
						metricUnit={metricUnit}
						dataType={dataType}
					/>
				)}
			</div>
		);
	}
};

export default ProgressBarDouble;
