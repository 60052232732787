import React from 'react';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import SelectorArticle from '../SelectorArticle';
import { ArticleImgSmallMedium } from 'UI/atoms/ArticleImg';
import { useProjects } from 'contexts/projectsContext';
import { formatDate } from 'utils/utils';
import { HELP_LINKS, downloadFileName } from '../../../constants';
import { useTable } from 'contexts/tableContext';
import {
	useDownloadExcel,
	useDownloadSmartLabelsExcel,
} from 'hooks/useDownloadFile.hook';
import { useAccount } from 'contexts/accountContext';
import { Help } from '../Help/Index';

interface IProps {
	artName: string;
	artRef: string;
	imageUrl: string;
	setShowArticleInfo: any;
	scrollTop: boolean
}

function handleBorder (elements: HTMLCollectionOf<Element>, action: 'add' | 'remove') {
	Array.prototype.forEach.call(elements, (element: any) => {
		element.classList[action]('border');
	});
}

type GenericPdfDownloaderProps = {
	rootElementId: string;
	downloadFileName: string;
	tableDownload?: boolean;
	disabled?: boolean;
};

export const GenericPdfDownloader = ({ rootElementId, downloadFileName, tableDownload = false, disabled = false }: GenericPdfDownloaderProps) => {
	const h = useHistory();
	const {
		handleLoading,
	} = useTable();
	const { projectSelected } = useProjects();

	const path = h.location.pathname;

	const handleExportData = () => {
		if (path.includes('smartlabels')) {
			handleLoading(true);
			useDownloadSmartLabelsExcel(
				projectSelected.id,
				projectSelected.name,
				handleLoading
			);
		} else {
			handleLoading(true);
			useDownloadExcel(
				projectSelected.id,
				projectSelected.name,
				handleLoading
			);
		}
	};
	const downloadPdfDocument = () => {
		const input = document.getElementById(
			rootElementId
		) as HTMLCanvasElement;
		const cards = input.getElementsByClassName('d-flex flex-column overflow-hidden w-100 h-100 rounded-08 box-shadow')
		handleBorder(cards, 'add')

		if (rootElementId === 'OnePageArticle') {
			const footerCards = input.getElementsByClassName('d-flex flex-column flex-grow-1 box-shadow text-dark-100 bg-white-100 rounded-08 p-4 m-2')
			handleBorder(footerCards, 'add')
		}

		const pdfType = rootElementId.includes("traceability") || rootElementId === 'OnePageArticle' ? 'landscape' : 'portrait';
		const pdf = new jsPDF(pdfType);

		html2canvas(input).then((canvas: any) => {
			const widthInMm = canvas.width * 0.2645833333;
			const heightInMm = canvas.height * 0.2645833333;
			if (
				(widthInMm > heightInMm && heightInMm < 210) ||
				(widthInMm < heightInMm && heightInMm < 210)
			) {
				const canvasHeight = Number(
					((heightInMm * 297) / widthInMm).toFixed(0)
				);
				if (canvasHeight > 210) {
					const canvasWidth = Number(
						((widthInMm * 210) / heightInMm).toFixed(0)
					);
					const marginLeftRight = (297 - canvasWidth) / 2;

					pdf.addImage(
						canvas,
						'PNG',
						marginLeftRight,
						0,
						canvasWidth,
						210
					);
					pdf.save(`${downloadFileName}.pdf`);
				} else {
					const marginTopBottom = (210 - canvasHeight) / 2;
					pdf.addImage(
						canvas,
						'PNG',
						0,
						marginTopBottom,
						297,
						canvasHeight
					);
					pdf.save(`${downloadFileName}.pdf`);
				}
			} else {
				const canvasWidth = Number(((widthInMm * 210) / heightInMm).toFixed(0));

				if (canvasWidth > 297) {
					const canvasHeight = Number(
						((heightInMm * 297) / widthInMm).toFixed(0)
					);
					const marginTopBottom = (210 - canvasHeight) / 2;
					pdf.addImage(
						canvas,
						'PNG',
						0,
						marginTopBottom,
						297,
						canvasHeight
					);
					pdf.save(`${downloadFileName}.pdf`);
				} else {
					const marginLeftRight = (pdf.internal.pageSize.getWidth() - canvasWidth) / 2;
					pdf.addImage(
						canvas,
						'PNG',
						marginLeftRight,
						0,
						canvasWidth,
						210
					);
					pdf.save(`${downloadFileName}.pdf`);
				}
			}
		})
		.finally(() => {
			handleBorder(cards, 'remove');
			if (rootElementId === 'OnePageArticle') {
				const footerCards = input.getElementsByClassName('d-flex flex-column flex-grow-1 box-shadow text-dark-100 bg-white-100 rounded-08 p-4 m-2')
				handleBorder(footerCards, 'add')
			}
		})
	};

	return (
		<span
			className={`icon-icon-download font-20px mx-1 ${disabled ? 'non-click text-dark-40' : 'cursor-pointer text-dark-60 text-dark-100-hover'}`}
			onClick={tableDownload ? handleExportData : downloadPdfDocument}
		></span>
	);
};

const OnePageHeader = (props: IProps) => {
	const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const { projectSelected } = useProjects();
	const { artName, artRef, imageUrl, setShowArticleInfo, scrollTop } = props;
	const h = useHistory();

	const { route } = useProjects();

	const handleCloseOnePage = () => {
		h.push(route.from);
	};

	const formatedActualDate = formatDate();
	const projectSelectedName = projectSelected?.name;
	const textDownloadFile = downloadFileName[generalProduct];

	const buildedFileName = `BCome ${textDownloadFile} Dashboard - ${projectSelectedName} - ${formatedActualDate}`;

	const helpContent = HELP_LINKS["articles"];

	return (
		<div
			className={`d-flex flex-row align-items-center px-3 py-2 position-sticky ${scrollTop ? 'bg-transparent' : 'bg-white'}`}
			style={{ zIndex: 6, top: '0px' }}
		>
			<div className="w-100 d-flex flex-row justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<ArticleImgSmallMedium imgUrl={imageUrl} />
					<SelectorArticle articleName={artName} />
					<span
						style={{ marginTop: '23px' }}
						className="icon-icon-info font-18px text-dark-60 text-dark-100-hover cursor-pointer"
						onClick={() => setShowArticleInfo(true)}
					></span>
				</div>
				<div className="d-flex flex-row align-items-center">
					<Help question={helpContent.question} link={helpContent.link} />
					<GenericPdfDownloader
						rootElementId="OnePageArticle"
						downloadFileName={buildedFileName}
					/>
					<span
						className="mr-3"
						style={{
							borderLeft: '1px solid #C6C7C8',
							height: '40px',
						}}
					/>
					<span
						className="icon-icon-close font-12px text-dark-60 hover-dark-100 cursor-pointer"
						onClick={handleCloseOnePage}
					></span>
				</div>
			</div>
		</div>
	);
};

export default OnePageHeader;
