/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArticleHttp from 'services/http/Article';
import ChartContainer from 'UI/Template/ChartContainer';
import ArticleSummary from '../../molecules/ArticleSummary/Index';
import SIcard from 'UI/molecules/SIcard/Index';
import OnePageHeader from 'UI/molecules/OnePageHeader/Index';
import { useAccount } from 'contexts/accountContext';
import {
	getEquivalencesOnePage,
	getOverviewOnePageData,
	getStagesDataOnePage,
	getSummaryOnePage,
	getTraceabilityData,
} from './ArticleData';
import LCStagePerImpactIndicatorChart from 'UI/organism/Dashboards/Charts/LCStagePerImpact';
import LCATableOnePage from 'UI/organism/Dashboards/Charts/LCATableOnePage';
import { ModalBaseArticleInfo } from 'UI/organism/ModalArticleInfo';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';
import { rest } from 'lodash';

const Article = new ArticleHttp();

const OnePageArticle = (props: any) => {
	const { t } = useTranslation();
	const [articleData, setArticleData]: any = useState({});
	const [origins, setOrigins]: any = useState({});
	const [compo, setCompo]: any = useState({});
	const [artCertSupplier, setArtCertSupplier]: any[] = useState();
	const [artCertMaterial, setArtCertMaterial]: any[] = useState();
	const articleId = props.match.params.idArticle;
	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);

	const articleOrigins = () => {
		const request = Article.articleOrigins(articleId);
		request.then((req) => {
			const data = req.data;
			setOrigins(data);
		});
	};

	const articleComposition = () => {
		const request = Article.articleComposition(articleId);
		request.then((req) => {
			const data = req.data;
			setCompo(data);
		});
	};

	const articleMaterialCertificates = () => {
		const request = Article.articleMaterialCertificates(articleId);
		request.then((req) => {
			const data = req.data;
			setArtCertMaterial(data);
		});
	};

	const articleSupplierCertificates = () => {
		const request = Article.articleSupplierCertificates(articleId);
		request.then((req) => {
			const data = req.data;
			setArtCertSupplier(data);
		});
	};

	const articleMetrics = () => {
		const request = Article.metricsSingleArticle(articleId);
		request.then((req) => {
			const data = req.data;
			setArticleData(data);
		});
	};

	useEffect(() => {
		articleOrigins();
		articleMetrics();
		articleComposition();
		articleMaterialCertificates();
		articleSupplierCertificates();
	}, [articleId]);

	const overviewData = getOverviewOnePageData(articleData);
	const equivalences = getEquivalencesOnePage();
	const summaryData = getSummaryOnePage(articleData, compo);
	let stagesData = getStagesDataOnePage(articleData);
	const traceabilityData = getTraceabilityData(articleData);

	if (validateReferenceType) {
		stagesData = stagesData.map((stage: any) => {
			const { manuf, ...rest } = stage;
			stage = rest;

			return stage
		});
	}

	const labelCopy = (value: any) => {
		return t(`dash-${value}`);
	};

	const [showArticleInfo, setShowArticleInfo] = useState(false);

	const [scrollTop, setScrollTop] = useState<boolean>(true)

	const handleScroll = (event:any) => {
		if( event.currentTarget.scrollTop < 20 ) {
			setScrollTop(true)
			return
		}
		setScrollTop(false)
	};

	return (
		<div
			className="d-flex w-100 flex-column flex-grow-1 h-100 overflow-auto pb-2"
			onScroll={handleScroll}
		>
			<div id="OnePageArticle">
				<OnePageHeader
					artName={articleData.name}
					imageUrl={articleData.imageUrl}
					artRef={articleData.reference}
					setShowArticleInfo={setShowArticleInfo}
					scrollTop={scrollTop}
				/>
				<div className="d-flex flex-wrap">
					<ArticleSummary data={traceabilityData} />
				</div>

				<div className="d-flex flex-column flex-grow-1">
					<div className="d-flex flex-wrap flex-grow-1 px-0">
						{/* LCA Overview by indicator */}
						<div
							className="col p-0 m-2 flex-grow-1"
							style={{ minWidth: '600px' }}
						>
							<ChartContainer title={t(`dash-title-LCA${validateReferenceType ? '-material' : ''}`)} subtitle={t('dash-subtitle-article-detail')}>
								<LCATableOnePage
									data={overviewData}
									equivalences={equivalences}
								/>
							</ChartContainer>
						</div>
						{/* LCA Overview by stages */}
						<div
							className="col p-0 m-2 flex-grow-1"
							style={{ minWidth: '600px' }}
						>
							<ChartContainer
								title={t('dash-title-LCStagePerImpact')}
								subtitle={t('dash-subtitle-LCStagePerImpact')}
							>
								<LCStagePerImpactIndicatorChart
									labelCopy={labelCopy}
									data={stagesData}
									validateReferenceType={validateReferenceType}
								/>
							</ChartContainer>
						</div>
					</div>
				</div>

				{/* Index overview */}
				<div className="d-flex flex-row flex-grow-1 flex-wrap px-0">
					{['Planet', 'People', 'Transparency', 'Circularity'].map(
						(index: string) => (
							<SIcard
								title={index}
								description={`${index.toLowerCase()}-result-card-help`}
								value={articleData[index]}
								metricName={index}
							/>
						)
					)}
				</div>
			</div>

			<ModalBaseArticleInfo
				isShow={showArticleInfo}
				handleClose={() => setShowArticleInfo(false)}
				articleData={articleData}
			/>
		</div>
	);
};

export default OnePageArticle;
