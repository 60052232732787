import React from 'react';
import RegexLibrary from 'utils/Regex';

interface IValidationObject { 
    feedbackMessage: string, 
    valid: boolean,
    validations: Array<"isEmail" | "required" | "password">,
}

export interface IValidationsObject {
    [key : string] : IValidationObject
}

function validateValue(value: any, validations : IValidationObject): boolean {
    let ret = false; 
    const check = validations.validations;
    if (value) {
        if (check.includes('isEmail')) {
            ret = RegexLibrary.email.test(value);
        }
        if (check.includes('required')) {
            ret = value.length > 0 && value != "" && value != null; 
        }

        if (check.length == 0) { ret = true ; }
    }
    return ret; 
}



export default function useFormFields<T>(initialValues: T, validationsObject?: IValidationsObject): any {
	const [formFields, setFormFields] = React.useState(initialValues);
    const [validations, setValidations] = React.useState(validationsObject);

    const onChange = (key: any, value: any) => {
        if (validationsObject) {
            const v = validationsObject[`${key}`]; // Typescript hack; 
            if (v) {
                const isValid = validateValue(value, v);
                v.valid = isValid; 
                setValidations((prev) => ({...prev, [key]: v}));
            }
        }
        // Set value; Se hace set aunque el valor puede ser invalid; 
		setFormFields((prev: T) => ({ ...prev, [key]: value }));
    }

    // Handler on change; 
	const onInputChange = (key: keyof T) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
        onChange(key, value);
	};

    const onInputChangeExt = (event: any, key: any) => {
        const value = event.target.value;
        onChange(key, value);
    }

    const validateForm = (customFormFields?: any, customValidation: any = {}): boolean => {
        let ret = true; 
        const v = customValidation || validations; 
        const fields = customFormFields || formFields; 

        let i = 0; 
        if (v) {
            const keys = Object.keys(v);
            while (i < keys.length && ret == true) {
                const key = keys[i]; 

                const values: any = fields; 
                const value = values[key];
                ret = validateValue(value, v[key]);
                const nV = v[`${key}`]; // Typescript hack; 
                nV.valid = ret; 
                setValidations((prev) => ({...prev, [key]: nV}));

                i++;
            }
        }
        return ret; 
    }

    const controlledByParent = (parentData: any) => {
        const d = {...formFields}; 
        for(const key in formFields) {
            d[key] = parentData[key];
        }
        setFormFields(d)
    }

	return { formFields, setFormFields, onInputChange, validations, validateForm, onInputChangeExt, controlledByParent, onChange };
}
