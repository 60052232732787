import React from 'react';
import { useTranslation } from 'react-i18next';
  
interface IProps {
	steps: string[],
    currentStep: string
}

const FormSteps = (props: IProps) => {
	const { steps, currentStep } = props;
	const { t }: any = useTranslation();
	
    return (
        <div className="d-flex justify-content-between my-5">
            {steps.map((s:string, i:number) => (
                <div key={`${currentStep}-${i}`} className={`${steps.length === i + 1 ? '' : 'bt'} d-flex flex-column w-100`}>
                    {currentStep === s && <span className="bg-dark-100 position-relative font-16px rounded-circle" style={{bottom: '8px', width: '16px', height: '16px'}} />}
                    {currentStep !== s && steps.indexOf(steps[i]) > steps.indexOf(currentStep) && <span className="bg-white-100 border-dark-40 position-relative font-16px rounded-circle" style={{bottom: '8px', width: '16px', height: '16px'}} />}
                    {currentStep !== s && steps.indexOf(steps[i]) < steps.indexOf(currentStep) && <span className="bg-white-100 border-dark-100 position-relative font-16px rounded-circle" style={{bottom: '8px', width: '16px', height: '16px'}} />}
                    
                    {currentStep === s && <span className="font-sfpro-medium font-12px text-dark-100">{t(s)}</span>}
                    {currentStep !== s && steps.indexOf(steps[i]) > steps.indexOf(currentStep) && <span className="font-sfpro-regular font-12px text-dark-40">{t(s)}</span>}
                    {currentStep !== s && steps.indexOf(steps[i]) < steps.indexOf(currentStep) && <span className="font-sfpro-regular font-12px text-dark-100">{t(s)}</span>}
                </div>
            ))}
        </div>
    )
}
export default FormSteps;
