import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardHttp from 'services/http/Dashboards';
import ChartContainer from 'UI/Template/ChartContainer';
import { formatNumberNoDecimals, numberWithDots } from 'utils/utils';

const ProjectSummary = () => {
	const { t } = useTranslation();
	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);
	const projectId = projectSelected?.id;
	const Dashboard = new DashboardHttp();

	const [projectSummaryData, setProjectSummaryData]: any[] = useState();

	const projectSummary = (projectId: string) => {
		const request = Dashboard.projectSummary(projectId);
		return request
			.then((res: any) => res.data)
			.catch((err: string) => console.log({ err }));
	};

	useEffect(() => {
		let isMounted = true;
		if (projectId) {
			projectSummary(projectId)
			.then(projectSummaryData => {
				if (isMounted) {
					setProjectSummaryData(projectSummaryData);
				}
			})
		}
		return () => {
			isMounted = false;
		};
	}, [projectSelected]);

	const projectSummarySectionConfig: any = {
		numArticles: {
			copy: t(`dash-summary-${validateReferenceType ? 'material-analyzed' : 'articles'}`),
			data: projectSummaryData?.numArticles,
		},
		amount: {
			copy: t('dash-summary-units'),
			data: projectSummaryData?.amount,
		},
		numMaterials: {
			copy: t('dash-summary-materials'),
			data: projectSummaryData?.numMaterials,
		},
		numSuppliers: {
			copy: t('dash-summary-suppliers'),
			data: projectSummaryData?.numSuppliers,
		},
	};

	const NoDataToDisplay = () => (
		<div
			className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
			style={{ height: ' 250px' }}
		>
			There's no data to display
		</div>
	);

	const projectSummarySections = [];

	const formatData = (section: string) =>
		formatNumberNoDecimals(
			parseInt(projectSummarySectionConfig[section]['data'])
		);

	for (const section in projectSummarySectionConfig) {
		const title: string = projectSummarySectionConfig[section]['copy'];
		const data: any = projectSummarySectionConfig[section]['data']
				? formatData(section)
				: '--';
		projectSummarySections.push(
			<div
				className="col p-0 m-2"
				style={{ minWidth: '200px', height: '188px' }}
			>
				<ChartContainer title={title}>
					{data !== '--' ? (
						<div
							className="d-flex m-auto font-sfpro-medium text-black-100"
							style={{ fontSize: '45px' }}
						>
							{numberWithDots(data)}
						</div>
					) : (
						<NoDataToDisplay />
					)}
				</ChartContainer>
			</div>
		);
	}

	return <>{projectSummarySections}</>;

	// const Section = ({ data, copy }: any) => (
	// 	<div className="d-flex flex-column justify-content-between mr-5">
	// 		<div className="mb-1">
	// 			<MetricNumberDashboard id={copy} value={data} dataType={EDataTypes.p} isInteger />
	// 		</div>
	// 		<div className="text-dark-60 font-14px font-sfpro-regular">
	// 			{copy}
	// 		</div>
	// 	</div>
	// );

	// return (
	// 	<div className="d-flex mr-auto">
	// 		<Section data={data?.numArticles} copy={t("dash-summary-articles")} />
	// 		<Section data={data?.amount} copy={t("dash-summary-units")} />
	// 		<Section data={data?.numMaterials} copy={t("dash-summary-materials")} />
	// 		<Section data={data?.numRM} copy={t("dash-summary-raw-materials")} />
	// 		<Section data={data?.numSuppliers} copy={t("dash-summary-suppliers")} />
	// 	</div>
	// );
};

export default ProjectSummary;
