import React, { CSSProperties, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EDataTypes,
	EMetricUnitTypes,
	EIndicators,
} from 'UI/Template/Table/types/TableEnums';
import { formatNumber, formatNumberNoDecimals, numberWithDots } from 'utils/utils';
import MetricUnit from 'UI/atoms/MetricUnit';
import { Tooltip, UncontrolledTooltipProps } from 'reactstrap';
import * as Popper from 'popper.js';

interface CustomTooltipProps {
	copy?: string;
	metric?: number | string;
	extraCss?: string;
	isPercent?: boolean | undefined;
	absolute?: number | undefined;
	indicator?: EIndicators | string | undefined;
	metricUnit?: EMetricUnitTypes | string | undefined;
	dataType?: EDataTypes | string | undefined;
	status?: { text: string, color: string };
}

interface SimpleTooltipProps {
	text?: string;
	JSXElement?: () => string | JSX.Element;
	tooltipId: string;
	tooltipOpen: boolean;
	placement?: Popper.Placement;
	styles?: any;
}

export const SimpleTooltip = (props: SimpleTooltipProps) => {
	const {
		text,
		JSXElement,
		tooltipId,
		tooltipOpen,
		styles,
		placement = 'bottom-start',
	} = props;

	return (
		<Tooltip
			innerClassName="tooltips"
			style={styles}
			arrowClassName="arrow-tooltip"
			placement={placement}
			isOpen={tooltipOpen}
			target={tooltipId}
		>
			<span dangerouslySetInnerHTML={{__html: text ? text : ""}} />
			{JSXElement && JSXElement()}
		</Tooltip>
	);
};

export const CustomTooltip = (props: CustomTooltipProps) => {
	const {
		copy = '',
		metric,
		extraCss = '',
		isPercent,
		absolute,
		indicator,
		metricUnit,
		dataType,
		status,
	} = props;
	const { t } = useTranslation();

	if (typeof absolute === 'number' && indicator && metricUnit && dataType) {
		return (
			<div className="d-flex flex-column tooltiptext">
				{/* Absolute data (units) */}
				<div className="d-flex flex-row text-nowrap">
					<span
						className={`${extraCss} font-sfpro-regular text-dark-100 font-12px`}
						style={{ width: '68px' }}
					>
						{t('savings-difference')}{' '}
					</span>
					<span
						className={`${extraCss} font-sfpro-regular text-dark-60 font-12px`}
					>
						{
							(dataType === EDataTypes.ue || dataType === EDataTypes.pe)
							? numberWithDots(formatNumberNoDecimals(Number(absolute)))
							: formatNumber(Number(absolute))
						}
					</span>
					<MetricUnit
						indicator={indicator}
						metricUnit={metricUnit}
						dataType={dataType}
						styles={
							'pl-1 font-sfpro-regular text-dark-60 font-12px'
						}
					/>
					{
						status?.text && (
							<span className='ml-1 text-lowercase' style={{ color: status?.color }}>{t(status.text ?? '')}</span>
						)
					}
				</div>
			</div>
		);
	} else {
		return (
			<span className={`${extraCss} tooltiptext text-nowrap`}>
				{metric && formatNumber(Number(metric))} {isPercent && '%'}{' '}
				{copy && copy}
			</span>
		);
	}
};

interface CustomSimpleTooltipProps {
	copy: string;
	maxWidth?: string;
	position?: {
		top: string;
		left: string;
	}
}

export const CustomSimpleTooltip = (props: CustomSimpleTooltipProps) => {
	const { copy, maxWidth = '300px', position } = props;
	const { t } = useTranslation();

	return (
		<div
			className="d-flex flex-column tooltiptext"
			style={{ minWidth: '150px', maxWidth: maxWidth, width: 'max-content', ...position }}
		>
			<span className="px-2">{t(copy)}</span>
		</div>
	);
};

interface TooltipTraceabilityScoreProps {
	children: any;
	minWidth?: number | string;
	top?: number;
	left?: number;
	customStyles?: any
}

export const TooltipTraceabilityScore = (props: TooltipTraceabilityScoreProps) => {
	const { children, minWidth = 200, top, left, customStyles } = props;

	return (
		<div
			className="d-flex flex-column tooltiptext overflow-auto"
			style={{ minWidth: minWidth, maxWidth: 'max-content', top: top, left: left, maxHeight: 150, height: 'max-content', ...customStyles }}
		>
			{children}
		</div>
	);
};


type DefaultTooltipProps = {
	tooltipText: string;
	tooltipTextAlign?: CSSProperties['textAlign'];
	children: any;
}

export const DefaultTooltip = ({tooltipText, tooltipTextAlign = 'start', children}: DefaultTooltipProps) => {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const updateTooltipPosition = (event: any) => {
		const tooltip = tooltipRef.current;
		const offsetX = 10;
		const offsetY = 15;
		const padding = 5;

		const x = event.clientX + offsetX;
		const y = event.clientY + offsetY;

		if (tooltip) {
			// Check if the tooltip would overflow the right edge
			if (x + tooltip.offsetWidth + padding > window.innerWidth) {
				tooltip.style.left = window.innerWidth - tooltip.offsetWidth - padding + "px";
			} else {
				tooltip.style.left = x + "px";
			}

			// Check if the tooltip would overflow the bottom edge
			if (y + tooltip.offsetHeight + padding > window.innerHeight) {
				tooltip.style.top = window.innerHeight - tooltip.offsetHeight - padding + "px";
			} else {
				tooltip.style.top = y + "px";
			}
		}
	}
	return (
		<div
			className="tooltip-container"
			onMouseEnter={(event: any) => updateTooltipPosition(event)}
		>
			{ children }
			<div
				ref={tooltipRef}
				className="tooltip text-dark-60"
				style={{
					textAlign: tooltipTextAlign,
				}}
			>{ tooltipText }</div>
		</div>
	)
}