
import React from "react";

type HelpProps = {
    link: string;
    question: string;
    inlineStyles?: any;
}

export const Help = ({ link, question, inlineStyles}: HelpProps) => {
    return (
        <a
            className="text-dark-60 text-dark-100-hover d-flex flex-row align-items-center mx-4 mx-md-0"
            href={link}
            target="__blank"
            rel="noopener"
            style={inlineStyles}
        >
            <span>{question}</span>
            <span className="icon-icon-bulbs font-15px pl-2"></span>
        </a>
    )
}