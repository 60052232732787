import Crud from "./Crud";
import { eCommerceCustom } from "types/eCommerceCustomization";

export default class Account extends Crud {
	constructor() {
		super("accounts");
	}

	async updateEcommerceConfiguration(id: string, data: any): Promise<eCommerceCustom> {
		return await this.patch(`${id}`, data);
	}
}
