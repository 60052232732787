import React from "react"
import { useTable } from 'contexts/tableContext';
import ItemsListHandler from "components/Common/ItemsListHandler/ItemsListHandler";

const NumberArticlesDisplayed = () => {
    const {res, req, currentPage, limitPagination, pageCount, items} = useTable();    
    let minArticle = 0
    let maxArticle = 0

    if(currentPage && limitPagination && pageCount){
         minArticle = ((currentPage - 1) * limitPagination ) + 1
         maxArticle = ((currentPage - 1) * limitPagination ) + items.length 
    }

    if (res.total === 0) {return( <div className="font-sfpro-regular text-dark-60 font-12px text-overflow-ellipsis overflow-hidden">0-0 of 0</div> )}
    else {
        return(
            <div className="font-sfpro-regular text-dark-60 font-12px text-overflow-ellipsis overflow-hidden d-flex justify-content-center mx-2"
                style={{width: "80px"}}>{minArticle}-{maxArticle} of {res.total}</div>
        )}
}

export default NumberArticlesDisplayed;