import React, { MouseEvent } from "react";

interface Props {
    select: boolean;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const SelectFavorite = ({ select, onClick }: Props) => {
	return (
		<button
			className="favorite"
			onClick={onClick}
		>
			<svg
				width="14"
				height="18"
				viewBox="0 0 14 18"
				fill="none"
				stroke="#706F7A"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill={select ? '#1C1C30' : ''}
					d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H13C13.2761 0.5 13.5 0.723858 13.5 1V16.1683C13.5 16.5639 13.0624 16.8028 12.7296 16.5889L7.81114 13.427C7.31702 13.1094 6.68298 13.1094 6.18886 13.427L1.27038 16.5889C0.937623 16.8028 0.5 16.5639 0.5 16.1683V1Z"
					stroke={select ? '#1C1C30' : '#706F7A'}
				/>
			</svg>
		</button>
	);
};
