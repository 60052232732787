import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	PieChart,
	Pie,
	Cell,
	Line,
	ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatNumberNoDecimals } from 'utils/utils';

const ScoreDistributionChart = ({ data, index }: any) => {
	const { t } = useTranslation();

	const totalAmount = data.totalAmount;
	const areas = ['circularity', 'people', 'planet', 'transparency'];

	const structuredData = [];

	for (const area in data) {
		if (areas.includes(area)) {
			const group = {
				name: area,
				A: (data[area]['A'] / totalAmount) * 100,
				B: (data[area]['B'] / totalAmount) * 100,
				C: (data[area]['C'] / totalAmount) * 100,
				D: (data[area]['D'] / totalAmount) * 100,
				E: (data[area]['E'] / totalAmount) * 100,
			};
			structuredData.push(group);
		}
	}

	const CustomTooltip = (props: any) => {
		const { payload } = props;

		return (
			<div className="tooltip-dashboard">
				{payload.map((entry: any, index: number) => (
					<div style={{ color: '#696980' }}>
						<span key={`item-${index}`} className="mr-1">
							{entry.name}:
						</span>
						<span className="mr-1 font-sfpro-regular">
							{formatNumber(entry.value)} %
						</span>
					</div>
				))}
			</div>
		);
	};

	const CustomLegend = (props: any) => {
		const { payload } = props;

		return (
			<div
				className="w-100 mt-2 d-flex justify-content-end align-items-center"
				style={{ top: '15px', borderTop: '1px solid #C6C7C8' }}
			>
				{payload.map((entry: any, index: number) => {
					if (entry.value !== 'null') {
						return (
							<div className="d-flex align-items-center mx-2 mt-2">
								<span
									key={`item-${index}`}
									className={`font-15px mr-1 rounded-10`}
									style={{
										backgroundColor: entry.color,
										width: '10px',
										height: '10px',
									}}
								></span>
								<span className="font-12px text-dark-60">
									{entry.value}
								</span>
							</div>
						);
					}
				})}
			</div>
		);
	};

	const labelCopy = (value: any) => {
		return t(`dash-${value}`);
	};

	const CustomYAxisTick = (props: any) => {
		const { y, payload } = props;

		return (
			<g transform={`translate(${0},${y})`}>
				<text
					x={0}
					y={0}
					textAnchor="start"
					fill="#706F7A"
					className="font-12px"
				>
					{labelCopy(payload.value)}
				</text>
			</g>
		);
	};

	const hasValues = (data: any) => {
		let value = 0;

		if (data) {
			data.forEach((area: any) => {
				Object.keys(area).forEach((labelCategory) => {
					if (labelCategory !== 'name') {
						value += area[labelCategory];
					}
				});
			});
			return value !== 0;
		}

		return value !== 0;
	};

	const NoDataToDisplay = () => (
		<div className="d-flex align-items-center text-dark-60 font-12px">
			There's no data to display
		</div>
	);

	return (
		<>
			{hasValues(structuredData) ? (
				<ResponsiveContainer height={250}>
					<BarChart
						layout="vertical"
						width={600}
						height={210}
						data={structuredData}
						stackOffset="sign"
						margin={{
							top: 15,
							right: 30,
							left: 10,
							bottom: 5,
						}}
						className="font-sfpro-regular"
						style={{ color: '#2B2D4D' }}
					>
						<CartesianGrid
							horizontal={false}
							strokeDasharray="0"
							stroke="#D6D6D7"
						/>
						<XAxis
							type="number"
							fontSize="12"
							domain={[0, 100]}
							tickCount={12}
							tick={{ fill: '#C6C7C8' }}
							tickFormatter={formatNumberNoDecimals}
							axisLine={false}
							tickLine={false}
							unit="%"
						/>
						<YAxis
							type="category"
							dataKey="name"
							fontSize="12"
							tickLine={{ stroke: 'transparent' }}
							width={85}
							axisLine={false}
							tick={<CustomYAxisTick />}
						/>
						<Tooltip content={CustomTooltip} />
						<Legend content={CustomLegend} />
						<Bar
							id="a"
							dataKey="A"
							stackId="a"
							fill="#527800"
							barSize={24}
							unit="%"
						></Bar>
						<Bar
							id="b"
							dataKey="B"
							stackId="a"
							fill="#9A9753"
							barSize={24}
							unit="%"
						></Bar>
						<Bar
							dataKey="C"
							stackId="a"
							fill="#F0D05F"
							barSize={24}
							unit="%"
						></Bar>
						<Bar
							dataKey="D"
							stackId="a"
							fill="#E0A75E"
							barSize={24}
							unit="%"
						></Bar>
						<Bar
							dataKey="E"
							stackId="a"
							fill="#B44D49"
							barSize={24}
							unit="%"
						></Bar>
					</BarChart>
				</ResponsiveContainer>
			) : (
				<NoDataToDisplay />
			)}
		</>
	);
};

export default ScoreDistributionChart;
