import React from "react"


export const SidebarDisplayer = ({setIsSideBar, isSideBar}:any) => {
    const marginLeft = isSideBar ? '-18px' : '12px';

    const handleClick = () => setIsSideBar(!isSideBar)

    return (
        <div onClick={() => handleClick()}
            className="icon-icon-sidebar-button text-yellow cursor-pointer font-18px"
            style={{
                transition: "all 0.5s ease",
				transform: isSideBar ? "rotate(180deg)" : "rotate(0deg)",
                zIndex: 1,
                marginLeft: marginLeft,
            }}
        >
        </div>
    )
}