import React from "react";

interface IntegrationItemProps {
	photo: string;
	title: string;
	description: string;
	linkText: string;
	linkUri: string;
	buttonText: string;
	onClick: () => void;
}

export const IntegrationItem = ({ photo, title, description, linkText, linkUri, buttonText, onClick }: IntegrationItemProps) => {
	return (
		<div
			className="my-3 rounded-10 py-4 d-flex flex-row"
			style={{ gap: '32px', paddingRight: '80px' }}
		>
			<div className="d-flex justify-content-center p-3" style={{
				background: '#F6F6F8',
			}}>
				<img src={photo} alt={title} style={{
					objectFit: 'contain',
					width: '283px',
					height: '258px'
				}} />
			</div>
			<div className="d-flex flex-column justify-content-between">
				<section>
					<h1 className="font-24px font-sfpro-regular">{ title }</h1>
					<p className="font-14px">{ description }</p>
					<a className="text-decoration-underline" href={linkUri} target="__blank">{ linkText }</a>
				</section>
				<button
					className="btn btn-primary d-flex justify-content-center w-max-content align-items-center"
					onClick={onClick}
					style={{
						minWidth: '200px',
					}}
				>{ buttonText }</button>
			</div>
		</div>
	)
}

export const IntegrationDivisor = () => <div className="w-100" style={{
	border: '.5px solid #1C1C30',
}}></div>