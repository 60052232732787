import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetricNumber from 'UI/atoms/MetricNumber';
import MetricUnit from 'UI/atoms/MetricUnit';
import { DefaultTooltip, SimpleTooltip,  } from 'UI/atoms/Tooltips/Index';
import { dataGroups } from 'UI/Template/Table/types/DataGroups';
import { isDivOverflow } from 'utils/checkIfTextOverflow';
import {
	EDataTypes,
	EIndicators,
	EMetricUnitTypes,
} from '../../../Template/Table/types/TableEnums';
import ChartContainer from 'UI/Template/ChartContainer';
import { formatNumber } from 'utils/utils';
import { TooltipWrapper } from 'UI/atoms/Tooltips/tooltip-wrapper';
import { ResultWrapperText, includeStyle } from 'UI/atoms/ResultWrapper/result-wrapper';
import { useProjects } from 'contexts/projectsContext';
import { useReferenceType } from 'hooks/useReferenceType.hook';

const LCASummary = (props: any) => {
	const { data, equivalences } = props;
	const { t } = useTranslation();
	const { projectSelected } = useProjects();
	const { validateReferenceType } = useReferenceType(projectSelected);

	const indicators = [
		{
			name: 'Water',
			indicator: EIndicators.waterUse,
			dataType: EDataTypes.p,
			color: '#015594',
			impactEq: equivalences.waterKeyName,
			metricUnit: EMetricUnitTypes.waterUse,
			metricUnitEq: EMetricUnitTypes.pool,
			savingsEq: equivalences.waterSavingsKeyName,
			metricUnitSavings: EMetricUnitTypes.waterUse,
			iconEqImpact: dataGroups.water.iconEqImpactP,
			iconEqBenchmark: dataGroups.water.iconEqImpactP,
            text: "water-scarcity"
		},
		{
			name: 'ClimateChange',
			indicator: EIndicators.climateChange,
			dataType: EDataTypes.p,
			color: '#424242',
			impactEq: equivalences.climateChangeKeyName,
			metricUnit: EMetricUnitTypes.climateChange,
			metricUnitEq: EMetricUnitTypes.electricity,
			savingsEq: equivalences.climateChangeSavingsKeyName,
			metricUnitSavings: EMetricUnitTypes.climateChange,
			iconEqImpact: dataGroups.climateChange.iconEqImpactP,
			iconEqBenchmark: dataGroups.climateChange.iconEqImpactP,
            text: "global-warming"
		},
		{
			name: 'Eutrophication',
			indicator: EIndicators.eutrophication,
			dataType: EDataTypes.p,
			color: '#887D36',
			impactEq: equivalences.eutroKeyName,
			metricUnit: EMetricUnitTypes.eutrophication,
			metricUnitEq: EMetricUnitTypes.detergent,
			savingsEq: equivalences.eutroSavingsKeyName,
			metricUnitSavings: EMetricUnitTypes.eutrophication,
			iconEqImpact: dataGroups.eutrophication.iconEqImpactP,
			iconEqBenchmark: dataGroups.eutrophication.iconEqImpactP,
            text: "eutrophication"
		},
		{
			name: 'FossilFuels',
			indicator: EIndicators.fossilFuels,
			dataType: EDataTypes.p,
			color: '#1F4A53',
			impactEq: equivalences.fossilFuelsKeyName,
			metricUnit: EMetricUnitTypes.fossilFuels,
			metricUnitEq: EMetricUnitTypes.planeTravelling,
			savingsEq: equivalences.fossilFuelsSavingsKeyName,
			metricUnitSavings: EMetricUnitTypes.fossilFuels,
			iconEqImpact: dataGroups.fossilFuels.iconEqImpactP,
			iconEqBenchmark: dataGroups.fossilFuels.iconEqImpactP,
            text: "abiotic-depletion"
		},
	];

	const columnHeaders = [
		'', // column 1
		'dash-overview-title-total-impacts', // column 2
		'dash-overview-title-total-impacts-eq', // column 3
		'dash-overview-title-total-savings', // column 4
		'dash-overview-title-total-savings-eq', // column 5
	];

	const HeaderTD = ({ text }: { text: string }) => {
		return (
			<td className="text-overflow-ellipsis overflow-hidden pr-5">
				{text}
			</td>
		);
	};

	const HeaderRow = () => (
		<tr
			id="header-row-lca-table"
			className="text-black-60 font-sfpro-regular font-12px"
			style={{ height: '45px' }}
		>
			{columnHeaders.map((h: string) => (
				<HeaderTD text={t(h)} />
			))}
		</tr>
	);

	const IndicatorNameTD = ({ text }: { text: string }) => {
		return (
			<td
				className="font-sfpro-regular text-dark-60 font-12px pr-5"
				style={{ maxWidth: '100px' }}
			>
				{text}
			</td>
		);
	};

	interface ImpactTDProps {
		dataType: EDataTypes;
		indicator: string;
		name: string;
		value: number;
	}
	const ImpactTD = ({ dataType, indicator, name, value }: ImpactTDProps) => {
		const [isTextOverflowed, setIsTextOverflowed] = useState(false);
		const ref = useRef<any>(null);

		const compareMetricTextSize = () => {
			const isOverflow = isDivOverflow(ref);
			setIsTextOverflowed(isOverflow);
		};

		// compare once and add resize listener on "componentDidMount"
		useEffect(() => {
			compareMetricTextSize();
			window.addEventListener('resize', compareMetricTextSize);
		}, []);

		// remove resize listener again on "componentWillUnmount"
		useEffect(
			() => () => {
				window.removeEventListener('resize', compareMetricTextSize);
			},
			[]
		);

		const textMetricUnitTooltip = () => (
			<>
				<span
					className="font-sfpro-regular font-12px text-overflow-ellipsis overflow-hidden text-dark-60"
				>{ formatNumber(value) }</span>
				<MetricUnit
					indicator={indicator}
					dataType={dataType}
					styles={'font-sfpro-regular text-dark-60 font-12px ml-1'}
				/>
			</>
		);

		return (
			<TooltipWrapper tooltipText={textMetricUnitTooltip}>
				<td
					ref={ref}
					className="pr-3 font-sfpro-regular text-overflow-ellipsis overflow-hidden"
				>
					<MetricNumber
						id={name}
						value={value}
						dataType={dataType}
						styles="text-dark-100 font-sfpro-medium"
					/>
					<MetricUnit
						indicator={indicator}
						dataType={dataType}
						styles={
							'pl-1 text-overflow-ellipsis overflow-hidden text-dark-60'
						}
					/>
				</td>
			</TooltipWrapper>
		);
	};

	interface SavingsTDProps {
		color: string;
		absolutValue: number;
		relativeValue: number;
		name: string;
		dataType: EDataTypes;
		metricUnit: EMetricUnitTypes;
		indicator: EIndicators;
	}

	const SavingsTD = ({
		color,
		absolutValue,
		relativeValue,
		name,
		dataType,
		metricUnit,
		indicator,
	}: SavingsTDProps) => {
		const ResultWrapperTextByResult = includeStyle(ResultWrapperText, relativeValue);

		const returnedValue = () => (
			<ResultWrapperTextByResult>
				<span
					className="font-sfpro-regular font-12px text-overflow-ellipsis overflow-hidden text-dark-60"
				>{ formatNumber(absolutValue) }</span>
				<MetricUnit
					indicator={indicator}
					metricUnit={metricUnit}
					dataType={dataType}
					styles={
						'pl-1 text-overflow-ellipsis overflow-hidden text-dark-60'
					}
				/>
			</ResultWrapperTextByResult>
		)

		return (
			<TooltipWrapper tooltipText={returnedValue}>
				<td
					className="pr-3 text-overflow-ellipsis"
				>
					<div className="d-flex flex-row">
						<div style={{ minWidth: '40px' }}>
							<ResultWrapperTextByResult>
								<MetricNumber
									id={name}
									value={relativeValue * 100}
									dataType={EDataTypes.r}
									styles="text-dark-100 font-sfpro-medium"
									isInteger
								/>
							</ResultWrapperTextByResult>
						</div>
					</div>
				</td>
			</TooltipWrapper>
		);
	};

	interface EquivalenceTDProps {
		name: string;
		value: number;
		icon?: string;
	}

	const EquivalenceTD = ({ name, value, icon }: EquivalenceTDProps) => {
		return (
			<td
				className="text-overflow-ellipsis overflow-hidden"
				style={{
					width: '15%',
					maxWidth: '100px',
				}}
			>
					<TooltipWrapper tooltipText={t(`dash-LCATable-${icon}`)}>
						<div className="d-flex align-items-center">
							<span
								className={`${icon} font-20px pr-1 text-dark-60`}
							></span>
							<MetricNumber
								id={name}
								value={value}
								dataType={EDataTypes.pe}
								styles="text-dark-100 font-sfpro-medium"
								isInteger
							/>
						</div>
					</TooltipWrapper>
			</td>
		);
	};

	const IndicatorRow = ({ i }: any) => {
		const impactValue = data[`impact_${i.name}`];
		const equivalenceImpactValue =
			data[`equivalences_${i.name}_${i.impactEq}`];
		const savingsRelativeValue = data[`savings_${i.name}_relative`];
		const savingsEquivaleceValue =
			data[`savings_equivalences_${i.name}_${i.savingsEq}`];
		const savingsAbsoluteValue = data[`savings_${i.name}_absolut`];

		return (
			<tr
				style={{
					borderBottom: '1px solid #D6D6D7',
					height: '40px',
				}}
			>
				<IndicatorNameTD text={t(`dash-${i.name}`)} />
				<ImpactTD
					name={i.name}
					value={impactValue}
					indicator={i.indicator}
					dataType={i.dataType}
				/>
				<EquivalenceTD
					name={i.name}
					icon={i.iconEqImpact}
					value={equivalenceImpactValue}
				/>
				<SavingsTD
					name={i.name}
					color={i.color}
					indicator={i.indicator}
					metricUnit={i.metricUnit}
					dataType={i.dataType}
					absolutValue={savingsAbsoluteValue}
					relativeValue={savingsRelativeValue}
				/>

				<EquivalenceTD
					name={i.name}
					icon={i.iconEqBenchmark}
					value={savingsEquivaleceValue}
				/>
			</tr>
		);
	};

	const indicatorsRows: JSX.Element[] = [];

	indicators.forEach((i) => {
		indicatorsRows.push(<IndicatorRow i={i} />);
	});
	const urlMethodology =
		'https://drive.google.com/file/d/1xWG9dkKwMBR_Wh3xwSMLsThzhQrJbE3l/view';

	return (
		<>
			{
                indicators.map((i, index) => (
                    <div className="col p-0 m-2 lca-statistics-card" key={i.name}>
                        <ChartContainer
							title={t(`dashboard-card-${i.text}-overview`)}
							titleTooltip={t(`dashboard-card-${validateReferenceType ? 'material' : i.text}-overview-tooltip`)}
						>
                            {data !== '--' ? (
                                <table className='m-auto'>
                                    <tbody>
                                        <tr className='py-3'>
                                            <td className='text-dark-100 font-14px py-3 pr-2'>{t("dash-overview-title-total-impacts")}</td>
                                            <ImpactTD
                                                name={i.name}
                                                value={data[`impact_${i.name}`]}
                                                indicator={i.indicator}
                                                dataType={i.dataType}
                                            />
                                            <EquivalenceTD
                                                name={i.name}
                                                icon={i.iconEqImpact}
                                                value={data[`equivalences_${i.name}_${i.impactEq}`]}
                                            />
                                        </tr>
                                        <tr className='py-3' style={{
                                            borderTop: "1px solid #D6D6D7"
                                        }}>
                                            <td className='text-dark-100 font-14px py-3 pr-2 position-relative'>
												<DefaultTooltip tooltipText={t('lca-benchmark-dashboard')}>
													{t("dash-overview-title-total-savings")}
												</DefaultTooltip>
											</td>
                                            <SavingsTD
                                                name={i.name}
                                                color={i.color}
                                                indicator={i.indicator}
                                                metricUnit={i.metricUnit}
                                                dataType={i.dataType}
                                                absolutValue={data[`savings_${i.name}_absolut`]}
                                                relativeValue={data[`savings_${i.name}_relative`]}
                                            />
                                            <EquivalenceTD
                                                name={i.name}
                                                icon={i.iconEqBenchmark}
                                                value={data[`savings_equivalences_${i.name}_${i.savingsEq}`]}
                                            />
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <NoDataToDisplay />
                            )}
                        </ChartContainer>
                    </div>
                ))
            }
		</>
	);
};

const NoDataToDisplay = () => (
    <div
        className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
        style={{ height: ' 250px' }}
    >
        There's no data to display
    </div>
);

export default LCASummary;
