import React, { useEffect, useState } from 'react';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { useProjects } from 'contexts/projectsContext';
import Ranking from './Ranking';
import ArticleHttp from 'services/http/Article';
import { IDataSchema } from '../types/ranking';
import { QuerySort, QuerySortOperator } from '@nestjsx/crud-request';

const ArticlesResquest = new ArticleHttp();

const RankingDashboard = () => {
	const { projectSelected } = useProjects();
	const [dataSchema, setDataSchema] = useState<IDataSchema | null>(null);
	const path = window.location.pathname;
	const product = path?.split('/')[1];

	const areas = ['Planet', 'People', 'Transparency', 'Circularity'];
	const savings = [
		'Savings_Water_Relative',
		'Savings_ClimateChange_Relative',
		'Savings_Eutrophication_Relative',
		'Savings_FossilFuels_Relative',
	];

	const createQuery = (key: string, sort: QuerySortOperator) => {
		const fieldSort: QuerySort[] = [
			{ field: key, order: sort },
			{ field: 'name', order: 'ASC' },
		];

		return RequestQueryBuilder.create({
			search: { projectId: { $eq: projectSelected?.id } },
			sort: fieldSort,
			page: 1,
			limit: 3,
			resetCache: true,
		}).query();
	};

	const getArticles = (group: string[]) => {
		return Promise.all(
			group.map(async (key: string) => {
				const bestArticlesQuery = createQuery(key, 'DESC');
				const worstArticlesQuery = createQuery(key, 'ASC');
				const bestArticlesResponse = await ArticlesResquest.articlesByProject(
					bestArticlesQuery
				);
				const worstArticlesResponse = await ArticlesResquest.articlesByProject(
					worstArticlesQuery
				);
				return {
					key,
					bestArticlesResponse,
					worstArticlesResponse,
				};
			})
		).then((responses) => {
			const data: any = {};

			responses.forEach(
				({ key, bestArticlesResponse, worstArticlesResponse }) => {
					data[key] = {
						bestArticles: [bestArticlesResponse.data.data],
						worstArticles: [worstArticlesResponse.data.data],
					};
				}
			);

			return data
		});
	};

	useEffect(() => {
		let isMounted = true;
		if (projectSelected) {
			product === 'metrics' && getArticles(savings).then((data) => { isMounted && setDataSchema(data) });
			product === 'evaluation' && getArticles(areas).then((data) => { isMounted && setDataSchema(data) });
		}

		return () => {
			isMounted = false;
		};
	}, [projectSelected]);

	return (
		<div className="d-flex flex-row flex-grow-1 overflow-auto">
			{dataSchema && <Ranking data={dataSchema} product={product} />}
		</div>
	);
};

export default RankingDashboard;
