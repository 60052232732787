import React from 'react';

import IconLeft from 'styles/icons/icon-chevron-left.svg';
import IconRight from 'styles/icons/icon-chevron-right.svg';

import '../styles/TablePagination.scss'

const TablePaginationItem = (props: any) => {
	return (
		<div
			className="item font-sfpro-medium rounded-10 d-flex align-items-center justify-content-center cursor-pointer mr-1">
			{props.children}
		</div>
	);
};

const TablePagination = (props: any) => {
	return (
		<nav aria-label="Page navigation">
			<div className="table-pagination d-flex flex-row justify-content-around align-items-center">
				<TablePaginationItem>
					<img src={IconLeft} />
				</TablePaginationItem>
				<TablePaginationItem>1</TablePaginationItem>
				<TablePaginationItem>2</TablePaginationItem>
				<TablePaginationItem>3</TablePaginationItem>
				<TablePaginationItem>
					<img src={IconRight} />
				</TablePaginationItem>
			</div>
		</nav>
	);
};
export default TablePagination;
