import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBarScore } from 'UI/atoms/ProgressBarScore/ProgressBarScore';
import { getResultLabelByScore } from 'utils/ProjectResults';

interface ISmartLabelCard {
	id: any;
	color: string;
	value?: string | undefined;
	label: string | undefined;
	score: number | undefined;
	handleClicShow?: any;
	noValues?: boolean
}

export const SmartLabelCard = (props: ISmartLabelCard) => {
	const { t } = useTranslation();
	const { id, color, value, label, score, handleClicShow, noValues } = props;
	let percent;
	let qualification;
	let resultLabel;
	if (score !== undefined) {
		percent = ((Number(value) / score) * 100).toFixed(0);
		qualification = (Number(value) / score) * 10;
		resultLabel = getResultLabelByScore(qualification);
	}

	return (
		<div className="w-100 d-flex flex-column justify-content-between position-relative">
			<div className="w-100 d-flex flex-row justify-content-between mb-2">
				<span className="d-flex font-sfpro-medium letter-spacing-normal font-16px text-dark-100">
					{t(`${label}`)}
				</span>
			</div>
			<span
				key={label?.toLowerCase()}
				className="text-dark-60 font-sfpro-regular letter-spacing-normal mb-2"
			>
				{t(`${label}-qr-description`)}
			</span>
			{
				noValues
				? 	<span
						id={id}
						className="icon-icon-add font-sfpro-medium letter-spacing-normal font-12px cursor-pointer position-absolute"
						style={{ right: '0px' }}
						onClick={() => handleClicShow(id)}
					></span>
				: 	<div className="d-flex flex-row justify-content-between align-items-center">
						<div className="w-100">
							<ProgressBarScore
								color={color}
								value={value}
								score={score}
								label={label}
							/>
						</div>
						{label !== 'Circularity' && (
							<span
								id={id}
								className="icon-icon-add font-sfpro-medium letter-spacing-normal font-12px cursor-pointer"
								onClick={() => handleClicShow(id)}
							></span>
						)}
					</div>
			}
		</div>
	);
};
