import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useProjects } from "contexts/projectsContext";
import Library from "services/http/Library";

export enum DataType {
    documents = 'Documents',
    resources = 'Resources',
}

export interface LibraryResource {
    id:        string;
    createdAt: Date;
    updatedAt: Date;
    title:     string;
    url:       string;
    category:  string;
    cover:     string;
    section:   DataType;
}


export const libraryLoader = (Component: any, dataType: DataType, filter: string, searchText: string, data: LibraryResource[]) => {
    return (props: any) => {
        const { t } = useTranslation();
        const { setShowHeader } = useProjects();
        let emptyText = t(`${dataType}-empty`)

        if (data.length > 0) {
            setShowHeader(true);
        } else {
            setShowHeader(false);
        }

        let filterData = filter === "All categories" ? data : data.filter(d => d.category === filter)

        if (searchText) {
            filterData = filterData.filter(d => d.title.toLowerCase().includes(searchText.toLowerCase()))
        }

        emptyText = searchText && filterData.length === 0 ? t('no-results-found') : emptyText

        return filterData.length === 0 ? <EmptyText text={emptyText} /> : <Component data={filterData} {...props} />
    }
}

const EmptyText = ({text}: {text: string}) => {
    return (
        <section className="w-100 p-2 d-flex justify-content-center align-items-center text-center" style={{ height: '253px'}}>
            <span className="text-dark-60 font-14px" style={{ maxWidth: 270 }}>{text}</span>
        </section>
    )
}
