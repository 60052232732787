import React from 'react';
import { useTranslation } from 'react-i18next';
import Qualification from '../Qualification';

interface IProgressBarScore {
	color: string;
	value: string | undefined;
	label: string | undefined;
	score: number | undefined;
	detail?: string;
}

export const ProgressBarScore = (props: IProgressBarScore) => {
	const { t } = useTranslation();
	const { value, score, detail } = props;

	return (
		<div className="d-flex flex-row justify-content-between align-items-center">
			<div
				className={`d-flex flex-row progress-bar-bg ${detail ? 'justify-content-between align-items-start' : 'align-items-center'}`}
				style={{ width: '100%' }}
			>
				<span className={`text-dark-100 font-sfpro-medium letter-spacing-normal font-14px`}>
					{
						detail && (
							<span className='text-dark-60 font-14px' dangerouslySetInnerHTML={{ __html: detail }}></span>
						)
					}
				</span>
				<div className='w-30 d-flex align-items-center justify-content-between'>
					<span>
						{score ? score : '-'}{' '}{'| 10'}{' '}
						points
					</span>
					<Qualification label={value} styles={`ml-2 ${detail ? 'd-flex justify-content-end' : ''}`} />
				</div>
			</div>
		</div>
	);
};
