import Crud, { ICrudListResponse } from "./Crud";


export default class Process extends Crud {
    constructor() {
        super("process");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/')
    }

    async processByGroup(groupName:string): Promise<ICrudListResponse> {
        return await this.get(`processByGroup/${groupName}`);
    }

    async processForCalculator(groupName:string, categoryId:string): Promise<ICrudListResponse> {
        return await this.get(`processForCalculator/?group=${groupName}&category=${categoryId}`);
    }

}
