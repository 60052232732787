import React from 'react';

export type VisibilityPassword = 'password' | 'text'


interface EyeIconProps {
    inputType: 'text' | 'password',
    setInputType: any,
    isEmpty: boolean
}

export const isEmptyValue = (value: undefined | string) => value?.length === 0

const handlePasswordVisibility = (input: string, setInput: any) => {
    if (input === 'text') setInput('password');
    else setInput('text');
}

const HandlePasswordVisibility = (props: EyeIconProps) => {

    const { inputType, setInputType, isEmpty } = props

    const colorIcon = isEmpty ? 'transparent' : '#494853'

    return (
        <span
            onClick={() => handlePasswordVisibility(inputType, setInputType)}
            className={`password-visibility-icon icon-icon-eye${inputType === 'password' ? '-off' : ''} cursor-pointer`}
            id="togglePassword"
            style={{ marginLeft: '-35px', marginTop: '22px', zIndex: 2, color: colorIcon }}>
        </span>
    )
}

export default HandlePasswordVisibility