import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTable } from 'contexts/tableContext';
import { EDataTypes } from 'UI/Template/Table/types/TableEnums';
import SelectorColumns from 'UI/molecules/SelectorColumns/Index';
import columnsSchema from 'UI/Template/Table/schemas/columns.schema';
import BodyText from 'UI/atoms/BodyText';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';

interface ISelectorColumnsDropdown {
	grouping: any;
	groupingValue: string;
	selectorColumnsGroup: any;
	searchColumnsText?: string | undefined;
	searchedColumns?: any;
	secondGroupValues?: any;
}
export const SelectorColumnsDropdown = (props: ISelectorColumnsDropdown) => {
	const {
		grouping,
		groupingValue,
		selectorColumnsGroup,
		searchColumnsText,
		secondGroupValues,
	} = props;
	const { columnSelectorColumns, toggleSelectedAllColumn, enableBenchmarkData } = useTable();
	const { t } = useTranslation();
	const h = useHistory();
	const path = h.location.pathname;

	const dataTypeOrder = [
		EDataTypes.u,
		EDataTypes.ue,
		EDataTypes.p,
		EDataTypes.pe,
		EDataTypes.r,
		EDataTypes.bar,
	];

	const columns = columnsSchema.filter(
		(c: any) =>
			c[selectorColumnsGroup.columnsGroups[0]] === groupingValue &&
			secondGroupValues.includes(c[selectorColumnsGroup.columnsGroups[1]])
	);
	const orderedColumns = dataTypeOrder.map((dT) =>
		columns.filter((c) => c.dataType === dT)
	);

	// Columns groups by DataType
	const columnsByDataType: any = {};
	orderedColumns.map((dTArr) =>
		dTArr.map((c: any) => {
			const key = c.dataType;
			if (!c.isSaving || (c.isSaving && path.includes('benchmark'))) {
				if (!Object.keys(columnsByDataType).includes(c.dataType)) {
					columnsByDataType[key] = [c];
				} else {
					columnsByDataType[key].push(c);
				}
			}
		})
	);

	// Check if some "Select All" is selected
	let someAllSelected;
	for (const key in columnsByDataType) {
		const nMetricsByDataTypeNotIncluded = columnsByDataType[key]
			.map((c: any) => c.id)
			.filter((metric: any) => !columnSelectorColumns.includes(metric))
			.length;

		if (nMetricsByDataTypeNotIncluded === 0) someAllSelected = true;
	}

	const SelectAllColumnsIcon = ({
		dataType,
		allSelected,
		metricsByDataType,
	}: any) => {
		const [isShowTooltip, setIsShowTooltip] = useState(false);

		return (
			<>
				<span
					id={`all-columns-${dataType}`}
					onMouseEnter={() => setIsShowTooltip(true)}
					onMouseLeave={() => setIsShowTooltip(false)}
					className={`icon-dataType icon-icon-${dataType}${
						allSelected ? '-selected' : ''
					} ${
						allSelected ? 'text-dark-100' : 'text-dark-60'
					} font-22px cursor-pointer ml-2`}
					style={{ width: '22px' }}
					onClick={() => {
						toggleSelectedAllColumn(metricsByDataType);
					}}
				></span>
				<SimpleTooltip
					text={t(`tooltip-${dataType}`)}
					tooltipId={`all-columns-${dataType}`}
					tooltipOpen={isShowTooltip}
				/>
			</>
		);
	};

	return (
		<div
			className="pt-2 pb-2 pr-4 pl-4 bg-white-100"
			style={{
				borderBottom: '1px solid rgba(154, 154, 172, 0.7)',
				margin: '0 -15px',
			}}
		>
			{/* Select All */}
			{(!searchColumnsText && !path.includes('benchmark') && !enableBenchmarkData) && (
				<div className="d-flex flex-row justify-content-between align-items-center m-2 mt-3 mb-3">
					<div className="d-flex flex-row align-items-center">
						<BodyText
							copy={t(`common-select-all`)}
							color={someAllSelected ? '100' : '60'}
						/>
					</div>
					<div className="d-flex flex-row align-items-center">
						{Object.keys(columnsByDataType).map((dataType: any) => {
							const metricsByDataType = columnsByDataType[
								dataType
							].map((c: any) => c.id);
							const metricsNotIncluded = metricsByDataType.filter(
								(metric: any) =>
									!columnSelectorColumns.includes(metric)
							);
							const allSelected =
								metricsNotIncluded.length === 0 ? true : false;
							return (
								<SelectAllColumnsIcon
									dataType={dataType}
									allSelected={allSelected}
									metricsByDataType={metricsByDataType}
								/>
							);
						})}
					</div>
				</div>
			)}

			{/* -- */}

			<SelectorColumns
				columnsByDataType={columnsByDataType}
				secondGroupValues={secondGroupValues}
				grouping={grouping}
				groupingValue={groupingValue}
				selectorColumnsGroup={selectorColumnsGroup}
				searchColumnsText={searchColumnsText}
				enableBenchmarkData={enableBenchmarkData}
			/>
		</div>
	);
};
