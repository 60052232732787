import Axios from 'axios';
import { ICrudListResponse } from './Crud';
import Http from './Http';
import { handleError } from './utils';


type TChangePassword = {
  email: string,
  password: string,
  newPassword: string
}

export default class Users extends Http {

  constructor() {
    super("users")
  }

  async userById(id: string): Promise<ICrudListResponse> {
    return await this.get(`/${id}`);
  }

  async updateUser(id: string, data: any): Promise<ICrudListResponse> {
			return await this.patch(`/${id}`, data, this.requestConfig);
  }

  async changePassword(passwordReq: TChangePassword): Promise<void> {
    const { email, password, newPassword } = passwordReq;
		let result: any;
		try {
			result = await this.post(`/reset-password`, { email, password, newPassword }, this.requestConfig);
		} catch (err) {
			handleError(err);
		}
	}

  // async getLoggedUser(): Promise<any> {
  //   let result = { data: '' };
  //   try {
  //     result = await Axios.get(`/me`, this.requestConfig);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  //   return result.data;
  // }

  // getDetail(id: string) : Promise<any> {
  //   return crud.getDetail(id, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }

  // getList(search: object, pagination: object ) : Promise<any> {
  //   return crud.getList(search, pagination, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }

  // update(id: string, data: any): Promise<any> {
  //   return crud.update(id, data, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }

  // create(data: any): Promise<any> {
  //   return crud.create(data, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }

  // delete(id:string) : Promise<any> {
  //   return crud.deleteItem(id, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }

  // exportData(search: object): Promise<any> {
  //   return crud.exportData(search, {...this.requestConfig, baseURL: getRoleBasePath(this.backendHost, this.prefix, (this.role === 'regional') ) })
  // }
}
