import Crud, { ICrudListResponse } from "./Crud";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export default class Article extends Crud {
    constructor() {
        super("articles");
    }

    async metricsSingleArticle(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`/${articleId}/metrics`);
    }

    async metricsArticleByRef(refArticle: string): Promise<ICrudListResponse> {
        return await this.get(`/?refArticle=${refArticle}`);
    }

    async metricsArticleQRCode(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`/${articleId}/smartLabel`);
    }

    async allArticles(): Promise<ICrudListResponse> {
        return await this.get('/');
    }

    // async articlesByProject(queryString: RequestQueryBuilder): Promise<ICrudListResponse> {
    async articlesByProject(queryString: string): Promise<ICrudListResponse> {
        return await this.get(`?${queryString}`);
    }

    async articleOrigins(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`${articleId}/origins`);
    }

    async articleComposition(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`${articleId}/composition`);
    }

    async articleMaterialCertificates(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`${articleId}/materialCertificates`);
    }

    async articleSupplierCertificates(articleId: string): Promise<ICrudListResponse> {
        return await this.get(`${articleId}/supplierCertificates`);
    }

}