import React from 'react';
import { useTranslation } from 'react-i18next';
import { EDataTypes } from 'UI/Template/Table/types/TableEnums';
import FormatSubSupMetrics from 'utils/formatSubSupMetrics';

interface Props {
	indicator: string;
	metricUnit?: string;
	dataType?: string;
	deprived?: boolean;
	emitted?: boolean;
	saved?: boolean;
	consumed?: boolean;
	difference?: boolean;
	styles?: string;
	isRow?: boolean
}

function MetricUnit(props: Props) {
	const { t } = useTranslation();
	const {
		indicator,
		metricUnit,
		dataType,
		deprived,
		emitted,
		saved,
		consumed,
		difference,
		styles,
		isRow = false
	} = props;

	return (
		<>
			{(dataType === EDataTypes.u || dataType === EDataTypes.p) &&
				(indicator === 'waterUse' || indicator === 'water') && (
					<span className={styles}>
						m<sup>3</sup> water eq
					</span>
				)}
			{(dataType === EDataTypes.u || dataType === EDataTypes.p) &&
				indicator === 'climateChange' && (
					<span className={styles}>
						kg CO<sub>2</sub> eq
					</span>
				)}
			{(dataType === EDataTypes.u || dataType === EDataTypes.p) &&
				indicator === 'eutrophication' && (
					<span className={styles}>
						g phosphates eq
					</span>
				)}
			{(dataType === EDataTypes.u || dataType === EDataTypes.p) &&
				indicator === 'fossilFuels' && (
					<span className={styles}>
						MJ
					</span>
				)}
			{(dataType === EDataTypes.ue || dataType === EDataTypes.pe) &&
				metricUnit && (
					<span key={`${indicator}-key`} className={styles}>
						<FormatSubSupMetrics
							indicator={indicator}
							text={
								!isRow
								? t(metricUnit)
								: metricUnit === "ligthBulbs"
								? t("lca-integration-benchmark-bulb")
								: t(metricUnit)
							}
						/>
					</span>
				)}

			{dataType === EDataTypes.r && <span className={styles}>%</span>}
			{dataType === EDataTypes.bar && (
				<span className={styles}>{t('metric-unit-bar')}</span>
			)}
		</>
	);
}

export default MetricUnit;
