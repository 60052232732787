import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArticleMaterialInput from 'components/Common/ArticleMaterialInput/ArticleMaterialInput';

const ArticleFormMaterials = (props: any) => {
	const { t }: any = useTranslation();
	const [materials, setMaterials]: any = useState([]);
	const { data, setData } = props;

	useEffect(() => {
		let m = [...materials];
		if (data && data.materials) {
			m = [...m, ...data.materials];
		} else {
			m.push({ id: new Date().toISOString(), material: '', percentage: 0, weight: '' });
		}

		setMaterials([...m]);
	}, []);

	const updateData = () => {
		const n = { ...data };
		n.materials = [...materials];
		setData(n);
	};

	const onClickAdd = () => {
		setMaterials([
			...materials,
			{ id: new Date().toISOString(), material: '', percentage: 0, weight: '' }
		]);
		updateData();
	};

	const onClickRemove = (index: any) => {
		materials.splice(index, 1);
		setMaterials([...materials]);
		updateData();
	};

	const onInputChange = (index: any, key: string, value: any) => {
		const m = materials[index];
		m[key] = value;
		setMaterials([...materials]);
		updateData();
	};

	return (
		<div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
			<div
				style={{
					height: '100%',
					maxHeight: '382px',
					overflowY: 'auto',
					overflowX: 'hidden'
				}}>
				{materials.map((m: any, i: any) => {
					return (
						<div className="mt-3" key={i}>
							<ArticleMaterialInput
								showLabels={i == 0}
								last={
									Object.keys(materials).length > 4 &&
									(i == Object.keys(materials).length - 1 ||
										i == Object.keys(materials).length - 2)
								}
								{...m}
								onClickRemove={() => {
									onClickRemove(i);
								}}
								onInputChange={(key: any, value: any) => {
									onInputChange(i, key, value);
								}}
							/>
						</div>
					);
				})}
			</div>
			<div className="border-top align-self-end w-100 mt-3">
				<div
					className="cursor-pointer text-warning d-flex flex-row align-items-center p-3"
					onClick={() => {
						onClickAdd();
					}}>
					<span className="icon-icon-add" style={{ fontSize: '14px' }}></span>
					<span className="ml-3 text-uppercase" style={{ fontSize: '14px' }}>
						{t('forms-newMaterial')}
					</span>
				</div>
			</div>
		</div>
	);
};
export default ArticleFormMaterials;
