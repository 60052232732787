import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ISelectorColumns } from "../SelectorColumns/SelectorColumnsTypes";
import { SelectorColumnsSchema } from "../SelectorColumns/SelectorColumns.schema";
import { useProjects } from "contexts/projectsContext";
import { useTable } from "contexts/tableContext";

export const SelectorCategory = () => {
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-row flex-wrap flex-grow-1 align-content-start bg-gray-01 text-dark-60 font-sfpro-regular font-14px text-center rounded-08 p-1" style={{ maxHeight: '40px' }}>
			<div className="d-flex justify-content-center align-items-center flex-grow-1 px-3 bg-white rounded-08 shadow-02 font-sfpro-medium text-dark-100 font-sfpro-medium" style={{ height: '32px' }}>{t("articles")}</div>
		</div>
	);
}

export const SelectorTypeView = () => {
	const { t } = useTranslation();
	const { productSelected } = useProjects();
	const { setDataGroupSelectedColumnSelector, dataGroupSelectedColumnSelector, enableBenchmarkData } = useTable();

	const typeSelectedStyle = "selected-type-modal rounded-08 shadow-02 font-sfpro-medium text-dark-100";
	const typeViewSelected: ISelectorColumns = SelectorColumnsSchema[productSelected];

	return (
		<div className="cursor-pointer d-flex flex-row align-content-start bg-gray-01 font-14px text-center rounded-08 p-1 font-sfpro-regular text-dark-60" style={{ maxHeight: '40px' }}>
			{Object.entries(typeViewSelected).map(([key, item]) => (
				<div
					key={item.id}
					className={`${dataGroupSelectedColumnSelector === item.id && typeSelectedStyle} d-flex justify-content-center align-items-center flex-grow-1 px-3`}
					style={{
						height: '32px',
					}}
					onClick={() => setDataGroupSelectedColumnSelector(item.id)}
					id={item.id}
				>
					{t(`selector-view-${key}`)}
				</div>
			))}
		</div>
	);
}

interface IProps {
	menuItems: string[],
	itemSelected: string,
	handleSelectItem: any
}

export const SelectorHorizontal = (props: IProps) => {
	const { menuItems, itemSelected, handleSelectItem } = props;
	const { t } = useTranslation();

	const typeSelectedStyle = "bg-white rounded-08 shadow-02 font-sfpro-medium text-dark-100";

	return (
		<div className="cursor-pointer d-flex flex-row flex-wrap flex-grow-1 align-content-start bg-gray-01 text-dark-60 font-sfpro-regular font-14px text-center rounded-08 p-1" style={{ maxHeight: '40px' }}>
			{menuItems?.map((item: string) => (
				<div
					className={`${item === itemSelected && typeSelectedStyle} d-flex justify-content-center align-items-center flex-grow-1 px-3`}
					style={{ height: '32px' }}
					onClick={() => handleSelectItem(item)}
				>
					{t(`menu-horizontal-${item}`)}
				</div>
			))}
		</div>
	);
}