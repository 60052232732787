import React from 'react'; 
import { useTranslation } from 'react-i18next';

interface IProjectResultTableHeader {
    title: string, 
	removeBorderBottom?: boolean
}

const ProjectResultTableHeader = (props: IProjectResultTableHeader) => {
	const { t } = useTranslation();
	const { title, removeBorderBottom } = props; 
	
	const handleDownloadCertificates = () => {
		window.open('https://drive.google.com/file/d/1989zLnuwZk4_mAjd2Lg5y71ny74m2T27/view');
	}

	const handleDownloadZip = () => {
		// useDownloadQrZip(projectSelected?.id, `QR_Project_${projectSelected?.name}.zip`);
    }
	
	return (
		<div className={`w-100 d-flex flex-row justify-content-between align-items-center p-3 ${removeBorderBottom ? "" : "border-bottom"}`}>
			<div>
				<h3 className="text-dark-100 pb-0 mb-0 letter-spacing-normal font-sfpro-regular font-16px">{t(`${title}`)}</h3>
			</div>
				{/* TO DO: ADD FUNCTIONALITY */}
			<div className="d-flex flex-row align-items-center">
				{title === 'sustainabilityIndex' && <div className="d-flex flex-row align-items-center">
					<span className="cursor-pointer font-sfpro-regular text-dark-100 font-14px mr-2" onClick={handleDownloadCertificates}>{t('download-certificates')}</span>
					<span className="icon-icon-pdf cursor-pointer text-dark-100 font-22px" onClick={handleDownloadCertificates}></span>
				</div>}
				{title === 'dataDigitization' && <div className="d-flex flex-row align-items-center">
					<span className="cursor-pointer font-sfpro-regular text-dark-100 font-14px mr-3" onClick={handleDownloadZip}>Download all QR</span>
					<span className="icon-icon-zip cursor-pointer text-dark-100 font-22px" onClick={handleDownloadZip}></span>
				</div>}
				{/* <span
					className="icon-icon-download cursor-pointer text-dark-100"
					style={{ fontSize: '22px' }}></span>
				<span
					className="icon-icon-share cursor-pointer text-dark-100 ml-2"
					style={{ fontSize: '22px' }}></span> */}
			</div>
		</div>
	);
};
export default ProjectResultTableHeader;