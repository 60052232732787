import Crud, { ICrudListResponse } from "./Crud";


export default class ArticleCategories extends Crud {
    constructor() {
        super("articleCategory");
    }

    async getAll(): Promise<ICrudListResponse> {
        return await this.get('/')
    }
    
}
