import React, { useContext, useEffect, useState } from "react";
import ProjectHttp from "services/http/Project";
import EquivalencesHttp from "services/http/Equivalences";
import ArticleCategoriesHttp from "services/http/ArticleCategories";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { useHistory, useLocation } from "react-router-dom";
import RawMaterialsCategoriesHttp from "services/http/RawMaterialsCategories";
import FiltersHttp from "services/http/Filters";
import { useValidateSmartLabel } from "hooks/useValidateSmartLabel.hook";

const Project = new ProjectHttp();
const Equivalences = new EquivalencesHttp();
const ArticleCategories = new ArticleCategoriesHttp();
const RawMaterialsCategories = new RawMaterialsCategoriesHttp();
const Filters = new FiltersHttp()

export const ProjectsContext = React.createContext<any>(null);
export const useProjects = () => useContext(ProjectsContext);

export const ProjectsContextProvider = ({ children }: any): any => {
	const { validate } = useValidateSmartLabel()
	const h = useHistory();

	const [allProjects, setAllProjects]: any = useState();
	const [projectSelected, setProjectSelected]: any = useState();
	const [equivalencesList, setEquivalencesList]: any = useState();
	const [allArticleCategories, setAllArticleCategories]: any = useState();
	const [allRawMaterialsCategories, setAllRawMaterialsCategories]: any = useState();
	const [openModalSaveChanges, setOpenModalSaveChanges] = useState({ customView: false, open: false});
	const [filtersCategoriesByProject, setFiltersCategoriesByProject]: any = useState();
	const location = useLocation()
	const [route, setRoute] = useState({
		to: '',
		from: ''
	});
	const [accessType, setAccessType]: any = useState("");
	const [showHeader, setShowHeader] = useState<boolean>(false);

	useEffect(()=> {
		const section = location.pathname.split('/')[1]
		const previousSection = route.to.split('/')[1]

		if (section !== 'articles') setRoute({
			from: route.to,
			to: location.pathname
		})

	}, [location])

	const [isDashboard, setIsDashboard] = useState<boolean>(false);

	const wPath = h.location.pathname;
	const pathArray = wPath?.split("/");
	const pathProduct = pathArray[2];

	const productSelected = pathProduct || "lca";

	const getAllProjects = () => {
		const request = Project.allProjects('&sort=updatedAt,DESC');
		request.then((res) => {
			const projects = res.data;
			setAllProjects(projects);

			const defaultProject = res.data[0];
			setProjectSelected(defaultProject);
		});
	};

	const getEquivalencesData = () => {
		const queryString = RequestQueryBuilder.create({
			fields: ["name", "category", "keyName", "amount"],
			resetCache: true,
		}).query();

		const request = Equivalences.getAll(queryString);
		request.then((res) => {
			const list = res.data;
			setEquivalencesList(list);
		});
	};

	const getFiltersCategories = () => {
		const request = Filters.categoriesByProject(projectSelected?.id);
		request.then((res) => {
			const list = res.data;
			setFiltersCategoriesByProject(list);
		})
			.catch(err => console.log({ err }));
	};

	useEffect(() => {
		validate(() => {
			getAllProjects();
			getEquivalencesData();
		})
	}, []);


	useEffect(() => {
		if (projectSelected) getFiltersCategories();
	}, [projectSelected]);


	const value = {
		allProjects,
		projectSelected,
		setProjectSelected,
		equivalencesList,
		productSelected,
		allArticleCategories,
		isDashboard,
		setIsDashboard,
		allRawMaterialsCategories,
		filtersCategoriesByProject,
		route,
		openModalSaveChanges,
		setOpenModalSaveChanges,
		accessType,
		setAccessType,
		showHeader,
		setShowHeader
	};

	return (
		<ProjectsContext.Provider value={value}>
			{children}
		</ProjectsContext.Provider>
	);
};
