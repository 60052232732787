import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Tabs.scss';

const HeaderComponent = (props: any) => {
	const { selected, tab, setSelectedTab } = props;

	let label = tab.header;
	let number = tab.headerExtra;
	useEffect(() => {
		label = tab.header;
		number = tab.headerExtra;
	}, [tab]);

	const onClick = () => {
		setSelectedTab(tab);
	};

	return (
		<div
			className={`mr-2 pl-2 pr-2 pt-1 pb-1 d-flex flex-row align-items-center cursor-pointer ${
				selected ? 'font-sfpro-medium selected' : 'font-sfpro-regular'
			}`}
			onClick={() => {
				onClick();
			}}>
			<span className="pb-0" style={{ fontSize: '16px' }}>
				{label}
			</span>
			{number ? (
				<span className="ml-1 d-flex justify-content-center align-items-center number">
					{number}
				</span>
			) : (
				''
			)}
		</div>
	);
};

const Tabs = (props: any) => {
	const { t } = useTranslation();
	const { tabs } = props;
	const [headerTabs, setHeaderTabs]: any = useState([]);
	const [selectedTabIndex, setSelectedTabIndex]: any = useState(0);

	const loadTabs = () => {
		if (tabs) {
			const t: any = [];
			tabs.forEach((tab: any, index: number) => {
				const selected = selectedTabIndex == index;
				const n = (
					<HeaderComponent
						key={index}
						selected={selected}
						tab={tab}
						setSelectedTab={() => { setSelectedTabIndex(index) }}
					/>
				);
				t.push(n);
				if (selected) {
					setSelectedTabIndex(index);
				}
			});
			setHeaderTabs(t);
		}
	};

	useEffect(() => {
		loadTabs();
	}, []);

	useEffect(() => {
		loadTabs();
	}, [selectedTabIndex, tabs]);

	return (
		<div className="w-100 tabs">
			<div
				className="w-100 d-flex flex-row tab-selector"
				style={{ background: '#F0F0F2', borderRadius: '10px', padding: '3px' }}>
				{headerTabs}
			</div>
			<div className="w-100">{tabs[selectedTabIndex] ? tabs[selectedTabIndex].content : ''}</div>
		</div>
	);
};
export default Tabs;
