import TUser from "types/TUser";
import jwt_decode from "jwt-decode";

enum EKeys {
	accessToken = "accessToken",
	refreshToken = 'refreshToken',
	rememberMe = 'rememberMe',
	loginAt = "loginAt",
}

type TSession = {
	accessToken: string | null;
	refreshToken: string | null;
	rememberMe: boolean | null;
	loginAt?: Date | null,
};

const KEYS: EKeys[] = [EKeys.accessToken, EKeys.refreshToken, EKeys.rememberMe];

export class Auth {
	public saveSession(session: TSession): void {
		this.setItem(EKeys.accessToken, session.accessToken);
		this.setItem(EKeys.refreshToken, session.refreshToken);
		this.setItem(EKeys.loginAt, new Date().toString());
		this.setItem(EKeys.rememberMe, String(session.rememberMe));
	}

	public removeSession(): void {
		KEYS.forEach((key: EKeys) => {
			this.removeItem(key);
		})
	}

	public getSession(): TSession {
		let loginAt: any = this.getItem(EKeys.loginAt);
		if (loginAt) {
			loginAt = new Date(loginAt);
		}

		return {
			accessToken: this.getItem(EKeys.accessToken),
			refreshToken: this.getItem(EKeys.refreshToken),
			loginAt: loginAt,
			rememberMe: this.getItem(EKeys.rememberMe) === 'true',
		};
	}

	public decodeAccessToken(token: string): TUser {
		return this.decodeJwt(token);
	}

	public decodeJwt(token: string): any {
		return jwt_decode(token);
	}

	public getSessionUser(): TUser | null {
		const accessToken = this.getItem(EKeys.accessToken);
		let ret = null;
		if (accessToken) {
			ret = this.decodeAccessToken(accessToken);
		}
		return ret;
	}

	public isTokenLapsed(session: TSession): boolean {
		return !this.isTokenAlive(session);
	}

	public isTokenAlive(session: TSession): boolean {
		const user = this.getSessionUser();
		let ret = false;
		if (user) {
			if (session.accessToken && session.refreshToken && session.loginAt) {
				const now = new Date();
				const lapseTime = session.loginAt;
		
				if (session.loginAt && user.exp && lapseTime) {
					lapseTime.setSeconds(lapseTime.getSeconds() + user.exp);
					if (now < lapseTime || session.rememberMe) {
						ret = true; 
					}
				}	
			}
		}
		return ret;
	}

	private setItem(key: EKeys, value: string | null) {
		localStorage.setItem(`auth.${key}`, String(value));
	}

	public getItem(key: EKeys): string | null {
		return localStorage.getItem(`auth.${key}`);
	}

	private removeItem(key: EKeys) {
		localStorage.removeItem(`auth.${key}`);
	}
}

export default Auth;
