import { handleError } from './utils';
import { languageDefault } from '../../utils/Language';
import Http from './Http';

type TLogin = {
	accessToken: string;
	refreshToken: string;
	userLanguage?: string;
};

type TRefreshToken = {
	accessToken: string,
}

export default class Auth extends Http {
	constructor() {
		super("auth");
	}

	async login(email: string, password: string, rememberMe: boolean): Promise<TLogin> {
		let result: any;
		try {
			result = await this.post(`/access`, { email, password, rememberMe }, this.requestConfig);
		} catch (err) {
			handleError(err);
		}
		const userLanguage = result.data.userLanguage || languageDefault.acronym;
		return {
			...result.data,
			userLanguage
		};
	}

	async refreshToken(refreshTokenString: string): Promise<TRefreshToken> {
		let result: any;
		try {
			result = await this.post(
				`/refresh`,
				{ refreshToken: refreshTokenString },
				this.requestConfig
			);
		} catch (err) {
			handleError(err);
		}

		const { accessToken } = result.data;

		return {
			accessToken
		};
	}

	async resetPassword(email: string) {
		try {
			return await this.get(`/forgot-password/${email}`, this.requestConfig);
		} catch (err) {
			handleError(err);
		}
	}
}
