import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, InputGroupAddon, FormGroup, FormFeedback } from 'reactstrap';

import './Input.scss';

export interface InputFieldProps { // to do
	[key: string]: any;
}

const InputField = (props: any) => {
	const { t } = useTranslation();
	const {
		type,
		name,
		bsSize,
		state,
		valid,
		invalid,
		tag,
		innerRef,
		plaintext,
		addon,
		className,
		defaultValue,
		placeholder,
		value,
		onChange,
		autoFocus,
		labelText,
		labelClassName,
		icon,
		autoComplete,
		feedbackMessage,
		id = '',
		children,
		divClassName
	} = props;

	const handleChange = (value: any) => {
		if (onChange) onChange(value);
	};

	return (
		<div className={`d-flex flex-column ${divClassName ? divClassName : ''}`}>
			{labelText && <label className={`input-label ${labelClassName}`}>{t(labelText)}</label>}
			<div className="position-relative mr-2">
				{icon && <div className={`icon-class ${icon}`} />}
				<Input
					type={type}
					name={name}
					onChange={(e) => handleChange(e)}
					defaultValue={defaultValue}
					value={value}
					placeholder={placeholder}
					className={`${className} ${icon && 'form-control-icon'}`}
					autoFocus={autoFocus}
					innerRef={innerRef}
					bsSize={bsSize}
					state={state}
					valid={valid}
					invalid={invalid}
					tag={tag}
					plaintext={plaintext}
					addon={addon}
					autoComplete={autoComplete}
					id={`${id}-${placeholder}`}
				/>
				{invalid ? <FormFeedback>{feedbackMessage}</FormFeedback> : ''}
				{children}
			</div>
		</div>
	);
};

export default InputField;
