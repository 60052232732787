const ImageExists = async (image_url:any) =>{
    try{
        const response = await fetch(image_url, { method: "GET" })

        if( response.ok ) return Promise.resolve(true)
        return Promise.resolve(false)

    }catch(err){
        console.log(err)
        return Promise.resolve(true);
    }
}

export default ImageExists