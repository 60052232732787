/* eslint-disable */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTable } from 'contexts/tableContext';
import TableMetrics from "UI/Template/Table";
import { ModalLateral } from "UI/organism/ModalLateral/Index";
import { ModalViewAction } from "UI/organism/ModalViewAction";
import { SelectorColumnsModal } from "UI/molecules/SelectorColumnsModal/Index";

const DiagnosisArticles = () => {
	const { handleCloseColumnSelectorModal, modalViewActionSelected, openColumnSelectorModal } = useTable();

	return (
        <>
        <TableMetrics />
            {openColumnSelectorModal && <ModalLateral handleClose={handleCloseColumnSelectorModal}>
                <SelectorColumnsModal title={'Columns'} handleClose={handleCloseColumnSelectorModal} />
            </ModalLateral>}
        {modalViewActionSelected && <ModalViewAction />}
   </>
	);
};

export default DiagnosisArticles;
