export const customData = {
    'AD-AF-U_IS': 100,
    'Eutr-AF-U_IS': 100,
    'GW-AF-U_IS': 100,
    'WS-AF-U_IS': 100,
    'WS-AF-UE_SD': 100,
    'GW-AF-UE_SD': 100,
    'Eutr-AF-UE_SD': 100,
    'AD-AF-UE_SD': 100,
    'WS-AF-PE_SD': 100,
    'GW-AF-PE_SD': 100,
    'Eutr-AF-PE_SD': 100,
    'AD-AF-PE_SD': 100,
    'WS-RM-U_IS': 100,
    'GW-RM-U_IS': 100,
    'Eutr-RM-U_IS': 100,
    'AD-RM-U_IS': 100,
    'WS-RM-UE_SD': 100,
    'GW-RM-UE_SD': 100,
    'Eutr-RM-UE_SD': 100,
    'AD-RM-UE_SD': 100,
    'WS-RM-PE_SD': 100,
    'GW-RM-PE_SD': 100,
    'Eutr-RM-PE_SD': 100,
    'AD-RM-PE_SD': 100,
    'WS-M-U_IS': 100,
    'GW-M-U_IS': 100,
    'Eutr-M-U_IS': 100,
    'AD-M-U_IS': 100,
    'WS-M-UE_SD': 100,
    'GW-M-UE_SD': 100,
    'Eutr-M-UE_SD': 100,
    'AD-M-UE_SD': 100,
    'WS-M-PE_SD': 100,
    'GW-M-PE_SD': 100,
    'Eutr-M-PE_SD': 100,
    'AD-M-PE_SD': 100,
    'WS-T-U_IS': 100,
    'GW-T-U_IS': 100,
    'Eutr-T-U_IS': 100,
    'AD-T-U_IS': 100,
    'WS-T-UE_SD': 100,
    'GW-T-UE_SD': 100,
    'Eutr-T-UE_SD': 100,
    'AD-T-UE_SD': 100,
    'WS-T-PE_SD': 100,
    'GW-T-PE_SD': 100,
    'Eutr-T-PE_SD': 100,
    'AD-T-PE_SD': 100,
    'WS-D-U_IS': 100,
    'GW-D-U_IS': 100,
    'Eutr-D-U_IS': 100,
    'AD-D-U_IS': 100,
    'WS-D-UE_SD': 100,
    'GW-D-UE_SD': 100,
    'Eutr-D-UE_SD': 100,
    'AD-D-UE_SD': 100,
    'WS-D-PE_SD': 100,
    'GW-D-PE_SD': 100,
    'Eutr-D-PE_SD': 100,
    'AD-D-PE_SD': 100,
    'WS-C-U_IS': 100,
    'GW-C-U_IS': 100,
    'Eutr-C-U_IS': 100,
    'AD-C-U_IS': 100,
    'WS-C-UE_SD': 100,
    'GW-C-UE_SD': 100,
    'Eutr-C-UE_SD': 100,
    'AD-C-UE_SD': 100,
    'WS-C-PE_SD': 100,
    'GW-C-PE_SD': 100,
    'Eutr-C-PE_SD': 100,
    'AD-C-PE_SD': 100,
    'WS-Total': 100,
    'GW-Total': 100,
    'Eutr-Total': 100,
    'AD-Total': 100,
    'WS-Total-UE_SD': 100,
    'GW-Total-UE_SD': 100,
    'Eutr-Total-UE_SD': 100,
    'AD-Total-UE_SD': 100,
    'WS-Total-PE_SD': 100,
    'GW-Total-PE_SD': 100,
    'Eutr-Total-PE_SD': 100,
    'AD-Total-PE_SD': 100,
    'WS-Total-U_IS': 100,
    'GW-Total-U_IS': 100,
    'Eutr-Total-U_IS': 100,
    'AD-Total-U_IS': 100,
}