import React from "react";
import { useTranslation } from 'react-i18next';

function RightsReserved() {
    const { t } = useTranslation();

    const url = 'https://bcome.biz/privacy-policy/'
    const actualYear = new Date().getFullYear();

    return (
        <div className='pt-3 fixed-bottom p-3 d-flex justify-content-between font-sfpro-regular text-dark-100 font-14px' style={{ width: '100vw' }}>
            <div className="d-flex">
                <div className='text-center pb-1'>{t('bcome-rights-reserved', { year: actualYear })}</div>
                <div className='text-center'>
                    <a className="text-overflow-ellipsis overflow-hidden" target="_blank" href={url}>
                        <span style={{ margin: "0px 10px" }}>{t(`privacy-policy`)}</span>
                        {/* <span className="icon-icon-new-window m-1 text-yellow"></span> */}
                    </a>
                </div>
            </div>
            <div>
                <a className="text-overflow-ellipsis overflow-hidden" href={'#'}>
                    <span style={{ margin: "0px 10px" }}>Subscribe</span>
                </a>
            </div>
        </div>
    )
}

export default RightsReserved;