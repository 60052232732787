import { useProjects } from 'contexts/projectsContext';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MetricNumber from 'UI/atoms/MetricNumber';
import MetricUnit from 'UI/atoms/MetricUnit';
import ProgressBarDouble from 'UI/atoms/ProgressBarDouble/Index';
import {
	EDataTypes,
	EIndicators,
	EMetricUnitTypes,
} from '../../../Template/Table/types/TableEnums';
import { dataGroups } from 'UI/Template/Table/types/DataGroups';
import { isDivOverflow } from 'utils/checkIfTextOverflow';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { calculateEquivalences } from 'utils/utils';

const LCATableOnePage = (props: any) => {
	const { data, equivalences } = props;
	const { equivalencesList } = useProjects();
	const { t } = useTranslation();

	const indicators = [
		{
			name: 'Water',
			indicator: EIndicators.waterUse,
			dataType: EDataTypes.u,
			color: '#015594',
			impactEq: equivalences.waterKeyName,
			metricUnit: EMetricUnitTypes.bottles,
			metricNumber: calculateEquivalences(data.Water, equivalencesList, 'bottles'),
			savingsEq: equivalences.waterKeyName,
			metricUnitSavings: EMetricUnitTypes.bottles,
			metricNumberSavings: calculateEquivalences(data.Savings_Water, equivalencesList, 'bottles'),
			iconEqImpact: dataGroups.water.iconEqImpactU,
			iconEqBenchmark: dataGroups.water.iconEqImpactU,
		},
		{
			name: 'ClimateChange',
			indicator: EIndicators.climateChange,
			dataType: EDataTypes.u,
			color: '#424242',
			impactEq: equivalences.climateChangeKeyName,
			metricUnit: EMetricUnitTypes.lightBulbs,
			metricNumber: calculateEquivalences(data.ClimateChange, equivalencesList, 'ligthBulbs'),
			savingsEq: equivalences.climateChangeKeyName,
			metricUnitSavings: EMetricUnitTypes.lightBulbs,
			metricNumberSavings: calculateEquivalences(data.Savings_ClimateChange, equivalencesList, 'ligthBulbs'),
			iconEqImpact: dataGroups.climateChange.iconEqImpactU,
			iconEqBenchmark: dataGroups.climateChange.iconEqImpactU,
		},
		{
			name: 'Eutrophication',
			indicator: EIndicators.eutrophication,
			dataType: EDataTypes.u,
			color: '#887D36',
			impactEq: equivalences.eutroKeyName,
			metricUnit: EMetricUnitTypes.washingMachine,
			metricNumber: calculateEquivalences(data.Eutrophication, equivalencesList, 'washingMachine'),
			savingsEq: equivalences.eutroKeyName,
			metricUnitSavings: EMetricUnitTypes.washingMachine,
			metricNumberSavings: calculateEquivalences(data.Savings_Eutrophication, equivalencesList, 'washingMachine'),
			iconEqImpact: dataGroups.eutrophication.iconEqImpactU,
			iconEqBenchmark: dataGroups.eutrophication.iconEqImpactU,
		},
		{
			name: 'FossilFuels',
			indicator: EIndicators.fossilFuels,
			dataType: EDataTypes.u,
			color: '#1F4A53',
			impactEq: equivalences.fossilFuelsKeyName,
			metricUnit: EMetricUnitTypes.carTravelling,
			metricNumber: calculateEquivalences(data.FossilFuels, equivalencesList, 'carTravelling'),
			savingsEq: equivalences.fossilFuelsSavingsKeyName,
			metricUnitSavings: EMetricUnitTypes.carTravelling,
			metricNumberSavings: calculateEquivalences(data.Savings_FossilFuels, equivalencesList, 'carTravelling'),
			iconEqImpact: dataGroups.fossilFuels.iconEqImpactU,
			iconEqBenchmark: dataGroups.fossilFuels.iconEqImpactU,
		},
	];

	const columnHeaders = [
		'', // column 1
		'onePage-overview-title-total-impacts', // column 2
		'onePage-overview-title-total-impacts-eq', // column 3
		'onePage-overview-title-total-savings', // column 4
		'onePage-overview-title-total-savings-eq', // column 5
	];

	const HeaderTD = ({ text }: { text: string }) => {
		return (
			<td className="text-overflow-ellipsis overflow-hidden pr-5">
				{text}
			</td>
		);
	};

	const HeaderRow = () => (
		<tr
			className="text-black-60 font-sfpro-regular font-12px"
			style={{ height: '45px' }}
		>
			{columnHeaders.map((h: string) => (
				<HeaderTD text={t(h)} />
			))}
		</tr>
	);

	const IndicatorNameTD = ({ text }: { text: string }) => {
		return (
			<td
				className="font-sfpro-regular text-dark-60 font-12px pr-5"
				style={{ maxWidth: '100px' }}
			>
				{text}
			</td>
		);
	};

	interface ImpactTDProps {
		dataType: EDataTypes;
		indicator: string;
		name: string;
		value: number;
	}
	const ImpactTD = ({ dataType, indicator, name, value }: ImpactTDProps) => {
		const [isTextOverflowed, setIsTextOverflowed] = useState(false);
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);
		const ref = useRef<any>(null);
		const tooltipId = `LCATable-impactTD-${indicator}`;

		const compareMetricTextSize = () => {
			const isOverflow = isDivOverflow(ref);
			setIsTextOverflowed(isOverflow);
		};

		// compare once and add resize listener on "componentDidMount"
		useEffect(() => {
			compareMetricTextSize();
			window.addEventListener('resize', compareMetricTextSize);
		}, []);

		// remove resize listener again on "componentWillUnmount"
		useEffect(
			() => () => {
				window.removeEventListener('resize', compareMetricTextSize);
			},
			[]
		);

		const textMetricUnitTooltip = () => (
			<MetricUnit
				indicator={indicator}
				//metricUnit={metricUnit}
				dataType={dataType}
				styles={'font-sfpro-regular text-dark-60 font-12px'}
			/>
		);

		return (
			<td
				ref={ref}
				id={tooltipId}
				className="pr-5 font-sfpro-regular text-overflow-ellipsis overflow-hidden w-25"
				style={{ maxWidth: '150px' }}
				onMouseEnter={() => setIsTooltipOpen(true)}
				onMouseLeave={() => setIsTooltipOpen(false)}
			>
				<MetricNumber
					id={name}
					value={value}
					dataType={dataType}
					styles="text-dark-100"
				/>
				<MetricUnit
					indicator={indicator}
					dataType={dataType}
					styles={
						'pl-1 text-overflow-ellipsis overflow-hidden text-dark-60'
					}
				/>
				{isTextOverflowed && (
					<SimpleTooltip
						placement="bottom-end"
						JSXElement={textMetricUnitTooltip}
						tooltipId={tooltipId}
						tooltipOpen={isTooltipOpen}
					/>
				)}
			</td>
		);
	};

	interface SavingsTDProps {
		color: string;
		absolutValue: number;
		relativeValue: number;
		name: string;
		dataType: EDataTypes;
		metricUnit: EMetricUnitTypes;
		indicator: EIndicators;
	}

	const SavingsTD = ({
		color,
		absolutValue,
		relativeValue,
		name,
		dataType,
		metricUnit,
		indicator,
	}: SavingsTDProps) => {
		return (
			<td
				className="pr-3 text-overflow-ellipsis w-25"
				style={{ maxWidth: '140px' }}
			>
				<div className="d-flex flex-row">
					<div style={{ width: '200px' }}>
						<ProgressBarDouble
							color={color}
							value={relativeValue}
							absolute={absolutValue}
							indicator={indicator}
							dataType={dataType}
							metricUnit={metricUnit}
						/>
					</div>
					<div style={{ minWidth: '40px' }}>
						<MetricNumber
							id={name}
							value={relativeValue * 100}
							dataType={EDataTypes.r}
							styles="text-dark-100"
							isInteger
						/>
					</div>
				</div>
			</td>
		);
	};

	interface EquivalenceTDProps {
		name: string;
		value: number;
		icon?: string;
	}

	const EquivalenceTD = ({ name, value, icon }: EquivalenceTDProps) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);
		const tooltipId = `LCATable-equivalenceTD-${icon}`;
		const extraStyles = icon === 'icon-icon-bottle' ? 'ml-1 mr-1' : '';
		return (
			<td
				className="text-overflow-ellipsis overflow-hidden"
				style={{
					width: '15%',
					maxWidth: '100px',
				}}
			>
				<div className="d-flex align-items-center">
					<span
						id={tooltipId}
						onMouseEnter={() => setIsTooltipOpen(true)}
						onMouseLeave={() => setIsTooltipOpen(false)}
						className={`${icon} font-20px pr-3 text-dark-60 ${extraStyles}`}
					></span>
					<MetricNumber
						id={name}
						value={value}
						dataType={EDataTypes.pe}
						styles="text-dark-100"
						isInteger
					/>
					<SimpleTooltip
						text={t(`dash-LCATable-${icon}`)}
						tooltipId={tooltipId}
						tooltipOpen={isTooltipOpen}
					/>
				</div>
			</td>
		);
	};

	const IndicatorRow = ({ i }: any) => {
		const impactValue = data[i.name];
		const equivalenceImpactValue = i.metricNumber;
		const savingsRelativeValue = data[`savings_${i.name}_relative`];
		const savingsAbsoluteValue = data[`savings_${i.name}_absolut`];
		const savingsEquivaleceValue = i.metricNumberSavings;

		return (
			<tr
				style={{
					borderBottom: '1px solid #D6D6D7',
					height: '40px',
				}}
			>
				<IndicatorNameTD text={t(`dash-${i.name}`)} />
				<ImpactTD
					name={i.name}
					value={impactValue}
					indicator={i.indicator}
					dataType={i.dataType}
				/>
				<EquivalenceTD
					name={i.name}
					icon={i.iconEqImpact}
					value={equivalenceImpactValue}
				/>
				<SavingsTD
					name={i.name}
					color={i.color}
					indicator={i.indicator}
					metricUnit={i.metricUnit}
					dataType={i.dataType}
					absolutValue={savingsAbsoluteValue}
					relativeValue={savingsRelativeValue}
				/>

				<EquivalenceTD
					name={i.name}
					icon={i.iconEqBenchmark}
					value={savingsEquivaleceValue}
				/>
			</tr>
		);
	};

	const indicatorsRows: JSX.Element[] = [];

	indicators.forEach((i) => {
		indicatorsRows.push(<IndicatorRow i={i} />);
	});
	const urlMethodology =
		'https://drive.google.com/file/d/1xWG9dkKwMBR_Wh3xwSMLsThzhQrJbE3l/view';

	return (
		<>
			<table>
				<thead>
					<HeaderRow />
				</thead>
				<tbody>{indicatorsRows}</tbody>
			</table>
			<div className="pt-3 text-dark-60 font-sfpro-regular font-12px">
				*Comparison between an article and its{' '}
				<a href={urlMethodology} target="_blank">
					industry standard
				</a>
			</div>
		</>
	);
};

export default LCATableOnePage;
