import React, { useState, useEffect } from "react";
import ItemCheck from "../ItemCheck/Index";
import { useProjects } from "contexts/projectsContext";
import { useTable } from "contexts/tableContext";
import { useTranslation } from "react-i18next";


export const SelectorFiltersDropdown =  ({dataGroup, filterGroup, searchText}: any) => {
	const { setFilter, filtersModified } = useTable();
	const [ showAll, setShowAll ] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleApplySelection = (id:any) => {
		if (filtersModified[filterGroup]?.includes(id)) {
            setFilter(filterGroup,(filtersModified[filterGroup].filter((x:string) => x !== id)));
        } else {
            setFilter(filterGroup,([...filtersModified[filterGroup], id]));
		}
	}	


	const FiltersNotFound = () => (
		<div className="d-flex align-items-center justify-content-center mt-3 font-sfpro-regular font-12px text-dark-60">
			{t('filters-not-found')}
		</div>
	)

	return (
		<div className="pt-2 pb-4 pr-4 pl-4 bg-white-100" style={{ borderBottom: "1px solid rgba(154, 154, 172, 0.7)", margin: "0 -15px" }}>
			{dataGroup.length === 0 && <FiltersNotFound />}
			{dataGroup?.map((cat: any, i: number) => {
				const isSelected = cat.id ? filtersModified[filterGroup].includes(cat.id) : filtersModified[filterGroup].includes(cat.name) ;
				if (i < 5 && !showAll) {
					return (
						<div className="m-3" style={{ flex: '1 0 50%' }}>
							<ItemCheck id={cat.id} name={cat.name} item={cat} handleSelect={() => cat.id ? handleApplySelection(cat.id) : handleApplySelection(cat.name)} 
							isSelected={isSelected} searchText={searchText} />
						</div>
					)
				} 
				if (showAll) {
					return (
						<div className="m-3" style={{ flex: '1 0 50%' }}>
							<ItemCheck id={cat.id} name={cat.name} item={cat} handleSelect={() =>  cat.id ? handleApplySelection(cat.id) : handleApplySelection(cat.name)} 
							isSelected={isSelected} searchText={searchText}/>
						</div>
					)
				}
			})}
			{!showAll && dataGroup?.length > 5 && <span onClick={() => setShowAll(!showAll)} className="font-sfpro-regular font-14px text-dark-40 m-3 cursor-pointer hover-dark-80">{t('filters-show-all')}...</span>}
		</div>
	);
};
