import React from "react";
import { SimpleTooltip } from "../Tooltips/Index";

export const Title = ({text}: {text: string}) => {
    const tooltip_id = text.replace(/[^A-Z0-9]/ig, "_");
    const tooltipId = tooltip_id;
    const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

    const handleMouseAction = (e: any, value: boolean) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setIsTooltipOpen(value);
        }
    }

    return (
        <>
            <h3
                className="font-14px text-dark-100 font-sfpro-regular letter-spacing-normal text-capitalize text-overflow-ellipsis overflow-hidden"
                id={tooltipId}
                onMouseEnter={(e) => handleMouseAction(e,true)}
                onMouseLeave={(e) => handleMouseAction(e,false)}
            >{text}</h3>
            <SimpleTooltip
                tooltipId={tooltipId}
                tooltipOpen={isTooltipOpen}
                text={text}
                styles={{ maxWidth: '250px', textAlign: 'start' }}
            />
        </>
    )
}