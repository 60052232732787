import routes, { PATHS } from "./AppPaths";

export enum EMenuState {
	all = "all",
	stage_comparator = "stage_comparator",
	integration = "integration",
	labeling = "labeling",
	library = "library",
	eco_score = "eco_score",
	cei = "cei",
	lca = "lca",
	plm = "plm",
	data_collection = "data_collection",
}

export interface ISidebarMenuItem {
	heading?: string;
	name?: string;
	icon?: string;
	translate?: string;
	path?: string;
	label?: {
		value: string | number;
		color: string;
	};
	level?: number;
	submenu?: Array<ISidebarMenuItem>;
	group?: number;
	state: EMenuState[] | EMenuState;
}

const Menu: Array<ISidebarMenuItem> = [
	{
		name: "Home",
		icon: "icon-icon-home",
		translate: "sidebar-nav-home",
		path: routes.home,
		level: 1,
		group: 1,
		state: EMenuState.all,
	},
	{
		name: "Traceability",
		icon: "icon-icon-calculator",
		translate: "sidebar-nav-traceability",
		path: routes.traceability,
		level: 1,
		group: 1,
		state: [EMenuState.data_collection, EMenuState.plm],
		submenu: [
			{
				name: "Data Collection",
				icon: "",
				translate: "sidebar-nav-traceability-data-collection",
				path: routes.dataCollection,
				level: 2,
				state: EMenuState.data_collection,
			},
			{
				name: "PLM",
				icon: "",
				translate: "sidebar-nav-traceability-plm",
				path: routes.traceabilityDashboard,
				level: 2,
				state: EMenuState.plm,
			},
		],
	},
	{
		name: "Metrics",
		icon: "icon-icon-metrics",
		translate: "sidebar-nav-metrics",
		path: routes.metrics,
		level: 1,
		group: 1,
		state: [EMenuState.lca, EMenuState.cei],
		submenu: [
			{
				name: "Life Cycle Assessment",
				icon: "",
				translate: "sidebar-nav-lca",
				path: routes.metricsLcaDashboard,
				level: 2,
				state: EMenuState.lca,
			},
			{
				name: "Circularity",
				icon: "",
				translate: "sidebar-nav-circularity",
				path: routes.ceiDashboard,
				level: 2,
				state: EMenuState.cei,
			},
		],
	},
	{
		name: "Evaluation",
		icon: "icon-icon-performance",
		translate: "sidebar-nav-index",
		path: routes.evaluationSiDashboard,
		level: 1,
		group: 1,
		state: EMenuState.eco_score
	},
	{
		name: "Digitization",
		icon: "icon-icon-digitization",
		translate: "sidebar-nav-digitization",
		path: routes.digitization,
		level: 1,
		group: 1,
		state: [EMenuState.labeling, EMenuState.integration],
		submenu: [
			{
				name: "Ecommerce",
				icon: "", // update icon
				translate: "sidebar-nav-ecommerce", // update translation
				path: routes.digitizatioEcommerceIntegration,
				level: 2,
				state: EMenuState.integration,
			},
			{
				name: "SmartLabels",
				icon: "", // update icon
				translate: "sidebar-nav-smartlabels", // update translation
				path: routes.digitizationSmartLabel,
				level: 2,
				state: EMenuState.labeling,
			},
			{
				name: "Configuration",
				icon: "", // update icon
				translate: "sidebar-nav-configuration", // update translation
				path: routes.digitizatioEcommerceCustomization,
				level: 2,
				state: EMenuState.integration,
			},
		],
	},
	{
		name: "Ecodesign",
		icon: "icon-icon-ecodesign",
		translate: "sidebar-nav-ecodesign",
		path: routes.ecodesign,
		level: 1,
		group: 1,
		state: [EMenuState.stage_comparator],
		submenu: [
			{
				name: "Calculator",
				icon: "",
				translate: "sidebar-nav-ecodesign-calculator",
				path: routes.calculator,
				level: 2,
				state: EMenuState.stage_comparator
			},
		],
	},
	{
		name: "Library",
		icon: "icon-icon-methodology",
		translate: "sidebar-nav-library",
		path: routes.library,
		level: 1,
		group: 1,
		state: EMenuState.library,
	},
];

export default Menu;
