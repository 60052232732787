import { SpinnerLoader } from "components/Common/Loader/Loader";
import React from "react";
import { useTranslation } from "react-i18next";
import EcodesignResultsHeader from "./EcodesignResultsHeader";
import EcodesignResultsIndicator from "./EcodesignResultsIndicator";
import { EcodesignInitSchema } from "./types/ecodesign";

interface IProps {
	ecoResults: any | undefined,
    request: any,
    units: string[],
    isLoading: boolean,
}

const EcodesignResults = (props:IProps) => {
	const { ecoResults, request, units, isLoading } = props;
    const { stage } = request;
	const { t } : any = useTranslation();
    const schema = EcodesignInitSchema.stage;
    let ecoResultsSorted:any = {};

    if (request.indicators.includes('water')) ecoResultsSorted = {...ecoResultsSorted, water: ecoResults?.water}
    if (request.indicators.includes('climateChange')) ecoResultsSorted = {...ecoResultsSorted, climateChange: ecoResults?.climateChange}
    if (request.indicators.includes('eutrophication')) ecoResultsSorted = {...ecoResultsSorted, eutrophication: ecoResults?.eutrophication}
    if (request.indicators.includes('fossilFuels')) ecoResultsSorted = {...ecoResultsSorted, fossilFuels: ecoResults?.fossilFuels}

    return (
        <div id="EcoResults" className="py-4">
            <EcodesignResultsHeader schema={schema} stage={stage} />
            {isLoading &&
				<div className="d-flex flex-column flex-wrap w-100 h-100 text-center justify-content-center align-content-center" style={{ marginTop: '30%'}}>
					<SpinnerLoader color={"#1C1C30"} width={"90px"} height={"90px"}/>
				</div>
			}
            {!isLoading && ecoResults && ecoResultsSorted && Object.entries(ecoResultsSorted).map(([key, value]) => (
                <EcodesignResultsIndicator key={key} indicator={key} ecoResults={value} schema={schema} stage={stage} units={units} />
            ))}
            {!isLoading && ecoResults && <div className="d-flex flex-column justify-content-center">
                <span className="text-dark-80 font-12px text-center">{t('powered-by-bcome')}</span>
            </div>}
        </div>
    )
}

export default EcodesignResults;