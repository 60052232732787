export interface IEcodesignRequest {
    stage: string, // RM, MP, WP, T, P
    indicators: string[], //water, climateChange, eutrophication, fossilFuels
    equivalences: {
      waterKeyName?: string, // 'bottles'
      climateChangeKeyName?: string, // 'ligthBulbs'
      eutroKeyName?: string, // 'laundry'
      fossilFuelsKeyName?: string, // 'gasoline'
    }
    itemA: {
      categoryId: string,
      processId: string, //processId or transportId
      originId?: string,
    },
    itemB: {
      categoryId: string,
      processId: string, //processId or transportId
      originId?: string,
    },
}

export interface IEcodesignSchema {
    [key: string]: IEcodesignGroup;
}
export interface IEcodesignGroup {
    [key: string]: IEcodesignItem;
}
export interface IEcodesignItem {
    id: string,
    label: string,
    options?: string[] | undefined,
    eq?: string | undefined,
    group: string,
}

export const EcodesignInitSchema = {
    stage: {
        RM: {id: 'RM', label: 'rawMaterials', options: ['category', 'process', 'origin'], group: 'Extraction'},
        MP: {id: 'MP', label: 'materials', options: ['category', 'process', 'origin'], group: 'Processing'},
        WP: {id: 'WP', label: 'wetProcess', options: ['category', 'process', 'origin'], group: 'Finishing'},
        T: {id: 'T', label: 'transport', options: ['process'], group: 'Transport'},
        P: {id: 'P', label: 'packaging', options: ['process', 'origin'], group: 'Packaging'},
    },
    indicator: {
        water: {id: 'water', label: 'waterUse', eq: ''},
        climateChange: {id: 'climateChange', label: 'climateChange', eq: ''},
        eutrophication: {id: 'eutrophication', label: 'eutrophication', eq: ''},
        fossilFuels: {id: 'fossilFuels', label: 'fossilFuels', eq: ''},
    },
    unit: {
        impact: {id: 'impact', label: 'impact'},
        equivalence: {id: 'equivalence', label: 'equivalence'},
        relative: {id: 'relative', label: 'relative'},
    }
}

export const initialReq = {
    stage: '', // RM, MP, WP, T, P
    indicators: [], //water, climateChange, eutrophication, fossilFuels
    equivalences: {
        waterKeyName: 'bottles',
        climateChangeKeyName: 'ligthBulbs',
        eutroKeyName: 'washingMachine',
        fossilFuelsKeyName: 'carTravelling',
    },
    itemA: {
      categoryId: '',
      processId: '', //processId or transportId
      originId: '',
    },
    itemB: {
      categoryId: '',
      processId: '', //processId or transportId
      originId: '',
    },
}