import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import Input from 'components/Elements/Input/Input';

const ArticleFormBasic = (props: any) => {
	const { data, setData, pushValidations, formName = 'defaultFormName' } = props;
	const { t }: any = useTranslation();
    const inputFile = useRef(null);
    const [imgPath, setImgPath]: any = useState('');

	const validationsObj: IValidationsObject = {
		reference: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		},
		name: {
			feedbackMessage: 'common-notEmpty',
			validations: ['required'],
			valid: true
		}
	};

	const {
		formFields,
		onInputChangeExt,
		validations,
		validateForm,
		controlledByParent
	} = useFormFields(
		{
			reference: data?.reference || '',
			name: data?.name || ''
		},
		validationsObj
	);

	useEffect(() => {
		if (pushValidations) {
			pushValidations(validationsObj);
		}
	}, []);

	useEffect(() => {
		if (data) {
			controlledByParent(data);
		}
	}, [data]);

	const onInput = async (event: any, key: string) => {
		onInputChangeExt(event, key);
		const value = event.target.value;
		if (data && setData) {
			const d = { ...data };
			d[key] = value;
			setData(d);
		}
	};

	const onClickBtnImg = () => {
        if (inputFile.current) {
            const input: any = inputFile.current;
            input.click();
        }
    };

	const onInputImgChange = (e: any) => {
        const img = e.target.files[0]; 
        if (img) {
            const reader = new FileReader()
            reader.readAsDataURL(img)
            reader.onload = () => {
                setImgPath(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }
	};
    
	return (
		<div className="row p-3" style={{ backgroundColor: '#F0F0F2' }}>
			<div className="col-12 col-md-5">
				<Input
					type="text"
					placeholder={t('reference')}
					invalid={!validations.reference.valid}
					value={formFields.reference}
					className=""
					onChange={(e: any) => {
						onInput(e, 'reference');
					}}
					labelText={t('reference')}
					feedbackMessage={t(validations.reference.feedbackMessage)}
					labelClassName=""
					id="article-form-reference"
					autoFocus={true}
				/>
			</div>
			<div className="col-12 col-md-5">
				<Input
					type="text"
					placeholder={t('name')}
					invalid={!validations.name.valid}
					value={formFields.name}
					className=""
					onChange={(e: any) => {
						onInput(e, 'name');
					}}
					labelText={t('name')}
					feedbackMessage={t(validations.name.feedbackMessage)}
					labelClassName=""
					id="article-form-name"
				/>
			</div>
			<div className="col-12 col-md-2 d-flex justify-content-end">
				<div
					className="rounded cursor-pointer d-flex justify-content-center align-items-center"
					style={{ width: '80px', height: '100%', backgroundColor: 'white' }}
                    onClick={onClickBtnImg}
                    >
					<input type="file" hidden onChange={(e) => {onInputImgChange(e) }} ref={inputFile} accept="image/x-png,image/gif,image/jpeg"/>
                    <span
						className={imgPath == '' ? `icon-icon-camera` : ''}
						style={{ fontSize: '22px', color: '#1C1C30', backgroundImage: imgPath != '' ? `url(${imgPath})` : '', backgroundRepeat: 'no-reapeat', backgroundSize: 'contain', width: imgPath != '' ? '100%' : '', height: imgPath != '' ? '100%' : ''}}></span>
				</div>
			</div>
		</div>
	);
};
export default ArticleFormBasic;
