import { EIndicators, EStages, EDataCollection } from "../types/TableEnums";
import { ITableColumns } from "../types/TableTypes";
import columnsSchema from "./columns.schema";

const dataCollectionSchema: ITableColumns = {
    projectDescription: {
        indicator: EIndicators.projectDescription,
        stage: EStages.projectDescription,
        label: 'projectDescription',
        columns: columnsSchema.filter(x => x.indicator === EDataCollection.projectDescription)
    },
    dataRecords: {
        indicator: EIndicators.dataRecords,
        stage: EStages.dataRecords,
        label: 'dataRecords',
        columns: columnsSchema.filter(x => x.indicator === EDataCollection.dataRecords)
    }
}

export default dataCollectionSchema