import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomSimpleTooltip, SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { TooltipWrapper } from 'UI/atoms/Tooltips/tooltip-wrapper';

interface IProps {
	schema: any;
	stage: string;
}

const EcodesignResultsHeader = (props: IProps) => {
	const { schema, stage } = props;
	const { t }: any = useTranslation();
	const s = schema[stage];

	return (
		<>
			<h3 className="font-sfpro-medium font-12px text-dark-60 text-uppercase">
				{t(`ecodesign-results-title-${s.label}`)}
			</h3>
			<div className='mb-4'>
				<h5 className="font-sfpro-regular font-14px text-dark-40 d-inline" dangerouslySetInnerHTML={{ __html: t(`ecodesign-results-description-${s.label}`) }}>
				</h5>
				<TooltipWrapper tooltipText={t('ecodesign-results-description')} styles={{
					maxWidth: '300px',
					textAlign: 'start'
				}}>
					<span className="icon-icon-info font-14px text-dark-60 hover-dark-100 pl-1"></span>
				</TooltipWrapper>
			</div>
		</>
	);
};
export default EcodesignResultsHeader;

interface IPropsIndicatorsHeader {
	indicator: any;
	stage: string;
}

export const EcodesignResultsIndicatorHeader = (
	props: IPropsIndicatorsHeader
) => {
	const { indicator, stage } = props;
	const { t }: any = useTranslation();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const tooltipId = `${indicator}-headerCalculator-Tooltip`;

	return (
		<>
			<h5 className="font-sfpro-regular font-14px text-dark-60">
				{t(`ecodesign-results-methodology-${indicator}`)}
			</h5>
			<div className="d-flex flex-row align-items-center w-100 position-relative">
				<h3 className="font-sfpro-medium font-14px text-dark-100 mr-1 my-0">
					{t(`ecodesign-results-title-${indicator}`)}
				</h3>
				<div
					id={tooltipId}
					className="icon-icon-info font-12px cursor-pointer position-relative"
					onMouseEnter={() => setIsTooltipOpen(true)}
					onMouseLeave={() => setIsTooltipOpen(false)}
				>
					<SimpleTooltip
						tooltipId={tooltipId}
						tooltipOpen={isTooltipOpen}
						text={t(`ecodesign-results-tooltip-${indicator}`)}
						styles={{ maxWidth: '300px', textAlign: 'start' }}
					/>
				</div>
			</div>
		</>
	);
};
