import React from 'react';

interface Props {
	label: string | undefined | null | 0;
	nullReplacement: string; //default '--'
	styles?: string | undefined;
}

const defaultProps: Partial<Props> = {
	nullReplacement: '--',
};

const Qualification = (props: Props) => {
	const { label, styles, nullReplacement } = props;

	const iconByLabelCategory: any = {
		A: 'icon-icon-index-a',
		B: 'icon-icon-index-b',
		C: 'icon-icon-index-c',
		D: 'icon-icon-index-d',
		E: 'icon-icon-index-e',
	};

	const icon = label && iconByLabelCategory[label];

	let textValue;
	if (label === null || label === undefined) {
		textValue = nullReplacement;
	} else {
		textValue = label;
	}

	return (
		<span
			className={`${
				icon
					? `${icon} font-20px`
					: 'font-sfpro-regular font-14px text-dark-100 text-overflow-ellipsis overflow-hidden'
			} ${styles ? styles : ''}`}
		>
			{!icon && textValue}
		</span>
	);
};

Qualification.defaultProps = defaultProps;

export default Qualification;
