import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormFields, { IValidationsObject } from 'hooks/formFields.hook';
import Input from 'components/Elements/Input/Input';
import InputSelect from 'UI/atoms/InputSelect/InputSelect';

const ArticleFormDescription = (props: any) => {
	const {
		data,
		setData,
		pushDataValidation,
		dataValidations,
		formName = 'article-form-description'
	} = props;
	const { t }: any = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const validationsObj: IValidationsObject = {
		unitsProduced: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		},
		category: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		},
		supplier: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		},
		totalWeight: {
			feedbackMessage: 'common-notEmpty',
			validations: [],
			valid: true
		}
	};

	const { formFields, onInputChangeExt, validations, onChange, controlledByParent } = useFormFields(
		{
			unitsProduced: data?.unitsProduced || '',
			category: data?.category || '',
			supplier: data?.supplier || '',
			totalWeight: data?.totalWeight || ''
		},
		validationsObj
	);

	useEffect(() => {
		if (data) {
			controlledByParent(data);
		}
	}, [data]);

	const updateData = (key: string, value: any) => {
		if (data && setData) {
			const d = { ...data };
			d[key] = value;
			setData(d);
		}
	}

	const onInput = async (event: any, key: string) => {
		onInputChangeExt(event, key);
		const value = event.target.value;
		updateData(key, value);
	};

	const selectChange = (key: string, value: any) => {
		onChange(key, value);
		updateData(key, value);
	}

	return (
		<>
			<h4 className="text-uppercase font-sfpro-medium" style={{ color: '#E0A75E', letterSpacing: '0.5px', fontSize: '14px' }}>
				{t('article-description')}
			</h4>
			<div className="row pl-3 pr-3 pb-3">
				<div className="col-12 col-md-6">
					<Input
						type="text"
						placeholder={t('unitsProduced')}
						invalid={!validations.unitsProduced.valid}
						value={formFields.unitsProduced}
						className=""
						onChange={(e: any) => {
							onInput(e, 'unitsProduced');
						}}
						labelText={t('unitsProduced')}
						feedbackMessage={t(validations.unitsProduced.feedbackMessage)}
						labelClassName=""
					/>
				</div>
				<div className="col-12 col-md-6">
					<InputSelect
						placeholder={t('supplier')}
						onChange={(value: any) => {
							selectChange('supplier', value);
						}}
						labelText={t('supplier')}
						defaultValue={{label: data?.supplier}}
						values={[
							{ value: 'supplier-1', label: 'supplier 1' },
							{ value: 'supplier-2', label: 'supplier 2' }
						]}></InputSelect>
				</div>
				<div className="col-12 col-md-6 mt-md-2">
					<InputSelect
						placeholder={t('category')}
						onChange={(value: any) => {
							selectChange('category', value);
						}}
						labelText={t('category')}
						defaultValue={{label: data?.category}}
						values={[
							{ value: 'category-1', label: 'category 1' },
							{ value: 'category-2', label: 'category 2' }
						]} />
				</div>
				<div className="col-12 col-md-6 mt-md-2">
					<Input
						type="text"
						placeholder={t('totalWeight')}
						invalid={!validations.totalWeight.valid}
						value={formFields.totalWeight}
						className=""
						onChange={(e: any) => {
							onInput(e, 'totalWeight');
						}}
						labelText={t('totalWeight')}
						feedbackMessage={t(validations.totalWeight.feedbackMessage)}
						labelClassName=""
					/>
				</div>
			</div>
		</>
	);
};
export default ArticleFormDescription;
