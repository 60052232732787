import React, { FunctionComponent, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { SpinnerLoader } from 'components/Common/Loader/Loader';

import { useTranslation } from 'react-i18next';

import './Modal.scss';
import { LocalLoader } from 'components/Common/Loader/Loader';
import ModalProvider from 'providers/modalProvider';

interface Props {
	[key: string]: any;
}

const CustomModal: FunctionComponent<Props> = (props: Props) => {
	const { t }: any = useTranslation();
	const {
		title,
		modal,
		setModal,
		children,
		handleSubmit,
		isLoading,
		createText,
		className,
		scrollable,
		noCancel,
		onClosed,
		goBackIcon,
		handleGoBackIcon,
		size = 'xl',
		loadingModal = false,
		showFooter = true,
		style = {},
		centered = true,
		modalName,
		FooterComponent,
		HeaderComponent,
		disableButton = true,
		isLongButton = false
	} = props;
	const modalContext = useContext(ModalProvider);

	const toggle = () => {
		setModal(!(modal == modalName));
	};
	const handleCloseIcon = () => {
		if (!isLoading) {
			toggle();
		}
	};

	let header = (
		<ModalHeader toggle={toggle} className="custom-modal-header">
			{title}
			<div className="close" onClick={handleCloseIcon} title={t('common-close')} />
		</ModalHeader>
	);
	if (HeaderComponent) {
		header = <HeaderComponent {...props} handleClose={handleCloseIcon} />
	}

	const widthButton = isLongButton ? '100%' : '94px'

	let footer = (
		<ModalFooter className={`d-flex ${goBackIcon ? 'justify-content-between' : 'justify-content-end'}`}>
			{goBackIcon && (
				<div className="d-flex flex-row align-items-center cursor-pointer">
					<div className="icon-icon-chevron-down transform-rotate-90" onClick={handleGoBackIcon} title={t('common-go-back')} style={{ fontSize: '30px' }} />
					<div className="font-14px" onClick={handleGoBackIcon} title={t('common-go-back')}>{t('common-go-back')}</div>
				</div>
			)}
			{!noCancel && (
				<Button
					color="tertiary"
					className="d-flex justify-content-center font-sfpro-medium mr-2"
					onClick={handleCloseIcon}
					disabled={isLoading}>
					{t('common-cancel')}
				</Button>
			)}
			<Button
				color="primary"
				className="d-flex justify-content-center"
				onClick={handleSubmit}
				disabled={isLoading || loadingModal || disableButton}
				style={{ width: widthButton }}
			>
				{createText || t('common-create')}
				{isLoading && <SpinnerLoader />}
			</Button>{' '}
		</ModalFooter>
	);
	if (FooterComponent) {
		footer = <FooterComponent {...props} handleClose={handleCloseIcon} />
	}

	return (
		<div>
			<Modal
				isOpen={modal == modalName}
				toggle={toggle}
				backdrop={'static'}
				modalClassName='backdrop-color'
				className={className}
				scrollable={scrollable}
				onClosed={onClosed}
				size={size}
				centered={centered}
				keyboard={true}
			>
				{header}
				<ModalBody id="modal-infinite-scroll-target" style={style}>
					{loadingModal && (
						<div className="feedback-layer modal-feedback-layer">
							<LocalLoader />
						</div>
					)}
					{children}
				</ModalBody>
				{showFooter && footer}
			</Modal>
		</div>
	);
};

export default CustomModal;
