import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { menuItem, SidebarItemStructureProps } from './TSidebar';
import { useProjects } from 'contexts/projectsContext';
import { CustomIcon } from 'UI/atoms/CustomIcon/Index';
import { useDisableCEI } from 'UI/Pages/CEI/hooks/useDisableCEI.hook';

const routeActive = (path: string) => {
	if (path === '/') return false

	return location.pathname.includes(path) || (path.includes('/traceability/plm') && location.pathname.includes('/traceability/plm'))
}

export const SidebarItemIconName = ({ item, activeHover }: { item: menuItem, activeHover?: boolean }) => {
	const { t } = useTranslation()
	const isSelected = routeActive(item.path);

	return (
		<div className='d-flex items-align-center'>
			<div className="ml-2 d-flex items-align-center" style={{ width: "28px" }}>
				{
					item.icon.includes("ecodesign")
					? <CustomIcon size={20} name={item.icon} isOpen={isSelected} activeHover={activeHover} />
					: <span className={`${item.icon} font-20px ${isSelected ? "text-dark-100" : ""}`}></span>
				}
			</div>
			<span className={`${isSelected ? "text-dark-100" : ""}`}>{t(item.translate)}</span>
		</div>
	)
};

export const LabelType = ({ state }: { state: string }) => {
	const { t } = useTranslation();

	let bgColor;
	if (state === "new") bgColor = "#0034EE";
	if (state === "soon") bgColor = "#CCAA5B";
	if (state === "upgrade") bgColor = "#75747F";
	if (state === "beta") bgColor = "#70767D";

	const style = {
		backgroundColor: bgColor,
		height: "18px",
		color: "#FFFFFF",
		marginRight: state === "new" ? '8px' : '',
	}

	return (
		<div className='rounded-10 font-10px d-flex px-2 ml-2' style={style}>
			<span className='m-auto'>{t(`common-${state}`)}</span>
		</div>
	)
};

export const Arrow = ({ isShow, level, isSelected }: { isShow: boolean, level: number, isSelected?: boolean }) => {

	const rotation = level === 1 ? "rotate(0deg)" : "rotate(-90deg)"

	return (
		<span
			className={`icon-icon-chevron-down font-30px mx-2 ${((isShow && isSelected) || isSelected) ? "text-dark-100" : ""}`}
			style={{
				transition: "all 0.5s ease",
				transformOrigin: "50% 50%",
				transform: isShow ? "rotate(-180deg)" : rotation
			}}
		/>
	)
};

export const SidebarItemLi = (props: { patern: any, children: JSX.Element, isSelected: boolean, state: string, setActiveHover: any }) => {
	const { patern, children, isSelected, state, setActiveHover } = props

	const style: any = {
		"active": "font-sfpro-regular text-dark-60 cursor-pointer bg-white-hover-48 text-dark-100-hover",
		"new": "font-sfpro-regular text-dark-60 cursor-pointer bg-white-hover-48 text-dark-100-hover",
		"soon": "font-sfpro-regular text-blue-metal-30",
		"upgrade": "font-sfpro-regular text-blue-metal-30",
		"isSelected": `font-sfpro-medium text-dark-100 ${patern ? "" : "bg-gray-2"} cursor-pointer`,
	}

	return (
		<li className={`sidebar-item
			${isSelected ? style.isSelected : style[state]}`}
			onMouseEnter={() => setActiveHover(true)}
			onMouseLeave={() => setActiveHover(false)}
		>
			{children}
		</li>

	)
};

export const LinkSidebarItem = (props: any) => {
	const { item } = props;
	const { openModalSaveChanges, setOpenModalSaveChanges } = useProjects();

	const stopRedirection = (event:any) => {
		if( openModalSaveChanges.customView ){
			event.preventDefault();
			setOpenModalSaveChanges({
				...openModalSaveChanges,
				open: true
			})
		}
	}

	return (
		<Link to={item.path} title={item.name} className="text-decoration-none w-100" onClick={stopRedirection}>
			<div className={`d-flex justify-content-${item.name === "Circularity" ? 'start': 'between'} w-100 align-items-center cursor-pointer`}>
				<SidebarItemIconName item={item} />
				{
					item.name === "Circularity" && (
						<LabelType state='beta' />
					)
				}
			</div>
		</Link>
	)
};

export const NoLinkSidebarItem = ({ item, handleClick, isShow, activeHover }: SidebarItemStructureProps) => {
	const isSelected = routeActive(item.path);

	return (
		<div className={`d-flex justify-content-between w-100 align-items-center`}
			onClick={handleClick ? () => handleClick(item.submenu) : () => { return }}>
			<SidebarItemIconName item={item} activeHover={activeHover} />
			<Arrow isShow={isShow ? isShow : false} level={item.level} isSelected={isSelected} />
		</div>
	)
};

export const SidebarItemStructure = ({ item, handleClick, isShow }: SidebarItemStructureProps) => {
	const [activeHover, setActiveHover] = useState(false);
	const path = item.path.split(":projectId")[0];

	const isSelected = location.pathname === path ? true : routeActive(path);

	return (
		// state(item, plan) for define state of menu link
		<SidebarItemLi patern={item.submenu} isSelected={isSelected} state={"active"} setActiveHover={setActiveHover}>
			{item.submenu
				? <NoLinkSidebarItem item={item} handleClick={handleClick} isShow={isShow} activeHover={activeHover} />
				: <LinkSidebarItem item={item} />
			}
		</SidebarItemLi>
	)
};

export const SidebarItem = ({ item, keyValue }: SidebarItemStructureProps) => {
	const [level2IsShow, setLevel2IsShow] = useState(false);
	const [level3IsShow, setLevel3IsShow] = useState(false);
	const { disabledCeiSidebar } = useDisableCEI();

	const handleClickLevel2 = (submenu?: any) => submenu ? setLevel2IsShow(!level2IsShow) : <></>
	const handleClickLevel3 = (submenu?: any) => submenu ? setLevel3IsShow(!level3IsShow) : <></>

	if (item.path === "/metrics" && disabledCeiSidebar()) {
		if (item.submenu.length > 1) item.submenu.pop()
	}

	return (
		<Fragment key={keyValue}>
			{
				<>
					<SidebarItemStructure item={item} handleClick={handleClickLevel2} isShow={level2IsShow} />
					{level2IsShow && item.submenu.map((subitem1: menuItem) => (
							<>
								<SidebarItemStructure item={subitem1} handleClick={handleClickLevel3} isShow={level3IsShow} />
								{level3IsShow && subitem1.submenu && subitem1.submenu.map((subitem2: menuItem) => (
									<SidebarItemStructure item={subitem2} />)
								)}
							</>
					))}
				</>
			}
		</Fragment>
	)
};

