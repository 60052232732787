import React, { useState } from 'react';
import { ApiResponse } from './ApiResponse';
import { ApiDetails } from './ApiDetails';
import { useTranslation } from 'react-i18next';

type Tabs = 'details' | 'response';

export const ApiIntegration = ({
	handleCloseModal,
}: {
	handleCloseModal: (e: any, close: boolean) => void;
}) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState<Tabs>('details');

	const handleTabChange = (tab: Tabs) => {
		setActiveTab(tab);
	};

	return (
		<div className="w-100 h-100 d-flex flex-column align-items-start justify-content-start position-relative p-4">
			<span
				onClick={(e: any) => handleCloseModal(e, true)}
				className="modal-lateral-close position-absolute"
				style={{
					width: '12px',
					height: '12px',
					right: '1.5rem',
					top: '1.5rem',
				}}
			></span>
			<div className="d-flex flex-row align-items-center api-integration-modal__header my-5 w-100">
				<h5
					onClick={() => handleTabChange('details')}
					className={
						activeTab === 'details'
							? 'text-dark-100 active'
							: 'text-dark-60'
					}
				>
					{t('integration-api-sheet-detail')}
				</h5>
				<h5
					onClick={() => handleTabChange('response')}
					className={
						activeTab === 'response'
							? 'text-dark-100 active'
							: 'text-dark-60'
					}
				>
					{t('integration-api-sheet-description')}
				</h5>
			</div>
			{activeTab === 'details' ? <ApiDetails /> : <ApiResponse />}

			<p
				className="mt-5 px-2"
				dangerouslySetInnerHTML={{
					__html:
						activeTab === 'details'
							? t('integration-api-sheet-detail-footer')
							: t('integration-api-sheet-description-footer'),
				}}
			></p>
		</div>
	);
};
