import React from 'react';
import { useTranslation } from 'react-i18next';
import ChartContainer from 'UI/Template/ChartContainer';
import { formatNumberNoDecimals } from 'utils/utils';

const ArticleSummary = (props: any) => {
	const { data } = props;
	const { t } = useTranslation();

	const articleSummarySectionConfig: any = {
		numKm: {
			copy: t('dash-article-summary-km'),
			data: data?.Km,
		},
		numUnitsProduced: {
			copy: t('dash-article-summary-amount'),
			data: data?.UnitsProduced,
		},
		numProcesses: {
			copy: t('dash-article-summary-processes'),
			data: data?.Process,
		},
		numSuppliers: {
			copy: t('dash-article-summary-suppliers'),
			data: data?.Suppliers,
		},
	};

	const NoDataToDisplay = () => (
		<div
			className="d-flex align-items-center justify-content-center text-dark-60 font-12px"
			style={{ height: ' 250px' }}
		>
			There's no data to display
		</div>
	);

	const articleSummarySections = [];

	const formatData = (section: string) =>
		formatNumberNoDecimals(
			parseFloat(articleSummarySectionConfig[section]['data'])
		);

	for (const section in articleSummarySectionConfig) {
		const title: string = articleSummarySectionConfig[section]['copy'];
		const data: any =
			articleSummarySectionConfig[section]['data'] === 0
			? articleSummarySectionConfig[section]['data']
			: articleSummarySectionConfig[section]['data']
			? formatData(section)
			: '--';

		articleSummarySections.push(
			<div
				className="col p-0 m-2"
				style={{ minWidth: '200px', height: '188px' }}
			>
				<ChartContainer title={title}>
					{data !== '--' ? (
						<div
							className="d-flex m-auto font-sfpro-medium text-black-100"
							style={{ fontSize: '45px' }}
						>
							{data}
						</div>
					) : (
						<NoDataToDisplay />
					)}
				</ChartContainer>
			</div>
		);
	}

	return <>{articleSummarySections}</>;
};

export default ArticleSummary;
