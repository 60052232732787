import React from "react";

export const Bell = ({isTooltipOpen}: {isTooltipOpen: boolean}) => {
    const color = isTooltipOpen ? `#1C1C30` : `#706F7A`;
    return (
        <svg
            width="16"
            height="20"
            viewBox="0 0 16 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.774194 16.613C0.550538 16.613 0.365763 16.539 0.219871 16.3909C0.0732901 16.2429 0 16.0701 0 15.8727C0 15.6589 0.0732901 15.4819 0.219871 15.3417C0.365763 15.2022 0.550538 15.1325 0.774194 15.1325H2.06452V7.97662C2.06452 6.66061 2.49462 5.48014 3.35484 4.43522C4.21505 3.39096 5.33333 2.72078 6.70968 2.42468V1.73377C6.70968 1.38831 6.83458 1.09616 7.08439 0.857299C7.33351 0.6191 7.63871 0.5 8 0.5C8.36129 0.5 8.6665 0.6191 8.91561 0.857299C9.16542 1.09616 9.29032 1.38831 9.29032 1.73377V2.42468C10.6667 2.72078 11.7849 3.39096 12.6452 4.43522C13.5054 5.48014 13.9355 6.66061 13.9355 7.97662V15.1325H15.2258C15.4495 15.1325 15.6346 15.2022 15.7812 15.3417C15.9271 15.4819 16 15.6589 16 15.8727C16 16.0701 15.9271 16.2429 15.7812 16.3909C15.6346 16.539 15.4495 16.613 15.2258 16.613H0.774194ZM8 19.5C7.48387 19.5 7.04516 19.3273 6.68387 18.9818C6.32258 18.6364 6.14193 18.2169 6.14193 17.7234H9.85806C9.85806 18.2169 9.67742 18.6364 9.31613 18.9818C8.95484 19.3273 8.51613 19.5 8 19.5ZM3.6129 15.1325H12.3871V7.97662C12.3871 6.80866 11.957 5.81737 11.0968 5.00275C10.2366 4.1888 9.2043 3.78182 8 3.78182C6.7957 3.78182 5.76344 4.1888 4.90323 5.00275C4.04301 5.81737 3.6129 6.80866 3.6129 7.97662V15.1325Z"
                fill={color}
            />
            <path
                d="M16 6C16 7.65685 14.6569 9 13 9C11.3431 9 10 7.65685 10 6C10 4.34315 11.3431 3 13 3C14.6569 3 16 4.34315 16 6Z"
                fill={`#CCAA5B`}
            />
        </svg>
    );
}
