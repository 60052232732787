import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { calculateYAxis, formatNumberNoDecimals } from 'utils/utils';

const ComparationByIndustryChart = ({
	data,
	equivalences
}: any) => {
	const { t } = useTranslation();
    const [customData, setCustomData] = React.useState<any[]>([]);

    React.useEffect(() => {
        if (data) {
            const savings_ClimateChange_relative = data['Project_GW-R_A'];
            const savings_Eutrophication_relative = data['Project_Eutr-R_A'];
            const savings_FossilFuels_relative = data['Project_AD-R_A'];
            const savings_Water_relative = data['Project_WS-R_A']

            setCustomData([
                {
                  name: 'water-scarcity',
                  data: savings_Water_relative,
                  equivalence: 100,
                },
                {
                  name: 'global-warming',
                  data: savings_ClimateChange_relative,
                  equivalence: 100,
                },
                {
                  name: 'eutrophication',
                  data: savings_Eutrophication_relative,
                  equivalence: 100,
                },
                {
                  name: 'abiotic-depletion',
                  data: savings_FossilFuels_relative,
                  equivalence: 100,
                }
            ]);
        }
    }, [data])


	const NoDataToDisplay = () => (
		<div className="d-flex align-items-center text-dark-60 font-12px">
			There's no data to display
		</div>
	);

	const ItemFooter = ({color, text}: {color: string, text: string}) => (
		<div className='d-flex align-items-center justify mx-2 mr-3'>
			<div style={{
				width: 12,
				height: 12,
				borderRadius: "50%",
				background: color,
				marginRight: 4
			}}/>
			<span className='text-dark-60 font-12px'>{text}</span>
		</div>
	)

	const labelShortCopy = (value:string) => {
		return t(`dashboard-lca-graph-${value}`);
	}

	const customTooltip = (props: any) => {
		const { label, payload } = props;

		return (
			<div className="tooltip-dashboard">
				<div className="my-1 text-dark-100">{t(`dashboard-lca-graph-${label}`)}</div>
				{payload?.map((entry: any, index: number) => (
					<div key={entry.name} style={{ color: '#696980' }}>
						<span key={`item-${index}`} className="mr-1">
							{t(`dashboard-lca-graph-tooltip-${entry.name}`)}:
						</span>
						<span>
							{formatNumberNoDecimals(entry.value)}%
						</span>
					</div>
				))}
			</div>
		);
	};

	return (
		<>
			{customData.length > 0 ? (
				<div className="d-flex flex-row">
					<ResponsiveContainer height={250}>
						<BarChart width={500} height={250} data={customData}>
							<CartesianGrid
								vertical={false}
								strokeDasharray="0"
								stroke="#D6D6D7"
							/>
							<XAxis
								type="category"
								dataKey="name"
								angle={0}
								tickFormatter={labelShortCopy}
								height={40}
								tickLine={{ stroke: 'transparent' }}
								fontSize="12"
								axisLine={false}
								tick={{ fill: '#C6C7C8' }}
							/>
							<YAxis
								type="number"
								fontSize="12"
								allowDataOverflow={false}
								axisLine={false}
								tickLine={false}
								tick={{ fill: '#C6C7C8' }}
								unit="%"
								domain={[0, calculateYAxis(customData, 'data')]}
							/>
							<Tooltip content={customTooltip} />
							<Bar
								dataKey="data"
								fill={"#001D6C"}
								barSize={40}
								radius={[2, 2, 2, 2]}
							/>
							<Bar
								dataKey="equivalence"
								fill={"#C9DBF8"}
								barSize={40}
								radius={[2, 2, 2, 2]}
							/>
						</BarChart>
					</ResponsiveContainer>
				</div>
			) : (
				<NoDataToDisplay />
			)}
			<div
				className='w-100 d-flex justify-content-end align-items-center'
				style={{ borderTop: "1px solid #C6C7C8", height: 37 }}
			>
				<ItemFooter color="#001D6C" text={t('unitary-impact')} />
				<ItemFooter color="#C9DBF8" text={t('lca-dashbaord-industry-standard')} />
			</div>
		</>
	);
};

export default ComparationByIndustryChart;