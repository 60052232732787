import diagnosticsSchema from "../schemas/diagnostics.schema";
import indicatorSchema from "../schemas/indicators.schema";
import smartlabelSchema from "../schemas/smartlabels.schema";
import stagesSchema from "../schemas/stages.schema";
import sustainabilityindexSchema from "../schemas/sustainabilityindex.schema";
import traceabilityArticleSchema from "../schemas/traceability.schema";
import dataCollectionSchema from "../schemas/datacollection.schema";
import ceiSchema from "../schemas/cei.schema";
import { ITableColumns } from "./TableTypes";

export interface ISchemaGroups {
	[key: string]: ISchemaGroupsItems,
}

export interface ISchemaGroupsItems {
	[key: string]: ITableColumns,
}

export const schemaGroups:ISchemaGroups = {
    indicators: {
        schemaType: indicatorSchema,
    },
    stages: {
        schemaType: stagesSchema,
    },
    sustainabilityindex: {
        schemaType: sustainabilityindexSchema,
    },
    benchmark: {
        schemaType: diagnosticsSchema,
    },
    smartlabels: {
        schemaType: smartlabelSchema,
    },
    traceability: {
        schemaType: traceabilityArticleSchema
    },
    dataCollection: {
        schemaType: dataCollectionSchema
    },
    cei: {
        schemaType: ceiSchema,
    },
}
